import { ProductStructure } from 'types/ProductStructure';
import type { ProductPrototype } from './ProductPrototype';

/**
 * Сховище прототипів об'єктів
 */
export interface PrototypeRegistry {
  /**
   * Додати прототип у регістр
   * @param key ключ прототипу
   * @param prototype об'єкт прототипу
   */
  addProto(key: string, prototype: ProductPrototype): boolean;
  /**
   * Отримати перелік всіх ключів наявних прототипів
   * @returns Масив строк, які можна використовувати в якості аргументу `key` інших методів.
   */
  listKeys(): string[];
  /**
   * Створити продукт на основі певного прототипу
   * @param key ключ прототипу
   * @returns Продукт на основі бажаного прототипу або null якщо ключ не дійсний
   */
  create(key: string): ProductPrototype | null;
  /**
   * Перетворити простий об'єкт, що представляє виріб, на клас відповідний типу цього виробу.
   * Інакше кажучи виконати "гідрацію" структури даних до повноцінного класу.
   *
   * Визначення відповідності об'єктів класам відбувається за властивістю `structure.calcMethod`. Якщо вона не визначена або
   * у сховищі немає прототипу за таким ключем, то буде використаний прототип заданий ключем за умовчанням.
   * @param structure  простий об'єкт структури даних виробу
   * @param defaultProtoKey Ключ прототипу який буде використаний за умовчанням
   * (якщо прототипу відповідного значенню властивості `calcMethod` немає в наявності).
   * Ця властивість обов'язково має містити дійсний ключ наявного у сховищі прототипу, інакше метод не буде виконаний.
   * @throws Error('defaultProtoKey not valid') у випадку відсутності прототипу у сховищі за наданим ключем за умовчанням.
   * @throws Error('Can`t load prototype instance') у випадку не дійсності отриманого за ключем прототипу.
   * @returns Інстанс класу виробу за даними вхідного об'єкту.
   */
  hydrate(structure: ProductStructure, defaultProtoKey: string): ProductPrototype;
  /**
   * Перетворити набір простих об'єктів, що представляють вироби, на набір класів відповідних типам цих виробів.
   * Інакше кажучи виконати "гідрацію" структур даних до повноцінних класів.
   *
   * Визначення відповідності об'єктів класам відбувається за властивістю `structure.calcMethod`. Якщо вона не визначена або
   * у сховищі немає прототипу за таким ключем, то буде використаний прототип заданий ключем за умовчанням.
   * @param productStructuresList набір виробів у вигляді простих об'єктів відповідної структури
   * @param defaultProtoKey Ключ прототипу який буде використаний за умовчанням
   * (якщо прототипу відповідного значенню властивості `calcMethod` немає в наявності).
   * Ця властивість обов'язково має містити дійсний ключ наявного у сховищі прототипу, інакше метод не буде виконаний.
   * @throws Error('defaultProtoKey not valid') у випадку відсутності прототипу у сховищі за наданим ключем за умовчанням.
   * @throws Error('Can`t load prototype instance') у випадку не дійсності отриманого за ключем прототипу.
   * Це критична помилка яка свідчить про проблему самого сховища, тому спроб гідрації всіх подальших структур у наборі не відбудеться.
   * @returns Набір інстансів класів виробів за даними вхідного переліку об'єктів.
   */
  hydrateList(productStructuresList: ProductStructure[], defaultProtoKey: string): ProductPrototype[];
}

export interface InitialPrototypesHashTable {
  [key: string]: ProductPrototype;
}

/**
 * Сховище прототипів об'єктів виробів
 */
export default class ProductPrototypeRegistry implements PrototypeRegistry {
  /**
   * Сховище зареєстрованих прототипів
   */
  private _items: Map<string, ProductPrototype>;

  constructor(initialPrototypesHashTable?: InitialPrototypesHashTable) {
    this._items = new Map<string, ProductPrototype>();

    if (initialPrototypesHashTable) {
      Object.keys(initialPrototypesHashTable).forEach(key => this._items.set(key, initialPrototypesHashTable[key]));
    }
  }

  public addProto(key: string, prototype: ProductPrototype): boolean {
    if (key && prototype && typeof prototype === 'object') {
      this._items.set(key, prototype);
      return true;
    }
    return false;
  }

  listKeys(): string[] {
    return Array.from(this._items.keys());
  }

  public create(key: string): ProductPrototype | null {
    const prototype = this._items.get(key);
    return prototype ? prototype.clone() : null;
  }

  public hydrate(structure: ProductStructure, defaultProtoKey: string): ProductPrototype {
    if (!defaultProtoKey || !this._items.has(defaultProtoKey)) {
      throw new Error('defaultProtoKey not valid');
    }

    const key = structure.calcMethod && this._items.has(structure.calcMethod) ? structure.calcMethod : defaultProtoKey;
    const prototype = this._items.get(key);
    if (!prototype) {
      throw new Error('Can`t load prototype instance');
    }
    return prototype.hydrate(structure);
  }

  public hydrateList(productStructuresList: ProductStructure[], defaultProtoKey: string): ProductPrototype[] {
    if (!defaultProtoKey || !this._items.has(defaultProtoKey)) {
      throw new Error('defaultProtoKey not valid');
    }

    return productStructuresList.map<ProductPrototype>(structure => {
      const key =
        structure.calcMethod && this._items.has(structure.calcMethod) ? structure.calcMethod : defaultProtoKey;
      const prototype = this._items.get(key);
      if (!prototype) {
        throw new Error('Can`t load prototype instance');
      }
      return prototype.hydrate(structure);
    }, this);
  }
}
