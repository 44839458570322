import React, { useState } from 'react';
import type { FC, ReactNode } from 'react';
import { NavLink as RouterLink, To } from 'react-router-dom';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { Button, Collapse, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import type { Theme } from 'theme';

interface NavItemProps {
  children?: ReactNode;
  className?: string;
  title: string;
  depth: number;
  href?: string;
  icon?: any;
  info?: any;
  open?: boolean;
}

interface NavLinkProps {
  className?: string;
  activeClassName?: string;
  style?: React.CSSProperties;
  to?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular as number,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: 'auto',
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

// eslint-disable-next-line react/display-name
const NavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(({ activeClassName, to, ...props }, ref) => {
  return (
    <RouterLink
      ref={ref}
      to={to as To}
      {...props}
      className={({ isActive }) => [props.className, isActive ? activeClassName : null].filter(Boolean).join(' ')}
    />
  );
});

const NavItem: FC<NavItemProps> = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(Boolean(openProp));
  const { formatMessage } = useIntl();

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <ListItem className={clsx(classes.item, className)} disableGutters key={title} {...rest}>
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{formatMessage({ id: title, defaultMessage: title })}</span>
          {open ? (
            <ExpandLessIcon fontSize="small" color="inherit" component="svg" />
          ) : (
            <ExpandMoreIcon fontSize="small" color="inherit" component="svg" />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ListItem className={clsx(classes.itemLeaf, className)} disableGutters key={title} {...rest}>
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={NavLink}
        style={style}
        to={href}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        {/* <span className={classes.title}>{}</span> */}
        {formatMessage({ id: title, defaultMessage: title })}
        {Info && <Info />}
      </Button>
    </ListItem>
  );
};

export default NavItem;
