import React from 'react';
import type { FC } from 'react';
import { OptionalChildrenOnlyProps } from 'types/generic';
import { Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const AdminGuard: FC<OptionalChildrenOnlyProps> = ({ children }) => {
  const { user } = useAuth();

  if (!user?.roles.includes('admin')) {
    return <Navigate to="/404" />;
  }

  return <>{children}</>;
};

export default AdminGuard;
