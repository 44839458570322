import React, { createContext, useState, ReactNode, ReactElement } from 'react';
import { merge } from 'lodash';
import { THEMES } from 'theme';

export interface LocalAppSettings {
  theme?: THEMES;
}

export interface LocalSettingsContextValue {
  settings: LocalAppSettings;
  // eslint-disable-next-line @typescript-eslint/ban-types
  saveSettings: Function;
}

const defaultSettings: LocalAppSettings = {
  theme: THEMES.LIGHT,
};

export function restoreSettings(): LocalAppSettings | null {
  let settings: LocalAppSettings | null = null;

  try {
    const storedData = localStorage.getItem('localset');

    if (storedData) {
      settings = JSON.parse(storedData);
    }
  } catch (err) {
    // If stored data is not a stringified JSON this might fail,
    // that's why we catch the error
  }

  return settings;
}

export function storeSettings(settings: LocalAppSettings): void {
  localStorage.setItem('localset', JSON.stringify(settings));
}

const LocalSettingsContext = createContext<LocalSettingsContextValue>({
  settings: defaultSettings,
  saveSettings: () => {},
});

export interface LocalAppSettingsProps {
  settings?: LocalAppSettings | null;
  children?: ReactNode;
}

export function LocalSettingsProvider({
  settings,
  children,
}: LocalAppSettingsProps): ReactElement<LocalAppSettingsProps> {
  const [currentSettings, setCurrentSettings] = useState<LocalAppSettings>(settings || defaultSettings);

  const handleSaveSettings = (updatedSettings: Partial<LocalAppSettings> = {}) => {
    const mergedSettings = merge({}, currentSettings, updatedSettings);
    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  return (
    <LocalSettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
      }}
    >
      {children}
    </LocalSettingsContext.Provider>
  );
}

export const LocalSettingsConsumer = LocalSettingsContext.Consumer;

export default LocalSettingsContext;
