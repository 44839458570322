/* eslint class-methods-use-this: ["error", { "exceptMethods": ["hydrate"] }] */
import type { ServiceKey } from 'types/product-services';
import type { ProductComponentKey } from 'types/ProductMaterials';
import type { ProductDesignSettingKey } from 'types/ProductDesignData';
import type { ProductStructure } from 'types/ProductStructure';
import type { ProductPrototype } from './ProductPrototype';
import type { MountTarget } from 'types/mountTarget';
import Product from './Product';

/**
 * Клас римської штори з підкладкою, системою та опціональними складовими
 */
export default class RomanBlindsLinedProduct extends Product implements ProductPrototype {
  protected readonly _validDesignSettings: ProductDesignSettingKey[] = [
    'width',
    'height',
    'mountTarget',
    'sticksOverallCount',
    'controlSide',
    'controlChainLength',
    'isComplexSewing',
    'productionOptions',
  ];

  protected readonly _requiredDesignSettings: ProductDesignSettingKey[] = [
    'width',
    'height',
    'controlSide',
    'controlChainLength',
  ];

  protected readonly _validComponentKeysOf: {
    [componentType: string]: ProductComponentKey[];
  } = {
    textile: ['mainTextile', 'edgingTextile', 'liningTextile', 'doublerine'],
    webbing: ['webbingAdhesive', 'webbing4Stick', 'webbingDecorative'],
    furniture: ['sRings', 'magnets'],
    curtainRod: [
      'romanBlindSystem',
      'sticks',
      'fixation',
      'weightingAgent',
      'chain',
      'chainWeighter',
      'bracketFasten',
      'wallBracket',
    ],
    accessory: [],
    productFulfillment: [],
  };

  protected readonly _validMountTargets: MountTarget[] = ['casement', 'window.hole', 'wall', 'ceiling'];

  protected readonly _validServiceKeysOf: {
    [serviceType: string]: ServiceKey[];
  } = {
    sewing: ['sewingRomanBlindsComplex', 'sewingCustom'],
    hanging: ['hangingRomanBlinds', 'hangingComplex', 'hangingCustom'],
    mount: ['mountRomanBlindSystem', 'mountRomanBlindSystemComplex', 'mountCustom'],
    disassembling: ['disassemblingCurtainRod', 'disassemblingCustom'],
    custom: ['customServices'],
  };

  constructor(baseProduct?: RomanBlindsLinedProduct | Product | ProductStructure) {
    super(baseProduct);
    this.calcMethod = 'romanBlindsLined';
    // Обов'язкові та типові складові включені за умовчанням
    /* Основна тканина */
    if (typeof this.design.mainTextile === 'undefined') {
      this.design.mainTextile = {
        amount: -1,
        unit: 'м.п.',
        amountToBuy: undefined,
        unitToBuy: 'м.п.',
        totalPrice: undefined,
        isManualAmount: false,
        manualAmountAuthor: undefined,
        isManualPrice: false,
        manualPriceAuthor: undefined,
        required: true,
      };
    } else {
      // Імплементація даних з базового виробу вже відбулась в конструкторі батьківського класу
      // проте залишається необхідність переконатись в обов'язковості складової та повному наборі даних
      this.design.mainTextile.required = true;
      this.design.mainTextile.amount = this.design.mainTextile.amount ?? -1;
      this.design.mainTextile.unit = this.design.mainTextile.unit ?? 'м.п.';
      this.design.mainTextile.unitToBuy = this.design.mainTextile.unitToBuy ?? 'м.п.';
      this.design.mainTextile.isManualAmount = this.design.mainTextile.isManualAmount ?? false;
      this.design.mainTextile.isManualPrice = this.design.mainTextile.isManualPrice ?? false;
    }
    /* Тканина підкладки */
    if (typeof this.design.liningTextile === 'undefined') {
      this.design.liningTextile = {
        amount: -1,
        unit: 'м.п.',
        amountToBuy: undefined,
        unitToBuy: 'м.п.',
        totalPrice: undefined,
        isManualAmount: false,
        manualAmountAuthor: undefined,
        isManualPrice: false,
        manualPriceAuthor: undefined,
        required: true,
      };
    } else {
      // Імплементація даних з базового виробу вже відбулась в конструкторі батьківського класу
      // проте залишається необхідність переконатись в обов'язковості складової та повному наборі даних
      this.design.liningTextile.required = true;
      this.design.liningTextile.amount = this.design.liningTextile.amount ?? -1;
      this.design.liningTextile.unit = this.design.liningTextile.unit ?? 'м.п.';
      this.design.liningTextile.unitToBuy = this.design.liningTextile.unitToBuy ?? 'м.п.';
      this.design.liningTextile.isManualAmount = this.design.liningTextile.isManualAmount ?? false;
      this.design.liningTextile.isManualPrice = this.design.liningTextile.isManualPrice ?? false;
    }
    /* Тасьма для прутів */
    if (typeof this.design.webbing4Stick === 'undefined') {
      this.design.webbing4Stick = {
        amount: -1,
        unit: 'м.п.',
        amountToBuy: undefined,
        unitToBuy: 'м.п.',
        totalPrice: undefined,
        isManualAmount: false,
        manualAmountAuthor: undefined,
        isManualPrice: false,
        manualPriceAuthor: undefined,
        required: true,
      };
    } else {
      // Імплементація даних з базового виробу вже відбулась в конструкторі батьківського класу
      // проте залишається необхідність переконатись в обов'язковості складової та повному наборі даних
      this.design.webbing4Stick.required = true;
      this.design.webbing4Stick.amount = this.design.webbing4Stick.amount ?? -1;
      this.design.webbing4Stick.unit = this.design.webbing4Stick.unit ?? 'м.п.';
      this.design.webbing4Stick.unitToBuy = this.design.webbing4Stick.unitToBuy ?? 'м.п.';
      this.design.webbing4Stick.isManualAmount = this.design.webbing4Stick.isManualAmount ?? false;
      this.design.webbing4Stick.isManualPrice = this.design.webbing4Stick.isManualPrice ?? false;
    }
    /* Римська система */
    if (typeof this.design.romanBlindSystem === 'undefined') {
      this.design.romanBlindSystem = {
        amount: 1,
        unit: 'шт.',
        amountToBuy: 1,
        unitToBuy: 'шт.',
        totalPrice: undefined,
        isManualAmount: false,
        manualAmountAuthor: undefined,
        isManualPrice: false,
        manualPriceAuthor: undefined,
        required: true,
      };
    } else {
      // Імплементація даних з базового виробу вже відбулась в конструкторі батьківського класу
      // проте залишається необхідність переконатись в обов'язковості складової та повному наборі даних
      this.design.romanBlindSystem.required = true;
      this.design.romanBlindSystem.amount = this.design.romanBlindSystem.amount ?? 1;
      this.design.romanBlindSystem.amountToBuy = this.design.romanBlindSystem.amountToBuy ?? 1;
      this.design.romanBlindSystem.unit = this.design.romanBlindSystem.unit ?? 'шт.';
      this.design.romanBlindSystem.unitToBuy = this.design.romanBlindSystem.unitToBuy ?? 'шт.';
      this.design.romanBlindSystem.isManualAmount = this.design.romanBlindSystem.isManualAmount ?? false;
      this.design.romanBlindSystem.isManualPrice = this.design.romanBlindSystem.isManualPrice ?? false;
    }

    // Типові послуги увімкнені за умовчанням
    if (
      typeof this.serviceOptions.sewingRomanBlindsComplex === 'undefined' &&
      // Вмикати просте пошиття за умовчанням доцільно лише при відсутності інших
      typeof this.serviceOptions.sewingCustom === 'undefined'
    )
      this.serviceOptions.sewingRomanBlindsComplex = -1;
  }

  clone(): ProductPrototype {
    const clone = new RomanBlindsLinedProduct(this);
    // Ідентифікатор клону не має збігатись з його прототипом!
    clone.id = undefined;
    return clone;
  }

  hydrate(structure: ProductStructure): ProductPrototype {
    const hydrated = new RomanBlindsLinedProduct(structure);
    hydrated.calcMethod = 'romanBlindsLined';
    return hydrated;
  }

  requireLiningTextile(newState?: boolean): boolean {
    // Встановити нове значення
    if (typeof newState !== 'undefined') {
      if (typeof this.design.liningTextile !== 'undefined') {
        // Оновити індикатор обов'язковості складової
        this.design.liningTextile.required = !!newState;
      } else if (newState) {
        // Якщо призначається обов'язковість і параметри дизайну ще не містять кількісний показник
        // — встановити його стартове значення
        // - встановити обчислення кількості підкладки автоматичним за умовчанням
        this.design.liningTextile = {
          ...this._defaultTextileUsage,
          required: !!newState,
          isManualAmount: false,
        };
      }
    }

    return !!this.design.liningTextile?.required;
  }
}
