import { EntityRequestFilter } from 'types/EntityRequestFilter';
import type { ProjectImage } from 'types/ProjectImage';
import { SessionMarker, UserSessions } from 'types/SessionMarker';
import { User } from 'types/user';
import apiClient, { CountSchema } from './coreAPI';
import { ComplexError, errorParser } from './errorsParser';

export type UserSorting = 'lastName' | 'roleAndLastName';

export interface GetUsersFilter {
  include?: ['company'];
  where?: {
    companyId?: string;
    roles?: string[];
    skills?: string[];
  };
  orderBy?: UserSorting;
}

export const getUsers = async (filterSettings?: GetUsersFilter) => {
  try {
    let filterString = '';
    if (filterSettings?.include || filterSettings?.where || filterSettings?.orderBy) {
      const filter: EntityRequestFilter = {};
      if (filterSettings?.include && filterSettings.include[0] === 'company')
        filter.include = [
          {
            relation: 'company',
            scope: { fields: ['name'] },
          },
        ];
      if (filterSettings?.where) {
        const { companyId, roles, skills } = filterSettings.where;
        filter.where = {
          companyId,
          roles: Array.isArray(roles) && roles.length > 0 ? { contains: roles } : undefined,
          skills: Array.isArray(skills) && skills.length > 0 ? { contains: skills } : undefined,
        };
      }
      if (filterSettings?.orderBy === 'lastName') filter.order = 'lastName ASC';
      if (filterSettings?.orderBy === 'roleAndLastName') filter.order = ['roles ASC', 'lastName ASC'];
      filterString = `?filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }
    const { data } = await apiClient.get<User[]>(`/users${filterString}`);
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const getMyActiveSessions = async () => {
  try {
    const { data } = await apiClient.get<SessionMarker[]>('/users/me/sessions');
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const logoutMeFromSession = async (sessionId?: string) => {
  try {
    await apiClient.post<void>('/users/me/logout-from', { sessionId });
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const getSpecificUserActiveSessions = async (userId: string) => {
  try {
    const { data } = await apiClient.get<SessionMarker[]>(`/users/${encodeURIComponent(userId)}/sessions`);
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const logoutSpecificUserFromSessions = async (userId: string) => {
  try {
    await apiClient.post<void>(`/users/${encodeURIComponent(userId)}/force-logout`);
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const getAllUsersActiveSessions = async (companyId?: string) => {
  try {
    const filter = companyId ? `?filterByCompanyId=${encodeURIComponent(companyId)}` : '';
    const { data } = await apiClient.get<UserSessions>(`/users/sessions${filter}`);
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const deactivateUser = async (userId: string) => {
  try {
    await apiClient.delete<void>(`/users/${encodeURIComponent(userId)}/`);
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const getProjectImagesByUser = async (userId: string, imageSlugList: string[]): Promise<ProjectImage[]> => {
  try {
    // Налаштувати фільтр на вибірку зображень за їх `slug`
    let whereFilter = '';
    if (Array.isArray(imageSlugList)) {
      whereFilter = encodeURIComponent(
        JSON.stringify({
          where: {
            slug: {
              inq: [...imageSlugList],
            },
          },
          order: ['projectId ASC', 'roomName ASC', 'productId ASC', 'position ASC'],
        }),
      );
    }

    const { data } = await apiClient.get<ProjectImage[]>(
      `/users/${encodeURIComponent(userId)}/project-images${whereFilter ? `?filter=${whereFilter}` : ''}`,
    );
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const postSingleProjectImageByUser = async (
  userId: string,
  file: File,
  fileName: string,
  fields: ProjectImage,
): Promise<ProjectImage> => {
  try {
    // Перевірити наявність обов'язкових даних
    if (typeof fileName !== 'string' || fileName.trim().length === 0 || !file) {
      throw new Error('Відсутній файл зображення або його дані');
    }
    if (
      typeof userId !== 'string' ||
      typeof fields.source !== 'string' ||
      typeof fields.targetModel !== 'string' ||
      userId.trim().length === 0 ||
      fields.source.trim().length === 0 ||
      fields.targetModel.trim().length === 0
    ) {
      throw new Error("Відсутні обов'язкові параметри зображення");
    }

    // Підготувати multipart/form-data
    const multipart = new FormData();
    multipart.append('position', fields.position.toString(10));
    multipart.append('printInDocuments', fields.printInDocuments ? 'true' : 'false');
    multipart.append('source', fields.source.trim());
    if (typeof fields.mime === 'string' && fields.mime.trim().length > 0) multipart.append('mime', fields.mime.trim());
    if (typeof fields.fileSize === 'number' && fields.fileSize > 0)
      multipart.append('fileSize', fields.fileSize.toString(10));
    if (typeof fields.originalName === 'string' && fields.originalName.trim().length > 0)
      multipart.append('originalName', fields.originalName.trim());
    if (fields.sketchData) multipart.append('sketchData', JSON.stringify(fields.sketchData));
    multipart.append('targetModel', fields.targetModel.trim());
    if (typeof fields.productId === 'string' && fields.productId.trim().length > 0)
      multipart.append('productId', fields.productId.trim());
    if (typeof fields.roomName === 'string' && fields.roomName.trim().length > 0)
      multipart.append('roomName', fields.roomName.trim());
    if (typeof fields.projectId === 'string' && fields.projectId.trim().length > 0)
      multipart.append('projectId', fields.projectId.trim());
    multipart.append('image', file, fileName);

    const { data } = await apiClient.post<ProjectImage>(
      `/users/${encodeURIComponent(userId)}/project-images`,
      multipart,
    );
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const patchSingleProjectImageByUser = async (
  userId: string,
  imageSlug: string,
  payload: Partial<Omit<ProjectImage, 'userId'>>,
): Promise<CountSchema> => {
  if (typeof imageSlug !== 'string' || imageSlug.trim().length === 0) {
    throw new Error("Відсутній обов'язковий аргумент imageSlug");
  }
  try {
    const { data } = await apiClient.patch<CountSchema>(
      `/users/${encodeURIComponent(userId)}/project-images?where[slug]=${encodeURIComponent(imageSlug)}`,
      payload,
    );
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const deleteSingleProjectImageByUser = async (userId: string, imageSlug: string): Promise<CountSchema> => {
  if (typeof imageSlug !== 'string' || imageSlug.trim().length === 0) {
    throw new Error("Відсутній обов'язковий аргумент imageSlug");
  }
  try {
    const { data } = await apiClient.delete<CountSchema>(
      `/users/${encodeURIComponent(userId)}/project-images?where[slug]=${encodeURIComponent(imageSlug)}`,
    );
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const deleteAllImagesOfProductByUser = async (userId: string, productId: string): Promise<CountSchema> => {
  if (typeof productId !== 'string' || productId.trim().length === 0) {
    throw new Error("Відсутній обов'язковий аргумент productId");
  }
  try {
    const { data } = await apiClient.delete<CountSchema>(
      `/users/${encodeURIComponent(userId)}/project-images?where[productId]=${encodeURIComponent(productId)}`,
    );
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export default {
  getMyActiveSessions,
  logoutMeFromSession,
  getProjectImagesByUser,
  postSingleProjectImageByUser,
  patchSingleProjectImageByUser,
  deleteSingleProjectImageByUser,
  deleteAllImagesOfProductByUser,
};
