import React from 'react';
import type { FC } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  DialogContentText,
  Box,
  Typography,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'store/hooks';

import { hideModal } from 'slices/modal';
import Alert from '@material-ui/lab/Alert';

interface DefaultProps {
  titleMessage?: string;
  titleMessageId?: string;
  bodyMessageId?: string;
  actionSubjectsList?: string[];
  warningMessageId?: string;
  handleCancel?: () => void;
  handleSubmit: () => void;
  cancelButtonLabelId?: string;
  submitButtonLabelId: string;
}

const PromptDialog: FC<DefaultProps> = ({
  titleMessage,
  titleMessageId,
  bodyMessageId,
  actionSubjectsList,
  warningMessageId,
  cancelButtonLabelId = 'generic.cancel',
  handleCancel,
  handleSubmit,
  submitButtonLabelId,
}): JSX.Element => {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(hideModal());

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage id={titleMessageId ?? titleMessage} defaultMessage={titleMessageId ?? titleMessage} />
      </DialogTitle>
      <DialogContent>
        {bodyMessageId ? (
          <DialogContentText id="alert-dialog-description">
            <FormattedMessage id={bodyMessageId} />
          </DialogContentText>
        ) : null}
        {actionSubjectsList ? (
          <Box px={3} pb={1}>
            <Typography color="textSecondary" variant="body2">
              <ol>
                {actionSubjectsList.map((subject, ind) => (
                  <li key={`${ind}-${subject}`}>{subject}</li>
                ))}
              </ol>
            </Typography>
          </Box>
        ) : null}
        {warningMessageId ? (
          <Alert severity="warning">
            <FormattedMessage id={warningMessageId} />
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Box width="100%" mt={0} mb={0} display="flex" justifyContent="space-between">
          <Button onClick={handleCancel || handleClose} color="primary" size="small">
            <FormattedMessage id={cancelButtonLabelId} />
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus variant="contained">
            <FormattedMessage id={submitButtonLabelId} />
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default PromptDialog;
