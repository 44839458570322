import React from 'react';

import type { User } from 'types/user';
import apiClient from 'api/coreAPI';
import { resetState, saveState } from 'utils/localStorage';
import { b64EncodeUnicode } from 'utils/encoding';
import type { SecureProcessConfirmation, SecureProcessMarker } from 'types/security';

export interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  isClearedConsSession: boolean;
}

export interface IPassword {
  password: string;
  newPassword: string;
}

/** Заява на відновлення забутого користувачем паролю */
export interface IForgotPasswordClaim {
  email: string;
  processCode: string;
}

export interface AuthContextValue extends AuthState {
  method: 'session' | 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  updateUserProfile: (data: any) => Promise<void>;
  updateUserPassword: (password: IPassword) => Promise<void>;
  passwordRecoveryStart: () => Promise<SecureProcessMarker>;
  passwordRecoveryFor: (claim: IForgotPasswordClaim) => Promise<SecureProcessMarker>;
  passwordRecoveryConfirm: (check: SecureProcessConfirmation) => Promise<void>;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type UpdateUserProfileAction = {
  type: 'UPDATE_USER_PROFILE';
  payload: {
    user: User;
  };
};

type Action = InitializeAction | LoginAction | LogoutAction | UpdateUserProfileAction;

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  isClearedConsSession: false,
};

export const clearLocalStorage = (): void => {
  resetState('acctkn');
  resetState('rth');
  resetState();
};

export const setSession = (accessToken: string | null, refreshHint?: number): void => {
  if (accessToken) {
    saveState(accessToken, 'acctkn');
    apiClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // TMP Тестувати примусово спотворений токен вже після авторизації: START
    // setTimeout(() => {
    //   apiClient.defaults.headers.common.Authorization = `Bearer FALSY${accessToken}`;
    //   console.log('Bearer token falsified');
    // }, 3e3);
    // TMP Тестувати примусово спотворений токен вже після авторизації: END

    if (typeof refreshHint === 'number') saveState(refreshHint, 'rth');
  } else {
    delete apiClient.defaults.headers.common.Authorization;
    clearLocalStorage();
  }
};

export const safeConvertUserDataResponse = (data: User): User => {
  return {
    ...data,
    id: data.id || '',
    name: data.firstName || data.lastName ? `${data.firstName} ${data.lastName}` : 'Anonymous User',
    roles: data.roles || [],
    skills: data.skills || [],
  };
};

export const authReducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALIZE': {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        isInitialized: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }

    case 'UPDATE_USER_PROFILE': {
      const { user } = action.payload;
      return {
        ...state,
        user,
      };
    }

    default: {
      return { ...state };
    }
  }
};

const AuthContext = React.createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'session',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateUserProfile: () => Promise.resolve(),
  updateUserPassword: () => Promise.resolve(),
  passwordRecoveryStart: () => Promise.resolve({ processCode: '', activeUntil: 1 }),
  passwordRecoveryFor: () => Promise.resolve({ processCode: '', activeUntil: 1 }),
  passwordRecoveryConfirm: () => Promise.resolve(),
});

export const updateUserPassword = async (password: IPassword) => {
  await apiClient.patch<Record<string, unknown>>('/users/me/password', {
    password: b64EncodeUnicode(password.password),
    newPassword: b64EncodeUnicode(password.newPassword),
  });
};

export const passwordRecoveryStart = async () => {
  const { data } = await apiClient.get<SecureProcessMarker>('/users/password-forgotten');
  return data;
};

export const passwordRecoveryFor = async (claim: IForgotPasswordClaim) => {
  const { data } = await apiClient.post<SecureProcessMarker>('/users/password-recovery-for', claim);
  return data;
};

export const passwordRecoveryConfirm = async (check: SecureProcessConfirmation) => {
  await apiClient.post<void>('/users/password-recovery-confirm', check);
};

AuthContext.displayName = 'AuthContext';
export default AuthContext;
