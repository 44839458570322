import { Action, combineReducers, createAction } from '@reduxjs/toolkit';

import { reducer as consultantReducer } from 'slices/consultant';
import { reducer as settingsReducer } from 'slices/settings';
import { reducer as registriesReducer } from 'slices/registries';
import { reducer as materialsReducer } from 'slices/materials';
import { reducer as representativeReducer } from 'slices/representative';
import { reducer as suppliersReducer } from 'slices/suppliers';
import { reducer as modalReducer } from 'slices/modal';
import { reducer as designerReducer } from 'slices/designer';
import { reducer as projectsReducer } from 'slices/projects';
import { reducer as importsReducer } from 'slices/imports';
import { reducer as viewDataReducer } from 'slices/viewData';

export const userLogoutAction = createAction('user/logout');

const appReducer = combineReducers({
  consultant: consultantReducer,
  designer: designerReducer,
  representative: representativeReducer,
  projects: projectsReducer,
  viewData: viewDataReducer,
  materials: materialsReducer,
  suppliers: suppliersReducer,
  modal: modalReducer,
  settings: settingsReducer,
  registries: registriesReducer,
  imports: importsReducer,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const rootReducer = (state: any, action: Action) => {
  if (action.type === 'user/logout') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
