import { createSelector, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { RootState } from 'store';
import { loadState, saveState } from 'utils/localStorage';
import { DEFAULT_LOCALE } from 'constants/strings';
import { Locale } from 'locales';

interface Settings {
  language: Locale;
}

const persistedState = loadState();
const initialState: Settings = {
  language: persistedState?.settings?.language ?? (loadState('language') || DEFAULT_LOCALE),
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLanguage: (state, { payload }) => {
      saveState(payload, 'language');
      state.language = payload ?? DEFAULT_LOCALE;
      moment.locale(state.language);
    },
  },
});

const selectSelf = (state: RootState): Settings => state.settings;

export const getLanguage = createSelector(selectSelf, state => state.language);
export const {
  reducer,
  actions: { setLanguage },
} = slice;
