import React, { useState, useRef, useEffect } from 'react';
import type { FC } from 'react';
import { startCase } from 'lodash';
import { Box, Button, IconButton, Popover, SvgIcon, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from 'hooks/useSettings';

import { THEMES } from 'theme';
import { FormattedMessage, useIntl } from 'react-intl';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { locales } from 'locales';
import { useSelector, useDispatch } from 'store/hooks';
import { getLanguage, setLanguage } from 'slices/settings';
import useAuth from 'hooks/useAuth';

import type { Theme } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    width: 320,
    padding: theme.spacing(2),
  },
  formControl: {
    width: '100%',
  },
}));

const Settings: FC = () => {
  const { user, updateUserProfile } = useAuth();
  const classes = useStyles();
  const ref = useRef<any>(null);
  const { settings, saveSettings } = useSettings();
  const intl = useIntl();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [values, setValues] = useState({
    theme: settings.theme,
  });
  const dispatch = useDispatch();
  const language = useSelector(state => getLanguage(state));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field: string, value: any) => {
    setValues({
      ...values,
      [field]: value,
    });
  };

  const handleSave = () => {
    saveSettings(values);
    setOpen(false);
  };

  const handleChangeLanguage = (event: React.ChangeEvent<{ name?: string; value: unknown | string }>) => {
    const locale = event.target.value;
    const profObj = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      language: locale,
    };
    dispatch(setLanguage(locale));
    updateUserProfile(profObj);
  };

  useEffect(() => {}, []);

  return (
    <>
      <Tooltip title={intl.formatMessage({ id: 'generic.settings' })}>
        <IconButton color="inherit" onClick={handleOpen} ref={ref}>
          <SvgIcon fontSize="small">
            <SettingsIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography variant="h6" color="textPrimary">
          {intl.formatMessage({ id: 'generic.settings' })}
        </Typography>
        <Box mt={4}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">
              <FormattedMessage id="settings.language" />
            </InputLabel>
            <Select
              native
              value={language}
              onChange={handleChangeLanguage}
              label={intl.formatMessage({ id: 'settings.language' })}
              inputProps={{
                name: 'language',
                id: 'outlined-language-native',
              }}
            >
              {locales.map(locale => (
                <option key={locale} value={locale}>
                  {intl.formatMessage({ id: `settings.language.${locale}` })}
                </option>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box mt={4}>
          <TextField
            fullWidth
            label={intl.formatMessage({ id: 'settings.theme' })}
            name="theme"
            onChange={event => handleChange('theme', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
            disabled
          >
            {Object.keys(THEMES).map(theme => (
              <option key={theme} value={theme}>
                {startCase(theme)}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mt={4}>
          <Button variant="contained" color="secondary" fullWidth onClick={handleSave}>
            {`${intl.formatMessage({ id: 'generic.save' })} ${intl.formatMessage({ id: 'generic.settings' })}`}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default Settings;
