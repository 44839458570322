import { createSelector, createSlice } from '@reduxjs/toolkit';
import { isEmpty, get, flatten, findIndex, find } from 'lodash';

import type { ProductExamples } from 'types/ExampleProduct';
import productExamples from 'data/mockProductExamples';
import { RootState } from 'store';
import { loadState } from 'utils/localStorage';
import { Filter, FilterData, ProductSelected, Step } from 'data/interfaces';
import { mechanismNames, productTypes, productObj, steps, ProductType, ProductTypes } from 'data/defaultData';
import { DEFAULT_ACTIVE_STEP, DEFAULT_PRODUCT_CARD } from 'constants/state';

const persistedState = loadState('consultant_tool');
const persistedFilters = get(persistedState, 'filters', []);
const persistedProduct = get(persistedState, 'product', productObj);
const persistedSteps = get(persistedState, 'steps', steps);
const persistedActiveStep = get(persistedState, 'activeStep', DEFAULT_ACTIVE_STEP);
const persistedProductCard = get(persistedState, 'productCard', DEFAULT_PRODUCT_CARD);

interface ProductDimensions {
  width: number;
  height: number;
}

interface ConsultantState {
  isInitialized: boolean;
  isClearedConsSession: boolean;
  productData: ProductExamples;
  productType: ProductType;
  productTypes: ProductTypes;
  mechanismNames: string[];
  product: ProductSelected[];
  productCard: number;
  isAtLeastOnePriceSelected: boolean;
  activeStep: number;
  steps: Step[];
  selectedInvoices: string[];
  filters: Filter;
}

const initialState: ConsultantState = {
  isInitialized: false,
  isClearedConsSession: false,
  productData: productExamples,
  productType: productTypes[0],
  productTypes,
  mechanismNames,
  product: persistedProduct,
  productCard: persistedProductCard,
  activeStep: persistedActiveStep,
  isAtLeastOnePriceSelected: false,
  steps: persistedSteps,
  selectedInvoices: [],
  filters: persistedFilters,
};

const slice = createSlice({
  name: 'consultant',
  initialState,
  reducers: {
    startInitialize: (state, action) => {
      state.isInitialized = true;
      const {
        productData,
        productType,
        productTypes,
        mechanismNames,
        product,
        productCard,
        activeStep,
        steps,
        selectedInvoices,
        filters,
      } = action.payload;
      state.productData = productData;
      state.productType = productType;
      state.productTypes = productTypes;
      state.mechanismNames = mechanismNames;
      state.product = product;
      state.productCard = productCard;
      state.isAtLeastOnePriceSelected = !isEmpty(flatten(state.product.map(item => item.prices)));
      state.activeStep = activeStep;
      state.steps = steps;
      state.selectedInvoices = selectedInvoices;
      state.filters = filters;
      return state;
    },
    updateProductsTypes: (state, action) => {
      const { productType } = action.payload;
      state.productType = productType;
      return state;
    },
    updateProduct: (state, action) => {
      const { product } = action.payload;
      state.product = product;
      state.isAtLeastOnePriceSelected = !isEmpty(flatten(state.product.map(item => item.prices)));
    },
    updateProductCard: (state, { payload }) => {
      state.productCard = payload;
    },
    setIsAtLeastOnePriceSelected: (state, action) => {
      const { isAtLeastOnePriceSelected } = action.payload;
      state.isAtLeastOnePriceSelected = isAtLeastOnePriceSelected;
      return state;
    },
    clearConsultantSession: (state: ConsultantState, action) => {
      const {
        isClearedConsSession,
        product,
        productType,
        productCard,
        steps,
        activeStep,
        isAtLeastOnePriceSelected,
        selectedInvoices,
        filters,
      } = action.payload;
      state.isClearedConsSession = isClearedConsSession;
      state.product = product;
      state.productType = productType;
      state.productCard = productCard;
      state.steps = steps;
      state.activeStep = activeStep;
      state.isAtLeastOnePriceSelected = isAtLeastOnePriceSelected;
      state.selectedInvoices = selectedInvoices;
      state.filters = filters;
      return state;
    },
    setActiveStep: (state, { payload }) => {
      state.activeStep = payload;
    },
    setSteps: (state, { payload }) => {
      state.steps = payload;
    },
    setSelectedInvoices: (state, action) => {
      state.selectedInvoices = action.payload;
    },
    setFilter: (state, action) => {
      state.filters[state.productType].selected = action.payload;
    },
    toggleAllFilters: (state, action) => {
      state.filters[state.productType].selected = action.payload;
    },
    setSelectedProducts: (state, { payload }) => {
      const productIndex = findIndex(state.product, ['id', state.productCard]);

      state.product[productIndex].selectedProducts = payload;
    },
  },
});

export const {
  startInitialize,
  updateProductsTypes,
  updateProduct,
  updateProductCard,
  setIsAtLeastOnePriceSelected,
  clearConsultantSession,
  setActiveStep,
  setSteps,
  setSelectedInvoices,
  setFilter,
  toggleAllFilters,
  setSelectedProducts,
} = slice.actions;

export const { reducer } = slice;

const selectSelf = (state: RootState): ConsultantState => state.consultant;

export const getActiveStep = (state: RootState): number => state.consultant.activeStep;
export const getSteps = (state: RootState): Step[] => state.consultant.steps;
export const getProduct = (state: RootState): ProductSelected[] => state.consultant.product;
export const getProductData = (state: RootState): ProductExamples => state.consultant.productData;
export const getProductType = (state: RootState): ProductType => state.consultant.productType;
export const getProductsTypes = (state: RootState): ProductTypes => state.consultant.productTypes;
export const getMechanismNames = (state: RootState): string[] => state.consultant.mechanismNames;
export const getProductCard = (state: RootState): number => state.consultant.productCard;
export const getIsAtLeastOnePriceSelected = (state: RootState): boolean => state.consultant.isAtLeastOnePriceSelected;
export const getIsClearedConsSession = (state: RootState): boolean => state.consultant.isClearedConsSession;
export const getSelectedInvoices = (state: RootState): string[] => state.consultant.selectedInvoices;
export const getAllFilters = (state: RootState): Filter => state.consultant.filters;
export const getFilters = (state: RootState): FilterData => state.consultant.filters[state.consultant.productType];
export const getProductPrices = createSelector(getProduct, products =>
  products.map((item: ProductSelected) => item.prices).flat(),
);
export const getProductDimensions = createSelector(selectSelf, state => {
  const productIndex = findIndex(state.product, ['id', state.productCard]);
  const cardDimensions = state.product[productIndex];

  return {
    width: cardDimensions?.x !== undefined && !Number.isNaN(+cardDimensions.x) ? +cardDimensions.x : 0,
    height: cardDimensions?.y !== undefined && !Number.isNaN(+cardDimensions.y) ? +cardDimensions.y : 0,
  } as ProductDimensions;
});
export const getSelectedProducts = (state: RootState, id: number): any[] =>
  get(find(state.consultant.product, { id }), 'selectedProducts', []);

export const getAllSelectedProducts = createSelector(getProduct, product =>
  product.flatMap((productSelected: ProductSelected) => productSelected.selectedProducts),
);
