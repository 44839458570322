import React from 'react';
import type { FC } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from 'store/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Button, TextField, FormHelperText } from '@material-ui/core';

import { ComplexError, errorParser } from 'api/errorsParser';
import useAuth from 'hooks/useAuth';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { MAX_NUMBER_OF_LOGIN_ATTEMPTS } from 'constants/numbers';
import { resetState } from 'utils/localStorage';
import { userLogoutAction } from 'store/rootReducer';

type Inputs = {
  email: string;
  password: string;
  submit?: string | null;
};

interface DefaultProps {
  withBackdrop?: boolean;
}

const Form: FC<DefaultProps> = ({ withBackdrop }): JSX.Element => {
  const { login } = useAuth();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const { formatMessage } = useIntl();

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(formatMessage({ id: 'form.emailValidationNotice' }))
      .max(255)
      .required(formatMessage({ id: 'form.emailRequiredNotice' })),
    password: Yup.string()
      .max(255)
      .required(formatMessage({ id: 'form.passwordRequiredNotice' })),
    submit: Yup.string().notRequired(),
  });

  const {
    handleSubmit,
    control,
    setError,
    formState: { isSubmitting, submitCount, errors },
  } = useForm<Inputs>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver<Inputs>(schema),
  });

  // eslint-disable-next-line consistent-return
  const onSubmit: SubmitHandler<Inputs> = async data => {
    try {
      await login(data.email, data.password);
    } catch (error) {
      if (withBackdrop) {
        if (submitCount + 1 >= MAX_NUMBER_OF_LOGIN_ATTEMPTS) {
          dispatch(userLogoutAction());
          resetState();
          window.location.href = '/';
        }
      }

      if (isMountedRef.current) {
        setError('submit', {
          type: 'manual',
          message: errorParser(error as ComplexError),
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="email"
        render={({ field: { onChange, value, name } }) => (
          <TextField
            autoFocus
            error={!!errors.email}
            fullWidth
            helperText={errors.email?.message}
            label={formatMessage({ id: 'form.emailLabel' })}
            margin="normal"
            type="email"
            variant="outlined"
            name={name}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="password"
        render={({ field: { onChange, value, name } }) => (
          <TextField
            autoFocus
            error={!!errors.password}
            fullWidth
            helperText={errors.password?.message}
            label={formatMessage({ id: 'form.passwordLabel' })}
            margin="normal"
            type="password"
            variant="outlined"
            name={name}
            value={value}
            onChange={onChange}
          />
        )}
      />
      {errors.submit?.message && (
        <Box mt={3}>
          <FormHelperText error>{errors.submit?.message}</FormHelperText>
        </Box>
      )}
      <Box mt={2}>
        <Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
          <FormattedMessage id="auth.signIn" />
        </Button>
      </Box>
    </form>
  );
};

export default Form;
