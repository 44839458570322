import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pull } from 'lodash';

import { RootState } from 'store';
import type { Material } from 'types/material';
import type { CreatingDocumentState } from 'domain/DocGenerator/CreatingDocumentState';
import { loadState } from 'utils/localStorage';

interface Filters {
  category: string;
  supplier?: string;
  sort: 'sku asc' | 'sku desc' | 'created asc' | 'created desc';
  search: string;
  page: number;
}

interface Materials {
  allMaterials: Material[];
  selectedMaterials: string[];
  filteredMaterials: string[];
  filters: Filters;
  awaitedDocuments: CreatingDocumentState[];
}

const persistedState = loadState('materials_tool');
const initialState: Materials = {
  selectedMaterials: [],
  allMaterials: [],
  filteredMaterials: [],
  filters: persistedState?.filters ?? {
    category: 'all',
    supplier: 'all',
    sort: 'sku asc',
    search: '',
    page: 0,
  },
  awaitedDocuments: persistedState?.awaitedDocuments ?? [],
};

const slice = createSlice({
  name: 'representative',
  initialState,
  reducers: {
    setSelectedMaterial: (state, { payload }: PayloadAction<string>) => {
      state.selectedMaterials.push(payload);
    },
    removeSelectedMaterial: (state, { payload }: PayloadAction<string>) => {
      state.selectedMaterials = pull(state.selectedMaterials, payload);
    },
    setAllMaterials: (state, { payload }) => {
      state.allMaterials = payload;
    },
    setFilteredMaterials: (state, { payload }) => {
      state.filteredMaterials = payload;
    },
    toggleAllSelectedMaterials: (state, { payload }) => {
      state.selectedMaterials = payload;
    },
    setFilter: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    setAwaitedDocument: (state, { payload }) => {
      const index = state.awaitedDocuments.findIndex(
        docState => docState.templateKey === payload.templateKey && docState.dataHash === payload.dataHash,
      );
      if (index < 0) state.awaitedDocuments.push(payload);
      else state.awaitedDocuments[index] = payload;
    },
    deleteAwaitedDocument: (state, { payload }) => {
      const index = state.awaitedDocuments.findIndex(
        docState => docState.templateKey === payload.templateKey && docState.dataHash === payload.dataHash,
      );
      if (index === 0) state.awaitedDocuments.shift();
      else if (index === state.awaitedDocuments.length - 1) state.awaitedDocuments.pop();
      else if (index > 0) state.awaitedDocuments.splice(index, 1);
    },
  },
});

const selectSelf = (state: RootState): Materials => state.representative;

export const getSelectedMaterials = createSelector(selectSelf, state => state.selectedMaterials);
export const getAllMaterials = createSelector(selectSelf, state => state.allMaterials);
export const getFilters = createSelector(selectSelf, state => state.filters);
export const getFilteredMaterials = createSelector(selectSelf, state => state.filteredMaterials);
export const getAwaitedDocuments = createSelector(selectSelf, state => state.awaitedDocuments);
export const {
  reducer,
  actions: {
    setSelectedMaterial,
    removeSelectedMaterial,
    setAllMaterials,
    setFilter,
    toggleAllSelectedMaterials,
    setFilteredMaterials,
    setAwaitedDocument,
    deleteAwaitedDocument,
  },
} = slice;
