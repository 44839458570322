/* eslint class-methods-use-this: ["error", { "exceptMethods": ["hydrate"] }] */
import type { ServiceKey } from 'types/product-services';
import type { ProductComponentKey } from 'types/ProductMaterials';
import type { ProductDesignSettingKey } from 'types/ProductDesignData';
import type { ProductStructure } from 'types/ProductStructure';
import type { ProductPrototype } from './ProductPrototype';
import Product, { InvalidDataError, InvalidServiceKey } from './Product';

/**
 * Клас подвійної римської штори (день-ніч) з моторизованою системою та опціональними складовими
 * (у т.ч. блоком живлення та пультом дистанційного керування)
 */
export default class RomanBlindsDoubleMotoProduct extends Product implements ProductPrototype {
  protected readonly _validDesignSettings: ProductDesignSettingKey[] = [
    'width',
    'height',
    'widthSecondary',
    'heightSecondary',
    'mountTarget',
    'sticksOverallCount',
    'controlSide',
    'controlChainLength',
    'isComplexSewing',
    'productionOptions',
  ];

  protected readonly _requiredDesignSettings: ProductDesignSettingKey[] = [
    'width',
    'height',
    'widthSecondary',
    'heightSecondary',
    'controlSide',
    'controlChainLength',
  ];

  protected readonly _validComponentKeysOf: {
    [componentType: string]: ProductComponentKey[];
  } = {
    textile: ['mainTextile', 'secondaryTextile', 'edgingTextile', 'liningTextile', 'doublerine'],
    webbing: ['webbingAdhesive', 'webbing4Stick', 'webbingDecorative'],
    furniture: ['sRings', 'magnets'],
    curtainRod: [
      'romanBlindSystem',
      'sticks',
      'rings4CurtainRod',
      'fixation',
      'weightingAgent',
      'chain',
      'chainWeighter',
      'bracketFasten',
      'wallBracket',
      'motor',
    ],
    accessory: ['powerSupplyUnit', 'charger', 'remoteControlUnit', 'smartHub'],
    productFulfillment: [],
  };

  protected readonly _validServiceKeysOf: {
    [serviceType: string]: ServiceKey[];
  } = {
    sewing: ['sewingRomanBlinds', 'sewingRomanBlindsComplex', 'sewingCustom'],
    hanging: ['hangingRomanBlinds', 'hangingComplex', 'hangingCustom'],
    mount: ['mountRomanBlindSystem', 'mountRomanBlindSystemComplex', 'mountCustom'],
    disassembling: ['disassemblingCurtainRod', 'disassemblingCustom'],
    custom: ['customServices'],
  };

  constructor(baseProduct?: RomanBlindsDoubleMotoProduct | Product | ProductStructure) {
    super(baseProduct);
    this.calcMethod = 'romanBlindsDoubleMoto';
    // Додаткові параметри дизайну характерні для даного типу виробу, але відсутні в базовому
    this.design.widthSecondary = this.design.widthSecondary || undefined;
    this.design.heightSecondary = this.design.heightSecondary || undefined;
    // Обов'язкові та типові складові включені за умовчанням
    /* Основна тканина */
    if (typeof this.design.mainTextile === 'undefined') {
      this.design.mainTextile = {
        amount: -1,
        unit: 'м.п.',
        amountToBuy: undefined,
        unitToBuy: 'м.п.',
        totalPrice: undefined,
        isManualAmount: false,
        manualAmountAuthor: undefined,
        isManualPrice: false,
        manualPriceAuthor: undefined,
        required: true,
      };
    } else {
      // Імплементація даних з базового виробу вже відбулась в конструкторі батьківського класу
      // проте залишається необхідність переконатись в обов'язковості складової та повному наборі даних
      this.design.mainTextile.required = true;
      this.design.mainTextile.amount = this.design.mainTextile.amount ?? -1;
      this.design.mainTextile.unit = this.design.mainTextile.unit ?? 'м.п.';
      this.design.mainTextile.unitToBuy = this.design.mainTextile.unitToBuy ?? 'м.п.';
      this.design.mainTextile.isManualAmount = this.design.mainTextile.isManualAmount ?? false;
      this.design.mainTextile.isManualPrice = this.design.mainTextile.isManualPrice ?? false;
    }
    /* Тюлева тканина */
    if (typeof this.design.secondaryTextile === 'undefined') {
      this.design.secondaryTextile = {
        amount: -1,
        unit: 'м.п.',
        amountToBuy: undefined,
        unitToBuy: 'м.п.',
        totalPrice: undefined,
        isManualAmount: false,
        manualAmountAuthor: undefined,
        isManualPrice: false,
        manualPriceAuthor: undefined,
        required: true,
      };
    } else {
      // Імплементація даних з базового виробу вже відбулась в конструкторі батьківського класу
      // проте залишається необхідність переконатись в обов'язковості складової та повному наборі даних
      this.design.secondaryTextile.required = true;
      this.design.secondaryTextile.amount = this.design.secondaryTextile.amount ?? -1;
      this.design.secondaryTextile.unit = this.design.secondaryTextile.unit ?? 'м.п.';
      this.design.secondaryTextile.unitToBuy = this.design.secondaryTextile.unitToBuy ?? 'м.п.';
      this.design.secondaryTextile.isManualAmount = this.design.secondaryTextile.isManualAmount ?? false;
      this.design.secondaryTextile.isManualPrice = this.design.secondaryTextile.isManualPrice ?? false;
    }
    /* Римська система */
    if (typeof this.design.romanBlindSystem === 'undefined') {
      this.design.romanBlindSystem = {
        amount: 1,
        unit: 'шт.',
        amountToBuy: 1,
        unitToBuy: 'шт.',
        totalPrice: undefined,
        isManualAmount: false,
        manualAmountAuthor: undefined,
        isManualPrice: false,
        manualPriceAuthor: undefined,
        required: true,
      };
    } else {
      // Імплементація даних з базового виробу вже відбулась в конструкторі батьківського класу
      // проте залишається необхідність переконатись в обов'язковості складової та повному наборі даних
      this.design.romanBlindSystem.required = true;
      this.design.romanBlindSystem.amount = this.design.romanBlindSystem.amount ?? 1;
      this.design.romanBlindSystem.amountToBuy = this.design.romanBlindSystem.amountToBuy ?? 1;
      this.design.romanBlindSystem.unit = this.design.romanBlindSystem.unit ?? 'шт.';
      this.design.romanBlindSystem.unitToBuy = this.design.romanBlindSystem.unitToBuy ?? 'шт.';
      this.design.romanBlindSystem.isManualAmount = this.design.romanBlindSystem.isManualAmount ?? false;
      this.design.romanBlindSystem.isManualPrice = this.design.romanBlindSystem.isManualPrice ?? false;
    }

    // Типові послуги увімкнені за умовчанням
    if (
      typeof this.serviceOptions.sewingRomanBlinds === 'undefined' &&
      // Вмикати просте пошиття за умовчанням доцільно лише при відсутності інших
      typeof this.serviceOptions.sewingRomanBlindsComplex === 'undefined' &&
      typeof this.serviceOptions.sewingCustom === 'undefined'
    )
      this.serviceOptions.sewingRomanBlinds = -1;

    // Для даного типу виробу характерна ймовірна комбінована складність пошиття, чого немає в базовому
    if (!this.serviceOptions.combinedSewingComplexity) {
      this.serviceOptions.combinedSewingComplexity = {
        isPrimaryComplex: false,
        isSecondaryComplex: false,
        primaryPrice: undefined,
        secondaryPrice: undefined,
      };
    }
    // Для даного типу виробу характерна ймовірна комбінована складність послуги навішування, чого немає в базовому
    if (!this.serviceOptions.combinedHangingComplexity) {
      this.serviceOptions.combinedHangingComplexity = {
        isPrimaryComplex: false,
        isSecondaryComplex: false,
        primaryPrice: undefined,
        secondaryPrice: undefined,
      };
    }
  }

  clone(): ProductPrototype {
    const clone = new RomanBlindsDoubleMotoProduct(this);
    // Ідентифікатор клону не має збігатись з його прототипом!
    clone.id = undefined;
    return clone;
  }

  hydrate(structure: ProductStructure): ProductPrototype {
    const hydrated = new RomanBlindsDoubleMotoProduct(structure);
    hydrated.calcMethod = 'romanBlindsDoubleMoto';
    return hydrated;
  }

  requireLiningTextile(newState?: boolean): boolean {
    // Встановити нове значення
    if (typeof newState !== 'undefined') {
      if (typeof this.design.liningTextile !== 'undefined') {
        // Оновити індикатор обов'язковості складової
        this.design.liningTextile.required = !!newState;
      } else if (newState) {
        // Якщо призначається обов'язковість і параметри дизайну ще не містять кількісний показник
        // — встановити його стартове значення
        // - встановити обчислення кількості підкладки автоматичним за умовчанням
        this.design.liningTextile = {
          ...this._defaultTextileUsage,
          required: !!newState,
          isManualAmount: false,
        };
      }
    }

    return !!this.design.liningTextile?.required;
  }

  setService(serviceKey: ServiceKey, data: number | undefined): void {
    if (!serviceKey) {
      // Не вказано ключ послуги
      throw new InvalidServiceKey(`(empty-${typeof serviceKey})`);
    }

    if (serviceKey === 'customServices') {
      // Не вказано ключ послуги
      throw new InvalidServiceKey(`setService() can't be used to set customServices`);
    }

    const clearServiceVariants = (activeServices: ServiceKey[]) => {
      activeServices.forEach(key => {
        if (key !== 'customServices') this.serviceOptions[key] = undefined;
      });
    };

    if (typeof data === 'undefined' || typeof data === 'number') {
      // Маємо очікуване значення на вході
      const currentServices = this.servicesOverview();
      if (this._validServiceKeysOf.sewing.includes(serviceKey)) {
        // Додаткові дії у випадку послуги певного виду
        // Якщо встановлюється новий варіант послуги, коли в групі вже був встановлений інакший — видалити його
        if (currentServices.sewing.length > 0 && !currentServices.sewing.includes(serviceKey))
          clearServiceVariants(currentServices.sewing);
        // Встановлений вручну варіант пошиття впливає на індикатор складності
        this.design.isComplexSewing = serviceKey.includes('Complex');
        if (this.serviceOptions.combinedSewingComplexity) {
          // Налаштування комбінованої складності має бути синхронізованим
          this.serviceOptions.combinedSewingComplexity.isPrimaryComplex = this.design.isComplexSewing;
          this.serviceOptions.combinedSewingComplexity.isSecondaryComplex = this.design.isComplexSewing;
        }
      } else if (this._validServiceKeysOf.hanging.includes(serviceKey)) {
        // Додаткові дії у випадку послуги певного виду
        // Якщо встановлюється новий варіант послуги, коли в групі вже був встановлений інакший — видалити його
        if (currentServices.hanging.length > 0 && !currentServices.hanging.includes(serviceKey))
          clearServiceVariants(currentServices.hanging);
        if (this.serviceOptions.combinedHangingComplexity) {
          // Налаштування комбінованої складності має бути синхронізованим
          const isComplex = serviceKey.includes('Complex') && typeof data === 'number';
          this.serviceOptions.combinedHangingComplexity.isPrimaryComplex = isComplex;
          this.serviceOptions.combinedHangingComplexity.isSecondaryComplex = isComplex;
        }
      } else if (this._validServiceKeysOf.mount.includes(serviceKey)) {
        // Додаткові дії у випадку послуги певного виду
        // Якщо встановлюється новий варіант послуги, коли в групі вже був встановлений інакший — видалити його
        if (currentServices.mount.length > 0 && !currentServices.mount.includes(serviceKey))
          clearServiceVariants(currentServices.mount);
      } else if (this._validServiceKeysOf.disassembling.includes(serviceKey)) {
        // Додаткові дії у випадку послуги певного виду
        // Якщо встановлюється новий варіант послуги, коли в групі вже був встановлений інакший — видалити його
        if (currentServices.disassembling.length > 0 && !currentServices.disassembling.includes(serviceKey))
          clearServiceVariants(currentServices.disassembling);
      } else {
        // Не дійсний ключ послуги
        throw new InvalidServiceKey(serviceKey);
      }
      // Встановити нове значення
      this.serviceOptions[serviceKey] = data;
      return;
    }

    // Дійти до цього місця можливо лише через не дійсне значення даних
    throw new InvalidDataError('number | undefined');
  }

  hangingComplex(newState?: boolean): boolean {
    // Встановити нове значення
    if (typeof newState !== 'undefined') {
      this.serviceOptions.hangingComplex = newState ? 1 : undefined;
      if (this.serviceOptions.combinedHangingComplexity) {
        // Налаштування комбінованої складності має бути синхронізованим
        this.serviceOptions.combinedHangingComplexity.isPrimaryComplex = newState;
        this.serviceOptions.combinedHangingComplexity.isSecondaryComplex = newState;
      }
    }

    return !!this.serviceOptions.hangingComplex;
  }
}
