import React from 'react';
import type { FC } from 'react';

import { useSelector } from 'store/hooks';
import { getModalProps, getModalType } from 'slices/modal';
import MODAL_COMPONENTS from './modalComponents';

const ModalRoot: FC = (): JSX.Element => {
  const modalType = useSelector(state => getModalType(state));
  const modalProps = useSelector(state => getModalProps(state));
  const Component = MODAL_COMPONENTS[modalType];

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...modalProps} />;
};

export default ModalRoot;
