import { useEffect } from 'react';
import { useLocation } from 'react-router';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function ScrollReset() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
}

export default ScrollReset;
