import isNull from 'lodash/isNull';

export type LocalStorageKeys =
  | 'state'
  | 'acctkn'
  | 'rth'
  | 'registries'
  | 'language'
  | 'consultant_tool'
  | 'create_order_tool'
  | 'projects_tool'
  | 'suppliers_tool'
  | 'materials_tool';

const ROOT_KEY: LocalStorageKeys = 'state';

export const loadState = (rootKey: LocalStorageKeys = ROOT_KEY): any => {
  try {
    const serializedState: string | null = localStorage.getItem(rootKey);
    if (isNull(serializedState)) return undefined;
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: object | string | number, rootKey: LocalStorageKeys = ROOT_KEY): void => {
  try {
    const serializedState: string = JSON.stringify(state);
    localStorage.setItem(rootKey, serializedState);
  } catch {
    // ignore write errors
  }
};

export const resetState = (localStorageKey?: LocalStorageKeys): void => {
  try {
    if (localStorageKey) localStorage.removeItem(localStorageKey);
    else localStorage.clear();
  } catch {
    // ignore write errors
  }
};
