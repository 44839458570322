import React from 'react';
import type { FC } from 'react';
import { OptionalChildrenOnlyProps } from 'types/generic';
import { Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const AuthGuard: FC<OptionalChildrenOnlyProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export default AuthGuard;
