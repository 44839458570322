import { іnhortProductFulfillment } from 'data/mockInhortProductFulfillment';

export function standardRomanBlindDecorationOptions(model: string): string[] {
  if (
    іnhortProductFulfillment.dependentOptionSets?.decoration &&
    іnhortProductFulfillment.dependentOptionSets.decoration[model]
  )
    return [...іnhortProductFulfillment.dependentOptionSets.decoration[model]];

  return [];
}
