import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Paper, Portal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Theme } from 'theme';
import * as serviceWorker from '../../serviceWorkerRegistration';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 430,
    position: 'fixed',
    top: 0,
    right: 0,
    margin: theme.spacing(3),
    outline: 'none',
    zIndex: 2000,
    padding: theme.spacing(2),
  },
}));

const UpdateNotification: FC = (): JSX.Element => {
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState<boolean>(false);
  const classes = useStyles();
  const [waitingWorker, setWaitingWorker] = useState<any>();
  // const { enqueueSnackbar } = useSnackbar();
  // const { formatMessage } = useIntl();

  const onServiceWorkerUpdate = (registration: any) => {
    setWaitingWorker(registration.waiting);
    setIsNewVersionAvailable(true);
  };

  const updateServiceWorker = (): void => {
    waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    setIsNewVersionAvailable(false);
    window.location.reload();
  };

  // TODO Left it here for use it later
  // const RefreshButton = (): JSX.Element => {
  //   return (
  //     <Button className="snackbar-button" size="small" onClick={updateServiceWorker}>
  //       refresh
  //     </Button>
  //   );
  // };

  const handleClose = (): void => {
    setIsNewVersionAvailable(false);
  };

  // TODO Left it here for use it later
  // useEffect(() => {
  //   if (isNewVersionAvailable)
  //     enqueueSnackbar(formatMessage({ id: 'app.newVersion' }), {
  //       persist: true,
  //       variant: 'success',
  //       action: RefreshButton,
  //     });
  // }, [isNewVersionAvailable, waitingWorker]);

  useEffect(() => {
    serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
  }, []);

  return (
    <>
      {isNewVersionAvailable ? (
        <Portal>
          <Paper className={classes.root} elevation={3}>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              <FormattedMessage id="app.newVersion.title" />
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <FormattedMessage id="app.newVersion.body" />
            </Typography>
            <Box mt={2} display="flex" justifyContent="space-between">
              <Button onClick={handleClose}>
                <FormattedMessage id="generic.close" />
              </Button>
              <Button color="secondary" variant="contained" onClick={updateServiceWorker}>
                <FormattedMessage id="generic.update" />
              </Button>
            </Box>
          </Paper>
        </Portal>
      ) : null}
    </>
  );
};

export default UpdateNotification;
