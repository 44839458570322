import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MaterialsImportSummary } from 'types/MaterialsImportSummary';
import { RootState } from 'store';

import apiClient from 'api/coreAPI';

type FetchingStatus = 'idle' | 'loading' | 'fulfilled' | 'rejected';

interface Imports {
  importsList: MaterialsImportSummary[];
  loading: FetchingStatus;
  loadingSingle: FetchingStatus;
  selectedImport: MaterialsImportSummary | null;
}

const initialState: Imports = {
  importsList: [],
  loading: 'idle',
  loadingSingle: 'idle',
  selectedImport: null,
};

export const fetchImportsSummary = createAsyncThunk<
  MaterialsImportSummary[],
  { sku: string; dateRange: { from: Date | null; to: Date | null } } | undefined
>(
  'fetchImports',
  async filter => {
    const urlFilter: any = { order: 'date DESC', where: {} };

    if (filter) {
      const { dateRange, sku } = filter;
      const { from, to } = dateRange;
      if (dateRange && from && to) {
        urlFilter.where = {
          and: [{ date: { gte: from } }, { date: { lte: to } }],
        };
      }

      if ((!from && to) || (from && !to)) {
        const rangeDate: any = {};
        if (from) rangeDate.gte = from;
        if (to) rangeDate.lte = to;
        urlFilter.where.date = rangeDate;
      }

      if (sku) {
        urlFilter.where.allGeneratedSku = { contains: [sku] };
      }
    }

    const { data } = await apiClient.get<MaterialsImportSummary[]>(
      '/materials-import-summaries?filter=' + encodeURIComponent(JSON.stringify(urlFilter)),
    );

    return data;
  },
  {
    condition: (_, { getState }) => {
      const { imports } = getState() as RootState;
      if (imports) {
        const fetchStatus = (imports as Imports).loading;
        if (fetchStatus === 'loading') {
          return false;
        }
      }
      return undefined;
    },
  },
);

export const fetchImportById = createAsyncThunk<MaterialsImportSummary, { id: string }>(
  'fetchImportById',
  async ({ id }) => {
    const urlFilter: any = { where: { id: { eq: id } } };

    const { data } = await apiClient.get<MaterialsImportSummary[]>(
      '/materials-import-summaries?filter=' + encodeURIComponent(JSON.stringify(urlFilter)),
    );

    return data[0];
  },
);

const slice = createSlice({
  name: 'imports',
  initialState,
  reducers: {
    selectImport: (state, { payload: { id } }: PayloadAction<Record<'id', string>>) => {
      state.selectedImport = state.importsList.find(item => item.id === id) || null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchImportById.pending, state => {
        state.loadingSingle = 'loading';
      })
      .addCase(fetchImportById.fulfilled, (state, { payload }) => {
        state.selectedImport = payload;
        state.loadingSingle = 'fulfilled';
      })
      .addCase(fetchImportById.rejected, state => {
        state.loadingSingle = 'rejected';
      })
      .addCase(fetchImportsSummary.pending, state => {
        state.loading = 'loading';
      })
      .addCase(fetchImportsSummary.fulfilled, (state, { payload }) => {
        state.importsList = payload;
        state.loading = 'fulfilled';
      })
      .addCase(fetchImportsSummary.rejected, state => {
        state.loading = 'rejected';
      });
  },
});

const selectSelf = (state: RootState): Imports => state.imports;

export const getImportsList = createSelector(selectSelf, ({ importsList }) => importsList);

export const getSelectedImport = createSelector(selectSelf, ({ selectedImport }) => selectedImport);

export const getLoading = createSelector(selectSelf, ({ loading }) => loading);

export const {
  reducer,
  actions: { selectImport },
} = slice;
