import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import SplashScreen from 'components/SplashScreen';
import { useDispatch } from 'store/hooks';

import type { ChildrenOnlyProps } from 'types/generic';
import type { User } from 'types/user';
import apiClient from 'api/coreAPI';
import { loadState } from 'utils/localStorage';
import { userLogoutAction } from 'store/rootReducer';
import { hideModal } from 'slices/modal';
import { setLanguage } from 'slices/settings';
import { b64EncodeUnicode } from 'utils/encoding';
import AuthContext, {
  authReducer,
  clearLocalStorage,
  initialAuthState,
  passwordRecoveryConfirm,
  passwordRecoveryFor,
  passwordRecoveryStart,
  safeConvertUserDataResponse,
  setSession,
  updateUserPassword,
} from './AuthContext';

const isValidToken = (accessToken: string): boolean => {
  return typeof accessToken === 'string' && accessToken.trim().length > 0;
};

export const SessionAuthProvider: FC<ChildrenOnlyProps> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialAuthState);
  const reduxDispatch = useDispatch();

  const login = async (email: string, password: string) => {
    const response = await apiClient.post<{ token: string; refreshHint: number }>('/users/login', {
      email,
      password: b64EncodeUnicode(password),
    });
    const { token, refreshHint } = response.data;
    setSession(token, refreshHint);
    reduxDispatch(hideModal());
    const { data } = await apiClient.get<User>('/users/me');
    const user = safeConvertUserDataResponse(data);
    dispatch({ type: 'LOGIN', payload: { user } });
    reduxDispatch(setLanguage(user.language));
  };

  const updateUserProfile = async (data: any) => {
    await apiClient.patch<Record<string, unknown>>('/users/me', { ...data });
    const user: User = {
      ...state.user,
      ...data,
      name: `${data.firstName} ${data.lastName}`,
    };
    dispatch({ type: 'UPDATE_USER_PROFILE', payload: { user } });
  };

  const logout = async () => {
    await apiClient
      .get<User>('/users/logout')
      // eslint-disable-next-line no-console
      .catch(error => console.error('Failed logout request:', error))
      .finally(() => {
        // Локальне закриття виконувати незалежно від результату запиту на вихід
        reduxDispatch(userLogoutAction());
        setSession(null);
        dispatch({ type: 'LOGOUT' });
        // eslint-disable-next-line no-console
        console.log('Logged out');
      });
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const persistedAccessToken: string = loadState('acctkn');

        if (persistedAccessToken && isValidToken(persistedAccessToken)) {
          setSession(persistedAccessToken);
          // Можливе настання моменту рекомендованого оновлення сесійного токену (заданого міткою часу в хвилинах)
          const refreshTokenHint: number = loadState('rth');
          if (typeof refreshTokenHint === 'number' && Date.now() > refreshTokenHint * 60 * 1000) {
            const refreshed = await apiClient.post<{ token: string; refreshHint: number }>('/users/refresh');
            setSession(refreshed.data.token, refreshed.data.refreshHint);
          }
          // Запит на повні дані автентифікованого користувача
          const { data } = await apiClient.get<User>('/users/me');
          const user = safeConvertUserDataResponse(data);
          dispatch({ type: 'INITIALIZE', payload: { isAuthenticated: true, user } });
        } else {
          clearLocalStorage();
          dispatch({ type: 'INITIALIZE', payload: { isAuthenticated: false, user: null } });
        }
      } catch (err) {
        dispatch({ type: 'INITIALIZE', payload: { isAuthenticated: false, user: null } });
      }
    };

    initialize();
  }, []);

  if (!state.isInitialized) return <SplashScreen />;

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'session',
        login,
        logout,
        updateUserProfile,
        updateUserPassword,
        passwordRecoveryStart,
        passwordRecoveryFor,
        passwordRecoveryConfirm,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
