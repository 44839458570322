/* eslint-disable @typescript-eslint/no-use-before-define */
/* Специфічні утилітарні функції призначені для роботи з можливими структурами даних виробів */

/**
 * Перевірити чи надане значення є дійсним об'єктом з даними, але не масив і не функція.
 * @param value будь-яке вхідне значення
 * @returns Чи надане значення є типовим об'єктом (не примітив, не масив і не функція).
 */
export const isObjectStrict = (value: unknown): boolean => {
  return value != null && typeof value === 'object' && !Array.isArray(value);
};

/**
 * Клонувати об'єкт з рекурсивним клонуванням об'єктів та масивів всередині нього.
 *
 * Примітивний тип повертається як є. Якщо сам елемент це масив, то він повертається по референсу.
 * @param value вхідний елемент
 * @returns Клон вхідного елементу якщо він дійсний об'єкт, референс якщо він масив, примітивне значення у іншому випадку.
 */
export function itemCloneDeep<T>(value: T): T {
  if (!isObjectStrict(value)) {
    // Якщо елемент являє собою масив – він буде переданий по референсу!
    return value;
  }

  const cloned: Record<string, unknown> = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key in value) {
    if (Object.prototype.hasOwnProperty.call(value, key)) {
      cloned[key] = anyCloneDeep(value[key]);
    }
  }

  return cloned as T;
}

/**
 * Клонувати масив з рекурсивним клонуванням об'єктів які можуть бути його елементами.
 *
 * Важливо! Якщо елемент являє собою масив – він буде переданий по референсу!
 * Тобто дана функція не призначена для клонування структури з багатовимірними масивами [[], []].
 * @param value вхідний масив
 * @returns Клон вхідного масиву або вхідне значення, якщо воно не є масивом.
 */
export function arrayCloneDeep<EL>(value: EL[]): EL[] {
  if (!Array.isArray(value)) return value;
  return [...value].map<EL>(item => itemCloneDeep(item));
}

/**
 * Клонувати значення з рекурсивним клонуванням у випадку об'єкту чи масиву.
 *
 * Примітка! Не передбачене повне клонування багатовимірних масивів, оскільки ця структура даних
 * не використовується в проєкті. Якщо на вході буде [[a, b], [c, d]], то зовнішній масив буде клоновано,
 * але всі вкладені елементи-масиви будуть передані за референсом.
 * @param value вхідне значення
 * @returns Глибоку копію вхідного значення згідно можливих структур даних виробів.
 */
export function anyCloneDeep<T, EL>(value: T | EL[]): T | EL[] {
  if (Array.isArray(value)) return arrayCloneDeep<EL>(value);
  if (isObjectStrict(value)) return itemCloneDeep<T>(value);
  return value;
}
