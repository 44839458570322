/* eslint-disable no-continue */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { ProjectWithRelations } from 'types/ProjectStructure';
import { getProjectWithAllRelationsById, getProjectFinanceTransactions } from 'api/projectEndpoints';
import type { FinanceTransactionStructure } from 'types/FinanceTransactionStructure';

export type FetchingStatus = 'idle' | 'loading' | 'fulfilled' | 'rejected';

interface ViewData {
  loadingProjectWithRelations: FetchingStatus;
  loadingFinanceTransactions: FetchingStatus;
  /**
   * Повний об'єкт проєкту в збереженому на сервері стані з додатковими пов'язаними з ним моделями
   * - client (клієнт що здійснив замовлення)
   * - participants (перелік наявних учасників проєкту)
   * - images (зображення по проєкту)
   *
   * Включення пов'язаних моделей дозволяє отримувати необхідні дані одним запитом.
   */
  projectWithRelations: ProjectWithRelations | null;
  /** Платежі по проєкту (вже здійснені та збережені на сервері) */
  financeTransactions: FinanceTransactionStructure[];
}

const initialState: ViewData = {
  projectWithRelations: null,
  financeTransactions: [],
  loadingProjectWithRelations: 'idle',
  loadingFinanceTransactions: 'idle',
};

export const fetchProjectWithRelations = createAsyncThunk('fetchProjectWithRelations', getProjectWithAllRelationsById);
export const fetchProjectFinanceTransactions = createAsyncThunk(
  'fetchProjectFinanceTransactions',
  getProjectFinanceTransactions,
);

const slice = createSlice({
  name: 'viewData',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clearProjectViewer: (state, _: { payload: undefined | null; type: string }) => {
      // Очищення інструменту завжди має супроводжуватись поверненням до переліку всіх проєктів
      state.projectWithRelations = null;
      state.financeTransactions = [];
      state.loadingProjectWithRelations = 'idle';
      state.loadingFinanceTransactions = 'idle';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProjectWithRelations.pending, state => {
        state.loadingProjectWithRelations = 'loading';
      })
      .addCase(fetchProjectWithRelations.fulfilled, (state, { payload }) => {
        state.projectWithRelations = payload;
        state.loadingProjectWithRelations = 'fulfilled';
      })
      .addCase(fetchProjectWithRelations.rejected, state => {
        state.projectWithRelations = null;
        state.loadingProjectWithRelations = 'rejected';
      })
      .addCase(fetchProjectFinanceTransactions.pending, state => {
        state.loadingFinanceTransactions = 'loading';
      })
      .addCase(fetchProjectFinanceTransactions.fulfilled, (state, { payload }) => {
        state.financeTransactions = payload;
        state.loadingFinanceTransactions = 'fulfilled';
      })
      .addCase(fetchProjectFinanceTransactions.rejected, state => {
        state.financeTransactions = [];
        state.loadingFinanceTransactions = 'rejected';
      });
  },
});

const selectSelf = (state: RootState): ViewData => state.viewData;

export const getLoadingFullProject = createSelector(selectSelf, state => state.loadingProjectWithRelations);
export const getLoadingFinanceTransactions = createSelector(selectSelf, state => state.loadingFinanceTransactions);
export const getProject = createSelector(selectSelf, state => state.projectWithRelations);
export const getProjectId = createSelector(selectSelf, state => state.projectWithRelations?.id);
export const getProjectRooms = createSelector(selectSelf, state => state.projectWithRelations?.rooms ?? []);
export const getProjectParticipants = createSelector(
  selectSelf,
  state => state.projectWithRelations?.participants ?? [],
);
export const getImages = createSelector(selectSelf, state => state.projectWithRelations?.images ?? []);
export const getFinanceTransactions = createSelector(selectSelf, state => state.financeTransactions);

export const {
  reducer,
  actions: { clearProjectViewer },
} = slice;
