/* eslint-disable react/display-name */
import React, { lazy, Suspense, Fragment } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import DashboardLayout from 'layouts/DashboardLayout';
import LoadingScreen from 'components/LoadingScreen';
import AuthGuard from 'views/auth/AuthGuard';
import GuestGuard from 'views/auth/GuestGuard';
import DirectLinkGuard from 'views/auth/DirectLinkGuard';
import AdminGuard from 'views/auth/AdminGuard';
import DesignerGuard from 'views/auth/DesignerGuard';
import SupportGuard from 'views/auth/SupportGuard';
import RepresentativeGuard from 'views/auth/RepresentativeGuard';
import AccountantGuard from 'views/auth/AccountantGuard';
import DirectorGuard from 'views/auth/DirectorGuard';
import ConsultantGuard from 'views/auth/ConsultantGuard';
import ManagerGuard from 'views/auth/ManagerGuard';
import ProductionGuard from 'views/auth/ProductionGuard';
import AtelierGuard from 'views/auth/AtelierGuard';
import DistributorGuard from 'views/auth/DistributorGuard';
import MounterGuard from 'views/auth/MounterGuard';
import HangerGuard from 'views/auth/HangerGuard';
import CourierGuard from 'views/auth/CourierGuard';
import InternGuard from 'views/auth/InternGuard';
import RbacGuard from 'views/auth/RbacGuard';

type Routes = {
  exact?: boolean;
  path?: string;
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
  topBarComponent?: any;
}[];

export const routesConfig: Routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/',
    component: lazy(() => import('views/LoginScreen')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset-password',
    component: lazy(() => import('views/PasswordRecovery/PasswordRecoveryView')),
  },
  {
    exact: true,
    path: '*',
    guard: AuthGuard,
    component: lazy(() => import('views/error/Error404View')),
  },
  {
    path: '/account',
    exact: true,
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('views/account/AccountView')),
  },
  {
    path: '/view',
    guard: DirectLinkGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/view',
        component: () => <Navigate to="/404" />,
      },
      {
        exact: true,
        path: '/view/order/:projectId',
        component: lazy(() => import('views/ProjectViewer/ProjectViewer')),
      },
    ],
  },
  {
    path: '/projects',
    guard: RbacGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/projects',
        component: lazy(() => import('views/projects/ProjectsDashboard')),
      },
    ],
  },
  {
    path: '/consultation',
    guard: RbacGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/consultation',
        component: lazy(() => import('views/consultant')),
      },
    ],
  },
  {
    path: '/materials',
    guard: RbacGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/materials',
        component: lazy(() => import('views/MaterialsView')),
      },
      {
        exact: true,
        path: '/materials/imports',
        component: lazy(() => import('views/ImportsView')),
      },
      {
        exact: true,
        path: '/materials/imports/:importId',
        component: lazy(() => import('views/ImportsView/ImportItem')),
      },
    ],
  },
  {
    path: '/new-project',
    guard: RbacGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/new-project',
        component: lazy(() => import('views/designer')),
      },
      {
        exact: true,
        path: '/new-project/add-product',
        component: lazy(() => import('views/designer/NewOrderProduct')),
      },
      {
        exact: true,
        path: '/new-project/registration-data',
        component: lazy(() => import('views/designer/SaveOrder')),
      },
    ],
  },
  {
    path: '/order',
    guard: RbacGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/order',
        component: () => <Navigate to="/404" />,
      },
      {
        exact: true,
        path: '/order/:projectId/edit',
        component: lazy(() => import('views/ProjectEditor/ProjectEditor')),
      },
      {
        exact: true,
        path: '/order/:projectId/edit/product',
        component: lazy(() => import('views/ProjectEditor/OrderProductEditor/ProductEditor')),
      },
      {
        exact: true,
        path: '/order/:projectId/edit/product/:productId/',
        component: lazy(() => import('views/ProjectEditor/OrderProductEditor/ProductEditor')),
      },
      {
        exact: true,
        path: '/order/:projectId/edit/registration-data',
        component: lazy(() => import('views/ProjectEditor/RegistrationData/RegistrationDataEditor')),
      },
      {
        exact: true,
        path: '/order/:projectId/add-payment',
        component: lazy(() => import('views/payments/RegisterOrderPayment')),
      },
      {
        exact: true,
        path: '/order/:projectId/split',
        component: lazy(() => import('views/ProjectEditor/SubProjectExtractor/SubProjectExtractor')),
      },
    ],
  },
  {
    path: '/user-administration',
    guard: RbacGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/user-administration',
        component: lazy(() => import('views/UserAdministrationView/UserAdministrationView')),
      },
    ],
  },
  {
    path: '/admin',
    guard: AdminGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/admin',
        component: () => <Navigate to="/admin/dashboard" />,
      },
      {
        exact: true,
        path: '/admin/dashboard',
        component: lazy(() => import('components/ProjectsListScreen')),
      },
      // {
      //   component: () => <Navigate to="/404" />,
      // },
    ],
  },
  {
    path: '/designer',
    guard: DesignerGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/designer',
        component: () => <Navigate to="/projects" />,
      },
      // {
      //   component: () => <Navigate to="/404" />,
      // },
    ],
  },
  {
    path: '/support',
    guard: SupportGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/support',
        component: () => <Navigate to="/projects" />,
      },
      // {
      //   component: () => <Navigate to="/404" />,
      // },
    ],
  },
  {
    path: '/representative',
    guard: RepresentativeGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/representative',
        component: () => <Navigate to="/materials" />,
      },
      // {
      //   component: () => <Navigate to="/404" />,
      // },
    ],
  },
  {
    path: '/accountant',
    guard: AccountantGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/accountant',
        component: () => <Navigate to="/accountant/dashboard" />,
      },
      {
        exact: true,
        path: '/accountant/dashboard',
        component: lazy(() => import('components/ProjectsListScreen')),
      },
      // {
      //   component: () => <Navigate to="/404" />,
      // },
    ],
  },
  {
    path: '/director',
    guard: DirectorGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/director',
        component: () => <Navigate to="/director/dashboard" />,
      },
      {
        exact: true,
        path: '/director/dashboard',
        component: lazy(() => import('components/ProjectsListScreen')),
      },
      // {
      //   component: () => <Navigate to="/404" />,
      // },
    ],
  },
  {
    path: '/consultant',
    guard: ConsultantGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/consultant',
        component: () => <Navigate to="/consultation" />,
      },
      {
        exact: true,
        path: '/consultant/tool',
        component: () => <Navigate to="/consultation" />,
      },
      // {
      //   component: () => <Navigate to="/404" />,
      // },
    ],
  },
  {
    path: '/manager',
    guard: ManagerGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/manager',
        component: () => <Navigate to="/projects" />,
      },
      // {
      //   component: () => <Navigate to="/404" />,
      // },
    ],
  },
  {
    path: '/production',
    guard: ProductionGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/production',
        component: () => <Navigate to="/production/dashboard" />,
      },
      {
        exact: true,
        path: '/production/dashboard',
        component: lazy(() => import('components/ProjectsListScreen')),
      },
      // {
      //   component: () => <Navigate to="/404" />,
      // },
    ],
  },
  {
    path: '/atelier',
    guard: AtelierGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/atelier',
        component: () => <Navigate to="/atelier/dashboard" />,
      },
      {
        exact: true,
        path: '/atelier/dashboard',
        component: lazy(() => import('components/ProjectsListScreen')),
      },
      // {
      //   component: () => <Navigate to="/404" />,
      // },
    ],
  },
  {
    path: '/distributor',
    guard: DistributorGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/distributor',
        component: () => <Navigate to="/distributor/dashboard" />,
      },
      {
        exact: true,
        path: '/distributor/dashboard',
        component: lazy(() => import('components/ProjectsListScreen')),
      },
      // {
      //   component: () => <Navigate to="/404" />,
      // },
    ],
  },
  {
    path: '/mounter',
    guard: MounterGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/mounter',
        component: () => <Navigate to="/mounter/dashboard" />,
      },
      {
        exact: true,
        path: '/mounter/dashboard',
        component: lazy(() => import('components/ProjectsListScreen')),
      },
      // {
      //   component: () => <Navigate to="/404" />,
      // },
    ],
  },
  {
    path: '/hanger',
    guard: HangerGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/hanger',
        component: () => <Navigate to="/hanger/dashboard" />,
      },
      {
        exact: true,
        path: '/hanger/dashboard',
        component: lazy(() => import('components/ProjectsListScreen')),
      },
      // {
      //   component: () => <Navigate to="/404" />,
      // },
    ],
  },
  {
    path: '/courier',
    guard: CourierGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/courier',
        component: () => <Navigate to="/courier/dashboard" />,
      },
      {
        exact: true,
        path: '/courier/dashboard',
        component: lazy(() => import('components/ProjectsListScreen')),
      },
      // {
      //   component: () => <Navigate to="/404" />,
      // },
    ],
  },
  {
    path: '/intern',
    guard: InternGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/intern',
        component: () => <Navigate to="/intern/dashboard" />,
      },
      {
        exact: true,
        path: '/intern/dashboard',
        component: lazy(() => import('components/ProjectsListScreen')),
      },
      // {
      //   component: () => <Navigate to="/404" />,
      // },
    ],
  },
  // {
  //   component: () => <Navigate to="/404" />,
  // },
];

export const renderRoutes: any = (routes: Routes = []) => {
  return routes.map(route => {
    const Guard = route.guard || Fragment;
    const Layout = route.layout || Fragment;
    const Component = route.component;
    return {
      path: route.path,
      element: route.component && (
        <Guard path={route.path}>
          <Layout>
            <Component />
          </Layout>
        </Guard>
      ),
      children:
        route.routes && renderRoutes(route.routes.map(r => ({ ...r, layout: route.layout, guard: route.guard }))),
    };
  });
};

const RenderRoutes: React.FC = (): JSX.Element => {
  const routing = useRoutes(renderRoutes(routesConfig));
  return <Suspense fallback={<LoadingScreen />}>{routing}</Suspense>;
};

export default RenderRoutes;
