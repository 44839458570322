/* eslint class-methods-use-this: ["error", { "exceptMethods": ["hydrate"] }] */
import type { ServiceGroupKey, ServiceKey } from 'types/product-services';
import type { ProductComponentKey } from 'types/ProductMaterials';
import type { ProductDesignSettingKey } from 'types/ProductDesignData';
import type { ProductStructure } from 'types/ProductStructure';
import type { ProductPrototype } from './ProductPrototype';
import Product from './Product';

/**
 * Клас римської штори "Стандарт"
 */
export default class StandardRomanBlindDoubleProduct extends Product implements ProductPrototype {
  protected readonly _validDesignSettings: ProductDesignSettingKey[] = [
    'width',
    'height',
    'mountTarget',
    'controlSide',
    'controlChainLength',
    'sticksOverallCount',
    'productionOptions',
    'model',
    'nonStandardForm',
  ];

  protected readonly _requiredDesignSettings: ProductDesignSettingKey[] = [
    'width',
    'height',
    'controlSide',
    'controlChainLength',
  ];

  protected readonly _validComponentKeysOf: {
    [componentType: string]: ProductComponentKey[];
  } = {
    textile: [],
    webbing: [],
    furniture: [],
    curtainRod: [],
    accessory: [],
    productFulfillment: ['standardRomanBlind', 'standardRomanBlindSecondary', 'standardRomanBlindDoubleSystem'],
  };

  protected readonly _validServiceGroupKeys: ServiceGroupKey[] = ['mount', 'disassembling', 'custom'];

  protected readonly _validServiceKeysOf: {
    [serviceType: string]: ServiceKey[];
  } = {
    sewing: [],
    hanging: [],
    mount: ['mountRomanBlindInhort', 'mountRomanBlindInhortComplex', 'mountCustom'],
    disassembling: ['disassemblingCurtainRod', 'disassemblingCustom'],
    custom: ['customServices'],
  };

  constructor(baseProduct?: StandardRomanBlindDoubleProduct | Product | ProductStructure) {
    super(baseProduct);
    this.calcMethod = 'standardRomanBlindDouble';
    // Обов'язкові та типові складові включені за умовчанням
    /* Стандартизований виріб від постачальника */
    if (typeof this.design.standardRomanBlind === 'undefined') {
      this.design.standardRomanBlind = {
        amount: 1,
        unit: 'шт.',
        amountToBuy: 1,
        unitToBuy: 'шт.',
        totalPrice: undefined,
        isManualAmount: false,
        manualAmountAuthor: undefined,
        isManualPrice: false,
        manualPriceAuthor: undefined,
        required: true,
      };
    } else {
      // Імплементація даних з базового виробу вже відбулась в конструкторі батьківського класу
      // проте залишається необхідність переконатись в обов'язковості складової та повному наборі даних
      this.design.standardRomanBlind.required = true;
      this.design.standardRomanBlind.amount = this.design.standardRomanBlind.amount ?? 1;
      this.design.standardRomanBlind.amountToBuy = this.design.standardRomanBlind.amountToBuy ?? 1;
      this.design.standardRomanBlind.unit = this.design.standardRomanBlind.unit ?? 'шт.';
      this.design.standardRomanBlind.unitToBuy = this.design.standardRomanBlind.unitToBuy ?? 'шт.';
      this.design.standardRomanBlind.isManualAmount = this.design.standardRomanBlind.isManualAmount ?? false;
      this.design.standardRomanBlind.isManualPrice = this.design.standardRomanBlind.isManualPrice ?? false;
    }

    if (typeof this.design.standardRomanBlindSecondary === 'undefined') {
      this.design.standardRomanBlindSecondary = {
        amount: 1,
        unit: 'шт.',
        amountToBuy: 1,
        unitToBuy: 'шт.',
        totalPrice: undefined,
        isManualAmount: false,
        manualAmountAuthor: undefined,
        isManualPrice: false,
        manualPriceAuthor: undefined,
        required: true,
      };
    } else {
      // Імплементація даних з базового виробу вже відбулась в конструкторі батьківського класу
      // проте залишається необхідність переконатись в обов'язковості складової та повному наборі даних
      this.design.standardRomanBlindSecondary.required = true;
      this.design.standardRomanBlindSecondary.amount = this.design.standardRomanBlindSecondary.amount ?? 1;
      this.design.standardRomanBlindSecondary.amountToBuy = this.design.standardRomanBlindSecondary.amountToBuy ?? 1;
      this.design.standardRomanBlindSecondary.unit = this.design.standardRomanBlindSecondary.unit ?? 'шт.';
      this.design.standardRomanBlindSecondary.unitToBuy = this.design.standardRomanBlindSecondary.unitToBuy ?? 'шт.';
      this.design.standardRomanBlindSecondary.isManualAmount =
        this.design.standardRomanBlindSecondary.isManualAmount ?? false;
      this.design.standardRomanBlindSecondary.isManualPrice =
        this.design.standardRomanBlindSecondary.isManualPrice ?? false;
    }

    if (typeof this.design.standardRomanBlindDoubleSystem === 'undefined') {
      this.design.standardRomanBlindDoubleSystem = {
        amount: 1,
        unit: 'шт.',
        amountToBuy: 1,
        unitToBuy: 'шт.',
        totalPrice: undefined,
        isManualAmount: false,
        manualAmountAuthor: undefined,
        isManualPrice: false,
        manualPriceAuthor: undefined,
        required: true,
      };
    } else {
      // Імплементація даних з базового виробу вже відбулась в конструкторі батьківського класу
      // проте залишається необхідність переконатись в обов'язковості складової та повному наборі даних
      this.design.standardRomanBlindDoubleSystem.required = true;
      this.design.standardRomanBlindDoubleSystem.amount = this.design.standardRomanBlindDoubleSystem.amount ?? 1;
      this.design.standardRomanBlindDoubleSystem.amountToBuy =
        this.design.standardRomanBlindDoubleSystem.amountToBuy ?? 1;
      this.design.standardRomanBlindDoubleSystem.unit = this.design.standardRomanBlindDoubleSystem.unit ?? 'шт.';
      this.design.standardRomanBlindDoubleSystem.unitToBuy =
        this.design.standardRomanBlindDoubleSystem.unitToBuy ?? 'шт.';
      this.design.standardRomanBlindDoubleSystem.isManualAmount =
        this.design.standardRomanBlindDoubleSystem.isManualAmount ?? false;
      this.design.standardRomanBlindDoubleSystem.isManualPrice =
        this.design.standardRomanBlindDoubleSystem.isManualPrice ?? false;
    }

    // Типові послуги увімкнені за умовчанням — відсутні
  }

  clone(): ProductPrototype {
    const clone = new StandardRomanBlindDoubleProduct(this);
    // Ідентифікатор клону не має збігатись з його прототипом!
    clone.id = undefined;
    return clone;
  }

  hydrate(structure: ProductStructure): ProductPrototype {
    const hydrated = new StandardRomanBlindDoubleProduct(structure);
    hydrated.calcMethod = 'standardRomanBlindDouble';
    return hydrated;
  }
}
