import type { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
        backgroundColor: '#f4f6f8',
      },
      '#root': {
        height: '100%',
      },
      'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      'input[type=number]': {
        '-moz-appearance': 'textfield',
      },
    },
  }),
);

const GlobalStyles: FC = (): null => {
  useStyles();

  return null;
};

export default GlobalStyles;
