export const SIDEBAR_WIDTH = 256;
export const MATERIAL_CARD_WIDTH = 120;
export const MATERIAL_CARD_HEIGHT = 140;
export const DETAILED_MATERIAL_CARD_WIDTH = 320;
export const DETAILED_MATERIAL_CARD_WIDTH_FULL_TABLET = 680;
export const DETAILED_MATERIAL_CARD_HEIGHT = 200;
export const DETAILED_MATERIAL_CARD_MEDIA_WIDTH = 120;
export const MATERIAL_DETAILS_AREA_MEDIA_WIDTH = 200;
export const TABLE_MIN_WIDTH = 1150;
export const TOOLTIP_ENTER_DELAY = 200;
export const TABLE_ROW_IMAGE_SIZE = 40;
