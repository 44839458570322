import type { FinanceTransactionStructure } from 'types/FinanceTransactionStructure';
import type { ProjectParticipant } from 'types/ProjectParticipant';
import type { ProjectsByStatus } from 'types/ProjectsByStatus';
import type { ProjectStructure, ProjectWithRelations } from 'types/ProjectStructure';
import type { ClientStructure } from 'types/ClientStructure';
import type { ProjectImage } from 'types/ProjectImage';
import type { SplitProjectRequestData } from 'types/SplitProjectRequestData';
import apiClient, { CountSchema } from './coreAPI';
import { ComplexError, errorParser } from './errorsParser';

export const getMyProjects = async (
  search?: string,
  includeCompleted?: boolean,
  includeRefusal?: boolean,
): Promise<ProjectsByStatus> => {
  try {
    // Зібрати параметри запиту
    const queryParams = new URLSearchParams();
    if (search) queryParams.append('search', search);
    if (includeCompleted) queryParams.append('includeCompleted', 'true');
    if (includeRefusal) queryParams.append('includeRefusal', 'true');
    const { data } = await apiClient.get<ProjectsByStatus>(
      `/projects/my${queryParams.toString() ? '?' + queryParams.toString() : ''}`,
    );
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const getProjectById = async (id: string): Promise<ProjectWithRelations> => {
  try {
    const { data } = await apiClient.get<ProjectWithRelations>(
      `/projects/${encodeURIComponent(id)}?filter[include][]=participants`,
    );
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const getProjectWithAllRelationsById = async (id: string): Promise<ProjectWithRelations> => {
  try {
    const { data } = await apiClient.get<ProjectWithRelations>(
      `/projects/${encodeURIComponent(
        id,
      )}?filter[include][]=client&filter[include][]=participants&filter[include][]=images`,
    );
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const patchProjectById = async (id: string, payload: Partial<ProjectStructure>): Promise<void> => {
  try {
    await apiClient.patch<Partial<ProjectStructure>>(`/projects/${encodeURIComponent(id)}`, payload);
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

/**
 * Встановлення або зміна ідентифікатора CRM угоди пов'язаної з проєктом
 * @param id ідентифікатор проєкту
 * @param crmId ідентифікатор угоди в CRM
 */
export const updateDealIdOfSpecificProject = async (id: string, crmId: string): Promise<void> => {
  try {
    await apiClient.patch<Partial<ProjectStructure>>(`/projects/${encodeURIComponent(id)}/lead-id`, { crmId });
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const postProject = async (payload: ProjectStructure): Promise<ProjectStructure> => {
  try {
    const { data } = await apiClient.post<ProjectStructure>('/projects', payload);
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const postProjectParticipant = async (
  projectId: string,
  payload: Omit<ProjectParticipant, 'projectId'>,
): Promise<ProjectParticipant> => {
  try {
    const { data } = await apiClient.post<ProjectParticipant>(
      `/projects/${encodeURIComponent(projectId)}/participants`,
      payload,
    );
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const getClientOfProject = async (projectId: string): Promise<ClientStructure> => {
  try {
    const { data } = await apiClient.get<ClientStructure>(`/projects/${encodeURIComponent(projectId)}/client`);
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const countProjectsOfClient = async (clientId: string): Promise<CountSchema> => {
  try {
    const { data } = await apiClient.get<CountSchema>(
      `/projects/count?where[clientId]=${encodeURIComponent(clientId)}`,
    );
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const getProjectFinanceTransactions = async (projectId: string): Promise<FinanceTransactionStructure[]> => {
  try {
    const { data } = await apiClient.get<FinanceTransactionStructure[]>(
      `/projects/${encodeURIComponent(projectId)}/finance-transactions?filter[order]=when%20ASC`,
    );
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const postProjectFinanceTransaction = async (
  projectId: string,
  payload: Omit<FinanceTransactionStructure, 'projectId'>,
): Promise<FinanceTransactionStructure> => {
  try {
    const { data } = await apiClient.post<FinanceTransactionStructure>(
      `/projects/${encodeURIComponent(projectId)}/finance-transactions`,
      payload,
    );
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const getProjectImages = async (projectId: string): Promise<ProjectImage[]> => {
  try {
    const { data } = await apiClient.get<ProjectImage[]>(`/projects/${encodeURIComponent(projectId)}/images`);
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const postSingleProjectImage = async (
  projectId: string,
  file: File,
  fileName: string,
  fields: ProjectImage,
): Promise<ProjectImage> => {
  try {
    // Перевірити наявність обов'язкових даних
    if (typeof fileName !== 'string' || fileName.trim().length === 0 || !file) {
      throw new Error('Відсутній файл зображення або його дані');
    }
    if (
      typeof fields.userId !== 'string' ||
      typeof fields.source !== 'string' ||
      typeof fields.targetModel !== 'string' ||
      fields.userId.trim().length === 0 ||
      fields.source.trim().length === 0 ||
      fields.targetModel.trim().length === 0
    ) {
      throw new Error("Відсутні обов'язкові параметри зображення");
    }

    // Підготувати multipart/form-data
    const multipart = new FormData();
    multipart.append('userId', fields.userId.trim());
    multipart.append('position', fields.position.toString(10));
    multipart.append('printInDocuments', fields.printInDocuments ? 'true' : 'false');
    multipart.append('source', fields.source.trim());
    if (typeof fields.mime === 'string' && fields.mime.trim().length > 0) multipart.append('mime', fields.mime.trim());
    if (typeof fields.fileSize === 'number' && fields.fileSize > 0)
      multipart.append('fileSize', fields.fileSize.toString(10));
    if (typeof fields.originalName === 'string' && fields.originalName.trim().length > 0)
      multipart.append('originalName', fields.originalName.trim());
    if (fields.sketchData) multipart.append('sketchData', JSON.stringify(fields.sketchData));
    multipart.append('targetModel', fields.targetModel.trim());
    if (typeof fields.productId === 'string' && fields.productId.trim().length > 0)
      multipart.append('productId', fields.productId.trim());
    if (typeof fields.roomName === 'string' && fields.roomName.trim().length > 0)
      multipart.append('roomName', fields.roomName.trim());
    multipart.append('image', file, fileName);

    const { data } = await apiClient.post<ProjectImage>(`/projects/${encodeURIComponent(projectId)}/images`, multipart);
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const patchSingleProjectImage = async (
  projectId: string,
  imageSlug: string,
  payload: Partial<Omit<ProjectImage, 'projectId'>>,
): Promise<CountSchema> => {
  if (typeof imageSlug !== 'string' || imageSlug.trim().length === 0) {
    throw new Error("Відсутній обов'язковий аргумент imageSlug");
  }
  try {
    const { data } = await apiClient.patch<CountSchema>(
      `/projects/${encodeURIComponent(projectId)}/images?where[slug]=${encodeURIComponent(imageSlug)}`,
      payload,
    );
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const deleteSingleProjectImage = async (projectId: string, imageSlug: string): Promise<CountSchema> => {
  if (typeof imageSlug !== 'string' || imageSlug.trim().length === 0) {
    throw new Error("Відсутній обов'язковий аргумент imageSlug");
  }
  try {
    const { data } = await apiClient.delete<CountSchema>(
      `/projects/${encodeURIComponent(projectId)}/images?where[slug]=${encodeURIComponent(imageSlug)}`,
    );
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const deleteAllImagesOfProduct = async (projectId: string, productId: string): Promise<CountSchema> => {
  if (typeof productId !== 'string' || productId.trim().length === 0) {
    throw new Error("Відсутній обов'язковий аргумент imageSlug");
  }
  try {
    const { data } = await apiClient.delete<CountSchema>(
      `/projects/${encodeURIComponent(projectId)}/images?where[productId]=${encodeURIComponent(productId)}`,
    );
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const postSplitProjectRequest = async (payload: SplitProjectRequestData): Promise<ProjectStructure> => {
  try {
    if (!payload.baseProject.id) {
      throw new Error(
        'Запит не виконано: відсутній ідентифікатор базового замовлення (розділяти допустимо лише збережене замовлення)',
      );
    }
    const { data } = await apiClient.post<ProjectStructure>(
      `/projects/${encodeURIComponent(payload.baseProject.id)}/split`,
      payload,
    );
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export default {
  getMyProjects,
  getProjectById,
  getProjectWithAllRelationsById,
  patchProjectById,
  updateDealIdOfSpecificProject,
  postProject,
  postProjectParticipant,
  getClientOfProject,
  countProjectsOfClient,
  getProjectFinanceTransactions,
  postProjectFinanceTransaction,
  getProjectImages,
  postSingleProjectImage,
  patchSingleProjectImage,
  deleteSingleProjectImage,
  deleteAllImagesOfProduct,
  postSplitProjectRequest,
};
