/* eslint-disable no-continue */
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { merge, get, pull, isEmpty, pullAll, remove } from 'lodash';
import { v4 as uuid } from 'uuid';

import { RootState } from 'store';
import { ProjectStructure, ProjectWithRelations } from 'types/ProjectStructure';
import { loadState } from 'utils/localStorage';
import type { ProjectsByStatus } from 'types/ProjectsByStatus';
import {
  getMyProjects,
  getProjectById,
  getProjectFinanceTransactions,
  getClientOfProject,
  getProjectImages,
  postSingleProjectImage,
  patchSingleProjectImage,
  deleteSingleProjectImage,
  deleteAllImagesOfProduct,
  postSplitProjectRequest,
} from 'api/projectEndpoints';
import FreeformProduct from 'domain/products/FreeformProduct';
import type { FinanceTransactionStructure } from 'types/FinanceTransactionStructure';
import type { ProductStructure } from 'types/ProductStructure';
import type { ClientStructure } from 'types/ClientStructure';
import type { ProjectParticipant } from 'types/ProjectParticipant';
import type { ProjectImage, ProjectImageProto } from 'types/ProjectImage';
import type { CountSchema } from 'api/coreAPI';
import type { SplitProjectRequestData } from 'types/SplitProjectRequestData';
import type { ProjectStatus } from 'domain/project/status';

export type FetchingStatus = 'idle' | 'loading' | 'fulfilled' | 'rejected';
export type ProcessingStatus = 'idle' | 'processing' | 'fulfilled' | 'rejected';
export interface ItemProcessingState {
  updating: ProcessingStatus;
  deleting: ProcessingStatus;
  errorMessage?: string;
}
export interface ProjectExtractionState {
  state: ProcessingStatus;
  baseProjectId: string | null;
  extractedProjectId: string | null;
  errorMessage?: string;
}
/** Налаштування фільтрів та пошукового запиту по замовленням  */
export interface ProjectSearchFilters {
  searchQuery?: string;
  isIncludeCompleted: boolean;
  isIncludeRefusal: boolean;
}

interface Projects {
  projectTool:
    | {
        status: 'viewAll';
        projectId: null;
      }
    | {
        status: 'view';
        projectId: string;
      }
    | {
        status: 'payments';
        projectId: string;
      }
    | {
        status: 'edit';
        projectId: string;
      };
  productTool:
    | {
        status: 'create';
        productId: null;
      }
    | {
        status: 'edit';
        productId: string;
      };
  searchFilters: ProjectSearchFilters;
  selectedStatusTab: ProjectStatus;
  loadingProjectsByStatus: FetchingStatus;
  loadingFullProject: FetchingStatus;
  loadingFinanceTransactions: FetchingStatus;
  loadingClient: FetchingStatus;
  loadingImages: FetchingStatus;
  processingImages: Record<string, ItemProcessingState | undefined>;
  projectsByStatus: ProjectsByStatus | null;
  /** Повний об'єкт проєкту в оригінальному стані */
  project: ProjectWithRelations | null;
  /** Об'єкт змін до проєкту */
  projectChanges: Partial<ProjectStructure>;
  /** Перелік наявних учасників проєкту */
  participants: ProjectParticipant[];
  /** Зображення по проєкту (вже збережені на сервері) */
  images: ProjectImage[];
  /** Нові зображення по проєкту (дані зображень для збереження на сервері) */
  newImages: ProjectImageProto[];
  /** Платежі по проєкту (вже здійснені та збережені на сервері) */
  financeTransactions: FinanceTransactionStructure[];
  /** Нові платежі по проєкту (дані налаштувань платежів для збереження) */
  newFinanceTransactions: FinanceTransactionStructure[];
  /** Клієнт що здійснив замовлення (поточне при редагуванні чи детальному перегляді) */
  client: ClientStructure | null;
  /** Об'єкт змін до клієнта */
  clientChanges: Partial<ClientStructure>;
  /** Новий учасник проєкту (наявні перебувають у властивості `participants`) */
  newParticipant: ProjectParticipant | null;
  /** Набір виробів при редагуванні замовлення */
  products: ProductStructure[];
  /** Перелік обраних виробів */
  selectedProducts: string[];
  /** Поточний крок інструменту створення нового виробу */
  activeStep: number | null;
  /** Виріб що налаштовується редактором (поточний) */
  product: ProductStructure;
  /* Дані запиту на виокремлення з редагованого проєкту частини виробів у самостійний проєкт */
  subProjectExtractionData: SplitProjectRequestData | null;
  subProjectExtractionState: ProjectExtractionState;
}

const persistedState = loadState('projects_tool');
const persistedProjectTool = get(persistedState, 'projectTool', { status: 'viewAll', projectId: null });
const persistedProductTool = get(persistedState, 'productTool', { status: 'create', productId: null });
const persistedSelectedStatusTab = get<ProjectStatus>(persistedState, 'selectedStatusTab', 'new');
const persistedLoadingFullProject = get<FetchingStatus>(persistedState, 'loadingFullProject', 'idle');
const persistedLoadingFinanceTransactions = get<FetchingStatus>(persistedState, 'loadingFinanceTransactions', 'idle');
const persistedLoadingClient = get<FetchingStatus>(persistedState, 'loadingClient', 'idle');
const persistedProjectsByStatus = get<ProjectsByStatus | null>(persistedState, 'projectsByStatus', null);
const persistedProject = get<ProjectStructure | null>(persistedState, 'project', null);
const persistedProjectChanges = get<Partial<ProjectStructure>>(persistedState, 'projectChanges', {});
const persistedParticipants = get<ProjectParticipant[]>(persistedState, 'participants', []);
const persistedImages = get<ProjectImage[]>(persistedState, 'images', []);
const persistedFinanceTransactions = get<FinanceTransactionStructure[]>(persistedState, 'financeTransactions', []);
const persistedNewFinanceTransactions = get<FinanceTransactionStructure[]>(
  persistedState,
  'newFinanceTransactions',
  [],
);
const persistedProducts = get<ProductStructure[]>(persistedState, 'products', []);
const persistedSelectedProducts = get<string[]>(persistedState, 'selectedProducts', []);
const persistedActiveStep = get<number>(persistedState, 'activeStep', 0);
const persistedProduct = get<ProductStructure>(persistedState, 'product', new FreeformProduct());
const persistedClient = get<ClientStructure | null>(persistedState, 'client', null);
const persistedClientChanges = get<Partial<ClientStructure>>(persistedState, 'clientChanges', {});
const persistedNewParticipant = get<ProjectParticipant | null>(persistedState, 'newParticipant', null);
const persistedSubProjectExtractionData = get<SplitProjectRequestData | null>(
  persistedState,
  'subProjectExtractionData',
  null,
);
const persistedSubProjectExtractionState = get<ProjectExtractionState>(persistedState, 'subProjectExtractionState', {
  state: 'idle',
  baseProjectId: null,
  extractedProjectId: null,
});

const initialState: Projects = {
  projectTool: persistedProjectTool,
  searchFilters: {
    searchQuery: undefined,
    isIncludeCompleted: false,
    isIncludeRefusal: false,
  },
  selectedStatusTab: persistedSelectedStatusTab,
  productTool: persistedProductTool,
  activeStep: persistedActiveStep,
  projectsByStatus: persistedProjectsByStatus,
  project: persistedProject,
  projectChanges: persistedProjectChanges,
  participants: persistedParticipants,
  images: persistedImages,
  newImages: [],
  financeTransactions: persistedFinanceTransactions,
  newFinanceTransactions: persistedNewFinanceTransactions,
  products: persistedProducts,
  selectedProducts: persistedSelectedProducts,
  product: persistedProduct,
  client: persistedClient,
  clientChanges: persistedClientChanges,
  newParticipant: persistedNewParticipant,
  // При перезавантаженні сторінки завжди має виконуватись повторний запит на проєкти користувача!
  loadingProjectsByStatus: 'idle',
  // Цільний поточний проєкт та його фінансові транзакції навпаки повинні перезавантажуватись у той самий стан
  // тому необхідно крім даних зберігати індикатори стану запитів.
  loadingFullProject: persistedLoadingFullProject,
  loadingFinanceTransactions: persistedLoadingFinanceTransactions,
  loadingClient: persistedLoadingClient,
  // Дані зображень краще отримувати заново після перезавантаження
  loadingImages: 'idle',
  processingImages: {},
  // Дані та стан запиту на виокремлення дочірнього замовлення має залишатись сталими при перезавантаженні
  subProjectExtractionData: persistedSubProjectExtractionData,
  subProjectExtractionState: persistedSubProjectExtractionState,
};

export const fetchMyProjects = createAsyncThunk<ProjectsByStatus, ProjectSearchFilters | undefined>(
  'fetchMyProjects',
  async thunkArgs => getMyProjects(thunkArgs?.searchQuery, thunkArgs?.isIncludeCompleted, thunkArgs?.isIncludeRefusal),
);
export const fetchFullProject = createAsyncThunk('fetchFullProject', getProjectById);
export const fetchProjectClient = createAsyncThunk('fetchProjectClient', getClientOfProject);
export const fetchProjectImages = createAsyncThunk('fetchProjectImagesForEditedOrder', getProjectImages, {
  condition: (_, { getState }) => {
    const { projects } = getState() as RootState;
    if (projects) {
      const fetchStatus = (projects as Projects).loadingImages;
      if (fetchStatus === 'loading' || fetchStatus === 'fulfilled') {
        // Вже в процесі або був успішно виконаний, повторний запит не потрібен
        return false;
      }
    }
    return undefined;
  },
});
export const fetchProjectFinanceTransactions = createAsyncThunk(
  'fetchProjectFinanceTransactions',
  getProjectFinanceTransactions,
);

export const postNewProjectImage = createAsyncThunk<
  ProjectImage,
  { projectId: string; file: File; fileName: string; fields: ProjectImage }
>('postNewProjectImageForEditedOrder', async thunkArgs =>
  postSingleProjectImage(thunkArgs.projectId, thunkArgs.file, thunkArgs.fileName, thunkArgs.fields),
);
export const patchProjectImage = createAsyncThunk<
  CountSchema,
  { projectId: string; imageSlug: string; payload: Partial<Omit<ProjectImage, 'projectId'>> }
>('patchProjectImageForEditedOrder', async thunkArgs =>
  patchSingleProjectImage(thunkArgs.projectId, thunkArgs.imageSlug, thunkArgs.payload),
);
export const delProjectImage = createAsyncThunk<CountSchema, { projectId: string; imageSlug: string }>(
  'delProjectImageForEditedOrder',
  async thunkArgs => deleteSingleProjectImage(thunkArgs.projectId, thunkArgs.imageSlug),
);
export const delAllProductImages = createAsyncThunk<CountSchema, { projectId: string; productId: string }>(
  'delAllProductImagesInEditedOrder',
  async thunkArgs => deleteAllImagesOfProduct(thunkArgs.projectId, thunkArgs.productId),
);

export const splitProjectRequest = createAsyncThunk<ProjectStructure, SplitProjectRequestData>(
  'postSplitProjectRequest',
  async thunkArgs => postSplitProjectRequest(thunkArgs),
  {
    condition: (_, { getState }) => {
      const { projects } = getState() as RootState;
      if (projects) {
        const extractionState = (projects as Projects).subProjectExtractionState.state;
        if (extractionState === 'processing' || extractionState === 'fulfilled') {
          // Вже в процесі або був успішно виконаний, повторний запит не потрібен
          return false;
        }
      }
      return undefined;
    },
  },
);

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setupViewAllProjectTool: state => {
      state.projectTool = { status: 'viewAll', projectId: null };
      state.project = null;
      state.participants = [];
      state.searchFilters = {
        isIncludeCompleted: false,
        isIncludeRefusal: false,
      };
    },
    setupViewProjectTool: (
      state,
      { payload: { id, project } }: PayloadAction<{ id: string; project: ProjectWithRelations }>,
    ) => {
      state.projectTool = { status: 'view', projectId: id };
      state.project = merge({}, project);
      state.participants = project.participants || [];
    },
    setupPaymentsProjectTool: (state, { payload: { id } }: PayloadAction<{ id: string }>) => {
      state.projectTool = { status: 'payments', projectId: id };
      state.project = null;
      state.projectChanges = {};
      state.participants = [];
      state.products = [];
      state.images = [];
      state.newImages = [];
      state.selectedProducts = [];
      state.financeTransactions = [];
      state.newFinanceTransactions = [];
      state.loadingFullProject = 'idle';
      state.loadingFinanceTransactions = 'idle';
      state.loadingImages = 'idle';
      state.processingImages = {};
    },
    setupEditProjectTool: (state, { payload: { id } }: PayloadAction<{ id: string }>) => {
      state.projectTool = { status: 'edit', projectId: id };
      state.project = null;
      state.participants = [];
      state.client = null;
      state.images = [];
      state.newImages = [];
      state.financeTransactions = [];
      state.projectChanges = {};
      state.clientChanges = {};
      state.newParticipant = null;
      state.newFinanceTransactions = [];
      state.loadingFullProject = 'idle';
      state.loadingFinanceTransactions = 'idle';
      state.loadingClient = 'idle';
      state.loadingImages = 'idle';
      state.processingImages = {};
      state.productTool = { status: 'create', productId: null };
      state.activeStep = 0;
      state.product = new FreeformProduct();
      state.products = [];
      state.selectedProducts = [];
    },
    updateSearchFilters: (state, { payload }: PayloadAction<ProjectSearchFilters>) => {
      state.searchFilters = payload;
    },
    updateSelectedStatusTab: (state, { payload }: PayloadAction<ProjectStatus>) => {
      state.selectedStatusTab = payload;
    },
    setupCreateProductTool: (state, { payload }: PayloadAction<'blank' | 'continue'>) => {
      if (state.product === null || payload === 'blank') {
        state.activeStep = 0;
      } else if (state.productTool.status === 'edit') {
        // Цією дією можливо продовжити створення виробу, а не редагування
        state.activeStep = 0;
      }
      state.productTool = { status: 'create', productId: null };
      state.newImages = [];
      state.processingImages = {};
    },
    setupEditProductTool: (
      state,
      { payload: { id, product } }: PayloadAction<{ id: string; product: ProductStructure }>,
    ) => {
      state.productTool = { status: 'edit', productId: id };
      state.activeStep = 1;
      state.product = merge({}, product);
      state.newImages = [];
      state.processingImages = {};
    },
    setActiveStep: (state, { payload }) => {
      state.activeStep = payload;
    },
    updateProject: (state, { payload }: PayloadAction<ProjectStructure | null>) => {
      state.project = payload;
    },
    updateProjectChanges: (state, { payload }: PayloadAction<Partial<ProjectStructure>>) => {
      state.projectChanges = payload;
    },
    updateProjectsByStatus: (state, { payload }: PayloadAction<ProjectsByStatus | null>) => {
      state.projectsByStatus = payload;
    },
    updateNewFinanceTransactions: (
      state,
      { payload }: PayloadAction<{ index: number | null; payments: FinanceTransactionStructure[] }>,
    ) => {
      if (payload.index === null) {
        state.newFinanceTransactions = payload.payments;
      } else if (payload.index >= 0) {
        if (payload.payments.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          state.newFinanceTransactions[payload.index] = payload.payments[0];
        } else if (payload.payments.length > 1) {
          state.newFinanceTransactions = [
            ...state.newFinanceTransactions.slice(0, payload.index),
            ...payload.payments,
            ...state.newFinanceTransactions.slice(payload.index + payload.payments.length),
          ];
        }
      }
    },
    addSelectedOrderProduct: (state, { payload }: PayloadAction<string>) => {
      state.selectedProducts.push(payload);
      // Зберегти факт зміни в перелік виробів як тригер необхідності зафіксувати зміни в проєкт
      state.projectChanges.extraData = {
        ...(state.projectChanges.extraData || {}),
        products: state.products,
      };
    },
    removeSelectedOrderProduct: (state, { payload }: PayloadAction<string>) => {
      pull(state.selectedProducts, payload);
      // Зберегти факт зміни в перелік виробів як тригер необхідності зафіксувати зміни в проєкт
      state.projectChanges.extraData = {
        ...(state.projectChanges.extraData || {}),
        products: state.products,
      };
    },
    toggleAllSelectedOrderProducts: (state, { payload }: PayloadAction<string[]>) => {
      isEmpty(state.selectedProducts)
        ? (state.selectedProducts = [...state.selectedProducts, ...payload])
        : pullAll(state.selectedProducts, payload);

      // Зберегти факт зміни в перелік виробів як тригер необхідності зафіксувати зміни в проєкт
      state.projectChanges.extraData = {
        ...(state.projectChanges.extraData || {}),
        products: state.products,
      };
    },
    /** Зміна властивості, що зберігає окремий виріб який редагується */
    updateProduct: (state, { payload }: PayloadAction<ProductStructure>) => {
      state.product = merge({}, payload);
    },
    /** Видалити виріб із набору виробів, що редагуються та зафіксувати факт зміни */
    deleteProduct: (state, { payload }: PayloadAction<string>) => {
      remove(state.products, (product: any) => product.id === payload);
      pull(state.selectedProducts, payload);
      // Зберегти факт зміни в перелік виробів
      state.projectChanges.extraData = {
        ...(state.projectChanges.extraData || {}),
        products: state.products,
      };
    },
    /** Додати виріб до набору виробів, що редагуються та зафіксувати факт зміни */
    addProduct: (state, { payload }: PayloadAction<ProductStructure>) => {
      // Генерувати унікальний ідентифікатор (якщо виріб його не має)
      if (typeof payload.id !== 'string') payload.id = uuid();
      state.products.push(payload);
      state.selectedProducts.push(payload.id);
      // Зберегти факт зміни в перелік виробів
      state.projectChanges.extraData = {
        ...(state.projectChanges.extraData || {}),
        products: state.products,
      };
    },
    /** Замінити виріб у наборі виробів, що редагуються та зафіксувати факт зміни */
    replaceProduct: (state, { payload }: PayloadAction<ProductStructure>) => {
      // Редагований виріб завжди повинен мати ідентифікатор
      if (typeof payload.id !== 'string') {
        // eslint-disable-next-line no-console
        console.error('Відсутній ідентифікатор у редагованому виробі.');
        return;
      }
      const index = state.products.findIndex(item => item.id === payload.id);
      if (index < 0) {
        // eslint-disable-next-line no-console
        console.error('Відсутній виріб з даним ідентифікатором у замовленні.');
        return;
      }
      state.products.splice(index, 1, payload);
      // Зберегти факт зміни в перелік виробів
      state.projectChanges.extraData = {
        ...(state.projectChanges.extraData || {}),
        products: state.products,
      };
    },
    /** Додати в набір виробів редактора передані чернетки та очистити набір чернеток у проєкті та об'єкті його змін */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    activateProductDraftsForEditing: (state, _: PayloadAction<undefined | null>) => {
      if (
        state.project &&
        state.project.extraData &&
        Array.isArray(state.project.extraData.productDrafts) &&
        state.project.extraData.productDrafts.length > 0
      ) {
        const roomCandidates: string[] = [];
        // Додати чернетки в загальний перелік виробів редактора
        // eslint-disable-next-line no-restricted-syntax
        for (const draft of state.project.extraData.productDrafts) {
          // Генерувати унікальний ідентифікатор (якщо чернетка його не має, в стандартному випадку він вже наявний)
          if (typeof draft.id !== 'string') draft.id = uuid();
          state.products.push(draft);
          if (draft.roomName) roomCandidates.push(draft.roomName);
        }

        // Додати за потреби приміщення на які призначено чернетки, якщо ці приміщення відсутні у замовленні
        if (roomCandidates.length > 0) {
          if (Array.isArray(state.project.rooms)) {
            const initialRoomsCount = state.project.rooms.length;
            // eslint-disable-next-line no-restricted-syntax
            for (const roomWithDraft of roomCandidates) {
              if (!state.project.rooms.includes(roomWithDraft)) state.project.rooms.push(roomWithDraft);
            }
            if (state.project.rooms.length !== initialRoomsCount) {
              // Зафіксувати потребу оновити перелік приміщень при збереженні
              state.projectChanges.rooms = [...state.project.rooms];
            }
          } else {
            state.project.rooms = roomCandidates;
            // Зафіксувати потребу оновити перелік приміщень при збереженні
            state.projectChanges.rooms = [...state.project.rooms];
          }
        }

        // Видалити властивість productDrafts у об'єкті змін
        if (state.projectChanges.extraData) {
          state.projectChanges.extraData.productDrafts = undefined;
        }
        // TODO: Видалити productDrafts з об'єкту виробу (при потребі буде заново доданий в момент збереження)
        state.project.extraData.productDrafts = undefined;
      }
    },
    updateClient: (state, { payload }: PayloadAction<ClientStructure | null>) => {
      state.client = payload;
    },
    updateClientChanges: (state, { payload }: PayloadAction<Partial<ClientStructure>>) => {
      state.clientChanges = payload;
    },
    updateNewParticipant: (state, { payload }: PayloadAction<ProjectParticipant | null>) => {
      state.newParticipant = payload;
    },
    deleteNewImage: (state, { payload }: PayloadAction<string>) => {
      const protoIndex = state.newImages.findIndex(image => image.description.slug === payload);
      if (protoIndex >= 0) {
        // Видалити файл з кешу по objectURL
        URL.revokeObjectURL(state.newImages[protoIndex].objectURL);
        state.newImages.splice(protoIndex, 1);
      }
    },
    updateSubProjectExtractionData: (state, { payload }: PayloadAction<SplitProjectRequestData | null>) => {
      state.subProjectExtractionData = payload;
      state.subProjectExtractionState = {
        state: 'idle',
        baseProjectId: state.subProjectExtractionData?.baseProject.id ?? null,
        extractedProjectId: null,
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resetSubProjectExtractionState: (state, _: PayloadAction<undefined | null>) => {
      state.subProjectExtractionState = {
        state: 'idle',
        baseProjectId: null,
        extractedProjectId: null,
      };
      state.subProjectExtractionData = null;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clearProjectEditingTool: (state, _: PayloadAction<undefined | null>) => {
      // Очищення інструменту завжди має супроводжуватись поверненням до переліку всіх проєктів
      state.projectTool = { status: 'viewAll', projectId: null };
      state.productTool = { status: 'create', productId: null };
      state.activeStep = 0;
      state.project = null;
      state.projectChanges = {};
      state.participants = [];
      state.images = [];
      state.newImages = [];
      state.products = [];
      state.selectedProducts = [];
      state.client = null;
      state.clientChanges = {};
      state.financeTransactions = [];
      state.newFinanceTransactions = [];
      state.newParticipant = null;
      state.product = new FreeformProduct();
      state.loadingFullProject = 'idle';
      state.loadingFinanceTransactions = 'idle';
      state.loadingClient = 'idle';
      state.loadingImages = 'idle';
      state.processingImages = {};
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    discardProjectChanges: (state, _: PayloadAction<undefined | null>) => {
      state.projectChanges = {};
      state.clientChanges = {};
      state.project = null;
      state.participants = [];
      state.images = [];
      state.newImages = [];
      state.newFinanceTransactions = [];
      state.products = [];
      state.selectedProducts = [];
      state.product = new FreeformProduct();
      state.client = null;
      state.newParticipant = null;
      state.productTool = { status: 'create', productId: null };
      state.activeStep = 0;
      state.loadingFullProject = 'idle';
      state.loadingClient = 'idle';
      state.loadingImages = 'idle';
      state.processingImages = {};
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMyProjects.pending, state => {
        state.loadingProjectsByStatus = 'loading';
      })
      .addCase(fetchMyProjects.fulfilled, (state, { payload }) => {
        state.projectsByStatus = payload;
        state.loadingProjectsByStatus = 'fulfilled';
      })
      .addCase(fetchMyProjects.rejected, state => {
        state.projectsByStatus = null;
        state.loadingProjectsByStatus = 'rejected';
      })
      .addCase(fetchFullProject.pending, state => {
        state.loadingFullProject = 'loading';
      })
      .addCase(fetchFullProject.fulfilled, (state, { payload }) => {
        state.project = payload;
        state.products = payload.extraData.products;
        state.selectedProducts = state.products.map(
          (product, index) => product.id ?? `item#${index}: product without id`,
        );
        state.participants = payload.participants || [];
        state.loadingFullProject = 'fulfilled';
      })
      .addCase(fetchFullProject.rejected, state => {
        state.project = null;
        state.products = [];
        state.selectedProducts = [];
        state.participants = [];
        state.loadingFullProject = 'rejected';
      })
      .addCase(fetchProjectClient.pending, state => {
        state.loadingClient = 'loading';
      })
      .addCase(fetchProjectClient.fulfilled, (state, { payload }) => {
        state.client = payload;
        state.loadingClient = 'fulfilled';
      })
      .addCase(fetchProjectClient.rejected, state => {
        state.client = null;
        state.loadingClient = 'rejected';
      })
      .addCase(fetchProjectFinanceTransactions.pending, state => {
        state.loadingFinanceTransactions = 'loading';
      })
      .addCase(fetchProjectFinanceTransactions.fulfilled, (state, { payload }) => {
        state.financeTransactions = payload;
        state.loadingFinanceTransactions = 'fulfilled';
      })
      .addCase(fetchProjectFinanceTransactions.rejected, state => {
        state.financeTransactions = [];
        state.loadingFinanceTransactions = 'rejected';
      })
      .addCase(fetchProjectImages.pending, state => {
        state.loadingImages = 'loading';
      })
      .addCase(fetchProjectImages.fulfilled, (state, { payload }) => {
        state.images = payload;
        state.loadingImages = 'fulfilled';
      })
      .addCase(fetchProjectImages.rejected, state => {
        state.images = [];
        state.loadingImages = 'rejected';
      })
      .addCase(postNewProjectImage.pending, (state, action) => {
        if (action.meta.arg.fields && action.meta.arg.file) {
          // Зберегти об'єкт нового зображення у newImages
          // попередньо підставити в якості тимчасового slug значення requestId
          // та перевірити чи вже є в наявності зображення з таким slug
          // (захист від дублікатів при повторному виконанні запиту на збереження)
          if (state.newImages.findIndex(image => image.description.slug === action.meta.requestId) < 0)
            state.newImages.push({
              objectURL: URL.createObjectURL(action.meta.arg.file),
              description: {
                ...action.meta.arg.fields,
                slug: action.meta.requestId,
              },
              uploadingState: 'processing',
              errorMessage: undefined,
            });
        }
      })
      .addCase(postNewProjectImage.fulfilled, (state, action) => {
        // Покласти збережене зображення у перелік images
        state.images.push(action.payload);
        // Видалити прототип зображення з newImages
        const protoIndex = state.newImages.findIndex(image => image.description.slug === action.meta.requestId);
        if (protoIndex >= 0) {
          URL.revokeObjectURL(state.newImages[protoIndex].objectURL);
          state.newImages.splice(protoIndex, 1);
        }
      })
      .addCase(postNewProjectImage.rejected, (state, action) => {
        const protoIndex = state.newImages.findIndex(image => image.description.slug === action.meta.requestId);
        if (protoIndex >= 0) {
          state.newImages[protoIndex].uploadingState = 'rejected';
          state.newImages[protoIndex].errorMessage = action.error?.message;
        }
      })
      .addCase(patchProjectImage.pending, (state, action) => {
        if (action.meta.arg?.imageSlug) {
          const processingState = state.processingImages[action.meta.arg.imageSlug];
          if (processingState) {
            processingState.updating = 'processing';
            processingState.errorMessage = undefined;
          } else {
            state.processingImages[action.meta.arg.imageSlug] = {
              updating: 'processing',
              deleting: 'idle',
              errorMessage: undefined,
            };
          }
        }
      })
      .addCase(patchProjectImage.fulfilled, (state, action) => {
        if (action.meta.arg) {
          const { imageSlug, payload } = action.meta.arg;
          if (typeof imageSlug !== 'string') {
            // eslint-disable-next-line no-console
            console.error('Відсутній ідентифікатор редагованого зображення.');
            return;
          }
          const processingState = state.processingImages[imageSlug];

          if (action.payload?.count && action.payload.count > 0) {
            // Оновити зображення в store
            const index = state.images.findIndex(item => item.slug === imageSlug);
            if (index < 0) {
              // eslint-disable-next-line no-console
              console.error('Відсутнє зображення з даним ідентифікатором.');
              return;
            }
            state.images.splice(index, 1, {
              ...state.images[index],
              ...payload,
            });
            // Позначити успішний статус процесу
            if (processingState) {
              processingState.updating = 'fulfilled';
            } else {
              state.processingImages[imageSlug] = {
                updating: 'fulfilled',
                deleting: 'idle',
              };
            }
          } else {
            // Позначити що процес не відбувся
            // eslint-disable-next-line no-lonely-if
            if (processingState) {
              processingState.updating = 'rejected';
              processingState.errorMessage = undefined;
            } else {
              state.processingImages[imageSlug] = {
                updating: 'rejected',
                deleting: 'idle',
                errorMessage: undefined,
              };
            }
          }
        }
      })
      .addCase(patchProjectImage.rejected, (state, action) => {
        if (action.meta.arg?.imageSlug) {
          const processingState = state.processingImages[action.meta.arg.imageSlug];
          if (processingState) {
            processingState.updating = 'rejected';
            processingState.errorMessage = action.error?.message;
          } else {
            state.processingImages[action.meta.arg.imageSlug] = {
              updating: 'rejected',
              deleting: 'idle',
              errorMessage: action.error?.message,
            };
          }
        }
      })
      .addCase(delProjectImage.pending, (state, action) => {
        if (action.meta.arg?.imageSlug) {
          const processingState = state.processingImages[action.meta.arg.imageSlug];
          if (processingState) {
            processingState.deleting = 'processing';
            processingState.errorMessage = undefined;
          } else {
            state.processingImages[action.meta.arg.imageSlug] = {
              updating: 'idle',
              deleting: 'processing',
              errorMessage: undefined,
            };
          }
        }
      })
      .addCase(delProjectImage.fulfilled, (state, action) => {
        if (action.meta.arg) {
          const { imageSlug } = action.meta.arg;
          if (typeof imageSlug !== 'string') {
            // eslint-disable-next-line no-console
            console.error('Відсутній ідентифікатор редагованого зображення.');
            return;
          }
          const processingState = state.processingImages[imageSlug];

          if (action.payload?.count && action.payload.count > 0) {
            // Видалити зображення зі store
            remove(state.images, (image: any) => image.slug === imageSlug);
            // Видалити slug зображення з переліку для поєднання з проєктом якщо такий перелік ще залишився після збереження проєкту
            if (state.project && state.project.extraData && Array.isArray(state.project.extraData?.imagesSlugs)) {
              const imageIndex = state.project.extraData.imagesSlugs.findIndex(slug => slug === imageSlug);
              if (imageIndex >= 0) state.project.extraData.imagesSlugs.splice(imageIndex, 1);
            }
            // Видалити об'єкт статусу обробки зображення якого вже не існує
            if (processingState) {
              state.processingImages[imageSlug] = undefined;
            }
          } else {
            // Позначити що процес не відбувся
            // eslint-disable-next-line no-lonely-if
            if (processingState) {
              processingState.deleting = 'rejected';
              processingState.errorMessage = undefined;
            } else {
              state.processingImages[imageSlug] = {
                updating: 'idle',
                deleting: 'rejected',
                errorMessage: undefined,
              };
            }
          }
        }
      })
      .addCase(delProjectImage.rejected, (state, action) => {
        if (action.meta.arg?.imageSlug) {
          const processingState = state.processingImages[action.meta.arg.imageSlug];
          if (processingState) {
            processingState.deleting = 'rejected';
            processingState.errorMessage = action.error?.message;
          } else {
            state.processingImages[action.meta.arg.imageSlug] = {
              updating: 'idle',
              deleting: 'rejected',
              errorMessage: action.error?.message,
            };
          }
        }
      })
      .addCase(delAllProductImages.pending, (state, action) => {
        if (action.meta.arg?.productId) {
          const allImagesOfProduct = state.images.filter(image => image.productId === action.meta.arg.productId);
          // eslint-disable-next-line no-restricted-syntax
          for (const image of allImagesOfProduct) {
            if (typeof image.slug !== 'string' || image.slug.trim().length === 0) {
              // eslint-disable-next-line no-console
              console.error(
                `Неможливо видалити з переліку state.images зображення без ідентифікатора: ${image.originalName}`,
              );
              continue;
            }

            const processingState = state.processingImages[image.slug];
            if (processingState) {
              processingState.deleting = 'processing';
              processingState.errorMessage = undefined;
            } else {
              state.processingImages[image.slug] = {
                updating: 'idle',
                deleting: 'processing',
                errorMessage: undefined,
              };
            }
          }
        }
      })
      .addCase(delAllProductImages.fulfilled, (state, action) => {
        if (action.meta.arg) {
          const { productId } = action.meta.arg;
          if (typeof productId !== 'string') {
            // eslint-disable-next-line no-console
            console.error('Відсутній ідентифікатор виробу зображення якого необхідно видалити.');
            return;
          }

          const allImagesOfProduct = state.images.filter(image => image.productId === action.meta.arg.productId);
          // eslint-disable-next-line no-restricted-syntax
          for (const image of allImagesOfProduct) {
            if (typeof image.slug !== 'string' || image.slug.trim().length === 0) {
              // eslint-disable-next-line no-console
              console.error(
                `Неможливо видалити з переліку state.images зображення без ідентифікатора: ${image.originalName}`,
              );
              continue;
            }

            const processingState = state.processingImages[image.slug];

            if (action.payload?.count && action.payload.count > 0) {
              // TODO: Логіка має потенціал переробки на видалення всіх зображень та їх slug у project.extraData.imagesSlugs за один прохід (тобто без ітерації по allImagesOfProduct)
              // Видалити зображення зі store
              remove(state.images, (img: any) => img.slug === image.slug);
              // Видалити slug зображення з переліку для поєднання з проєктом якщо такий перелік ще залишився після збереження проєкту
              if (state.project && state.project.extraData && Array.isArray(state.project.extraData?.imagesSlugs)) {
                const imageIndex = state.project.extraData.imagesSlugs.findIndex(slug => slug === image.slug);
                if (imageIndex >= 0) state.project.extraData.imagesSlugs.splice(imageIndex, 1);
              }
              // Видалити об'єкт статусу обробки зображення якого вже не існує
              if (processingState) {
                state.processingImages[image.slug] = undefined;
              }
            } else {
              // Позначити що процес не відбувся
              // eslint-disable-next-line no-lonely-if
              if (processingState) {
                processingState.deleting = 'rejected';
                processingState.errorMessage = undefined;
              } else {
                state.processingImages[image.slug] = {
                  updating: 'idle',
                  deleting: 'rejected',
                  errorMessage: undefined,
                };
              }
            }
          }
        }
      })
      .addCase(delAllProductImages.rejected, (state, action) => {
        if (action.meta.arg?.productId) {
          const allImagesOfProduct = state.images.filter(image => image.productId === action.meta.arg.productId);
          // eslint-disable-next-line no-restricted-syntax
          for (const image of allImagesOfProduct) {
            if (typeof image.slug !== 'string' || image.slug.trim().length === 0) {
              // eslint-disable-next-line no-console
              console.error(
                `Неможливо видалити з переліку state.images зображення без ідентифікатора: ${image.originalName}`,
              );
              continue;
            }

            const processingState = state.processingImages[image.slug];
            if (processingState) {
              processingState.deleting = 'rejected';
              processingState.errorMessage = action.error?.message;
            } else {
              state.processingImages[image.slug] = {
                updating: 'idle',
                deleting: 'rejected',
                errorMessage: action.error?.message,
              };
            }
          }
        }
      })
      .addCase(splitProjectRequest.pending, state => {
        state.subProjectExtractionState.state = 'processing';
      })
      .addCase(splitProjectRequest.fulfilled, (state, action) => {
        state.subProjectExtractionState.state = 'fulfilled';
        state.subProjectExtractionState.errorMessage = undefined;
        if (action.payload.id) {
          state.subProjectExtractionState.extractedProjectId = action.payload.id;
        } else {
          // eslint-disable-next-line no-console
          console.error(new Error('Дані успішно виокремленого замовлення пошкоджені: відсутній ідентифікатор'));
          state.subProjectExtractionState.errorMessage =
            'Дані успішно виокремленого замовлення пошкоджені: відсутній ідентифікатор';
        }

        // Виконати інверсію відміток активності виробів у базовому замовленні: ті що винесені у нове замовлення стають неактивними, а всі інші навпаки вмикаються.
        if (state.subProjectExtractionData?.selectedProducts && state.products && state.selectedProducts) {
          const allBaseProjectProductIds = state.products
            .map(product => product.id ?? 'Undefined')
            .filter(item => item !== 'Undefined');
          state.selectedProducts = allBaseProjectProductIds.filter(
            id => !state.subProjectExtractionData?.selectedProducts.includes(id),
          );
        }
      })
      .addCase(splitProjectRequest.rejected, (state, action) => {
        state.subProjectExtractionState.state = 'rejected';
        state.subProjectExtractionState.errorMessage = action.error?.message;
      });
  },
});

const selectSelf = (state: RootState): Projects => state.projects;

export const getProjectTool = createSelector(selectSelf, state => state.projectTool);
export const getSearchFilters = createSelector(selectSelf, state => state.searchFilters);
export const getSelectedStatusTab = createSelector(selectSelf, state => state.selectedStatusTab);
export const getProductTool = createSelector(selectSelf, state => state.productTool);
export const getActiveStep = createSelector(selectSelf, state => state.activeStep);
export const getLoadingProjectsByStatus = createSelector(selectSelf, state => state.loadingProjectsByStatus);
export const getLoadingFullProject = createSelector(selectSelf, state => state.loadingFullProject);
export const getLoadingFinanceTransactions = createSelector(selectSelf, state => state.loadingFinanceTransactions);
export const getLoadingClient = createSelector(selectSelf, state => state.loadingClient);
export const getLoadingImages = createSelector(selectSelf, state => state.loadingImages);
export const getProcessingImagesStates = createSelector(selectSelf, state => state.processingImages);
export const getProjectsByStatus = createSelector(selectSelf, state => state.projectsByStatus);
export const getProject = createSelector(selectSelf, state => state.project);
export const getProjectId = createSelector(selectSelf, state => state.project?.id);
export const getProjectRooms = createSelector(selectSelf, state => state.project?.rooms ?? []);
export const getProjectParticipants = createSelector(selectSelf, state => state.participants ?? []);
export const getNewParticipant = createSelector(selectSelf, state => state.newParticipant);
export const getProjectChanges = createSelector(selectSelf, state => state.projectChanges);
export const getImages = createSelector(selectSelf, state => state.images);
export const getNewImages = createSelector(selectSelf, state => state.newImages);
export const getFinanceTransactions = createSelector(selectSelf, state => state.financeTransactions);
export const getNewFinanceTransactions = createSelector(selectSelf, state => state.newFinanceTransactions);
export const getProducts = createSelector(selectSelf, state => state.products);
export const getProduct = createSelector(selectSelf, state => state.product);
export const getSelectedProducts = createSelector(selectSelf, state => state.selectedProducts);
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const getAllProductsIds = createSelector(selectSelf, state => state.products.map(item => item.id!));
export const getClient = createSelector(selectSelf, state => state.client);
export const getClientPhones = createSelector(selectSelf, state => state.client?.phones ?? []);
export const getClientCity = createSelector(selectSelf, state => state.client?.city);
export const getClientChanges = createSelector(selectSelf, state => state.clientChanges);
export const getSubProjectExtractionData = createSelector(selectSelf, state => state.subProjectExtractionData);
export const getSubProjectExtractionState = createSelector(selectSelf, state => state.subProjectExtractionState);

export const {
  reducer,
  actions: {
    setupViewAllProjectTool,
    setupViewProjectTool,
    setupPaymentsProjectTool,
    setupEditProjectTool,
    updateSearchFilters,
    updateSelectedStatusTab,
    setupCreateProductTool,
    setupEditProductTool,
    setActiveStep,
    updateProject,
    updateProjectChanges,
    updateProjectsByStatus,
    updateNewFinanceTransactions,
    updateNewParticipant,
    updateClient,
    updateClientChanges,
    updateProduct,
    deleteProduct,
    addProduct,
    replaceProduct,
    activateProductDraftsForEditing,
    addSelectedOrderProduct,
    removeSelectedOrderProduct,
    toggleAllSelectedOrderProducts,
    deleteNewImage,
    clearProjectEditingTool,
    discardProjectChanges,
    updateSubProjectExtractionData,
    resetSubProjectExtractionState,
  },
} = slice;
