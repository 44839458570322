/* eslint class-methods-use-this: ["error", { "exceptMethods": ["hydrate"] }] */
import type { ServiceKey } from 'types/product-services';
import type { ProductComponentKey } from 'types/ProductMaterials';
import type { ProductDesignSettingKey } from 'types/ProductDesignData';
import type { ProductStructure } from 'types/ProductStructure';
import type { ProductPrototype } from './ProductPrototype';
import Product from './Product';

/**
 * Клас простої штори або тюлі на тасьмі з опціональними аксесуарами чи карнизом
 */
export default class CurtainsSimpleGrommetProduct extends Product implements ProductPrototype {
  protected readonly _validDesignSettings: ProductDesignSettingKey[] = [
    'width',
    'height',
    'mountTarget',
    'draperyCoefficient',
    'controlSide',
    'controlChainLength',
    'isComplexSewing',
    'productionOptions',
  ];

  protected readonly _requiredDesignSettings: ProductDesignSettingKey[] = ['width', 'height', 'draperyCoefficient'];

  protected readonly _validComponentKeysOf: {
    [componentType: string]: ProductComponentKey[];
  } = {
    textile: [
      'mainTextile',
      'secondaryTextile',
      'thirdTextile',
      'fourthTextile',
      'edgingTextile',
      'liningTextile',
      'doublerine',
    ],
    webbing: ['webbing', 'webbingDecorative'],
    furniture: ['grommet', 'magnets'],
    curtainRod: [
      'curtainProfile',
      'curtainRod',
      'miniCurtainRod',
      'bracketFasten',
      'handpiece',
      'cap',
      'rings4CurtainRod',
      'hookSigma',
      'wallBracket',
      'runners',
      'stopper',
      'clipsFasten',
      'pipeConnector',
      'bracketExtension',
      'thirdPipeHolder',
      'bendingCurtainRod',
      'curtainProfile2',
      'runners2',
      'stopper2',
      'curtainRod2',
      'handpiece2',
      'cap2',
      'rings4CurtainRod2',
      'pipeConnector2',
      'lambrequinLath',
      'weightingAgent',
      'motor',
    ],
    accessory: [
      'hookDrapery',
      'rosette',
      'pin',
      'tieBack',
      'curtainGuides',
      'powerSupplyUnit',
      'charger',
      'remoteControlUnit',
      'smartHub',
    ],
    productFulfillment: [],
  };

  protected readonly _validServiceKeysOf: {
    [serviceType: string]: ServiceKey[];
  } = {
    sewing: ['sewingCurtainsComplex', 'sewingCustom'],
    hanging: ['hangingCurtains', 'hangingComplex', 'hangingCustom'],
    mount: ['mountCurtainRod', 'mountCurtainRodComplex', 'mountCafeSystem', 'mountHookDrapery', 'mountCustom'],
    disassembling: ['disassemblingCurtainRod', 'disassemblingCustom'],
    custom: ['customServices'],
  };

  constructor(baseProduct?: CurtainsSimpleGrommetProduct | Product | ProductStructure) {
    super(baseProduct);
    this.calcMethod = 'curtainsSimpleGrommet';
    // Обов'язкові та типові складові включені за умовчанням
    /* Основна тканина */
    if (typeof this.design.mainTextile === 'undefined') {
      this.design.mainTextile = {
        amount: -1,
        unit: 'м.п.',
        amountToBuy: undefined,
        unitToBuy: 'м.п.',
        totalPrice: undefined,
        isManualAmount: false,
        manualAmountAuthor: undefined,
        isManualPrice: false,
        manualPriceAuthor: undefined,
        required: true,
      };
    } else {
      // Імплементація даних з базового виробу вже відбулась в конструкторі батьківського класу
      // проте залишається необхідність переконатись в обов'язковості складової та повному наборі даних
      this.design.mainTextile.required = true;
      this.design.mainTextile.amount = this.design.mainTextile.amount ?? -1;
      this.design.mainTextile.unit = this.design.mainTextile.unit ?? 'м.п.';
      this.design.mainTextile.unitToBuy = this.design.mainTextile.unitToBuy ?? 'м.п.';
      this.design.mainTextile.isManualAmount = this.design.mainTextile.isManualAmount ?? false;
      this.design.mainTextile.isManualPrice = this.design.mainTextile.isManualPrice ?? false;
    }
    /* Тасьма (під люверси) */
    if (typeof this.design.webbing === 'undefined') {
      this.design.webbing = {
        amount: -1,
        unit: 'м.п.',
        amountToBuy: undefined,
        unitToBuy: 'м.п.',
        totalPrice: undefined,
        isManualAmount: false,
        manualAmountAuthor: undefined,
        isManualPrice: false,
        manualPriceAuthor: undefined,
        required: true,
      };
    } else {
      // Імплементація даних з базового виробу вже відбулась в конструкторі батьківського класу
      // проте залишається необхідність переконатись в обов'язковості складової та повному наборі даних
      this.design.webbing.required = true;
      this.design.webbing.amount = this.design.webbing.amount ?? -1;
      this.design.webbing.unit = this.design.webbing.unit ?? 'м.п.';
      this.design.webbing.unitToBuy = this.design.webbing.unitToBuy ?? 'м.п.';
      this.design.webbing.isManualAmount = this.design.webbing.isManualAmount ?? false;
      this.design.webbing.isManualPrice = this.design.webbing.isManualPrice ?? false;
    }
    /* Люверси) */
    if (typeof this.design.grommet === 'undefined') {
      this.design.grommet = {
        amount: -1,
        unit: 'шт.',
        amountToBuy: undefined,
        unitToBuy: 'уп.',
        totalPrice: undefined,
        isManualAmount: false,
        manualAmountAuthor: undefined,
        isManualPrice: false,
        manualPriceAuthor: undefined,
        required: true,
      };
    } else {
      // Імплементація даних з базового виробу вже відбулась в конструкторі батьківського класу
      // проте залишається необхідність переконатись в обов'язковості складової та повному наборі даних
      this.design.grommet.required = true;
      this.design.grommet.amount = this.design.grommet.amount ?? -1;
      this.design.grommet.unit = this.design.grommet.unit ?? 'шт.';
      this.design.grommet.unitToBuy = this.design.grommet.unitToBuy ?? 'уп.';
      this.design.grommet.isManualAmount = this.design.grommet.isManualAmount ?? false;
      this.design.grommet.isManualPrice = this.design.grommet.isManualPrice ?? false;
    }

    // Типові послуги увімкнені за умовчанням
    if (
      typeof this.serviceOptions.sewingCurtainsComplex === 'undefined' &&
      // Вмикати складне пошиття за умовчанням доцільно лише при відсутності інших
      typeof this.serviceOptions.sewingCustom === 'undefined'
    ) {
      this.design.isComplexSewing = true;
      this.serviceOptions.sewingCurtainsComplex = -1;
      if (typeof this.serviceOptions.sewingCurtains === 'undefined') {
        // За наявності простого пошиття його необхідно вимкнути, адже пошиття на люверсах завжди складне
        this.serviceOptions.sewingCurtains = undefined;
      }
    }
  }

  clone(): ProductPrototype {
    const clone = new CurtainsSimpleGrommetProduct(this);
    // Ідентифікатор клону не має збігатись з його прототипом!
    clone.id = undefined;
    return clone;
  }

  hydrate(structure: ProductStructure): ProductPrototype {
    const hydrated = new CurtainsSimpleGrommetProduct(structure);
    hydrated.calcMethod = 'curtainsSimpleGrommet';
    return hydrated;
  }

  requireLiningTextile(newState?: boolean): boolean {
    // Встановити нове значення
    if (typeof newState !== 'undefined') {
      if (typeof this.design.liningTextile !== 'undefined') {
        // Оновити індикатор обов'язковості складової
        this.design.liningTextile.required = !!newState;
      } else if (newState) {
        // Якщо призначається обов'язковість і параметри дизайну ще не містять кількісний показник
        // — встановити його стартове значення
        // - встановити обчислення кількості підкладки автоматичним за умовчанням
        this.design.liningTextile = {
          ...this._defaultTextileUsage,
          required: !!newState,
          isManualAmount: false,
        };
      }
    }

    return !!this.design.liningTextile?.required;
  }
}
