import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import React from 'react';
import ReactDOM from 'react-dom';
import store from 'store';
import { Provider } from 'react-redux';

import { LocalSettingsProvider, restoreSettings } from 'contexts/LocalSettingsContext';
import App from 'App';
import AppThemeProvider from 'theme';
import * as serviceWorker from './serviceWorkerRegistration';

const settings = restoreSettings();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalSettingsProvider settings={settings}>
        <AppThemeProvider>
          <App />
        </AppThemeProvider>
      </LocalSettingsProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.register();
