import type { PaymentType } from 'types/FinanceTransactionStructure';

/** Стандартна частка передоплати замовлення */
export const STANDARD_PREPAID_PART = 0.7;
/** Дійсні варіанти типів фінансових операцій */
export const validPaymentTypes: PaymentType[] = ['full', 'collateral', 'prepaid', 'surcharge', 'suburbanTransfer'];
/** Допустимі варіанти термінів виконання замовлення у робочих днях */
export const validSupplyTimeClasses = [
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
];

/** Точність врахування розміру виробу у метрах */
export const MIN_PRODUCT_SIZE = 0.001;
/** Максимальне значення розміру виробу у метрах */
export const MAX_PRODUCT_SIZE = 50;

/** Мінімально допустиме значення коефіцієнту драпірування */
export const DRAPERY_COEFFICIENT_MIN = 1;
/** Крок зміни значення коефіцієнту драпірування */
export const DRAPERY_COEFFICIENT_STEP = 0.1;
/** Максимально допустиме значення коефіцієнту драпірування */
export const DRAPERY_COEFFICIENT_MAX = 4;

/** Мінімально допустиме значення довжини шнура/ланцюга керування механізмом */
export const CONTROL_CHAIN_LENGTH_MIN = 25;
/** Крок зміни довжини шнура/ланцюга керування механізмом */
export const CONTROL_CHAIN_LENGTH_STEP = 25;
/** Максимально допустиме значення довжини шнура/ланцюга керування механізмом */
export const CONTROL_CHAIN_LENGTH_MAX = 250;
/** Варіанти значень довжини шнура/ланцюга керування механізмом */
export const CONTROL_CHAIN_LENGTH_OPTIONS = [50, 75, 100, 125, 150, 175, 200, 225, 250];
