import { ProductSelected, Step } from 'data/interfaces';

export const mechanismNames: string[] = [
  'none',
  'mock',
  'standard-cord-roman',
  'standard-chain-roman',
  'standard-double-cord-roman',
  'cord-roman',
  'chain-roman',
  'cord-semiauto-roman',
  'double-sync-roman',
  'double-nonsync-roman',
  'motorized-roman',
  'vertical-jalousie-89',
  'vertical-jalousie-127',
  'vertical-jalousie-tulls',
  'open-chain-roller',
  'open-spring-roller',
  'open-spring-chain-roller',
  'open-spring-noautofix-roller',
  'open-carrierTopBar-spring-cordTensionFix-roller',
  'closed-flat-chain-roller',
  'closed-C-type-chain-roller',
  'closed-C-type-spring-roller',
  'closed-L-type-spring-roller',
  'closed-bigCase-noGuides-chainSmooth-roller',
];

export const productTypes = ['curtains', 'romanBlinds', 'jalousie', 'rollerBlinds', 'curtainRod'] as const;

export type ProductType = (typeof productTypes)[number];

export type ProductTypes = typeof productTypes;

export const productObj: ProductSelected[] = [
  {
    id: 0,
    x: '1.5',
    y: '2.7',
    prices: [],
    selectedProducts: [],
  },
];

export const steps: Step[] = [
  {
    localeId: 'steps.step1',
    shortLabel: '1',
    // isActivated: true,
  },
  {
    localeId: 'steps.step2',
    shortLabel: '2',
    // isActivated: false,
  },
  {
    localeId: 'steps.step3',
    shortLabel: '3',
    // isActivated: false,
  },
];
