import ProductPrototypeRegistry from './ProductPrototypeRegistry';
import BasicProduct from './Product';
import FreeformProduct from './FreeformProduct';
import CurtainsSimpleProduct from './CurtainsSimpleProduct';
import CurtainsSimpleGrommetProduct from './CurtainsSimpleGrommetProduct';
import RomanBlindsSimpleProduct from './RomanBlindsSimpleProduct';
import RomanBlindsLinedProduct from './RomanBlindsLinedProduct';
import RomanBlindsSimpleWithoutSystemProduct from './RomanBlindsSimpleWithoutSystemProduct';
import RomanBlindsDoubleProduct from './RomanBlindsDoubleProduct';
import RomanBlindsSimpleMotoProduct from './RomanBlindsSimpleMotoProduct';
import RomanBlindsDoubleMotoProduct from './RomanBlindsDoubleMotoProduct';
import StandardRomanBlindProduct from './StandardRomanBlindProduct';
import StandardRomanBlindDoubleProduct from './StandardRomanBlindDoubleProduct';

export type { ProductPrototype } from './ProductPrototype';

const productRegistry = new ProductPrototypeRegistry({
  basic: new BasicProduct(),
  freeform: new FreeformProduct(),
  curtainsSimple: new CurtainsSimpleProduct(),
  curtainsSimpleGrommet: new CurtainsSimpleGrommetProduct(),
  romanBlindsSimple: new RomanBlindsSimpleProduct(),
  romanBlindsLined: new RomanBlindsLinedProduct(),
  romanBlindsSimpleWithoutSystem: new RomanBlindsSimpleWithoutSystemProduct(),
  romanBlindsDouble: new RomanBlindsDoubleProduct(),
  romanBlindsSimpleMoto: new RomanBlindsSimpleMotoProduct(),
  romanBlindsDoubleMoto: new RomanBlindsDoubleMotoProduct(),
  standardRomanBlind: new StandardRomanBlindProduct(),
  standardRomanBlindDouble: new StandardRomanBlindDoubleProduct(),
});

export {
  BasicProduct,
  FreeformProduct,
  CurtainsSimpleProduct,
  CurtainsSimpleGrommetProduct,
  RomanBlindsSimpleProduct,
  RomanBlindsLinedProduct,
  RomanBlindsSimpleWithoutSystemProduct,
  RomanBlindsDoubleProduct,
  RomanBlindsSimpleMotoProduct,
  RomanBlindsDoubleMotoProduct,
  StandardRomanBlindProduct,
  StandardRomanBlindDoubleProduct,
};
export default productRegistry;
