import type { MessagesKeys } from './index';

// prettier-ignore
const messages: MessagesKeys = {
  // ПОЧАТОК СТРОК ДЛЯ ПЕРЕКЛАДУ
  // ПЕРЕКЛАДУ ПІДЛЯГАЄ ЛИШЕ ПРАВА ЧАСТИНА (ТА ЩО В ЛАПКАХ ПІСЛЯ ДВОКРАПКИ)

  'app.description': 'Инструмент дизайнера штор.',
  'app.name': 'Платформа',
  'app.newVersion.body': 'Когда вам будет удобно, нажмите "Обновить" чтобы страница перезагрузилась в новой версии. Возможно обновиться позже: сейчас нажмите кнопку "Закрыть", а когда будете готовы выйдете из приложения и закроете его либо вкладку браузера, при следующем открытии вы будете пользоваться новой версией.',
  'app.newVersion.title': 'В наличии новая версия приложения!',

  'auth.account': 'Аккаунт',
  'auth.anonymous': 'Аноним',
  'auth.login': 'Вход в систему',
  'auth.loginFormDesc': 'Авторизуйтесь на платформе',
  'auth.signIn': 'Войти',
  'auth.signOut': 'Выйти',
  'auth.signOutFromAllDevices': 'Выйти на всех устройствах',
  'auth.unableToLogoutNotice': 'Невозможно выйти',
  'auth.forgotPasswordLink': 'Забыли пароль?',
  'auth.PasswordRecovery.title': 'Возобновление пароля',
  'auth.PasswordRecovery.expectEmail.desc': 'Введите email адрес вашего аккаунта',
  'auth.PasswordRecovery.claimFailed.desc': 'Не удалось применить введённый email адрес либо истёк период активности процесса. Попробуйте вернуться на экран авторизации и начать процесс восстановления пароля заново.',
  'auth.PasswordRecovery.confirmationCode': 'Код подтверждения',
  'auth.PasswordRecovery.expectConfirmation.desc': 'В ваш персональный чат с Платформой должно было прийти сообщение с кодом подтверждения. Введите этот код в поле ниже.',
  'auth.PasswordRecovery.confirmationFailed.desc': 'Не удалось подтвердить действительность кода либо истёк период активности процесса. Попробуйте вернуться на экран авторизации и начать процесс восстановления пароля заново.',
  'auth.PasswordRecovery.success.desc': 'Успешно! Новый пароль уже должен быд прийти вам сообщением в персональный чат с Платформой. Можете вернуться на страницу входа в систему.',
  'auth.sessions': 'Активные сессии авторизации',
  'auth.sessions.short': 'Сессии',
  'auth.sessions.my.control.desc': 'Управление всеми активными сессиями использования приложения на всех устройствах и браузерах.',
  'auth.sessions.current': 'Текущая сессия',
  'auth.sessions.start': 'Начало',
  'auth.sessions.latestActivity': 'Последняя активность',

  'city': 'Город',
  // Перехресний переклад населених пунктів (міста та інші)
  // При додаванні нової локалізації, крім перекладу правої частини кожного міста, до кожного з міст потрібно додати новий рядок де ліва та права частини містимуть назву міста згідно нової локалі
  // Також новий ряд з лівою частиною назви міста новою локаллю потрібно додати у всі інші файли локалізації.
  'Київ': 'Киев',
  'Киев': 'Киев',
  'Kyiv': 'Киев',
  'Kijów': 'Киев',
  'Одеса': 'Одесса',
  'Одесса': 'Одесса',
  'Odesa': 'Одесса',
  'Odessa': 'Одесса',
  'Харків': 'Харьков',
  'Харьков': 'Харьков',
  'Kharkiv': 'Харьков',
  'Charków': 'Харьков',
  'Львів': 'Львов',
  'Львов': 'Львов',
  'Lviv': 'Львов',
  'Lwów': 'Львов',
  'Дніпро': 'Днепр',
  'Днепр': 'Днепр',
  'Dnipro': 'Днепр',
  'Dniepr': 'Днепр',
  'Івано-Франківськ': 'Ивано-Франковск',
  'Ивано-Франковск': 'Ивано-Франковск',
  'Ivano-Frankivsk': 'Ивано-Франковск',
  'Iwano-Frankiwsk': 'Ивано-Франковск',
  'Хмельницький': 'Хмельницкий',
  'Хмельницкий': 'Хмельницкий',
  'Khmelnytskyi': 'Хмельницкий',
  'Chmielnicki': 'Хмельницкий',
  'Ужгород': 'Ужгород',
  'Uzhhorod': 'Ужгород',
  'Użhorod': 'Ужгород',
  'Мукачево': 'Мукачево',
  'Mukachevo': 'Мукачево',
  'Mukaczewo': 'Мукачево',
  'Софіївська Борщагівка': 'Софиевская Борщаговка',
  'Софиевская Борщаговка': 'Софиевская Борщаговка',
  'Sofiivska Borschahivka': 'Софиевская Борщаговка',
  'Sowijiwśka Borszczahiwka': 'Софиевская Борщаговка',
  'Петропавлівська Борщагівка': 'Петропавловская Борщаговка',
  'Петропавловская Борщаговка': 'Петропавловская Борщаговка',
  'Petropavlivska Borshchahivka': 'Петропавловская Борщаговка',
  'Petropawliwśka Borszczahiwka': 'Петропавловская Борщаговка',
  'Крюківщина': 'Крюковщина',
  'Крюковщина': 'Крюковщина',
  'Kriukivshchyna': 'Крюковщина',
  'Kriukiwszczyna': 'Крюковщина',
  'Святопетрівське': 'Святопетровское',
  'Святопетровское': 'Святопетровское',
  'Sviatopetrivske': 'Святопетровское',
  'Swiatopetriwskie': 'Святопетровское',
  'Бориспіль': 'Борисполь',
  'Борисполь': 'Борисполь',
  'Boryspil': 'Борисполь',
  'Boryspol': 'Борисполь',
  'Бровари': 'Бровары',
  'Бровары': 'Бровары',
  'Brovary': 'Бровары',
  'Browary': 'Бровары',
  'Ірпінь': 'Ирпень',
  'Ирпень': 'Ирпень',
  'Irpin': 'Ирпень',
  'Irpień': 'Ирпень',
  'Буча': 'Буча',
  'Bucha': 'Буча',
  'Bucza': 'Буча',
  'Обухів': 'Обухов',
  'Обухов': 'Обухов',
  'Obukhiv': 'Обухов',
  'Obuchów': 'Обухов',
  'Вишгород': 'Вышгород',
  'Вышгород': 'Вышгород',
  'Vyshhorod': 'Вышгород',
  'Wyszogród': 'Вышгород',
  'Вишневе': 'Вишневое',
  'Вишневое': 'Вишневое',
  'Vyshneve': 'Вишневое',
  'Wysznewe': 'Вишневое',
  'Боярка': 'Боярка',
  'Boyarka': 'Боярка',
  'Bojarka': 'Боярка',
  'Гатне': 'Гатное',
  'Гатное': 'Гатное',
  'Hatne': 'Гатное',
  'Тарасівка': 'Тарасовка',
  'Тарасовка': 'Тарасовка',
  'Tarasivka': 'Тарасовка',
  'Tarasiwka': 'Тарасовка',
  'Білогородка': 'Белогородка',
  'Белогородка': 'Белогородка',
  'Bilohorodka': 'Белогородка',
  'Biłohorodka': 'Белогородка',
  'Українка': 'Украинка',
  'Украинка': 'Украинка',
  'Ukrainka': 'Украинка',
  'Васильків': 'Васильков',
  'Васильков': 'Васильков',
  'Vasylkiv': 'Васильков',
  'Wasylkiw': 'Васильков',
  'Черкаси': 'Черкассы',
  'Черкассы': 'Черкассы',
  'Cherkasy': 'Черкассы',
  'Czerkasy': 'Черкассы',
  'Полтава': 'Полтава',
  'Poltava': 'Полтава',
  'Połtawa': 'Полтава',
  'Рівне': 'Ровно',
  'Ровно': 'Ровно',
  'Rivne': 'Ровно',
  'Równe': 'Ровно',
  'Вінниця': 'Винница',
  'Винница': 'Винница',
  'Vinnytsia': 'Винница',
  'Winnica': 'Винница',
  'Запоріжжя': 'Запорожье',
  'Запорожье': 'Запорожье',
  'Zaporizhzhia': 'Запорожье',
  'Zaporoże': 'Запорожье',
  'Славутич': 'Славутич',
  'Slavutych': 'Славутич',
  'Sławutycz': 'Славутич',
  'Кривий Ріг': 'Кривой Рог',
  'Кривой Рог': 'Кривой Рог',
  'Kryvyi Rih': 'Кривой Рог',
  'Krzywy Róg': 'Кривой Рог',
  'Миколаїв': 'Николаев',
  'Николаев': 'Николаев',
  'Mykolaiv': 'Николаев',
  'Mikołajów': 'Николаев',
  'Житомир': 'Житомир',
  'Zhytomyr': 'Житомир',
  'Żytomierz': 'Житомир',
  'Чернігів': 'Чернигов',
  'Чернигов': 'Чернигов',
  'Chernihiv': 'Чернигов',
  'Czernihów': 'Чернигов',
  'Херсон': 'Херсон',
  'Kherson': 'Херсон',
  'Chersoń': 'Херсон',
  'Луцьк': 'Луцк',
  'Луцк': 'Луцк',
  'Lutsk': 'Луцк',
  'Łuck': 'Луцк',
  'Суми': 'Сумы',
  'Сумы': 'Сумы',
  'Sumy': 'Сумы',
  'Краматорськ': 'Краматорск',
  'Краматорск': 'Краматорск',
  'Kramatorsk': 'Краматорск',

  'consultant.steps.combined.size': 'Размеры (ширина по карнизу х высота изделия)',
  'consultant.steps.step': 'Шаг',
  'consultant.steps.table.category': 'Категория',
  'consultant.steps.tableHead.draperyCoefficient.short': 'КД',
  'consultant.steps.tableHead.draperyCoefficient': 'Коэффициент драпировки',
  'consultant.steps.tableHead.luxury': 'Люкс',
  'consultant.steps.tableHead.premium': 'Премиум',
  'consultant.steps.tableHead.standard': 'Стандарт',

  'designer.steps.step1': 'Тип изделия',
  'designer.steps.step2': 'Базовые параметры',
  'designer.steps.step3': 'Бланк изделия',

  'errors.error': 'Ошибка',
  'errors.unknown': 'Неизвестная ошибка. Обратитесь в поддержку.',
  'errors.error404': '404: Не найдено',
  'errors.pageNotFound.description': 'Возможно вы самостоятельно ввели ошибочный адрес либо запрашиваемая страница уже не актуальна.',
  'errors.pageNotFound.title': '404: Такой страницы не существует',

  'form.city': 'Населённый пункт',
  'form.residentialComplex': 'Жилой комплекс',
  'form.address': 'Адрес',
  'form.emailAddress': 'Адрес электронной почты',
  'form.emailAlerts': 'Оповещения по электронной почте',
  'form.emailLabel': 'Email Адрес',
  'form.emailRequiredNotice': 'Email необходим',
  'form.emailValidationNotice': 'Должен быть действующий email',
  'form.language': 'Язык интерфейса',
  'form.firstName': 'Имя',
  'form.firstNameRequiredNotice': 'Имя обязательно',
  'form.lastName': 'Фамилия',
  'form.lastNameRequiredNotice': 'Фамилия обязательна',
  'form.secondName': 'Отчество',
  'form.secondNameRequiredNotice': 'Отчество обязательно',
  'form.materials.searchResults.message': 'Данный артикул не найдено',
  'form.materials.searchResults.placeholder': 'Введите артикул',
  'form.minCharacters': 'Должно быть не менее 8 символов',
  'form.passwordConfirmation': 'Повторите пароль',
  'form.passwordLabel': 'Пароль',
  'form.passwordRequiredNotice': 'Пароль необходим',
  'form.passwordsMustMatch': 'Пароли должны совпадать',
  'form.passwordUpdated': 'Пароль обновлен',
  'form.phones': 'Телефонные номера',
  'form.phoneNumber': 'Телефонный номер',
  'form.additionalPhoneNumber': 'Дополнительный тел.',
  'form.profile': 'Профиль',
  'form.profileUpdated': 'Профиль обновлен',
  'form.pushNotifications': 'Push-напоминания',
  'form.system': 'Система',
  'form.projectRooms.inputHelper': 'Выберите одно или несколько помещений из предложенных вариантов либо введите собственные.',
  'form.projectRooms.emptyNotice': 'Предварительно настройте перечень возможных помещений!',
  'form.telegramNotifications': 'Уведомления Telegram',
  'form.weWillUseThisEmailToContactYou': 'Мы будем использовать этот адрес электронной почты для связи с вами',
  'form.youWillReceiveEmails': 'Вы будете получать электронные письма на свой рабочий адрес электронной почты',
  'form.password': 'Пароль не должен быть короче 8 символов',
  'form.validate.minValue': 'Минимальное значение {value}',
  'form.validate.maxValue': 'Максимальное значение {value}',

  'generic.in': 'в ', // важливо зберігати пробіл після літери
  'generic.all': 'Все',
  'generic.add': 'Добавить',
  'generic.assortment': 'Ассортимент',
  'generic.new': 'Новый',
  'generic.next': 'Далее',
  'generic.confirm': 'Подтвердить',
  'generic.compoundPersonName': 'ФИО',
  'generic.loading': 'Загрузка',
  'generic.download': 'Загрузить',
  'generic.repeat': 'Повторить',
  'generic.repeatTrying': 'Повторить попытку',
  'generic.processStart': 'Начало процесса...',
  'generic.processing': 'Выполнения процесса...',
  'generic.moreOptions': 'Больше вариантов',
  'generic.moreActions': 'Больше вариантов действий',
  'generic.noOptions': 'Нет вариантов',
  'generic.addOption': 'Добавить "{value}"',
  'generic.category': 'Категория',
  'generic.change': 'Изменить',
  'generic.changes': 'Изменения',
  'generic.discardChanges': 'Отменить изменения',
  'generic.clear': 'Очистить',
  'generic.clearSession': 'Очистить сессию',
  'generic.clearBlank': 'Очистить бланк',
  'generic.close': 'Закрыть',
  'generic.copied': 'Скопировано',
  'generic.colour': 'Цвет',
  'generic.description': 'Описание',
  'generic.createdAsc': 'Дата создания (сначала новые)',
  'generic.createdDesc': 'Дата создания (сначала старые)',
  'generic.current': 'Текущий',
  'generic.days': `{days, plural, one {# день} =2 {# дня} =3 {# дня} =4 {# дня} =22 {# дня} =23 {# дня} =24 {# дня} other {# дней} }`,
  'generic.delete': 'Удалить',
  'generic.edit': 'Изменить',
  'generic.view': 'Обзор',
  'generic.export': 'Экспорт',
  'generic.filter': 'Фильтр',
  'generic.from': 'от',
  'generic.import': 'Импорт',
  'generic.material': 'Материал',
  'generic.notifications': 'Сообщения',
  'generic.prev': 'Добавить другой вариант',
  'generic.price': 'Цена',
  'generic.total': 'Всего',
  'generic.required': 'Обязательное',
  'generic.send': 'Отправить',
  'generic.save': 'Сохранить',
  'generic.save.changes': 'Сохранить изменения',
  'generic.security': 'Безопасность',
  'generic.settings': 'Настройки',
  'generic.sortBy': 'Сортировать по',
  'generic.succeeded': 'Успешно',
  'generic.failed': 'Неудачно',
  'generic.sum': 'Сумма',
  'generic.totalPrice': 'Сумма',
  'generic.discount': 'Скидка',
  'generic.switch': 'Переключить',
  'generic.dragHandleLabel': 'Потяните за иконку чтобы переместить на другое место',
  'generic.room.title': 'Помещение',
  'generic.title': 'Название',
  'generic.undefined': 'Неопределенное',
  'generic.unselected': 'Не выбрано',
  'generic.update': 'Обновить',
  'generic.sku': 'Артикул',
  'generic.width': 'Ширина',
  'generic.height': 'Высота',
  'generic.length': 'Длина',
  'generic.diameter': 'Диаметр',
  'generic.inner': 'внутренний',
  'generic.inner.short': 'внутр.',
  'generic.outer': 'внешний',
  'generic.outer.short': 'внешн.',
  'generic.skuAsc': 'Артикул (А-Я/A-Z)',
  'generic.skuDesc': 'Артикул (Я-А/Z-A)',
  'generic.lastNameAsc': 'Фамилия (А-Я/A-Z)',
  'generic.latestActivity': 'Последняя активность',
  'generic.supplier': 'Поставщик',
  'generic.brand': 'Бренд',
  'generic.mechanism': 'Механизм',
  'generic.fasteningMechanism': 'Механизм крепления',
  'generic.mountTarget': 'Тип крепления',
  'generic.dimensions': 'Размеры',
  'generic.mainFabric': 'Основная ткань, арт.',
  'generic.cornice': 'Карниз',
  'generic.choose': 'Выбрать',
  'generic.chooseAs': 'Выбрать как',
  'generic.draperyCoefficient': 'Коэффициент драпировки',
  'generic.draperyCoefficient.short': 'КД',
  'generic.cancel': 'Отмена',
  'generic.hinges': 'Навеска',
  'generic.montage': 'Монтаж',
  'generic.dismantling': 'Демонтаж',
  'generic.customAny': 'Произвольные',
  'generic.wall': 'На стену',
  'generic.casement': 'На створку',
  'generic.ceiling': 'На потолок',
  'generic.window.hole': 'В проем',
  'generic.other': 'Другое',
  'generic.empty': 'Ваш список пуст',
  'generic.amount': 'Количество',
  'generic.unit': 'Единица измерения',
  'generic.component.amount': 'Количество на изделие',
  'generic.component.unit': 'Единица измерения',
  'generic.component.amountToBuy': 'К-во покупки',
  'generic.component.unitToBuy': 'Ед. измерения',
  'generic.rollAmount': 'Метраж рулона',
  'generic.packageAmount': 'Шт. в упаковке',
  'generic.model': 'Модель',
  'generic.cascade': 'Каскадная сборка',
  'generic.decoration': 'Вариант декора',
  'generic.mainTextileName': 'Название основной ткани',
  'generic.mainTextileColour': 'Цвет основной ткани',
  'generic.edgingTextileName': 'Название ткани канта',
  'generic.edgingTextileColour': 'Цвет ткани канта',
  'generic.chainFixation': 'Фиксация шнура/цепи карниза',
  'generic.grommetColour': 'Цвет люверсов',
  'generic.systemType': 'Тип системы карниза',
  'generic.systemColour': 'Цвет карниза',
  'generic.system': 'Вариант системи карниза',
  'generic.nonStandardForm': 'Нестандартная форма',
  'generic.controlSide': 'Сторона управления',
  'generic.controlChainLength': 'Высота цепи управления',
  'generic.fixation': 'Фиксация шторы',
  'generic.fixation.curtain': 'Фиксация шторы',
  'generic.none': 'Нету',
  'generic.sewing': 'Пошив',
  'generic.sewingAndProcessing': 'Способ пошива и обработки',
  'generic.hangingFor': 'Для навески',
  'generic.hanging': 'Навеска',
  'generic.mounting': 'Для монтажа',
  'generic.mount': 'Монтаж',
  'generic.disassembling': 'Демонтаж',
  'generic.service': 'Услуга',
  'generic.isComplex': 'Сложная',
  'generic.toOrder': 'Оформить',
  'generic.ordering': 'Оформление',
  'generic.note': 'Комментарий',
  'generic.serviceName': 'Название услуги',
  'generic.compositionGroup': 'Группа составляющих',
  'generic.AllProducts': 'Все изделия',
  'generic.payments': 'Платежи',
  'generic.image': 'Изображение',
  'generic.sketch': 'Эскиз',
  'generic.position': 'Позиция',
  'generic.control': 'Управление',
  'generic.projectServices.onlyDelivery.products': 'Избранные для доставки изделия',
  'generic.projectServices.suburbanTransfer.distance': 'Километраж',
  'generic.projectServices.suburbanTransfer.transferType': 'Назначение трансфера',
  'generic.projectServices.suburbanTransfer.deliveryId': 'Услуга доставки которой касается выезд',
  'generic.projectDiscounts': 'Группа скидки',
  'generic.projectDiscounts.property.group': 'Группа скидки',
  'generic.projectDiscounts.property.percent': 'Процент скидки',
  'generic.projectDiscounts.property.flat': 'Сумма скидки',
  'generic.projectDiscounts.property.oneTimeCode': 'Промокод',
  'generic.projectDiscounts.property.referralCode': 'Партнерский код',
  'generic.name': 'Название',
  'generic.nameUk': 'Название (укр)',
  'generic.packageAmount.long': 'Количество штук в упаковке',
  'generic.workingDays': 'рабочих дней',
  'generic.workingDays.short': 'р.д.',

  'nav.back': 'Назад',
  'nav.backToHome': 'Вернуться на главную',
  'nav.main': 'Главное',
  'nav.dashboard': 'Dashboard',
  'nav.projects': 'Проекты',
  'nav.materials': 'Материалы',
  'nav.imports': 'Отчёт импорта материалов',
  'nav.blankOrder': 'Бланк заказа',
  'nav.consultation': 'Консультация',
  'nav.userAdministration': 'Пользователи',
  'nav.needHelp': 'Нужна помощь?',
  'nav.documentation': 'Документация',

  'compositionGroup.materials': 'Материалы',
  'compositionGroup.system': 'Карниз',
  'compositionGroup.accessories': 'Аксессуары',

  'unit.м.п.': 'м.п.',
  'unit.шт.': 'шт.',
  'unit.рулон': 'рулон',
  'unit.уп.': 'уп.',
  'unit.комплект': 'комплект',
  'unit.кг': 'кг',
  'component.unit.м.п.': 'м.п.',
  'component.unit.шт.': 'шт.',
  'component.unit.рулон': 'рулон',
  'component.unit.уп.': 'уп.',
  'component.unit.комплект': 'комплект',
  'component.unit.кг': 'кг',
  'component.unitToBuy.м.п.': 'м.п.',
  'component.unitToBuy.шт.': 'шт.',
  'component.unitToBuy.рулон': 'рулон',
  'component.unitToBuy.уп.': 'уп.',
  'component.unitToBuy.комплект': 'комплект',
  'component.unitToBuy.кг': 'кг',

  'note.sewing': 'Способ пошива и обработки',
  'note.hanging': 'Для навески',
  'note.mounting': 'Для монтажа',

  'product.service.combinedComplexity': 'Комбинация сложности',
  'product.service.combinedComplexity.primary': 'Основная',
  'product.service.combinedComplexity.secondary': 'Вторая',
  'romanBlindsDouble.service.combinedComplexity.primary': 'Штора',
  'romanBlindsDouble.service.combinedComplexity.secondary': 'Тюль',

  'service.sewingCurtains': 'Пошив комплектов штор',
  'service.sewingCurtainsComplex': 'Пошив комплектов штор сложных',
  'service.sewingRomanBlinds': 'Пошив римских штор простых',
  'service.sewingRomanBlindsComplex': 'Пошив римских штор сложных',
  'service.sewingBlanket': 'Пошив покрывал',
  'service.sewingCustom': 'Нестандартный пошив',
  'service.hangingCurtains': 'Навеска тюли/шторы',
  'service.hangingRomanBlinds': 'Навеска римской шторы',
  'service.hangingComplex': 'Сложная навеска',
  'service.hangingBlanket': 'Покрывало простое (утюжка и выкладка)',
  'service.hangingBlanketComplex': 'Покрывало с оборками и др. декором (утюжка и выкладка)',
  'service.hangingChairCover': 'Чехол на стул простой (утюжка и выкладка)',
  'service.hangingChairCoverComplex': 'Чехол на стул с оборкой, бантами и др. декором (утюжка и выкладка)',
  'service.hangingCustom': 'Нестандартная навеска',
  'service.mountCurtainRod': 'Монтаж шторного карниза ПРОСТОЙ',
  'service.mountCurtainRodComplex': 'Монтаж шторного карниза СЛОЖНЫЙ',
  'service.mountRomanBlindSystem': 'Монтаж римского карниза ПРОСТОЙ',
  'service.mountRomanBlindSystemComplex': 'Монтаж римского карниза СЛОЖНЫЙ',
  'service.mountRomanBlindInhort': 'Монтаж готовой римской "Стандарт" ПРОСТОЙ',
  'service.mountRomanBlindInhortComplex': 'Монтаж готовой римской "Стандарт" СЛОЖНЫЙ',
  'service.mountRollerBlindSystem': 'Монтаж тканевых ролет ПРОСТОЙ',
  'service.mountRollerBlindSystemComplex': 'Монтаж тканевых ролет СЛОЖНЫЙ',
  'service.mountJalousieSystem': 'Монтаж жалюзи ПРОСТОЙ',
  'service.mountJalousieSystemComplex': 'Монтаж жалюзи СЛОЖНЫЙ',
  'service.mountCafeSystem': 'Монтаж мини-карниза "кафе"',
  'service.mountHookDrapery': 'Монтаж крючков драпировочных (без карнизов)',
  'service.mountCustom': 'Нестандартный монтаж',
  'service.disassembling': 'Тип услуги Демонтаж',
  'service.disassemblingCurtainRod': 'Демонтаж старых карнизов для установки новых',
  'service.disassemblingCustom': 'Нестандартный демонтаж',

  'mechanism.chainRoman': 'Цепочно-роторный',
  'mechanism.closedBigCaseNoGuidesChainSmoothRoller': 'Закрытая система с большим коробом без направляющих, цепочный плавный механизм',
  'mechanism.closedCTypeChainRoller': 'Закрытая система с П-образными направляющими, цепочный механизм',
  'mechanism.closedCTypeSpringRoller': 'Закрытая система с П-образными направляющими, пружинный механизм',
  'mechanism.closedFlatChainRoller': 'Закрытая система с плоскими направляющими, цепочный механизм',
  'mechanism.closedLTypeSpringRoller': 'Закрытая система с Г-образными направляющими, пружинный механизм',
  'mechanism.cordRoman': 'Веревочный',
  'mechanism.cordSemiautoRoman': 'Цепочно-роторный с автоспуском',
  'mechanism.doubleNonsyncRoman': 'Цепочно-роторный двойной независимый',
  'mechanism.doubleSyncRoman': 'Цепочно-роторный двойной синхронный',
  'mechanism.mock': 'Муляж',
  'mechanism.motorizedRoman': 'Моторизированный',
  'mechanism.none': 'Без карниза',
  'mechanism.openCarrierTopBarSpringCordTensionFixRoller': 'Открытая система на несущей верхней планке, пружинный механизм с фиксацией на шнуровом натяжении',
  'mechanism.openChainRoller': 'Открытая система, цепочный механизм',
  'mechanism.openSpringChainRoller': 'Открытая система, цепочно-пружинный механизм',
  'mechanism.openSpringNoautofixRoller': 'Открытая система, пружинный механизм без самофиксации',
  'mechanism.openSpringRoller': 'Открытая система, пружинный механизм',
  'mechanism.standardChainRoman': 'Стандарт цепочно-роторный',
  'mechanism.standardCordRoman': 'Стандарт веревочный',
  'mechanism.standardDoubleCordRoman': 'Стандарт двойной веревочный',
  'mechanism.verticalJalousie127': 'Верт. жалюзи 127 мм',
  'mechanism.verticalJalousie89': 'Верт. жалюзи 89 мм',
  'mechanism.verticalJalousieTulls': 'Верт. жалюзи Tulls',

  'product.title': 'Название продукта',
  'product.dimensions': 'Размеры и другие числовые параметры',
  'product.dimensions.secondarySameAsPrimary': 'Размеры совпадают',
  
  'product.component.group.textile': 'Ткани',
  'product.component.mainTextile': 'Основная ткань',
  'product.component.secondaryTextile': 'Вторая ткань',
  'product.component.thirdTextile': 'Третья ткань',
  'product.component.fourthTextile': 'Четвёртая ткань',
  'product.component.edgingTextile': 'Ткань канта',
  'product.component.liningTextile': 'Подкладка',
  'product.component.doublerine': 'Дублерин',

  'product.component.group.webbing': 'Тесьма',
  'product.component.webbing': 'Тесьма',
  'product.component.webbing4Stick': 'Тесьма под прут',
  'product.component.webbingAdhesive': 'Тесьма-липучка',
  'product.component.webbingDecorative': 'Тесьма декоративная',

  'product.component.group.furniture': 'Фурнитура',
  'product.component.grommet': 'Люверс',
  'product.component.sRings': 'S-кольца',
  'product.component.magnets': 'Магниты',
  'product.component.zipper': 'Молния',
  'product.component.syntheticFluff': 'Синтепух',
  'product.component.sintepon': 'Синтепон',

  'product.component.group.curtainRodAndAccessory': 'Карнизы и аксессуары',

  'product.component.group.curtainRod': 'Карнизы',
  'product.component.curtainRod': 'Труба',
  'product.component.curtainRod2': 'Труба-2',
  'product.component.curtainProfile': 'Профиль',
  'product.component.curtainProfile2': 'Профиль-2',
  'product.component.handpiece': 'Наконечники',
  'product.component.handpiece2': 'Наконечники-2',
  'product.component.cap': 'Заглушки',
  'product.component.cap2': 'Заглушки-2',
  'product.component.stopper': 'Стопор',
  'product.component.stopper2': 'Стопор-2',
  'product.component.pipeConnector': "Соединитель трубы/профиля",
  'product.component.pipeConnector2': "Соединитель трубы/профиля-2",
  'product.component.bracketExtension': 'Удлинитель кронштейна',
  'product.component.bracketFasten': 'Кронштейн',
  'product.component.wallBracket': 'Стеновой кронштейн',
  'product.component.clipsFasten': 'Клипсы',
  'product.component.thirdPipeHolder': 'Держатель третей трубы',
  'product.component.bendingCurtainRod': 'Изгиб карниза',
  'product.component.romanBlindSystem': 'Римская система',
  'product.component.sticks': 'Прут',
  'product.component.weightingAgent': 'Утяжелитель',
  'product.component.chain': 'Цепочка',
  'product.component.chainWeighter': 'Грузик',
  'product.component.fixation': 'Фиксация',
  'product.component.hookSigma': 'Сигма',
  'product.component.runners': 'Бегунки',
  'product.component.runners2': 'Бегунки-2',
  'product.component.rings4CurtainRod': 'Кольца',
  'product.component.rings4CurtainRod2': 'Кольца-2',
  'product.component.lambrequinLath': 'Планка ламбрикен',
  'product.component.motor': 'Мотор',
  'product.component.miniCurtainRod': 'Мини карниз',
  
  'product.component.group.accessory': 'Аксессуары',
  'product.component.hookDrapery': 'Крючки драпировочные',
  'product.component.rosette': 'Розетка',
  'product.component.pin': 'Прищепка',
  'product.component.tieBack': 'Подхваты',
  'product.component.curtainGuides': 'Штороводители',
  'product.component.powerSupplyUnit': 'Блок питания',
  'product.component.charger': 'Зарядное устройство',
  'product.component.remoteControlUnit': 'Пульт ДУ',
  'product.component.smartHub': 'Smart HUB',

  'product.component.group.productFulfillment': 'Стандартизированные изделия',
  'product.component.standardRomanBlind': 'Римская штора "Стандарт"',
  'product.component.standardRomanBlindSecondary': 'Вторая римская штора "Стандарт"',
  'product.component.standardRomanBlindDoubleSystem': 'Двойной механизм римской шторы "Стандарт"',
  'product.component.textileRollerBlind': 'Тканевые ролеты',
  'product.component.verticalJalousie': 'Вертикальные жалюзи',

  'product.component.shortName.mainTextile': 'Основная',
  'product.component.shortName.secondaryTextile': 'Вторая',
  'product.component.shortName.thirdTextile': 'Третья',
  'product.component.shortName.fourthTextile': 'Четвёртая',
  'product.component.shortName.edgingTextile': 'Кант',
  'product.component.shortName.liningTextile': 'Подкладка',
  'product.component.shortName.doublerine': 'Дублерин',

  'product.design.width': 'Ширина изделия, м',
  'curtainsSimple.design.width': 'Ширина по карнизу, м',
  'curtainsSimpleGrommet.design.width': 'Ширина по карнизу, м',
  'romanBlindsDouble.design.width': 'Ширина полотна шторы, м',
  'product.design.height': 'Высота изделия, м',
  'romanBlindsDouble.design.height': 'Высота полотна шторы, м',
  'product.design.widthSecondary': 'Ширина 2-го полотна, м',
  'product.design.heightSecondary': 'Высота 2-го полотна, м',
  'romanBlindsDouble.design.widthSecondary': 'Ширина полотна тюли, м',
  'romanBlindsDouble.design.heightSecondary': 'Высота полотна тюли, м',

  'product.types.curtainRod': 'Карнизы',
  'product.types.curtains': 'Шторы',
  'product.types.jalousie': 'Жалюзи',
  'product.types.rollerBlinds': 'Ролеты',
  'product.types.romanBlinds': 'Римские',
  
  'product.error.unableToCalculate': 'Изделие содержит ошибки, которые делают невозможным расчет. Дополните настройки, поскольку в таком виде изделие невозможно сохранить.',

  'role': 'Роль',
  'role.admin': 'Администратор',
  'role.support': 'Поддержка',
  'role.director': 'Директор',
  'role.accountant': 'Бухгалтер',
  'role.representative': 'Представитель',
  'role.consultant': 'Консультант',
  'role.designer': 'Дизайнер',
  'role.manager': 'Менеджер',
  'role.production': 'Производство',
  'role.atelier': 'Ателье',
  'role.distributor': 'Комплектовщик',
  'role.mounter': 'Монтажник',
  'role.hanger': 'Навесчик',
  'role.courier': 'Курьер',
  'role.intern': 'Интерн',
  
  'skill': 'Способность',
  'skill.manageSaloon': 'Менеджер салона',
  'skill.callCenter': 'КЦ (колл-центр)',
  'skill.manageDialog': 'Диалог-менеджер',
  'skill.manageHoveredProjects': 'Менеджер по думающим',
  'skill.startingProjects': 'Запуск заказов',
  'skill.KVP': 'РОП (руководитель отдела продаж)',
  
  'position': 'Должность',
  'position.creator': 'Творец',
  'position.admin': 'Администратор',
  'position.support': 'Поддержка',
  'position.director': 'Директор',
  'position.accountant': 'Бухгалтер',
  'position.representative': 'Представитель',
  'position.consultant': 'Ведущий менеджер',
  'position.designer': 'Дизайнер',
  'position.manager': 'Менеджер',
  'position.production': 'Производство',
  'position.atelier': 'Ателье',
  'position.distributor': 'Комплектовщик',
  'position.mounter': 'Монтажник',
  'position.hanger': 'Навесчик',
  'position.courier': 'Курьер',
  'position.intern': 'Интерн',
  'position.manageSaloon': 'Менеджер салона',
  'position.callCenter': 'КЦ',
  'position.manageDialog': 'Диалог-менеджер',
  'position.manageHoveredProjects': 'Менеджер по думающим',
  'position.startingProjects': 'Специалист по запуску',
  
  'settings.autoUpdateNotice': 'Мы автоматически установили вам начальные настройки. Вы всегда можете их изменить в соответствующих панелях управления.',
  'settings.general': 'Общее',

  'settings.language': 'Язык',
  // Опції мовної локалізації (кожен варіант правої частини має залишатись оригінальною мовою)
  'settings.language.uk': 'Українська',
  'settings.language.ru': 'Русский',
  'settings.language.pl': 'Polski',

  'settings.settingsUpdated': 'Настройки обновлены',
  'settings.theme': 'Стиль интерфейса',

  'steps.step1': 'Выбрать изделия',
  'steps.step2': 'Редактировать',
  'steps.step3': 'Вызов на замер',

  'table.rowsPerPage': 'Строк на странице',
  'table.of': 'из',

  'views.consultant.title': 'Консультант',
  'views.consultant.clearSession': 'Действительно очистить текущую сессию консультации?',

  'views.ProjectDesigner.pageTitle': 'Бланк заказа',
  'views.designer.title': 'Дизайнер',
  'views.designer.newProduct': 'Создать новое изделие',
  'views.designer.freeform': 'Произвольное изделие',
  'views.designer.curtainsSimpleDrape': 'Портьера на тесьме',
  'views.designer.curtainsSimpleTulle': 'Тюль на тесьме',
  'views.designer.curtainsSimpleGrommetDrape': 'Портьера на люверсах',
  'views.designer.curtainsSimpleGrommetTulle': 'Тюль на люверсах',
  'views.designer.romanBlindsSimple': 'Римская штора простая',
  'views.designer.romanBlindsLined': 'Римская штора на подкладке',
  'views.designer.romanBlindsDouble': 'Двойные римские шторы',
  'views.designer.romanBlindsSimpleWithoutSystem': 'Римская штора без карниза',
  'views.designer.romanBlindsSimpleMoto': 'Моторизированная римская штора',
  'views.designer.romanBlindsDoubleMoto': 'Моторизированная римская день-ночь',
  'views.designer.standardRomanBlind': 'Римские шторы "Стандарт"',
  'views.designer.standardRomanBlindDouble': 'Римские "Стандарт" день-ночь',
  'views.designer.clearProjectTool.question': 'Удалить текущий заказ и очистить бланк?',
  'views.designer.deleteProductModal.title': 'Удалить «{product}»?',
  'views.designer.deleteProductModal.body': 'Внимание! Данные будут удалены безвозвратно',
  'views.designer.materials.title': 'Материалы',
  'views.designer.services.title': 'Услуги',
  'views.designer.summary.title': 'Итоги',
  'views.designer.orderSummary.title': 'Итоги заказа',
  'views.designer.comments.title': 'Комментарии',
  'views.designer.productComponent': 'Тип составляющей',
  'views.designer.orderContent': 'Содержание заказа',
  'views.designer.editOrderContent': 'Изменить содержание заказа',
  'views.designer.addDiscount': 'Добавить скидку',
  'views.designer.saveOrder': 'Оформить заказ',
  'views.designer.sendOrder': 'Отправить заказ',

  'views.projects.searchLabel': 'Поиск по номеру запуска, телефону, имени, фамилии, адресу, городу, ID',
  'views.projects.count': `{count, plural, one {Всего # заказ} =2 {Всего # заказа} =3 {Всего # заказа} =4 {Всего # заказа} =5 {Всего # заказов} =21 {Всего # заказ} =22 {Всего # заказа} =23 {Всего # заказа} =24 {Всего # заказа} =25 {Всего # заказов} other {Всего # заказов} }`,
  'views.projects.continueToProjectEditing': 'Продолжить редактирование проекта',

  'views.ProjectViewer.loading': 'Обзор заказа ID:{id}',
  'views.ProjectViewer.pageTitle': 'Обзор заказа №{orderNr} относительно соглашения №{dealId} (ID:{id})',
  'views.ProjectViewer.title': 'Заказ (без номера запуска, номер соглашения не определён)',
  'views.ProjectViewer.titleWithDealId': 'Заказ (без номера запуска) относительно соглашения №{dealId}',
  'views.ProjectViewer.titleStarted': 'Заказ №{orderNr} (номер соглашения не определён)',
  'views.ProjectViewer.titleStartedWithDealId': 'Заказ №{orderNr} относительно соглашения №{dealId}',
  'views.ProjectViewer.internalID': 'Внутренний ID: {id}',
  'views.ProjectViewer.participants': 'Участники проекта',
  'views.ProjectViewer.addPaymentsButton': 'Добавить платежи',
  'views.ProjectViewer.goToNotEmptyEditorMessage': 'Ви планируете перейти на один из экранов редактирования заказа. Но в данный момент другой заказ уже пребывает в процессе редактирования.',
  'views.ProjectViewer.goToNotEmptyEditorWarning': 'Если вы подтвердите действие — все не сохранённые изменения в тот заказ будут утрачены!',
  'views.ProjectViewer.totals': 'Общий итог',
  'views.ProjectViewer.services': 'Детализация услуг по заказу',
  'views.ProjectViewer.serviceComplexity': 'Сложность',
  'views.ProjectViewer.serviceComplexity.normal': 'Простая',
  'views.ProjectViewer.serviceComplexity.complex': 'Сложная',
  'views.ProjectViewer.producedItems': 'Изделия',
  'views.ProjectViewer.sewing': 'Пошив',
  'views.ProjectViewer.sewing.sewingCurtains': 'Комплектов штор',
  'views.ProjectViewer.sewing.sewingRomanBlinds': 'Римских штор',
  'views.ProjectViewer.sewing.sewingBlanket': 'Покрывал',
  'views.ProjectViewer.sewing.sewingCustom': 'Нестандартный',
  'views.ProjectViewer.hanging': 'Навеска',
  'views.ProjectViewer.hanging.curtainsNormal': 'Тюли/шторы',
  'views.ProjectViewer.hanging.romanBlindsNormal': 'Римской шторы',
  'views.ProjectViewer.hanging.hangingBlanket': 'Утюжка и выкладка покрывал',
  'views.ProjectViewer.hanging.hangingChairCover': 'Утюжка и выкладка чехлов на стулья',
  'views.ProjectViewer.hanging.hangingCustom': 'Нестандартная',
  'views.ProjectViewer.mount': 'Монтаж',
  'views.ProjectViewer.mount.mountCurtainRod': 'Шторного карниза',
  'views.ProjectViewer.mount.mountRomanBlindSystem': 'Римской системы',
  'views.ProjectViewer.mount.mountRomanBlindInhort': 'Готовой римской "Стандарт"',
  'views.ProjectViewer.mount.mountRollerBlindSystem': 'Тканевых ролет',
  'views.ProjectViewer.mount.mountJalousieSystem': 'Жалюзи',
  'views.ProjectViewer.mount.mountCafeSystem': 'Мини-карниза "Кафе"',
  'views.ProjectViewer.mount.mountHookDrapery': 'Крючков драпировочных (без карнизов)',
  'views.ProjectViewer.mount.mountCustom': 'Нестандартный',
  'views.ProjectViewer.disassembling': 'Демонтаж',
  'views.ProjectViewer.disassembling.disassemblingCurtainRod': 'старых карнизов для установки новых',
  'views.ProjectViewer.disassembling.disassemblingCustom': 'нестандартный',
  'views.ProjectViewer.customServices': 'Произвольные услуги',
  'views.ProjectViewer.products': 'Изделия',
  'views.ProjectViewer.products.itemsCount': '(количество отдельных элементов в производство - {count})',
  'views.ProjectViewer.productDrafts': 'Черновики изделий',
  'views.ProjectViewer.documents': 'Документы к заказу',
  'views.ProjectViewer.documents.autogenerateNotice': 'Готовый документ отсутствовал - создаю новый',

  'documents.generateNew': 'Сгенерировать новый документ',
  'documents.client': 'Клиентский',
  'documents.common': 'Общий производственный',
  'documents.mounter': 'Монтажный',
  
  'views.EditClient.notFound': 'Клиент не найден. Заполните его карту самостоятельно.',
  'views.EditClient.selectAmongDuplicates': 'Клиент (выберите среди найденных дубликатов)',

  'views.ProductViewer.note.sewing': 'Способ пошива и обработки',
  'views.ProductViewer.note.hanging': 'Примечания к навеске',
  'views.ProductViewer.note.mounting': 'Примечания к монтажу',
  
  'views.ProjectEditor.pageTitle': 'Редактор заказа',
  'views.ProjectEditor.discardProjectChanges.question': 'Отменить все совершённые но ещё не сохранённые изменения в заказ?',
  'views.ProjectEditor.saveProjectChanges.preConfirmationInfo': 'Вы планируете сохранить изменения в изделия, помещения и сопроводительные услуги заказа. Система обновит документы и отправит их во все необходимые чаты.',
  'views.ProjectEditor.saveProjectChanges.preConfirmationWarning': 'Обратите внимание, что изменения в данные оформления этим действием не сохраняются! Если необходимо сменить статус, скидку либо другие параметры данных оформления или же добавить платёж, то сохранять нужно на экране «Данные оформления».',
  'views.ProjectEditor.registrationData': 'Данные оформления',
  'views.ProjectEditor.displayProductDrafts': `{count, plural, one {Показать # черновик} =2 {Показать # черновика} =3 {Показать # черновика} =4 {Показать # черновика} =5 {Показать # черновиков} =21 {Показать # черновик} =23 {Показать # черновика} =24 {Показать # черновика} =25 {Показать # черновиков} other {Показать # черновика} }`,
  'views.ProjectEditor.crmIdSave.title': 'Сохранить идентификатор сделки',
  'views.ProjectEditor.crmIdSave.notice': 'Введите идентификатор сделки и нажмите «Сохранить». Запрос на сохранение произойдёт немедленно. Даже в результате успеха никакие сообщения в чаты не отправляются! Обратите внимание, что сохранению подлежит только уникальный идентификатор: к одной сделке может быть присоединён только один проект.',
  'views.ProjectEditor.crmIdSave.success': 'ID сделки успешно сохранён',

  'views.SubProjectExtractor.pageTitle': 'Создание нового заказа с избранными изделиями',
  'views.SubProjectExtractor.returnToBaseProject': 'Вернуться к основному заказу',
  'views.SubProjectExtractor.editNewProject': 'Редактировать новый заказ',
  'views.SubProjectExtractor.starterButton': 'Собрать в отдельный заказ',
  'views.SubProjectExtractor.starterButton.preConfirmationInfo': 'После подтверждения будет создан новый заказ, который будет состоять из выбранных изделий текущего, а также получит аналогичные услуги и скидки. Новый заказ будет касаться того же клиента, по тому же адресу.',
  'views.SubProjectExtractor.starterButton.preConfirmationWarning': 'Новый заказ рассматривается как независимый от текущего. Он получит начальный статус «Замер» и считается, что по нему не было совершено ни единого платежа.',
  'views.SubProjectExtractor.error.notice': 'Процесс прекращён из-за ошибки. Описание ошибки должно возникнуть внизу экрана. Зависимо от его содержания, новый заказ возможно уже был частично создан. Тем не менее для уверенности в полноте и правильности данных следует попробовать повторить запрос сразу же либо предварительно вернуться к основному заказу и исправить недостатки которые спровоцировали проблему.',
  'views.SubProjectExtractor.success.notice': 'Новый заказ данного клиента создан успешно! Вы можете сразу же перейти в редактор только что созданного заказа кнопкой ниже либо найти его позднее среди проектов в статусе «Замер». Новый заказ получил все изображения которые касаются его изделий, активных помещений и его самого в целом. Считается, что никаких платежей не было совершено.',
  'views.SubProjectExtractor.success.info': 'Вы также можете вернуться к редактированию основного заказа кнопкой вверху экрана. Обратите внимание что все изделия в основном заказе остались без изменений. В частности если вы редактировали их но не сохраняли изменения — новый заказ построен уже с новых версий, а основной до сих пор содержит старые варианты. Единственное изменение которое произошло в редакторе основного заказа (при этом не было сохранено) это выключение активности изделий которые вынесены в новый заказ и выбор противоположных.',
  
  'views.ProductEditor.pageTitle': 'Редактор изделия',
  'views.ProductEditor.material.notFound': 'Материал не найден. Измените параметры поиска либо заполните его карту самостоятельно.',
  'views.ProductEditor.material.history': 'История выбора материалов',

  'views.SummaryImports.date': 'Дата',
  'views.SummaryImports.mode': 'Режим импорта',
  'views.SummaryImports.dateSearch.from': 'Поиск от',
  'views.SummaryImports.dateSearch.to': 'Поиск до',
  'views.SummaryImports.mode.update': 'Дополнение / Обновление',
  'views.SummaryImports.mode.reCreate': 'Частичная перезапись',
  'views.SummaryImports.mode.allReset': 'Полная перезапись',
  'views.SummaryImports.errors': 'Проблеми',
  'views.SummaryImports.warnings': 'Замечания',
  'views.SummaryImports.success': 'Успішно',
  'views.SummaryImports.tables': 'Таблицы материалов',
  'views.SummaryImports.searchSku': 'Поиск Артикулов',
  'views.SummaryImports.summaryImport': 'Итоги импорта',
  'views.SummaryImports.summaryImport.add': 'Добавленные',
  'views.SummaryImports.summaryImport.update': 'Обновлённые',
  'views.SummaryImports.summaryImport.duration': 'Время выполнения',
  'views.SummaryImports.summaryImport.sheetImported': 'Таблицы которые импортировались',
  'views.SummaryImports.summaryImport.sheetImported.success': 'Успешно рассмотрены',
  'views.SummaryImports.summaryImport.sheetImported.error': 'Ошибка чтения',
  
  'views.payments.title': 'Платежи по заказу',
  'views.payments.existedPayments': 'Совершенные платежи',
  'views.payments.changeStatus': 'Сменить статус',
  'views.payments.addPayment': 'Добавить платеж',
  'views.payments.addPayments': `{count, plural, one {Добавить платеж} other {Добавить платежи}}`,
  'views.payments.updateProjectNote': 'Дополнить комментарий к заказу',
  'views.payments.updatePaymentsNote': 'Дополнить примечания к платежам',
  'views.payments.crmIdRequired': 'Введите идентификатор сделки т.к. он обязательно должен быть в назначении платежа!',
  'views.payments.noPayOfficesNotice': 'Отсутствуют варианты кассы — перезагрузите пожалуйста страницу.',
  'views.payments.enterpriseNotice': 'Плательщикам частным предпринимателям или юридическим лицам обязательно формировать «Счёт-фактуру». Обратитесь к ответственному менеджеру по вопросу формирования!',
  'views.payments.invoiceLink': 'Ссылка на оплату',
  'views.payments.copyInvoiceDetails': 'Копировать реквизиты',
  'views.payments.success': 'Платёж успешно зарегистрирован',

  'views.materials.title': 'Материалы',
  'views.materials.newOffer': 'Новый материал',
  'views.materials.searchOffers': 'Поиск материалов',
  'views.materials.platform.sku': 'Артикул платформы',
  'views.materials.supplier.sku': 'Артикул поставщика',
  'views.materials.supplier.price': 'Цена поставщика',
  'views.materials.export.title': 'Експорт таблицы цен на материалы',
  'views.materials.export.fromAllSuppliers': 'от всех поставщиков',
  'views.materials.export.fromSupplier': 'от поставщика «{name}»',

  'document.selector.label' : 'Шаблон документа',
  'document.template.TextileMaterialPricelist' : 'Таблица цен на ткани',
  'document.template.WebbingMaterialPricelist' : 'Таблица цен на тесьму',
  'document.template.FurnitureMaterialPricelist' : 'Таблица цен на фурнитуру',
  'document.template.CurtainRodMaterialPricelist' : 'Таблица цен на карнизы и аксессуары',
  'document.restriction.noAllSuppliersForTextileCurtainRod' : 'Недопустимо виполнение запроса на таблицу цен тканей, карнизов или аксессуаров от всех поставщиков вместе из-за технического ограничения. Если необходимы все таблицы ассортимента - выполните запросы по каждому поставщику отдельно. Выбор поставщика в фильтре таблицы.',
  'document.state.creation' : 'создание документа',
  'document.state.queued' : 'находится в очереди',
  'document.state.recreation' : 'создание обновлённого документа',
  'document.state.done' : 'готов к загрузке',
  'document.state.failure' : 'ошибка генерации',
  'document.awaiting.estimatedTimeRemaining' : 'приблизительное время готовности: {time}',
  'document.awaiting.completedAt' : 'выполнено {dateTime}',

  'product.total.materialsPrice': 'Материалы (всего)',
  'product.total.systemAndAccessoriesPrice': 'Карниз и аксессуары (всего)',
  'product.total.productPrice': 'Стоимость изделия',
  'product.total.optionalServicesPrice': 'Дополнительные услуги (всего)',

  'order.total.disassembling.total': 'Демонтаж (всего)',
  'order.total.disassembling.disassemblingCurtainRod': 'Демонтаж старых карнизов для установки новых',
  'order.total.disassembling.disassemblingCustom': 'Нестандартный демонтаж',
  'order.total.sewing.curtains': 'Пошив штор',
  'order.total.sewing.romanBlinds': 'Пошив римских штор',
  'order.total.sewing.sewingBlanket': 'Пошив покрывал',
  'order.total.sewing.sewingCustom': 'Нестандартный пошив',
  'order.total.sewing.total': 'Пошив (всего)',
  'order.total.hanging.curtains': 'Навеска тюли/шторы',
  'order.total.hanging.romanBlinds': 'Навеска римской шторы',
  'order.total.hanging.blanketTotal': 'Утюжка и выкладка покрывал',
  'order.total.hanging.chairCoverTotal': 'Утюжка и выкладка чехлов на стулья',
  'order.total.hanging.hangingCustom': 'Нестандартная навеска',
  'order.total.hanging.total': 'Навеска (всего)',
  'order.total.mount.curtainRodTotal': 'Монтаж шторного карниза',
  'order.total.mount.romanBlindTotal': 'Монтаж римского карниза',
  'order.total.mount.romanBlindInhortTotal': 'Монтаж готовой римской "Стандарт"',
  'order.total.mount.jalousieAndRollerBlindTotal': 'Монтаж тканевых ролет и жалюзи',
  'order.total.mount.cafeSystemTotal': 'Монтаж мини-карниза "кафе"',
  'order.total.mount.hookDraperyTotal': 'Монтаж крючков драпировочных (без карнизов)',
  'order.total.mount.mountCustom': 'Нестандартный монтаж',
  'order.total.mount.total': 'Монтаж (всего)',
  'order.total.custom.total': 'Произвольные услуги (всего)',
  'order.total.projectServices.onlyDeliveryTotal': 'Доставка по городу',
  'order.total.projectServices.suburbanTransferForMeasuring': 'Выезд за город на замер',
  'order.total.projectServices.suburbanTransferOtherTotal': 'Выезды за город (будущие)',
  'order.total.projectServices.total': 'Сопроводительные услуги (всего)',
  'order.total.materials': 'Материалы (всего)',
  'order.total.systemAndAccessories': 'Карнизы и аксессуары (всего)',
  'order.total.productsPrice': 'Сумма стоимости изделий',
  'order.total.services': 'Общая стоимость услуг',
  'order.total.prepaid': 'Предоплата ({value}%)',
  'order.total.expectedPayments': 'Ожидаемые платежи',
  'order.total.expectedPayments.no': 'Заказ оплачен в полном размере.',
  'order.total.expectedPayments.prepaid': 'Предоплата ({value}%)',
  'order.total.expectedPayments.notCompletePrepaidRemainder': 'Ожидаемая предоплата ₴{wholePrepayment} не внесена целиком, недостача',
  'order.total.expectedPayments.suburbanTransferForMeasuring': 'Выезд за город на замер',
  'order.total.expectedPayments.surcharge': 'Доплата',
  'order.total.expectedPayments.surchargeCombined': 'Остаток до полной оплаты',
  'order.total.inAll': 'Всего',
  'order.total.splitByRoom': "(отдельно по помещениям)",
  'order.total.economyOnCompoundOrder': "Экономия объединения",
  'order.total.discounts': 'Скидки',
  'order.total.discounts.custom': 'Индивидуальная скидка',
  'order.total.discounts.flyer': 'Скидка по флаеру',
  'order.total.discounts.referral': 'Партнерская скидка',
  'order.total.discounts.compensatory': 'Компенсация удорожания заказа',
  'order.total.discounts.warranty': 'Компенсация по гарантии',
  'order.total.totalAfterDiscount': 'Всего со скидками',
  'order.total.VAT': 'НДС ({percent}%)',
  'order.total.VAT.including': 'в т.ч. НДС {percent}%',
  'order.total.totalWithVAT': 'Всего с НДС',
  'order.total.general': 'Общая стоимость заказа',

  'images.imagesAndSketches.title': 'Изображения и эскизы',
  'images.error.rejectedFetching': "Проблема загрузки изображений. Проверьте наличие интернет связи.",
  'images.error.rejectedUpload': "Проблема сохранения",
  'images.error.unknownUser': "Проблема авторизации пользователя для добавления изображения",
  'images.error.unknownProject': "Проблема определения проекта для добавления изображения к нему",
  'images.printInDocuments.set.true': 'Изображение не печатается в документах. Нажмите чтобы включить',
  'images.printInDocuments.set.false': 'Изображение попадает в документы. Нажмите чтобы исключить из них', 

  'project.status.new': 'Консультация',
  'project.status.scheduledVisit': 'Назначен замер',
  'project.status.measuring': 'Замер',
  'project.status.hover': 'Думают',
  'project.status.reserve': 'Резерв',
  'project.status.prepaid': 'Оплата',
  'project.status.started': 'В работе',
  'project.status.production': 'В производстве',
  'project.status.completeSet': 'Комплектация',
  'project.status.mount': 'Монтаж',
  'project.status.mountDone': 'Монтаж состоялся',
  'project.status.hanging': 'Навеска',
  'project.status.delivery': 'Доставка',
  'project.status.refactoring': 'Переделка',
  'project.status.repairing': 'Ремонт',
  'project.status.repairOrRefactorDone': 'Переделка/Ремонт состоялась',
  'project.status.surchargeReceived': 'Получена доплата',
  'project.status.completed': 'Закончено',
  'project.status.refusal': 'Отказ',
  
  'project.subStatus.hesitates': 'Сомневается',
  'project.subStatus.commercialProposal': 'Приготовить коммерческое предложение',
  'project.subStatus.invoice': 'Предоставить счёт',
  'project.subStatus.contract': 'Составить договор',

  'project.status': 'Статус',
  'project.subStatus': 'Уточнение статуса',
  'project.isRemote': 'Дистанционный',
  'project.deliveryAndMount.title': 'Доставка и монтаж',
  'project.supplyTimeClass': 'Срок выполнения, р.д.',
  'project.supplyTimeClass.withoutUnit': 'Срок выполнения',
  'project.created': 'Дата создания',
  'project.modified': 'Дата крайнего изменения',
  'project.measuringDate': 'Дата замера',
  'project.measuringDateTime': 'Дата, время замера',
  'project.startDate': 'Дата запуска',
  'project.deliveryDate': 'Дата доставки',
  'project.mountingDate': 'Дата монтажа',
  'project.finishDate': 'Дата завершения',
  'project.finishDateTime': 'Дата, время завершения',
  'project.productionItemsCount': 'Кол-во изделий',
  'project.productionItemsCount.full': 'Количество изделий',
  'project.address.full': 'Полный адрес',
  'project.addressOfClient': 'Адрес доставки совпадает с адресом клиента',
  'project.crmId': 'ID сделки',
  'project.note': 'Общий комментарий',
  'project.paymentsNote': 'Примечания к платежам',
  'project.isPrepaidOnTheSpot': 'Предоплата на замере',
  'project.clientKind': 'Вид клиента как плательщика',
  'project.clientKind.undefinedNotice': 'вначале выберите вид клиента как плательщика',

  'clientKind.private-person': 'Частное лицо',
  'clientKind.private-entrepreneur-VAT-non-payer': 'ЧП без НДС',
  'clientKind.private-entrepreneur-VAT-payer': 'ЧП с НДС',
  'clientKind.LLC-VAT-non-payer': 'ООО без НДС',
  'clientKind.LLC-VAT-payer': 'ООО с НДС',

  'projectServices.title': 'Сопроводительные услуги',
  'projectServices.onlyDelivery': 'Доставка',
  'projectServices.suburbanTransfer': 'Выезд за город',
  'projectServices.suburbanTransfer.distanceLimit.desc': 'Введите километраж по счетчику сверх {distanceLimit} км от КПП в обе стороны',
  'projectServices.suburbanTransfer.transferType.measuring': 'Замер',
  'projectServices.suburbanTransfer.transferType.hangingAndMount': 'Навеска и монтаж (одним выездом)',
  'projectServices.suburbanTransfer.transferType.hanging': 'Навеска (отдельный выезд)',
  'projectServices.suburbanTransfer.transferType.mount': 'Монтаж (отдельный выезд)',
  'projectServices.suburbanTransfer.transferType.delivery': 'Доставка',
  'projectServices.suburbanTransfer.deliveryId.notAvailable': 'Для каждой из существующих доставок уже назначен трансфер. Данный выезд лишний!',
  'projectServices.view.measuringTransferNotice': 'оплачивается отдельным платежом',
  'projectServices.view.delivery': 'Доставка по городу',
  'projectServices.view.delivery.all': 'всех изделий',
  'projectServices.view.delivery.selected': 'избранных изделий',
  'projectServices.view.suburbanTransfer':
    'Выезд за город на {distance} км сверх {distanceLimit} км от КП.',
  'projectServices.view.suburbanTransfer.transferType.title': 'Предназначение:',

  'projectDiscounts.custom.title': 'Индивидуальная скидка',
  'projectDiscounts.flyer.title': 'Скидка по флаеру',
  'projectDiscounts.referral.title': 'Партнерская скидка',
  'projectDiscounts.compensatory.title': 'Компенсация удорожания заказа',
  'projectDiscounts.warranty.title': 'Компенсация по гарантии',
  'projectDiscounts.custom': 'Индивидуальная',
  'projectDiscounts.flyer': 'Флаер',
  'projectDiscounts.referral': 'Партнерская',
  'projectDiscounts.compensatory': 'Компенсаторная',
  'projectDiscounts.warranty': 'Гарантийная',

  'finance.amount': 'Сумма, грн',
  'finance.when': 'Дата совершения',
  'finance.paymentDetails': 'Реквизиты',
  'finance.purposeOfPayment': 'Назначение платежа',
  'finance.payOffice': 'Касса',
  'finance.paymentType': 'Тип платежа',
  'finance.paymentType.full': 'Полный',
  'finance.paymentType.prepaid': 'Предоплата',
  'finance.paymentType.collateral': 'Аванс',
  'finance.paymentType.surcharge': 'Доплата',
  'finance.paymentType.suburbanTransfer': 'Выезд за город',
  'finance.paymentForm': 'Форма',
  'finance.paymentForm.cash': 'Наличный',
  'finance.paymentForm.cash.short': 'нал.',
  'finance.paymentForm.non-cash': 'Безналичный',
  'finance.paymentForm.non-cash.short': 'б/н',
  'finance.notCompletePrepaidRemainder': 'Введенная сумма меньше чем ожидаемая предоплата на ₴{remainder}',
  'finance.notCompleteSurchargeRemainder': 'Введенная сумма меньше чем ожидаемая доплата на ₴{remainder}',
  'finance.notCompleteFullRemainder': 'Введенная сумма меньше чем ожидаемая полная оплата на ₴{remainder}',
  'finance.notCompleteTransferRemainder': 'Введенная сумма меньше чем ожидаемая стоимость выезда на ₴{remainder}',

  'company': 'Компания',
  'company.companyCode': 'ЕГРПОУ',

  'client.title': 'Клиент',
  'client.created': 'Дата регистрации',
  'client.modified': 'Последнее обновление',
  'client.address.main': 'Основной адрес',

  'participant.started': 'Дата привлечения',
  'participant.city': 'Регион',
  
  'materialUsage.isManualAmount.label': 'Вводить количество вручную',
  'materialUsage.isManualPrice.label': 'Вводить стоимость вручную',

  'material.textile.label.collection': 'Коллекция',
  'material.textile.label.design': 'Дизайн',
  'material.textile.label.textileType': 'Вид ткани',
  'material.textile.label.fabricComposition': 'Состав ткани',
  'material.textile.label.rapport': 'Рапорт',
  'material.textile.label.weighted': 'Утяжелитель',
  'material.textile.label.supplyTimeClass': 'Срок производства из ткани',
  'material.webbing.label.supplyTimeClass': 'Срок производства из тесьмы',
  'material.label.colourSet': 'Доступные цвета',
  'material.label.unitDivider': 'Кратность деления',
  'material.label.supplyTimeClass': 'Срок изготовления',
  'material.label.fabricComposition': 'Состав',
  'material.label.forProductCategory': 'Категории продукции назначения',
  'material.label.forProductCategory.short': 'Назначение',
  'material.label.materialType': 'Вид материала',
  'material.label.corniceSystem': 'Система карниза (механизма)',
  'material.label.corniceSystem.short': 'Система',
  'material.label.motorizedSystem': 'Моторизированная система',
  'material.label.diameterCompatibility': 'Совместим с карнизами диаметров',
  'material.label.bracketProfilesConf': 'Конфигурация кронштейна',
  'material.label.itemComposition': 'Комплектация',
  'material.label.madeIn': 'Страна производства',
  'material.label.madeIn.short': 'Страна',
  'material.label.minOrder': 'Минимальный заказ',
  'material.label.minOrder.short': 'Мин. заказ',
  'material.label.notes': 'Примечания',

  'model.romanBlindSolo': 'СОЛО',
  'model.romanBlindLine': 'ЛАЙН',
  'model.romanBlindStella': 'СТЕЛЛА',
  'model.romanBlindPrizma': 'ПРИЗМА',
  'model.romanBlindOlimpia': 'ОЛИМПИЯ',
  'model.romanBlindVenice': 'ВЕНЕЦИЯ',
  'model.romanBlindMilano': 'МИЛАНО',
  'model.romanBlindQuadro': 'КВАДРО',

  'systemType.standard-cord-roman': 'Стандарт веревочный',
  'systemType.standard-chain-roman': 'Стандарт цепочно-роторный',
  'systemType.standard-double-cord-roman': 'Стандарт двойной веревочный',
  'systemType.standard-double-chain-roman': 'Стандарт двойной цепочно-роторный',

  'systemColour.white': 'Белый',
  'systemColour.brown': 'Коричневый',

  'fixation.none': 'Нету',

  'decoration.none': 'Нету',

  'controlSide.left': 'Слева',
  'controlSide.right': 'Справа',

  'controlChainLength.25': '25 см',
  'controlChainLength.50': '50 см',
  'controlChainLength.75': '75 см',
  'controlChainLength.100': '100 см',
  'controlChainLength.125': '125 см',
  'controlChainLength.150': '150 см',
  'controlChainLength.175': '175 см',
  'controlChainLength.200': '200 см',
  'controlChainLength.225': '225 см',
  'controlChainLength.250': '250 см',

  'nonStandardForm.none': 'Нету',
  'nonStandardForm.triangle': 'треугольник',
  'nonStandardForm.trapeze': 'трапеция',

  'rooms.Bedroom': 'Спальня',
  'rooms.LivingRoom': 'Гостиная',
  'rooms.Kitchen': 'Кухня',
  'rooms.DiningRoom': 'Столовая',
  'rooms.ChildRoom': 'Детская',
  'rooms.Cabinet': 'Кабинет',
  'rooms.GuestRoom': 'Гостевая комната',
  'rooms.Corridor': 'Коридор',
  'rooms.Wardrobe': 'Гардеробная',
  'rooms.Balcony': 'Балкон',
  'rooms.Loggia': 'Лоджия',
  'rooms.Terrace': 'Терраса',
  'rooms.Bathroom': 'Ванная комната',
  'rooms.Studio': 'Студия',
  'rooms.Office': 'Офис',
  'rooms.Gazebo': 'Беседка',
  'rooms.Bedroom.1': 'Спальня 1',
  'rooms.Bedroom.2': 'Спальня 2',
  'rooms.Bedroom.3': 'Спальня 3',
  'rooms.Bedroom.son': 'Спальня сына',
  'rooms.Bedroom.daughter': 'Спальня дочки',
  'rooms.ChildRoom.daughter': 'Детская дочки',
  'rooms.ChildRoom.son': 'Детская сына',

  'sms.send': 'Отправить SMS',
  'sms.not_sent': 'SMS сообщение пребывает в очереди на отправку',
  'sms.ok_sent': 'Сообщение отправлено, но статус доставки ещё не известен',
  'sms.ok_delivered': 'Сообщение доставлено',
  'sms.err_src_invalid': 'Доставка SMS невозможна: неверные параметры отправителя',
  'sms.err_dest_invalid': 'Неверный номер - доставка SMS невозможна',
  'sms.err_not_allowed': 'Доставка SMS невозможна: данный оператор связи не обслуживается',
  'sms.err_delivery_failed': 'Доставка SMS не удалась. Проверьте правильность и доступность номера телефона и повторите попытку.',
  'sms.err_lost': 'Доставка SMS не произошла (потеря сообщения). Пожалуйста повторите попытку.',
  'sms.err_internal': 'Доставка SMS не произошла (внутренний сбой). Пожалуйста повторите попытку.',

  'user.createdAt': 'Дата регистрации',

  'views.userAdministration.title': 'Пользователи',
  'views.userAdministration.searchByName': 'Поиск по фамилии',
  'views.userAdministration.filterByRoles': 'Роли пользователя',
  'views.userAdministration.filterBySkills': 'Способности (уточнение роли)',
  'views.userAdministration.sortByRoleAndLastNameAsc': 'Роль и фамилия',
  'views.userAdministration.authenticatedSession': 'Сеcсия',
  'views.userAdministration.closeSelectedSessions': 'Закрыть сессии',
  'views.userAdministration.closeSelectedSessions.promptMessage': 'Вы планируете закрыть все активные сессии следующих пользователей:',
  'views.userAdministration.closeSelectedSessions.promptWarning': 'После подтверждения действия обозначенным пользователям для продолжения работы в приложении потребуется повторная авторизация на всех устройствах.',
  'views.userAdministration.block': 'Заблокировать',
  'views.userAdministration.block.promptMessage': 'Вы планируете заблокировать (прекратить все активные сессии и деактивировать с невозможностью повтроно авторизоваться в приложении) следующих пользователей:',
  'views.userAdministration.block.promptWarning': 'Заблокированные пользователи вообще исчезают из перечней существующих, не могут повторно авторизоваться в приложении, но данные их аккаунтов еще остаются сохранёнными в системе для работоспособности ранее связанных с ними проектов. При необходимости полоностью удалить данные либо восстановить активность аккаунта обратитесь к техническому администратору системы.',
  'views.userAdministration.bulkOperationResultMessage.failed': 'Неудачный результат выполнения операции для всех выбранных пользователей.',
  'views.userAdministration.bulkOperationResultMessage.partiallyFailed': 'Частично неудачный результат выполнения операции, а именно для: {subjects}',
  'views.userAdministration.bulkOperationResultMessage.succeeded': 'Успешно применено для всех выбранных пользователей.',
};

export default messages;
