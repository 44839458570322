import { MessagesKeys } from 'locales';

// prettier-ignore
const messages: MessagesKeys = {
  // ПОЧАТОК СТРОК ДЛЯ ПЕРЕКЛАДУ
  // ПЕРЕКЛАДУ ПІДЛЯГАЄ ЛИШЕ ПРАВА ЧАСТИНА (ТА ЩО В ЛАПКАХ ПІСЛЯ ДВОКРАПКИ)

  'app.description': 'Narzędzie projektant firan.',
  'app.name': 'Platforma',
  'app.newVersion.body':
    'W każdej chwili możesz nacisnąć przycisk "Odświeżyć", po czym strona zostanie odświeżona w nowej wersji. Aby odświeżyć później: naciśnij przycisk "Zamknąć", później, kiedy będziesz chciał odświeżyć stronę, wyjdź z aplikacji, zamknij aplikację albo wyszukiwarkę. Podczas następego uruchomienia strony, będziesz korzystać z odświeżonej wersji.',
  'app.newVersion.title': 'Istnieje nowa wersja aplikacji!',
  'auth.account': 'Konto',
  'auth.anonymous': 'Nieznany',
  'auth.login': 'Zalogować się',
  'auth.loginFormDesc': 'Zaloguj się do serwisu',
  'auth.signIn': 'Zaloguj się',
  'auth.signOut': 'Wyloguj się',
  'auth.signOutFromAllDevices': 'Wyloguj się na wszystkich urządzeniach',
  'auth.unableToLogoutNotice': 'Nie można się zalogować',
  'auth.forgotPasswordLink': 'Zapomniałeś hasła?',
  'auth.PasswordRecovery.title': 'Odzyskiwanie hasła',
  'auth.PasswordRecovery.expectEmail.desc': 'Wpisz adres e-mail swojego konta',
  'auth.PasswordRecovery.claimFailed.desc': 'Nie można było zastosować wprowadzonego adresu e-mail lub proces wygasł. Spróbuj wrócić do ekranu autoryzacji i ponownie rozpocząć proces odzyskiwania hasła.',
  'auth.PasswordRecovery.confirmationCode': 'Kod weryfikacyjny',
  'auth.PasswordRecovery.expectConfirmation.desc': 'Wiadomość z kodem potwierdzającym powinna pojawić się na Twoim osobistym czacie z Platformą. Wpisz ten kod w polu poniżej.',
  'auth.PasswordRecovery.confirmationFailed.desc': 'Nie można zweryfikować kodu lub proces wygasł. Spróbuj wrócić do ekranu autoryzacji i ponownie rozpocząć proces odzyskiwania hasła.',
  'auth.PasswordRecovery.success.desc': 'Z powodzeniem! Nowe hasło powinno już dotrzeć do Ciebie w wiadomości na osobistym czacie z Platformą. Możesz wrócić do strony logowania.',
  'auth.sessions': 'Aktywne sesje autoryzacyjne',
  'auth.sessions.short': 'Sesje',
  'auth.sessions.my.control.desc': 'Zarządzanie wszystkimi aktywnymi sesjami aplikacji na wszystkich urządzeniach i przeglądarkach.',
  'auth.sessions.current': 'Obecna sesja',
  'auth.sessions.start': 'Początek',
  'auth.sessions.latestActivity': 'Ostatnia aktywność',

  'city': 'Miasto',
  // Перехресний переклад населених пунктів (міста та інші)
  // При додаванні нової локалізації, крім перекладу правої частини кожного міста, до кожного з міст потрібно додати новий рядок де ліва та права частини містимуть назву міста згідно нової локалі
  // Також новий ряд з лівою частиною назви міста новою локаллю потрібно додати у всі інші файли локалізації.
  'Київ': 'Kijów',
  'Киев': 'Kijów',
  'Kyiv': 'Kijów',
  'Kijów': 'Kijów',
  'Одеса': 'Odessa',
  'Одесса': 'Odessa',
  'Odesa': 'Odessa',
  'Odessa': 'Odessa',
  'Харків': 'Charków',
  'Харьков': 'Charków',
  'Kharkiv': 'Charków',
  'Charków': 'Charków',
  'Львів': 'Lwów',
  'Львов': 'Lwów',
  'Lviv': 'Lwów',
  'Lwów': 'Lwów',
  'Дніпро': 'Dniepr',
  'Днепр': 'Dniepr',
  'Dnipro': 'Dniepr',
  'Dniepr': 'Dniepr',
  'Івано-Франківськ': 'Iwano-Frankiwsk',
  'Ивано-Франковск': 'Iwano-Frankiwsk',
  'Ivano-Frankivsk': 'Iwano-Frankiwsk',
  'Iwano-Frankiwsk': 'Iwano-Frankiwsk',
  'Хмельницький': 'Chmielnicki',
  'Хмельницкий': 'Chmielnicki',
  'Khmelnytskyi': 'Chmielnicki',
  'Chmielnicki': 'Chmielnicki',
  'Ужгород': 'Użhorod',
  'Uzhhorod': 'Użhorod',
  'Użhorod': 'Użhorod',
  'Мукачево': 'Mukaczewo',
  'Mukachevo': 'Mukaczewo',
  'Mukaczewo': 'Mukaczewo',
  'Софіївська Борщагівка': 'Sowijiwśka Borszczahiwka',
  'Софиевская Борщаговка': 'Sowijiwśka Borszczahiwka',
  'Sofiivska Borschahivka': 'Sowijiwśka Borszczahiwka',
  'Sowijiwśka Borszczahiwka': 'Sowijiwśka Borszczahiwka',
  'Петропавлівська Борщагівка': 'Petropawliwśka Borszczahiwka',
  'Петропавловская Борщаговка': 'Petropawliwśka Borszczahiwka',
  'Petropavlivska Borshchahivka': 'Petropawliwśka Borszczahiwka',
  'Petropawliwśka Borszczahiwka': 'Petropawliwśka Borszczahiwka',
  'Крюківщина': 'Kriukiwszczyna',
  'Крюковщина': 'Kriukiwszczyna',
  'Kriukivshchyna': 'Kriukiwszczyna',
  'Kriukiwszczyna': 'Kriukiwszczyna',
  'Святопетрівське': 'Swiatopetriwskie',
  'Святопетровское': 'Swiatopetriwskie',
  'Sviatopetrivske': 'Swiatopetriwskie',
  'Swiatopetriwskie': 'Swiatopetriwskie',
  'Бориспіль': 'Boryspol',
  'Борисполь': 'Boryspol',
  'Boryspil': 'Boryspol',
  'Boryspol': 'Boryspol',
  'Бровари': 'Browary',
  'Бровары': 'Browary',
  'Brovary': 'Browary',
  'Browary': 'Browary',
  'Ірпінь': 'Irpień',
  'Ирпень': 'Irpień',
  'Irpin': 'Irpień',
  'Irpień': 'Irpień',
  'Буча': 'Bucza',
  'Bucha': 'Bucza',
  'Bucza': 'Bucza',
  'Обухів': 'Obuchów',
  'Обухов': 'Obuchów',
  'Obukhiv': 'Obuchów',
  'Obuchów': 'Obuchów',
  'Вишгород': 'Wyszogród',
  'Вышгород': 'Wyszogród',
  'Vyshhorod': 'Wyszogród',
  'Wyszogród': 'Wyszogród',
  'Вишневе': 'Wysznewe',
  'Вишневое': 'Wysznewe',
  'Vyshneve': 'Wysznewe',
  'Wysznewe': 'Wysznewe',
  'Боярка': 'Bojarka',
  'Boyarka': 'Bojarka',
  'Bojarka': 'Bojarka',
  'Гатне': 'Hatne',
  'Гатное': 'Hatne',
  'Hatne': 'Hatne',
  'Тарасівка': 'Tarasiwka',
  'Тарасовка': 'Tarasiwka',
  'Tarasivka': 'Tarasiwka',
  'Tarasiwka': 'Tarasiwka',
  'Білогородка': 'Biłohorodka',
  'Белогородка': 'Biłohorodka',
  'Bilohorodka': 'Biłohorodka',
  'Biłohorodka': 'Biłohorodka',
  'Українка': 'Ukrainka',
  'Украинка': 'Ukrainka',
  'Ukrainka': 'Ukrainka',
  'Васильків': 'Wasylkiw',
  'Васильков': 'Wasylkiw',
  'Vasylkiv': 'Wasylkiw',
  'Wasylkiw': 'Wasylkiw',
  'Черкаси': 'Czerkasy',
  'Черкассы': 'Czerkasy',
  'Cherkasy': 'Czerkasy',
  'Czerkasy': 'Czerkasy',
  'Полтава': 'Połtawa',
  'Poltava': 'Połtawa',
  'Połtawa': 'Połtawa',
  'Рівне': 'Równe',
  'Ровно': 'Równe',
  'Rivne': 'Równe',
  'Równe': 'Równe',
  'Вінниця': 'Winnica',
  'Винница': 'Winnica',
  'Vinnytsia': 'Winnica',
  'Winnica': 'Winnica',
  'Запоріжжя': 'Zaporoże',
  'Запорожье': 'Zaporoże',
  'Zaporizhzhia': 'Zaporoże',
  'Zaporoże': 'Zaporoże',
  'Славутич': 'Sławutycz',
  'Slavutych': 'Sławutycz',
  'Sławutycz': 'Sławutycz',
  'Кривий Ріг': 'Krzywy Róg',
  'Кривой Рог': 'Krzywy Róg',
  'Kryvyi Rih': 'Krzywy Róg',
  'Krzywy Róg': 'Krzywy Róg',
  'Миколаїв': 'Mikołajów',
  'Николаев': 'Mikołajów',
  'Mykolaiv': 'Mikołajów',
  'Mikołajów': 'Mikołajów',
  'Житомир': 'Żytomierz',
  'Zhytomyr': 'Żytomierz',
  'Żytomierz': 'Żytomierz',
  'Чернігів': 'Czernihów',
  'Чернигов': 'Czernihów',
  'Chernihiv': 'Czernihów',
  'Czernihów': 'Czernihów',
  'Херсон': 'Chersoń',
  'Kherson': 'Chersoń',
  'Chersoń': 'Chersoń',
  'Луцьк': 'Łuck',
  'Луцк': 'Łuck',
  'Lutsk': 'Łuck',
  'Łuck': 'Łuck',
  'Суми': 'Sumy',
  'Сумы': 'Sumy',
  'Sumy': 'Sumy',
  'Краматорськ': 'Kramatorsk',
  'Краматорск': 'Kramatorsk',
  'Kramatorsk': 'Kramatorsk',

  'consultant.steps.combined.size': 'Szerokość (szerokość wzdłuż karniszu х wysokość produktu)',
  'consultant.steps.step': 'Krok',
  'consultant.steps.table.category': 'Kategoria',
  'consultant.steps.tableHead.draperyCoefficient.short': 'WM',
  'consultant.steps.tableHead.draperyCoefficient': 'Współczynnik marszczenia',
  'consultant.steps.tableHead.luxury': 'Luksusowe',
  'consultant.steps.tableHead.premium': 'Premium',
  'consultant.steps.tableHead.standard': 'Standard',

  'designer.steps.step1': 'Rodzaj produktu',
  'designer.steps.step2': 'Podstawowe parametry',
  'designer.steps.step3': 'Formularz produktu',

  'errors.error': 'Błąd',
  'errors.unknown': 'Nieznany błąd. Skontaktuj się z pomocą techniczną.',
  'errors.error404': '404: Nie znaleziono',
  'errors.pageNotFound.description':
    'Być może samodzielnie wprowadziłeś niepoprawny adres albo strona, której szukasz nie została odnaleziona.',
  'errors.pageNotFound.title': '404: Nie można znaleźć żądanej strony',

  'form.city': 'Miejscowość',
  'form.residentialComplex': 'Kompleks mieszkaniowy',
  'form.address': 'Adres',
  'form.emailAddress': 'Adres poczty elektronicznej',
  'form.emailAlerts': 'Powiadomienie e-mail',
  'form.emailLabel': 'Email Adres',
  'form.emailRequiredNotice': 'Email konieczny',
  'form.emailValidationNotice': 'Weryfikacja adresu e-mail',
  'form.language': 'Język',
  'form.firstName': "Imię",
  'form.firstNameRequiredNotice': "Wpisz imię",
  'form.lastName': 'Nazwisko',
  'form.lastNameRequiredNotice': 'Wpisz nazwisko',
  'form.secondName': 'Imię ojca',
  'form.secondNameRequiredNotice': 'Wpisz imię ojca',
  'form.materials.searchResults.message': 'Nie znaleziono produktu',
  'form.materials.searchResults.placeholder': 'Wpisz produkt',
  'form.minCharacters': 'Powinno zawierać przynajmniej 8 znaków',
  'form.passwordConfirmation': 'Powtórz hasło',
  'form.passwordLabel': 'Hasło',
  'form.passwordRequiredNotice': 'Musisz wpisać hasło',
  'form.passwordsMustMatch': 'Hasła powinny być takie same',
  'form.passwordUpdated': 'Hasło zostało zmienione',
  'form.phones': 'Numer telefonu',
  'form.phoneNumber': 'Numer telefonu',
  'form.additionalPhoneNumber': 'Dodatkowy numer telefonu',
  'form.profile': 'Profil',
  'form.profileUpdated': 'Aktualizacja profilu',
  'form.pushNotifications': 'Powiadomienia Push',
  'form.system': 'System',
  'form.projectRooms.inputHelper': 'Wybierz jedno lub kilka dostępnych pomieszczeń albo wpisz własne opcje.',
  'form.projectRooms.emptyNotice': 'Poprzednio ustaw listę pomieszczeń!',
  'form.telegramNotifications': 'Wiadomości Telegram',
  'form.weWillUseThisEmailToContactYou': "Wykorzystujemy podany adres poczty elektronicznej do kontaktowania się z Naszymi Klientami",
  'form.youWillReceiveEmails': 'Otrzymujesz e-mail na wpisaną przez Ciebie firmową pocztę elektroniczną',
  'form.password': 'Hasło nie może zawierać mniej niż 8 znaków',
  'form.validate.minValue': 'Minimalna wartość {value}',
  'form.validate.maxValue': 'Maksymalna wartość {value}',

  'generic.in': 'w ', // важливо зберігати пробіл після літери
  'generic.all': 'Wszystkie',
  'generic.add': 'Dodać',
  'generic.assortment': 'Asortyment',
  'generic.new': 'Nowy',
  'generic.next': 'Dalej',
  'generic.confirm': 'Potwierdzić',
  'generic.compoundPersonName': 'Pełne imię i nazwisko',
  'generic.loading': 'Ładowanie',
  'generic.download': 'Załadować',
  'generic.repeat': 'Powtórzyć',
  'generic.repeatTrying': 'Prosimy powtórz próbę',
  'generic.processStart': 'Proces start...',
  'generic.processing': 'Proces w toku...',
  'generic.moreOptions': 'Więcej opcji',
  'generic.moreActions': 'Więcej czynności',
  'generic.noOptions': 'Nie ma żadnych opcji',
  'generic.addOption': 'Dodać "{value}"',
  'generic.category': 'Kategoria',
  'generic.change': 'Zmienić',
  'generic.changes': 'Zmiany',
  'generic.discardChanges': 'Cofnąć zmiany',
  'generic.clear': 'Wyczyścić',
  'generic.clearSession': 'Wyczyścić sesję',
  'generic.clearBlank': 'Wyczyścić formularz',
  'generic.close': 'Zamknąć',
  'generic.copied': 'Zostało skopiowane',
  'generic.colour': 'Kolor',
  'generic.description': 'Opis',
  'generic.createdAsc': 'Data utworzenia (od najnowszych)',
  'generic.createdDesc': 'Data utworzenia (od najstarszych )',
  'generic.current': 'Aktualny',
  'generic.days': `{days, plural, one {# dzień} =2 {# dni} =3 {# dni} =4 {# dni} =22 {# dni} =23 {# dni} =24 {# dni} other {# dni} }`,
  'generic.delete': 'Usunąć',
  'generic.edit': 'Zmienić',
  'generic.view': 'Rewizja',
  'generic.export': 'Eksport',
  'generic.filter': 'Filtr',
  'generic.from': 'od',
  'generic.import': 'Import',
  'generic.material': 'Materiał',
  'generic.notifications': 'Powiadomienie',
  'generic.prev': 'Dodać inną opcję',
  'generic.price': 'Cena',
  'generic.total': 'Razem',
  'generic.required': "Obowiązkowe",
  'generic.send': 'Wysłać',
  'generic.save': 'Zapisz',
  'generic.save.changes': 'Zachować zmiany',
  'generic.security': 'Bezpieczeństwo',
  'generic.settings': 'Ustawienia',
  'generic.sortBy': 'Filtruj wg',
  'generic.succeeded': 'Z powodzeniem',
  'generic.failed': 'Przegrany',
  'generic.sum': 'Kwota',
  'generic.totalPrice': 'Kwota',
  'generic.discount': 'Rabat',
  'generic.switch': 'Zmienić',
  'generic.dragHandleLabel': 'Kliknij ikonkę, by przesunąć w inne miejsce',
  'generic.room.title': 'Pomieszczenie',
  'generic.title': 'Nazwa',
  'generic.undefined': 'Nieokreślony',
  'generic.unselected': 'Nic nie wybrano',
  'generic.update': 'Odświeżyć',
  'generic.sku': 'Produkt',
  'generic.width': 'Szerokość',
  'generic.height': 'Wysokość',
  'generic.length': 'Długość',
  'generic.diameter': 'Średnica',
  'generic.inner': 'wewnętrzny',
  'generic.inner.short': 'wewn.',
  'generic.outer': 'zewnętrzny',
  'generic.outer.short': 'zewn.',
  'generic.skuAsc': 'Produkt (A-Z)',
  'generic.skuDesc': 'Produkt (Z-A)',
  'generic.lastNameAsc': 'Nazwa (A-Z)',
  'generic.latestActivity': 'Ostatnia aktywność',
  'generic.supplier': 'Dostawca',
  'generic.brand': 'Marka',
  'generic.mechanism': 'Mechanizm',
  'generic.fasteningMechanism': 'Mechanizm mocowania',
  'generic.mountTarget': 'Rodzaj mechanizmu',
  'generic.dimensions': 'Wymiary',
  'generic.mainFabric': 'Podstawowy materiał, art.',
  'generic.cornice': 'Karnisz',
  'generic.choose': 'Wybrać',
  'generic.chooseAs': 'Wybrać',
  'generic.draperyCoefficient': 'Współczyynik marszczenia',
  'generic.draperyCoefficient.short': 'WM',
  'generic.cancel': 'Anulować',
  'generic.hinges': 'Wieszanie',
  'generic.montage': 'Montaż',
  'generic.dismantling': 'Demontaż',
  'generic.customAny': 'Dowolne',
  'generic.wall': 'Do ściany',
  'generic.casement': 'Na okiennicy',
  'generic.ceiling': 'Do sufitu',
  'generic.window.hole': 'Na skrzydło okna',
  'generic.other': 'Inne',
  'generic.empty': 'Twoja lista jest pusta',
  'generic.amount': 'Liczba',
  'generic.unit': 'Jednostka pomiaru',
  'generic.component.amount': 'Liczba na produkt',
  'generic.component.unit': 'Jednostka pomiaru',
  'generic.component.amountToBuy': 'Liczba do zakupu',
  'generic.component.unitToBuy': 'Jednostka pomiaru',
  'generic.rollAmount': 'Długość rolki',
  'generic.packageAmount': 'Szt. w opakowaniu',
  'generic.model': 'Model',
  'generic.cascade': 'Kaskadowy sposób zwijania',
  'generic.decoration': 'Opcja dekoracji',
  'generic.mainTextileName': 'Nazwa tkaniny podstawowej',
  'generic.mainTextileColour': 'Kolor tkaniny podstawowej',
  'generic.edgingTextileName': 'Nazwa tkaniny na brzegach',
  'generic.edgingTextileColour': 'Kolor tkaniny na brzegach',
  'generic.grommetColour': 'Kolor luwersów',
  'generic.chainFixation': 'Mocowanie sznurku/łańciuszka karnisza',
  'generic.systemType': 'Rodzaj systemu karnisza',
  'generic.systemColour': 'Kolor karnisza',
  'generic.system': 'Opcja systemu karniszowego',
  'generic.nonStandardForm': 'Nietypowy kszałt',
  'generic.controlSide': 'Strona sterowania',
  'generic.controlChainLength': 'Długość łańcuszka do sterowania żaluzją',
  'generic.fixation': 'Blokowanie',
  'generic.fixation.curtain': 'Blokowanie zasłon',
  'generic.none': 'Nie ma',
  'generic.sewing': 'Szycie',
  'generic.sewingAndProcessing': 'Technika szycia i wykańczania',
  'generic.hangingFor': 'Do zawieszania',
  'generic.hanging': 'Żabki',
  'generic.mounting': 'Do montażu',
  'generic.mount': 'Montaż',
  'generic.disassembling': 'Demontaż',
  'generic.service': 'Usługa',
  'generic.isComplex': 'Składane',
  'generic.toOrder': 'Złożyć zamówienie',
  'generic.ordering': 'Złożenie zamówienia',
  'generic.note': 'Komentarz',
  'generic.serviceName': 'Nazwa usługi',
  'generic.compositionGroup': 'Zestaw części składowych',
  'generic.AllProducts': 'Wszystkie produkty',
  'generic.payments': 'Płatności',
  'generic.image': 'Obraz',
  'generic.sketch': 'Szkic',
  'generic.position': 'Pozycja',
  'generic.control': 'Zarządzanie',
  'generic.projectServices.onlyDelivery.products': 'Wybrane produkty do dostawy',
  'generic.projectServices.suburbanTransfer.distance': 'Kilometraż',
  'generic.projectServices.suburbanTransfer.transferType': 'Przeznaczenie transferu',
  'generic.projectServices.suburbanTransfer.deliveryId': 'Usługa dostawy, dotycząca wyjazdu',
  'generic.projectDiscounts': 'Rodzaj rabatu',
  'generic.projectDiscounts.property.group': 'Rodzaj rabatu',
  'generic.projectDiscounts.property.percent': 'Procent rabatu',
  'generic.projectDiscounts.property.flat': 'Kwota rabatu',
  'generic.projectDiscounts.property.oneTimeCode': 'Kod promocyjny',
  'generic.projectDiscounts.property.referralCode': 'Kod dla partnerów',
  'generic.name': 'Nazwa',
  'generic.nameUk': 'Nazwa',
  'generic.packageAmount.long': 'Liczba szt. w opakowaniu',
  'generic.workingDays': 'dni robocze',
  'generic.workingDays.short': 'd.r.',

  'nav.back': 'Powrócić',
  'nav.backToHome': 'Powrócić do strony głównej',
  'nav.main': 'Główna',
  'nav.dashboard': 'Dashboard',
  'nav.materials': 'Materiały',
  'nav.projects': 'Projekty',
  'nav.imports': 'Podusmowanie importu materiałów',
  'nav.blankOrder': 'Formularz zamówienia',
  'nav.consultation': 'Pomoc',
  'nav.userAdministration': 'Użytkownicy',
  'nav.needHelp': 'Potrzebujesz pomocy?',
  'nav.documentation': 'Dokumenty',

  'compositionGroup.materials': 'Materiały',
  'compositionGroup.system': 'Karnisz',
  'compositionGroup.accessories': 'Akcesoria',

  'unit.м.п.': 'm.p.',
  'unit.шт.': 'szt.',
  'unit.рулон': 'rolka',
  'unit.уп.': 'op.',
  'unit.комплект': 'zestaw',
  'unit.кг': 'kg',
  'component.unit.м.п.': 'm.p.',
  'component.unit.шт.': 'szt.',
  'component.unit.рулон': 'rolka',
  'component.unit.уп.': 'op.',
  'component.unit.комплект': 'zestaw',
  'component.unit.кг': 'kg',
  'component.unitToBuy.м.п.': 'm.p.',
  'component.unitToBuy.шт.': 'szt.',
  'component.unitToBuy.рулон': 'rolka',
  'component.unitToBuy.уп.': 'op.',
  'component.unitToBuy.комплект': 'zestaw',
  'component.unitToBuy.кг': 'kg',

  'note.sewing': 'Technika szycia i wykańczania',
  'note.hanging': 'Do zawieszania',
  'note.mounting': 'Do montażu',

  'product.service.combinedComplexity': 'Kombinacja trudności',
  'product.service.combinedComplexity.primary': 'Główny',
  'product.service.combinedComplexity.secondary': 'Drugi',
  'romanBlindsDouble.service.combinedComplexity.primary': 'Główny',
  'romanBlindsDouble.service.combinedComplexity.secondary': 'Tiul',

  'service.sewingCurtains': 'Szycie zestawów zasłon',
  'service.sewingCurtainsComplex': 'Szycie zestawów zasłon plisowanych',
  'service.sewingRomanBlinds': 'Szycie zasłon rzymskich zwykłych',
  'service.sewingRomanBlindsComplex': 'Szycie zasłon rzymskich plisowanych',
  'service.sewingBlanket': 'Szycie narzut',
  'service.sewingCustom': 'Szycie nietypowe',
  'service.hangingCurtains': 'Zasłony/firany wieszanie',
  'service.hangingRomanBlinds': 'Zasłony rzymskie wieszanie',
  'service.hangingComplex': 'Wieszanie zasłon plisowanych',
  'service.hangingBlanket': 'Narzuta zwykła (prasowanie i ścielenie)',
  'service.hangingBlanketComplex': 'Narzuta z falbankami albo innymi elementami ozdobnymi (prasowanie i ścielenie)',
  'service.hangingChairCover': 'Pokrowiec na krzesło zwykły (prasowanie i zakładanie)',
  'service.hangingChairCoverComplex': 'Pokrowiec na krzesło z kokardą, falbanką czy innymi elementami ozdobnymi (prasowanie i zakładanie)',
  'service.hangingCustom': 'Nietypowe wieszanie',
  'service.mountCurtainRod': 'Montaż karnisza na zasłony ZWYKŁY',
  'service.mountCurtainRodComplex': 'Montaż karnisza na zasłony ZESTAW',
  'service.mountRomanBlindSystem': 'Montaż karnisza na rzymskie zasłony ZWYKŁY',
  'service.mountRomanBlindSystemComplex': 'Montaż karnisza na rzymskie zasłony ZESTAW',
  'service.mountRomanBlindInhort': 'Montaż gotowego karnisza na rzymskie zasłony "Standard" ZWYKŁY',
  'service.mountRomanBlindInhortComplex': 'Montaż gotowego karnisza na rzymskie zasłony "Standard" ZESTAW',
  'service.mountRollerBlindSystem': 'Montaż roletów materiałowych PROSTY',
  'service.mountRollerBlindSystemComplex': 'Montaż roletów materiałowych ZESTAW',
  'service.mountJalousieSystem': 'Montaż żałuzji ZWYKŁY',
  'service.mountJalousieSystemComplex': 'Montaż żaluzji ZESTAW',
  'service.mountCafeSystem': 'Montaż mini karniszów "cafe"',
  'service.mountHookDrapery': 'Montaż wsporników (bez karniszów)',
  'service.mountCustom': 'Nietypowy montaż',
  'service.disassembling': 'Rodzaj usługi Demontaż',
  'service.disassemblingCurtainRod': 'Demontaż starych karniszy przed montażem nowych karniszy',
  'service.disassemblingCustom': 'Nietypowy demontaż',

  'mechanism.chainRoman': 'Łańcuszkowo-obrotowy',
  'mechanism.closedBigCaseNoGuidesChainSmoothRoller':
    'System zamknięty z dużą zamknietą kasetą bez lameli, mechanizm łańcuszkowy',
  'mechanism.closedCTypeChainRoller': 'Zamknięty system z lamelami w kształcie litery P, mechanizm łańcuszkowy',
  'mechanism.closedCTypeSpringRoller': 'Zamknięty system z lamelami w kształcie litery P, mechanizm sprężynowy',
  'mechanism.closedFlatChainRoller': 'Zamknięty system z płaskimi lamelami, mechanizm łańcuszkowy',
  'mechanism.closedLTypeSpringRoller': 'Zamknięty system z lamelami w kształcie litery G, mechanizm sprężynowy',
  'mechanism.cordRoman': 'Sznurek',
  'mechanism.cordSemiautoRoman': 'Łańcuszkowo-obrotowy z automatycznym opuszczaniem',
  'mechanism.doubleNonsyncRoman': 'Łańcuszkowo-obrotowy podwójny samodzielny',
  'mechanism.doubleSyncRoman': 'Łańcuszkowo-obrotowy podwójny synchronizowany',
  'mechanism.mock': 'Model',
  'mechanism.motorizedRoman': 'Z napędem elektrycznym',
  'mechanism.none': 'Bez karnisza',
  'mechanism.openCarrierTopBarSpringCordTensionFixRoller':
    'System otwarty z przystawką nośną do kasety, mechanizm sprężynowy z mocowaniem',
  'mechanism.openChainRoller': 'System otwarty, mechanizm łańcuszkowy',
  'mechanism.openSpringChainRoller': 'System otwarty mechanizm łańcuszkowo-sprężynowy',
  'mechanism.openSpringNoautofixRoller': 'System otwarty, mechanizm sprężynowy bez mocowania',
  'mechanism.openSpringRoller': 'System owarty mechanizm sprężynowy',
  'mechanism.standardChainRoman': 'Standard łańcuszkowo-obrotowy',
  'mechanism.standardCordRoman': 'Standard sznurkowy',
  'mechanism.standardDoubleCordRoman': 'Standard podwójny sznurkowy',
  'mechanism.verticalJalousie127': 'Pion. żaluzje 127 mm',
  'mechanism.verticalJalousie89': 'Pion. żaluzje 89 mm',
  'mechanism.verticalJalousieTulls': 'Pion. żaluzje Tulls',

  'product.title': 'Nazwa produktu',
  'product.dimensions': 'Wymiary i inne parametry liczbowe',
  'product.dimensions.secondarySameAsPrimary': 'Wymiary się zgadzają',

  'product.component.group.textile': 'Tkanina',
  'product.component.mainTextile': 'Tkanina podstawowa',
  'product.component.secondaryTextile': 'Druga tkanina',
  'product.component.thirdTextile': 'Trzecia tkanina',
  'product.component.fourthTextile': 'Czwarta tkanina',
  'product.component.edgingTextile': 'Tkanina brzegów',
  'product.component.liningTextile': 'Podszewka',
  'product.component.doublerine': 'Dublerin',

  'product.component.group.webbing': 'Taśma tapicerska',
  'product.component.webbing': 'Taśma tapicerska',
  'product.component.webbing4Stick': 'Taśma na drążek',
  'product.component.webbingAdhesive': 'Taśma rzep',
  'product.component.webbingDecorative': 'Taśma ozdobna',

  'product.component.group.furniture': 'Tapicerka',
  'product.component.grommet': 'Luwers',
  'product.component.sRings': 'S-kółka',
  'product.component.magnets': 'Magnesy',
  'product.component.zipper': 'Zamek błyskawiczny',
  'product.component.syntheticFluff': 'Kulki silikonowe',
  'product.component.sintepon': 'Włóknina',

  'product.component.group.curtainRodAndAccessory': 'Karnisze i dodatki',

  'product.component.group.curtainRod': 'Karnisze',
  'product.component.curtainRod': 'Rurka',
  'product.component.curtainRod2': 'Rurka-2',
  'product.component.curtainProfile': 'Profil',
  'product.component.curtainProfile2': 'Profil-2',
  'product.component.handpiece': 'Zakończenie',
  'product.component.handpiece2': 'Zakończenie-2',
  'product.component.cap': 'Zaślepki',
  'product.component.cap2': 'Zaślepki-2',
  'product.component.stopper': 'Stoper',
  'product.component.stopper2': 'Stoper-2',
  'product.component.pipeConnector': "Łączniki do rury/profilu",
  'product.component.pipeConnector2': "Łączniki do rury/profilu-2",
  'product.component.bracketExtension': 'Przedłużki do szyny',
  'product.component.bracketFasten': 'Szyna',
  'product.component.wallBracket': 'Kołki montażowe dedykowane do ściany',
  'product.component.clipsFasten': 'Klipsy',
  'product.component.thirdPipeHolder': 'Wspornik trzeciej rury',
  'product.component.bendingCurtainRod': 'Wyginanie karnisza',
  'product.component.romanBlindSystem': 'System rzymski',
  'product.component.sticks': 'Rura',
  'product.component.weightingAgent': 'Obciążnik',
  'product.component.chain': 'Łańcuch',
  'product.component.chainWeighter': 'Grużok',
  'product.component.fixation': 'Blokowanie',
  'product.component.hookSigma': 'Sigma',
  'product.component.runners': 'Żabki',
  'product.component.runners2': 'Żabki-2',
  'product.component.rings4CurtainRod': 'Kółka',
  'product.component.rings4CurtainRod2': 'Kółka-2',
  'product.component.lambrequinLath': 'Szyna na lambrekin',
  'product.component.motor': 'Silnik',
  'product.component.miniCurtainRod': 'Mini karnisz',
  
  'product.component.group.accessory': 'Akcesoria',
  'product.component.hookDrapery': 'Wspornik do karnisza',
  'product.component.rosette': 'Gniazdko',
  'product.component.pin': 'Żabka',
  'product.component.tieBack': 'Upinacze/chwosty',
  'product.component.curtainGuides': 'Szyny na zasłony',
  'product.component.powerSupplyUnit': 'Zasilacz',
  'product.component.charger': 'Ładowarka',
  'product.component.remoteControlUnit': 'Pilot zdalnego sterowania',
  'product.component.smartHub': 'Smart HUB',

  'product.component.group.productFulfillment': 'Produkty standardowe',
  'product.component.standardRomanBlind': 'Zasłona rzymska "Standard"',
  'product.component.standardRomanBlindSecondary': 'Druga rzymska zasłona "Standard"',
  'product.component.standardRomanBlindDoubleSystem': 'Podwójny mechanizm rzymskiej zasłony "Standard"',
  'product.component.textileRollerBlind': 'Rolety materiałowe',
  'product.component.verticalJalousie': 'Żaluzje pionowe',

  'product.component.shortName.mainTextile': 'Podstawowa',
  'product.component.shortName.secondaryTextile': 'Druga',
  'product.component.shortName.thirdTextile': 'Trzecia',
  'product.component.shortName.fourthTextile': 'Czwarta',
  'product.component.shortName.edgingTextile': 'Brzegi',
  'product.component.shortName.liningTextile': 'Podszewka',
  'product.component.shortName.doublerine': 'Dublerin',

  'product.design.width': 'Szerokość produktu, м',
  'curtainsSimple.design.width': 'Szerokość wzdłuż karniszu, м',
  'curtainsSimpleGrommet.design.width': 'Szerokość wzdłuż karniszu, м',
  'romanBlindsDouble.design.width': 'Szerokość płótna kurtyny, м',
  'product.design.height': 'Wysokość produktu, м',
  'romanBlindsDouble.design.height': 'Wysokość płótna kurtyny, м',
  'product.design.widthSecondary': 'Szerokość drugiego płótna, m',
  'product.design.heightSecondary': 'Wysokość drugiego płótna, m',
  'romanBlindsDouble.design.widthSecondary': 'Szerokość płótna tiulowej, m',
  'romanBlindsDouble.design.heightSecondary': 'Wysokość płótna tiulowej, m',

  'product.types.curtainRod': 'Karnisze',
  'product.types.curtains': 'Zasłony',
  'product.types.jalousie': 'Żaluzje',
  'product.types.rollerBlinds': 'Rolety',
  'product.types.romanBlinds': 'Rzymskie',
  
  'product.error.unableToCalculate': 'Produkt zawiera błędy, co uniemożliwia obliczenie. Uzupełnij ustawienia, ponieważ dana opcja nie pozwala na zachowanie produktu.',

  'role': 'Rola',
  'role.admin': 'Administrator',
  'role.support': 'Wsparcie',
  'role.director': 'Dyrektor',
  'role.accountant': 'Księgowy',
  'role.representative': 'Przedstawiciel',
  'role.consultant': 'Obsługa Klienta',
  'role.designer': 'Projektant',
  'role.manager': 'Menedżer',
  'role.production': 'Produkcja',
  'role.atelier': 'Atelier',
  'role.distributor': 'Zbieracz',
  'role.mounter': 'Instalator',
  'role.hanger': 'Wieszak',
  'role.courier': 'Kurier',
  'role.intern': 'Intern',
  
  'skill': 'Zdolność',
  'skill.manageSaloon': 'Kierownik salonu',
  'skill.callCenter': 'CT (centrum telefoniczne)',
  'skill.manageDialog': 'Menedżer dialogu',
  'skill.manageHoveredProjects': 'Menedżer „myślicieli”',
  'skill.startingProjects': 'Rozpoczęcie zamówień',
  'skill.KVP': 'SDS (szef działu sprzedaży)',
  
  'position': 'Pozycja',
  'position.creator': 'Twórca',
  'position.admin': 'Administrator',
  'position.support': 'Wsparcie',
  'position.director': 'Dyrektor',
  'position.accountant': 'Księgowy',
  'position.representative': 'Przedstawiciel',
  'position.consultant': 'Wiodący menadżer',
  'position.designer': 'Projektant',
  'position.manager': 'Menedżer',
  'position.production': 'Produkcja',
  'position.atelier': 'Atelier',
  'position.distributor': 'Zbieracz',
  'position.mounter': 'Instalator',
  'position.hanger': 'Wieszak',
  'position.courier': 'Kurier',
  'position.intern': 'Intern',
  'position.manageSaloon': 'Kierownik salonu',
  'position.callCenter': 'CT (centrum telefoniczne)',
  'position.manageDialog': 'Menedżer dialogu',
  'position.manageHoveredProjects': 'Menedżer „myślicieli”',
  'position.startingProjects': 'Specjalista ds. startupów',
  
  'settings.autoUpdateNotice':
    'Automatycznie ustawiliśmy podstawowe ustawienia. Zawsze możesz je zmienić w odpowiednich panelach sterowania.',
  'settings.general': 'Ogólne',

  'settings.language': 'Język',
  // Опції мовної локалізації (кожен варіант правої частини має залишатись оригінальною мовою)
  'settings.language.uk': 'Українська',
  'settings.language.ru': 'Русский',
  'settings.language.pl': 'Polski',

  'settings.settingsUpdated': 'Ustawienia zaktualizowano',
  'settings.theme': 'Styl interfejsu',

  'steps.step1': 'Wybrać produkty',
  'steps.step2': 'Edycja',
  'steps.step3': 'Wezwać specjalistę ds. pomiaru',

  'table.rowsPerPage': 'Pól na stronie',
  'table.of': 'з',

  'views.consultant.title': 'Obsługa Klienta',
  'views.consultant.clearSession': 'Jesteś pewny, że chcesz wyczyścić aktualną sesję konsultacji?',

  'views.ProjectDesigner.pageTitle': 'Formularz zamówienia',
  'views.designer.title': 'Projektant',
  'views.designer.newProduct': 'Stworzyć nowy produkt',
  'views.designer.freeform': 'Dowolny produkt',
  'views.designer.curtainsSimpleDrape': 'Drapowana zasłona na taśmie',
  'views.designer.curtainsSimpleTulle': 'Firana na taśmie',
  'views.designer.curtainsSimpleGrommetDrape': 'Drapowana zasłona na luwersach',
  'views.designer.curtainsSimpleGrommetTulle': 'Firana na luwersach',
  'views.designer.romanBlindsSimple': 'Rzymska zasłona jest prosta',
  'views.designer.romanBlindsLined': 'Zasłona rzymska na podszewce',
  'views.designer.romanBlindsDouble': 'Podwójne zasłony rzymskie',
  'views.designer.romanBlindsSimpleWithoutSystem': 'Zasłona rzymska bez gzymsu',
  'views.designer.romanBlindsSimpleMoto': 'Zmotoryzowana zasłona rzymska',
  'views.designer.romanBlindsDoubleMoto': 'Zmotoryzowany rzymski dzień-noc',
  'views.designer.standardRomanBlind': 'Zasłona rzymska "Standard"',
  'views.designer.standardRomanBlindDouble': 'Rzymski "Standard" dzień-noc',
  'views.designer.clearProjectTool.question': 'Usunąć aktualne zamówienie i wyczyścić formularz?',
  'views.designer.deleteProductModal.title': 'Usunąć «{product}»?',
  'views.designer.deleteProductModal.body': 'Uwaga! Dane zostaną trwale usunięte',
  'views.designer.materials.title': 'Materiały',
  'views.designer.services.title': 'Usługi',
  'views.designer.summary.title': 'Podsumowanie',
  'views.designer.orderSummary.title': 'Podsumowanie zamówienia',
  'views.designer.comments.title': 'Komentarze',
  'views.designer.productComponent': 'Rodzaj części składowej',
  'views.designer.orderContent': 'Treść zamówienia',
  'views.designer.editOrderContent': 'Edytować treść zamówienia',
  'views.designer.addDiscount': 'Dodać rabat',
  'views.designer.saveOrder': 'Złożyć zamówienie',
  'views.designer.sendOrder': 'Wysłać zamówienie',
  
  'views.projects.searchLabel': 'Szukaj według numeru startowego, telefonu, imienia, nazwiska, adresu, miasta, ID',
  'views.projects.count': `{count, plural, one {Ogólna liczba # zamówień} =2 {Ogólna liczba # zamówień} =3 {Ogólna liczba # zamówień} =4 {Ogólna liczba # zamówień} =5 {Ogólna liczba # zamówień} =21 {Ogólna liczba # zamówień} =23 {Ogólna liczba # zamówień} =24 {Ogólna liczba # zamówień} =25 {Ogólna liczba # zamówień} other {Ogólna liczba # zamówień} }`,
  'views.projects.continueToProjectEditing': 'Kontynuować edycję projektu',
  
  'views.ProjectViewer.loading': 'Zobacz zamówienie ID:{id}',
  'views.ProjectViewer.pageTitle': 'Zobacz zamówienie №{orderNr} zgodnie z umową №{dealId} (ID:{id})',
  'views.ProjectViewer.title': 'Zamówienie (bez numeru startowego, numer umową niezdefiniowany)',
  'views.ProjectViewer.titleWithDealId': 'Zamówienie (bez numeru startowego) zgodnie z umową №{dealId}',
  'views.ProjectViewer.titleStarted': 'Zamówienie №{orderNr} (numer umową niezdefiniowany)',
  'views.ProjectViewer.titleStartedWithDealId': 'Zamówienie №{orderNr} zgodnie z umową №{dealId}',
  'views.ProjectViewer.internalID': 'Wewnętrzny: {id}',
  'views.ProjectViewer.participants': 'Uczestnicy projektu',
  'views.ProjectViewer.addPaymentsButton': 'Dodaj płatności',
  'views.ProjectViewer.goToNotEmptyEditorMessage': 'Planujesz przejść do jednego z ekranów edycji zamówienia. Jednak inne zamówienie jest obecnie edytowane.',
  'views.ProjectViewer.goToNotEmptyEditorWarning': 'Jeśli potwierdzisz akcję - wszystkie niezapisane zmiany w tym zamówieniu zostaną utracone!',
  'views.ProjectViewer.totals': 'Ogólne podsumowanie',
  'views.ProjectViewer.services': 'Szczegółowość usług na zamówienie',
  'views.ProjectViewer.serviceComplexity': 'Złożoność',
  'views.ProjectViewer.serviceComplexity.normal': 'Zwykły',
  'views.ProjectViewer.serviceComplexity.complex': 'Zestaw',
  'views.ProjectViewer.producedItems': 'Produkty',
  'views.ProjectViewer.sewing': 'Szycie',
  'views.ProjectViewer.sewing.sewingCurtains': 'Komplety zasłon',
  'views.ProjectViewer.sewing.sewingRomanBlinds': 'Rzymskich zasłon',
  'views.ProjectViewer.sewing.sewingBlanket': 'Narzut',
  'views.ProjectViewer.sewing.sewingCustom': 'Nietypowy',
  'views.ProjectViewer.hanging': 'Wieszanie',
  'views.ProjectViewer.hanging.curtainsNormal': 'Firan/zasłon',
  'views.ProjectViewer.hanging.romanBlindsNormal': 'Rzymskich zasłon',
  'views.ProjectViewer.hanging.hangingBlanket': 'Prasowanie i ścielenia narzut',
  'views.ProjectViewer.hanging.hangingChairCover': 'Prasowanie i zakładanie pokrowców na krzesła',
  'views.ProjectViewer.hanging.hangingCustom': 'Nietypowy',
  'views.ProjectViewer.mount': 'Montaż',
  'views.ProjectViewer.mount.mountCurtainRod': 'Karnisza na zasłony',
  'views.ProjectViewer.mount.mountRomanBlindSystem': 'Karnisza na rzymskie zasłony',
  'views.ProjectViewer.mount.mountRomanBlindInhort': 'Gotowego karnisza na rzymskie "Standard"',
  'views.ProjectViewer.mount.mountRollerBlindSystem': 'Roletów materiałowych',
  'views.ProjectViewer.mount.mountJalousieSystem': 'Żałuzji',
  'views.ProjectViewer.mount.mountCafeSystem': 'Mini karniszów "cafe"',
  'views.ProjectViewer.mount.mountHookDrapery': 'Wsporników (bez karniszów)',
  'views.ProjectViewer.mount.mountCustom': 'Nietypowy',
  'views.ProjectViewer.disassembling': 'Demontaż',
  'views.ProjectViewer.disassembling.disassemblingCurtainRod': 'starych karniszy przed montażem nowych karniszy',
  'views.ProjectViewer.disassembling.disassemblingCustom': 'nietypowy',
  'views.ProjectViewer.customServices': 'Inne usługi',
  'views.ProjectViewer.products': 'Produkty',
  'views.ProjectViewer.products.itemsCount': '(ilość poszczególnych elementów w produkcji - {count})',
  'views.ProjectViewer.productDrafts': 'Szkice produktów',
  'views.ProjectViewer.documents': 'Dokumenty do zamówienia',
  'views.ProjectViewer.documents.autogenerateNotice': 'Brakowało gotowego dokumentu - tworzę nowy',

  'documents.generateNew': 'Wygeneruj nowy dokument',
  'documents.client': 'Klienta',
  'documents.common': 'Ogólny produkcyjny',
  'documents.mounter': 'Montażowy',
  
  'views.EditClient.notFound': 'Nie znaleziono klienta. Sam wypełnij jego kartę.',
  'views.EditClient.selectAmongDuplicates': 'Klient (wybierz spośród znalezionych duplikatów)',

  'views.ProductViewer.note.sewing': 'Technika szycia i wykańczania',
  'views.ProductViewer.note.hanging': 'Uwagi dotyczące zawieszania',
  'views.ProductViewer.note.mounting': 'Uwagi dotyczące montażu',
  
  'views.ProjectEditor.pageTitle': 'Edytor zamówienia',
  'views.ProjectEditor.discardProjectChanges.question': 'Anulować wszystkie dokonane, ale jeszcze nie zapisane zmiany w zamówieniu?',
  'views.ProjectEditor.saveProjectChanges.preConfirmationInfo': 'Chcesz zachować zmiany w produkcie, pomieszczeniu czy usługach towarzyszących zamówieniu. System aktualizuje dokumenty i wyśle je w wiadomościach wszystkich dostępnych czatów.',
  'views.ProjectEditor.saveProjectChanges.preConfirmationWarning': 'Pamiętaj, że zmiany w danym zamówieniu po wykonaniu tej czynności się nie zapiszą! Chcesz zmienić status, rabat czy inne parametry danych zamówienia albo dodać płatności, musisz zapisać zmiany na ekranie «Dane zamówienia».',
  'views.ProjectEditor.registrationData': 'Dane zamówienia',
  'views.ProjectEditor.displayProductDrafts': `{count, plural, one {Wyświetlić # wersje robocze} =2 {Wyświetlić # wersje robocze} =3 {Wyświetlić # wersje robocze} =4 {Wyświetlić # wersji roboczych} =5 {Wyświetlić # wersję roboczą} =21 {Wyświetlić # wersje robocze} =23 {Wyświetlić # wersje robocze} =24 {Wyświetlić # wersje robocze} =25 {Wyświetlić # wersji roboczych} other {Wyświetlić # wersje robocze} }`,
  'views.ProjectEditor.crmIdSave.title': 'Zapisz identyfikator umowy',
  'views.ProjectEditor.crmIdSave.notice': 'Wprowadź identyfikator umowy i kliknij Zapisz. Żądanie zapisania identyfikatora nastąpi natychmiast. Nawet w przypadku sukcesu żadna wiadomość nie zostanie wysłana na czat! Należy pamiętać, że można zapisać tylko unikalny identyfikator: tylko jeden projekt może być powiązany z jedną umową.',
  'views.ProjectEditor.crmIdSave.success': 'Pomyślnie zapisano identyfikator umowy',
  
  'views.SubProjectExtractor.pageTitle': 'Tworzenie nowego zamówienia z wybranych produktów',
  'views.SubProjectExtractor.returnToBaseProject': 'Powrócić do zamówienia podstawowego',
  'views.SubProjectExtractor.editNewProject': 'Edytować nowe zamówienie',
  'views.SubProjectExtractor.starterButton': 'Zebrać w odrębne zamówienie',
  'views.SubProjectExtractor.starterButton.preConfirmationInfo': 'Po potwierdzeniu zostanie stworzone nowe zamówienie, które się będzie składać z wybranych produktów aktualnego, a także zostanie uzupełnione o identyczne usługi i rabaty. Nowe zamówienie będzie dotyczyć tego samego Klienta i tego samego adresu.',
  'views.SubProjectExtractor.starterButton.preConfirmationWarning': 'Nowe zamówinie jest traktowane jako niezależne od aktualnego. Otrzyma status «Pomiar» i będzie traktowane jako nieopłacone zamówienie.',
  'views.SubProjectExtractor.error.notice': 'Przerwano proces wystąpił błąd. Opis błędu zostanie wyświetlony na dole ekranu. W zależności od błędu, być może nowe zamówienie będzie częściowo stworzone. Aby jednak mieć pewność, że wszystkie dane zamówienia zostały poprawnie zapisane, najlepiej powtórzyć czy powrócić do zamówienia podstawowego i zmienić niepoprawne dane, które były przyczyną wystąpienia błędu.',
  'views.SubProjectExtractor.success.notice': 'Nowe zamówienie danego Klienta zostało stworzone! Możesz od razu przejść do edycji nowego zamówienia naciskając przycisk, znajdujący się na dole albo później go wyszukać wśród projektów w statusie «Pomiar». Nowe zamówienie zostało uzupełnione o wszystkie niezbędne obrazy, dotyczące produktów, aktualne pomieszczenia i bezpośrednio zamówienia. Zamówienie jest traktowane jako nieopłacone zamówienie.',
  'views.SubProjectExtractor.success.info': 'Naciskając przycisk na górze ekranu, możesz powrócić do edycji podstawowego zamówienia. Pamiętaj, że wszystkie produkty w podstawowym zamówieniu pozostały bez zmian. Jeśli edytowałeś je, ale nie zapisałeś zmian — nowe zamówienie będzie już zawierać nowe produkty, z kolei zamówienie podstawowe będzie zawierać poprzednie produkty. Jedyna zmiana, która zajdzie w edytorze zamówienia podstawowego (nie zapisana), wyłączenie aktywności produktów dodanych do nowego zamówienia oraz wybór innych.',

  'views.ProductEditor.pageTitle': 'Edytor produktu',
  'views.ProductEditor.material.notFound': 'Nie znaleziono materiału. Zmień parametry żądania lub samodzielnie wypełnij jego kartę.',
  'views.ProductEditor.material.history': 'Historia doboru materiałów',

  'views.SummaryImports.date': 'Data',
  'views.SummaryImports.mode': 'Tryb importu',
  'views.SummaryImports.dateSearch.from': 'Szukaj od',
  'views.SummaryImports.dateSearch.to': 'Szukaj do',
  'views.SummaryImports.mode.update': 'Dodatki / Aktualizacje',
  'views.SummaryImports.mode.reCreate': 'Zmienić',
  'views.SummaryImports.mode.allReset': 'Wyczyścić wszystko',
  'views.SummaryImports.errors': 'Błędy',
  'views.SummaryImports.warnings': 'Uwagi',
  'views.SummaryImports.success': 'Pomyślnie zapisany',
  'views.SummaryImports.tables': 'Tabele tkanin',
  'views.SummaryImports.searchSku': 'Szukaj produkty',
  'views.SummaryImports.summaryImport': 'Podsumowanie importu',
  'views.SummaryImports.summaryImport.add': 'Dodane',
  'views.SummaryImports.summaryImport.update': 'Zaktualizowane',
  'views.SummaryImports.summaryImport.duration': 'Czas realizacji',
  'views.SummaryImports.summaryImport.sheetImported': 'Importowane tabele',
  'views.SummaryImports.summaryImport.sheetImported.success': 'Pomyślnie importowane',
  'views.SummaryImports.summaryImport.sheetImported.error': 'Błąd wczytywania',

  'views.payments.title': 'Płatności dotyczące zamówienia',
  'views.payments.existedPayments': 'Dokonane płatności',
  'views.payments.changeStatus': 'Zmienić status',
  'views.payments.addPayment': 'Dodać płatności',
  'views.payments.addPayments': `{count, plural, one {Dodać opłatę} other {Dodać opłatę}}`,
  'views.payments.updateProjectNote': 'Dodać wpisy dotyczący zamówienia',
  'views.payments.updatePaymentsNote': 'Dodać wpisy dotyczące opłat',
  'views.payments.crmIdRequired': 'Wpisz numer umowy w tytule opłaty, gdyż jest to warunkiem koniecznym.!',
  'views.payments.noPayOfficesNotice': 'Brak opcji kasy — odśwież stronę.',
  'views.payments.enterpriseNotice': 'Płatnicy, indywidualni przedsiębiorcy lub osoby prawne, muszą wystawić „Fakturę”. Skontaktuj się z odpowiedzialnym kierownikiem w sprawie formacji!',
  'views.payments.invoiceLink': 'Link opłaty',
  'views.payments.copyInvoiceDetails': 'Skopiować dane do przelewu ',
  'views.payments.success': 'Płatność została pomyślnie zarejestrowana',

  'views.materials.title': 'Materiały',
  'views.materials.newOffer': 'Nowy materiał',
  'views.materials.searchOffers': 'Szukaj materiałów',
  'views.materials.platform.sku': 'Produkt sklepu',
  'views.materials.supplier.sku': 'Produkt dostawcy',
  'views.materials.supplier.price': 'Cena dostawcy',
  'views.materials.export.title': 'Eksportuj tabelę cen materiałów',
  'views.materials.export.fromAllSuppliers': 'od wszystkich dostawców',
  'views.materials.export.fromSupplier': 'od dostawcy «{name}»',

  'document.selector.label' : 'Szablon dokumentu',
  'document.template.TextileMaterialPricelist' : 'Tabela cen tkanin',
  'document.template.WebbingMaterialPricelist' : 'Tabela cen warkoczy',
  'document.template.FurnitureMaterialPricelist' : 'Tabela cen okucia',
  'document.template.CurtainRodMaterialPricelist' : 'Cennik gzymsów i akcesoriów',
  'document.restriction.noAllSuppliersForTextileCurtainRod' : 'Niedopuszczalne jest składanie zapytania o cennik tkanin, gzymsów lub dodatków od wszystkich dostawców łącznie ze względu na ograniczenia techniczne. Jeśli potrzebujesz wszystkich tabel asortymentu, wypełnij zapytania dla każdego dostawcy osobno. Wybór dostawcy w filtrze tabeli.',
  'document.state.creation' : 'tworzenie dokumentu',
  'document.state.queued' : 'jest w kolejce',
  'document.state.recreation' : 'tworzenie zaktualizowanego dokumentu',
  'document.state.done' : 'gotowy do pobrania',
  'document.state.failure' : 'błąd generacji',
  'document.awaiting.estimatedTimeRemaining' : 'przewidywany czas gotowości: {time}',
  'document.awaiting.completedAt' : 'zrobione {dateTime}',

  'product.total.materialsPrice': 'Materiały (razem)',
  'product.total.systemAndAccessoriesPrice': 'Karnisz i akcesoria (razem)',
  'product.total.productPrice': 'Cena produktu',
  'product.total.optionalServicesPrice': 'Dodatkowe usługi (razem)',

  'order.total.disassembling.total': 'Demontaż (razem)',
  'order.total.disassembling.disassemblingCurtainRod': 'Demontaż starych karniszy w celu montażu nowych karniszy',
  'order.total.disassembling.disassemblingCustom': 'Nietypowy demontaż',
  'order.total.sewing.curtains': 'Szycie zasłon',
  'order.total.sewing.romanBlinds': 'Szycie rzymskich zasłon',
  'order.total.sewing.sewingBlanket': 'Szycie narzut',
  'order.total.sewing.sewingCustom': 'Nietypowe szycie',
  'order.total.sewing.total': 'Szycie (razem)',
  'order.total.hanging.curtains': 'Wieszanie firan/zasłon',
  'order.total.hanging.romanBlinds': 'Wieszanie rzymskich zasłon',
  'order.total.hanging.blanketTotal': 'Prasowanie i ścielenia narzut',
  'order.total.hanging.chairCoverTotal': 'Prasowanie i zakładanie pokrowców na krzesła',
  'order.total.hanging.hangingCustom': 'Nietypowe wieszanie',
  'order.total.hanging.total': 'Wieszanie (razem)',
  'order.total.mount.curtainRodTotal': 'Montaż karnisza na zasłonę',
  'order.total.mount.romanBlindTotal': 'Montaż karnisza na rzymską zasłonę',
  'order.total.mount.romanBlindInhortTotal': 'Montaż gotowej zasłony rzymskiej "Standard"',
  'order.total.mount.jalousieAndRollerBlindTotal': 'Montaż rolety materiałowej i żaluzji',
  'order.total.mount.cafeSystemTotal': 'Montaż mini karniszów typu "cafe"',
  'order.total.mount.hookDraperyTotal': 'Montaż wsporników (bez karniszów)',
  'order.total.mount.mountCustom': 'Nietypowy montaż',
  'order.total.mount.total': 'Montaż (razem)',
  'order.total.custom.total': 'Inne usługi (razem)',
  'order.total.projectServices.onlyDeliveryTotal': 'Dostawa w granicach miasta',
  'order.total.projectServices.suburbanTransferForMeasuring': 'Wizyta specjalisty ds. pomiaru poza obszarem miasta',
  'order.total.projectServices.suburbanTransferOtherTotal': ' Wizyta poza granice miasta (w przyszłości)',
  'order.total.projectServices.total': 'Usługi towarzyszące (razem)',
  'order.total.materials': 'Materiały (razem)',
  'order.total.systemAndAccessories': 'Karnisze i akcesoria (razem)',
  'order.total.productsPrice': 'Ogólna wartość produktów',
  'order.total.services': 'Ogólna wartość usług',
  'order.total.prepaid': 'Przedpłata ({value}%)',
  'order.total.expectedPayments': 'Opłata została wniesiona w całości',
  'order.total.expectedPayments.no': 'Zamówić.',
  'order.total.expectedPayments.prepaid': 'Zaliczka ({value}%)',
  'order.total.expectedPayments.notCompletePrepaidRemainder': 'Oczekiwanie na zaliczkę ₴{wholePrepayment} nie została w całości wpłacona, brak kosztów',
  'order.total.expectedPayments.suburbanTransferForMeasuring': 'Wizyta specjalisty ds. pomiaru poza obszarem miasta',
  'order.total.expectedPayments.surcharge': 'Dodatkowa opłata',
  'order.total.expectedPayments.surchargeCombined': 'Brakująca kwota do całkowitej kwoty wpłaty',
  'order.total.inAll': 'Razem',
  'order.total.splitByRoom': "(oddzielnie każde pomieszczenie)",
  'order.total.economyOnCompoundOrder': "Oszczędzanie zestaw",
  'order.total.discounts': 'Rabaty',
  'order.total.discounts.custom': 'Rabaty indywidualne',
  'order.total.discounts.flyer': 'Ulotka z rabatem',
  'order.total.discounts.referral': 'Rabaty dla partnerów',
  'order.total.discounts.compensatory': 'Rekompensata za podwyżkę cen',
  'order.total.discounts.warranty': 'Rekompensata z tytułu gwarancji',
  'order.total.totalAfterDiscount': 'Razem z rabatami',
  'order.total.VAT': 'VAT ({percent}%)',
  'order.total.VAT.including': 'w tym VAT {percent}%',
  'order.total.totalWithVAT': 'Razem z VAT',
  'order.total.general': 'Całkowita kwota do zapłaty',

  'images.imagesAndSketches.title': 'Obrazy i szkice',
  'images.error.rejectedFetching': "Błąd ładowania obrazu. Upewnij się, że Twoje urządzenie jest połączone z internetem.",
  'images.error.rejectedUpload': "Błąd łądowania",
  'images.error.unknownUser': "Błąd podczas logowania. Nie możesz dodać zdjęcia.",
  'images.error.unknownProject': "Bład: nieznany projekt. Nie możesz wstawić obrazu.",
  'images.printInDocuments.set.true': 'Nie możesz wydrukować obrazu w dokumentach. Naciśnij,aby włączyć',
  'images.printInDocuments.set.false': 'Obraz jest wklejany do dokumentu. Naciśnij, aby usunąć z dokumentów',

  'project.status.new': 'Obsługa Klienta',
  'project.status.scheduledVisit': 'Planowana wizyta',
  'project.status.measuring': 'Pomiar',
  'project.status.hover': 'Czeka',
  'project.status.reserve': 'Rezerwacja',
  'project.status.prepaid': 'Wpłata',
  'project.status.started': 'W realizacji',
  'project.status.production': 'W magazynie',
  'project.status.completeSet': 'Skompletowane',
  'project.status.mount': 'Montaż',
  'project.status.mountDone': 'Montaż wykonany',
  'project.status.hanging': 'Wieszanie',
  'project.status.delivery': 'Dostawa',
  'project.status.refactoring': 'Ulepszenie',
  'project.status.repairing': 'Naprawa',
  'project.status.repairOrRefactorDone': 'Ulepszenie/Naprawa wykonano',
  'project.status.surchargeReceived': 'Dodatkowa płatność wpłynęła',
  'project.status.completed': 'Zakończony',
  'project.status.refusal': 'Anulowane',
  
  'project.subStatus.hesitates': 'Waha się',
  'project.subStatus.commercialProposal': 'Przygotuj ofertę komercyjną',
  'project.subStatus.invoice': 'Przygotuj konto',
  'project.subStatus.contract': 'Zawrzeć umowę',

  'project.status': 'Status',
  'project.subStatus': 'Sprawdzanie stanu',
  'project.isRemote': 'Zdalne',
  'project.deliveryAndMount.title': 'Dostawa i montaż',
  'project.supplyTimeClass': 'Termin realizacji, d.r.',
  'project.supplyTimeClass.withoutUnit': 'Termin realizacji',
  'project.created': 'Data utworzenia',
  'project.modified': 'Data ostatniej zmiany',
  'project.measuringDate': 'Data pomiaru',
  'project.measuringDateTime': 'Dzień, godzina pomiaru',
  'project.startDate': 'Data start',
  'project.deliveryDate': 'Data dostawy',
  'project.mountingDate': 'Data montażu',
  'project.finishDate': 'Data zakończenia',
  'project.finishDateTime': 'Dzień, godzina zakończenia',
  'project.productionItemsCount': 'Liczba szt. produktów',
  'project.productionItemsCount.full': 'Liczba sztuk produktów',
  'project.address.full': 'Pełny adres',
  'project.addressOfClient': 'Adres dostawy jest taki sam, jak adres Klienta',
  'project.crmId': 'Identyfikator umowy',
  'project.note': 'Ogólne notatki',
  'project.paymentsNote': 'Komentarz dotyczący wpłaty',
  'project.isPrepaidOnTheSpot': 'Zaliczka na pomiar',
  'project.clientKind': 'Typ klienta jako płatnika',
  'project.clientKind.undefinedNotice': 'najpierw wybierz typ klienta jako płatnika',

  'clientKind.private-person': 'Indywidualny',
  'clientKind.private-entrepreneur-VAT-non-payer': 'IP bez VAT',
  'clientKind.private-entrepreneur-VAT-payer': 'IP z VAT',
  'clientKind.LLC-VAT-non-payer': 'Sp. z o.o. bez VAT',
  'clientKind.LLC-VAT-payer': 'Sp. z o.o. z VAT',

  'projectServices.title': 'Usługi towarzyszące',
  'projectServices.onlyDelivery': 'Dostawa',
  'projectServices.suburbanTransfer': 'Wizyta poza granice miasta',
  'projectServices.suburbanTransfer.distanceLimit.desc':
    'Wpisz liczbę kilometrów na liczniku ponad {distanceLimit} km od granic miasta w obie strony',
  'projectServices.suburbanTransfer.transferType.measuring': 'Pomiar',
  'projectServices.suburbanTransfer.transferType.hangingAndMount': 'Wieszanie i montaż(podczas jednej wizyty)',
  'projectServices.suburbanTransfer.transferType.hanging': 'Wieszanie (inna wizyta)',
  'projectServices.suburbanTransfer.transferType.mount': 'Montaż (inna wizyta)',
  'projectServices.suburbanTransfer.transferType.delivery': 'Dostawa',
  'projectServices.suburbanTransfer.deliveryId.notAvailable':
    'Dla każdej aktualnej dostawy jest zaplanowana wizyta. Dostawa jest niedostępna!',
  'projectServices.view.measuringTransferNotice': 'jest wypłacana w osobnej wpłacie',
  'projectServices.view.delivery': 'Dostawa po mieście',
  'projectServices.view.delivery.all': 'wszystkich produktów',
  'projectServices.view.delivery.selected': 'wybrane produkty',
  'projectServices.view.suburbanTransfer':
    'Wyjazd poza miasto na {distance} km ponad {distanceLimit} km od PK.',
  'projectServices.view.suburbanTransfer.transferType.title': 'Cel',

  'projectDiscounts.custom.title': 'Rabaty indywidualne',
  'projectDiscounts.flyer.title': 'Ulotka z rabatem',
  'projectDiscounts.referral.title': 'Rabaty dla partnerów',
  'projectDiscounts.compensatory.title': 'Rekompensata za podwyżkę cen',
  'projectDiscounts.warranty.title': 'Rekompensata z tytułu gwarancji',
  'projectDiscounts.custom': 'Indywidualna',
  'projectDiscounts.flyer': 'Ulotka',
  'projectDiscounts.referral': 'Dla partnerów',
  'projectDiscounts.compensatory': 'Rekompensata',
  'projectDiscounts.warranty': 'Z tytułu gwarancji',

  'finance.amount': 'Kwota, hrn',
  'finance.when': 'Data realizacji',
  'finance.paymentDetails': 'Dane do przelewu',
  'finance.purposeOfPayment': 'Tytuł wpłaty',
  'finance.payOffice': 'Kasa',
  'finance.paymentType': 'Rodzaj wpłaty',
  'finance.paymentType.full': 'Całość',
  'finance.paymentType.prepaid': 'Przedpłata',
  'finance.paymentType.collateral': 'Zaliczka',
  'finance.paymentType.surcharge': 'Dopłata',
  'finance.paymentType.suburbanTransfer': 'Wizyta poza obszar miasta',
  'finance.paymentForm': 'Forma',
  'finance.paymentForm.cash': 'Gotówka',
  'finance.paymentForm.cash.short': 'got.',
  'finance.paymentForm.non-cash': 'Bezgotówkowy',
  'finance.paymentForm.non-cash.short': 'b/g',
  'finance.notCompletePrepaidRemainder': 'Wpisana kwota mniejsza niż kwota wymaganej przedpłaty o ₴{remainder}',
  'finance.notCompleteSurchargeRemainder': 'Wpisana kwota mniejsza niż kwota wymaganej dopłaty o ₴{remainder}',
  'finance.notCompleteFullRemainder': 'Wpisana kwota mniejsza niż wymagana kwota do wpłaty o ₴{remainder}',
  'finance.notCompleteTransferRemainder': 'Wpisana kwota mniejsza niż obliczony koszt wizyty specjalisty o ₴{remainder}',

  'company': 'Firma',
  'company.companyCode': 'ЄДРПОУ',

  'client.title': 'Klient',
  'client.created': 'Data rejestracji',
  'client.modified': 'Najnowsza aktualizacja',
  'client.address.main': 'Główny adres',

  'participant.started': 'Data zaangażowania',
  'participant.city': 'Region',

  'materialUsage.isManualAmount.label': 'Wpisz ilość ręcznie',
  'materialUsage.isManualPrice.label': 'Wprowadź koszt ręcznie',

  'material.textile.label.collection': 'Oferta',
  'material.textile.label.design': 'Design',
  'material.textile.label.textileType': 'Rodzaj tkaniny',
  'material.textile.label.fabricComposition': 'Skład tkaniny',
  'material.textile.label.rapport': 'Raport',
  'material.textile.label.weighted': 'Obciążniki',
  'material.textile.label.supplyTimeClass': 'Czas realizacji z tkaniny',
  'material.webbing.label.supplyTimeClass': 'Czas realizacji z oplotem',
  'material.label.colourSet': 'Dostępne kolory',
  'material.label.unitDivider': 'Wielość podziału',
  'material.label.supplyTimeClass': 'Termin produkcji',
  'material.label.fabricComposition': 'Skład',
  'material.label.itemComposition': 'Kompletny zestaw',
  'material.label.forProductCategory': 'Kategorie produktów wg przeznaczenia',
  'material.label.forProductCategory.short': 'Przeznaczenia',
  'material.label.materialType': 'Rodzaj materiału',
  'material.label.corniceSystem': 'System gzymsów (mechanizm)',
  'material.label.corniceSystem.short': 'System',
  'material.label.motorizedSystem': 'Zmotoryzowany system',
  'material.label.diameterCompatibility': 'Kompatybilny ze średnicami okapów',
  'material.label.bracketProfilesConf': 'Konfiguracja wspornika',
  'material.label.madeIn': 'Kraj pochodzenia produktu',
  'material.label.madeIn.short': 'Kraj',
  'material.label.minOrder': 'Minimalne zamówienie',
  'material.label.minOrder.short': 'Min. zamówienie',
  'material.label.notes': 'Komentarze',

  'model.romanBlindSolo': 'SOLO',
  'model.romanBlindLine': 'LINE',
  'model.romanBlindStella': 'STELLA',
  'model.romanBlindPrizma': 'PRIZMA',
  'model.romanBlindOlimpia': 'OLIMPIA',
  'model.romanBlindVenice': 'WENECJA',
  'model.romanBlindMilano': 'MILANO',
  'model.romanBlindQuadro': 'QUADRO',

  'systemType.standard-cord-roman': 'Standard sznurkowy',
  'systemType.standard-chain-roman': 'Standard łańcuszkowo-obrotowy',
  'systemType.standard-double-cord-roman': 'Standard podwójny sznurkowy',
  'systemType.standard-double-chain-roman': 'Standard podwójny łańcuszkowo-obrotowy',

  'systemColour.white': 'Biały',
  'systemColour.brown': 'Brązowy',

  'fixation.none': 'Nie ma',

  'decoration.none': 'Nie ma',

  'controlSide.left': 'Lewa',
  'controlSide.right': 'Prawa',

  'controlChainLength.25': '25 cm',
  'controlChainLength.50': '50 cm',
  'controlChainLength.75': '75 cm',
  'controlChainLength.100': '100 cm',
  'controlChainLength.125': '125 cm',
  'controlChainLength.150': '150 cm',
  'controlChainLength.175': '175 cm',
  'controlChainLength.200': '200 cm',
  'controlChainLength.225': '225 cm',
  'controlChainLength.250': '250 cm',

  'nonStandardForm.none': 'Nie ma',
  'nonStandardForm.triangle': 'Trójkąt',
  'nonStandardForm.trapeze': 'Trapez',

  'rooms.Bedroom': 'Sypialnia',
  'rooms.LivingRoom': 'Salon',
  'rooms.Kitchen': 'Kuchnia',
  'rooms.DiningRoom': 'Jadalnia',
  'rooms.ChildRoom': 'Pokój dziecięcy',
  'rooms.Cabinet': 'Pokój biurowy',
  'rooms.GuestRoom': 'Pokój gościnny',
  'rooms.Corridor': 'Korytarz',
  'rooms.Wardrobe': 'Garderoba',
  'rooms.Balcony': 'Balkon',
  'rooms.Loggia': 'Loggia',
  'rooms.Terrace': 'Taras',
  'rooms.Bathroom': 'Łazienka',
  'rooms.Studio': 'Studio',
  'rooms.Office': 'Biuro',
  'rooms.Gazebo': 'Altana',
  'rooms.Bedroom.1': 'Sypialnia 1',
  'rooms.Bedroom.2': 'Sypialnia 2',
  'rooms.Bedroom.3': 'Sypialnia 3',
  'rooms.Bedroom.son': 'Sypialnia dla syna',
  'rooms.Bedroom.daughter': 'Sypialnia dla córki',
  'rooms.ChildRoom.daughter': 'Pokój dziecięcy dla córki',
  'rooms.ChildRoom.son': 'Pokój dziecięcy dla syna',

  'sms.send': 'Wysłać SMS',
  'sms.not_sent': 'SMS w kolejce do wysłania',
  'sms.ok_sent': 'Wiadomość została wysłana, ale status dostarczenia jest nieznany',
  'sms.ok_delivered': 'Wiadomość dostarczona',
  'sms.err_src_invalid': 'Wiadomość SMS niewysłana: błędny numer nadawcy',
  'sms.err_dest_invalid': 'Błędny numer - SMS niewysłany',
  'sms.err_not_allowed': 'Wiadomość SMS niewysłana: nie znaleziono operatora komórkowego',
  'sms.err_delivery_failed': 'Wiadomość SMS niewysłana. Sprawdź poprawność wpisanego numeru telefonu i spróbuj ponownie.',
  'sms.err_lost': 'Wiadomość SMS niewysłana (utrata SMS). Spróbuj ponownie.',
  'sms.err_internal': 'Wiadomość SMS niewysłana (błąd wewnętrzny). Spróbuj ponownie.',

  'user.createdAt': 'Data rejestracji',

  'views.userAdministration.title': 'Użytkownicy',
  'views.userAdministration.searchByName': 'Szukaj według nazwiska',
  'views.userAdministration.filterByRoles': 'Role użytkowników',
  'views.userAdministration.filterBySkills': 'Umiejętności (wyjaśnienie roli)',
  'views.userAdministration.sortByRoleAndLastNameAsc': 'Rola i nazwisko',
  'views.userAdministration.authenticatedSession': 'Sesja',
  'views.userAdministration.closeSelectedSessions': 'Zamknij sesje',
  'views.userAdministration.closeSelectedSessions.promptMessage': 'Planujesz zamknąć wszystkie aktywne sesje następujących użytkowników:',
  'views.userAdministration.closeSelectedSessions.promptWarning': 'Po potwierdzeniu akcji wybrani użytkownicy będą potrzebować ponownej autoryzacji na wszystkich urządzeniach, aby kontynuować pracę w aplikacji.',
  'views.userAdministration.block': 'Blok',
  'views.userAdministration.block.promptMessage': 'Planujesz zablokować (zakończyć wszystkie aktywne sesje i dezaktywować z brakiem możliwości ponownego uwierzytelnienia w aplikacji) następujących użytkowników:',
  'views.userAdministration.block.promptWarning': 'Zablokowani użytkownicy generalnie znikają z list dostępnych użytkowników, nie mają możliwości ponownej autoryzacji w aplikacji, jednakże dane ich kont nadal przechowywane są w systemie dla funkcjonalności projektów wcześniej z nimi powiązanych. W razie potrzeby, w celu całkowitego usunięcia danych lub przywrócenia aktywności konta, skontaktuj się z administratorem technicznym systemu.',
  'views.userAdministration.bulkOperationResultMessage.failed': 'Operacja nie powiodła się dla wszystkich wybranych użytkowników.',
  'views.userAdministration.bulkOperationResultMessage.partiallyFailed': 'Częściowo nieudany wynik operacji, w szczególności dla: {subjects}',
  'views.userAdministration.bulkOperationResultMessage.succeeded': 'Pomyślnie zastosowano do wszystkich wybranych użytkowników.',
};

export default messages;
