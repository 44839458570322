import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { Supplier } from 'types/supplier';
import { loadState } from 'utils/localStorage';
import apiClient from '../api/coreAPI';

const persistedState = loadState('suppliers_tool');
const persistedSuppliers = persistedState?.suppliersList ?? [];

type FetchingStatus = 'idle' | 'loading' | 'fulfilled' | 'rejected';

interface Suppliers {
  suppliersList: Supplier[];
  loading: FetchingStatus;
}

const initialState: Suppliers = {
  suppliersList: persistedSuppliers,
  loading: 'idle',
};

export const fetchSuppliers = createAsyncThunk(
  'fetchSuppliers',
  async () => {
    const { data } = await apiClient.get('/suppliers?filter[order]=name%20ASC');

    return data;
  },
  {
    condition: (_, { getState }) => {
      const { suppliers } = getState() as RootState;
      if (suppliers) {
        const fetchStatus = (suppliers as Suppliers).loading;
        if (fetchStatus === 'fulfilled' || fetchStatus === 'loading') {
          // Вже завантажено або в процесі, повторний запит не потрібен
          return false;
        }
      }
      return undefined;
    },
  },
);

const slice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSuppliers.pending, state => {
        state.loading = 'loading';
      })
      .addCase(fetchSuppliers.fulfilled, (state, { payload }) => {
        state.suppliersList = payload;
        state.loading = 'fulfilled';
      })
      .addCase(fetchSuppliers.rejected, state => {
        state.loading = 'rejected';
      });
  },
});

// const selectSelf = (state: RootState): Suppliers => state.suppliers;

// export const getSuppliers = createSelector(selectSelf, state => state.suppliersList);
export const getSuppliers = (state: RootState) => state.suppliers.suppliersList;

export const getSupplierName = createSelector(
  [getSuppliers, (_, id: string) => id],
  (suppliers, id) => suppliers.find(item => item.id === id)?.name,
);

export const getSuppliersFilterOptions = createSelector(getSuppliers, suppliersList =>
  suppliersList.map(supplier => ({
    name: supplier.name,
    value: supplier.id,
  })),
);

export const { reducer } = slice;
