import { ProductFulfillment } from 'types/productFulfillment';

// eslint-disable-next-line import/prefer-default-export
export const іnhortProductFulfillment: ProductFulfillment = {
  optionNamesTable: {
    model: {
      romanBlindSolo: 'СОЛО',
      romanBlindLine: 'ЛАЙН',
      romanBlindStella: 'СТЕЛЛА',
      romanBlindPrizma: 'ПРИЗМА',
      romanBlindOlimpia: 'ОЛИМПИЯ',
      romanBlindVenice: 'ВЕНЕЦИЯ',
      romanBlindMilano: 'МИЛАНО',
      romanBlindQuadro: 'КВАДРО',
    },
    systemType: {
      'standard-cord-roman': 'Стандарт мотузковий',
      'standard-chain-roman': 'Стандарт ланцюгово-роторний',
      'standard-double-cord-roman': 'Стандарт подвійний мотузковий',
      'standard-double-chain-roman': 'Стандарт подвійний ланцюгово-роторний',
    },
  },
  dependentOptionSets: {
    decoration: {
      romanBlindSolo: [],
      romanBlindLine: ['лайн люверс', 'лайн люверс+'],
      romanBlindStella: ['стелла люверс', 'стелла люверс+'],
      romanBlindPrizma: ['призма люверс', 'призма2', 'призма3', 'призма4', 'призма люверс+', 'призма2+', 'призма4+'],
      romanBlindOlimpia: ['олимпия2', 'олимпия3', 'олимпия4', 'олимпия6', 'олимпия2+', 'олимпия4+', 'олимпия6+'],
      romanBlindVenice: ['венеция2', 'венеция3', 'венеция4', 'венеция6', 'венеция2+', 'венеция4+', 'венеция6+'],
      romanBlindMilano: ['милано4', 'милано6', 'милано8', 'милано12', 'милано4+', 'милано8+', 'милано12+'],
      romanBlindQuadro: ['квадро люверс', 'квадро4', 'квадро6', 'квадро8', 'квадро люверс+', 'квадро4+', 'квадро8+'],
    },
  },
  materialCategories: [
    {
      id: '0000-001',
      name: '',
      nameUk: '',
      itemsNames: ['Блэкаут перфект', 'Рогожка премиум', 'Блэкаутэклипс'],
    },
    {
      id: '0000-002',
      name: '',
      nameUk: '',
      itemsNames: ['Тюль Натурель', 'Блэкаут с рисунком'],
    },
    {
      id: '0000-003',
      name: '',
      nameUk: '',
      itemsNames: ['Рогожка Скайлайн', 'Джусивелюр', 'Рогожка dimout'],
    },
    {
      id: '0000-004',
      name: '',
      nameUk: '',
      itemsNames: ['Лён 900', 'Аларкона'],
    },
    {
      id: '0000-005',
      name: '',
      nameUk: '',
      itemsNames: ['Лён с отливом', 'Тюль скрин', 'Рогожка LUX'],
    },
    {
      id: '0000-006',
      name: 'Блэкаут Nova',
      nameUk: 'Блекаут Nova',
      itemsNames: ['Блэкаут Nova'],
    },
    {
      id: '0000-007',
      name: '',
      nameUk: '',
      itemsNames: ['Тюль лён', 'Тюль эйр'],
    },
    {
      id: '0000-008',
      name: '',
      nameUk: '',
      itemsNames: ['SOFT LUX', 'Лён'],
    },
    {
      id: '0000-009',
      name: '',
      nameUk: '',
      itemsNames: ['Лён Fine', 'Лён-лайт'],
    },
    {
      id: '0000-010',
      name: 'Дикий шелк',
      nameUk: 'Дикий шовк',
      itemsNames: ['Дикий шелк'],
    },
  ],
  materialCategoriesIndex: {
    'Блэкаут перфект': '0000-001',
    'Рогожка премиум': '0000-001',
    Блэкаутэклипс: '0000-001',
    'Тюль Натурель': '0000-002',
    'Блэкаут с рисунком': '0000-002',
    'Рогожка скайлайн': '0000-003',
    Джусивелюр: '0000-003',
    'Рогожка dimout': '0000-003',
    'Лён 900': '0000-004',
    Аларкона: '0000-004',
    'Лён с отливом': '0000-005',
    'Тюль скрин': '0000-005',
    'Рогожка LUX': '0000-005',
    'Блэкаут Nova': '0000-006',
    'Тюль лён': '0000-007',
    'Тюль эйр': '0000-007',
    'SOFT LUX': '0000-008',
    Лён: '0000-008',
    'Лён Fine': '0000-009',
    'Лён-лайт': '0000-009',
    'Дикий шелк': '0000-010',
  },
  priceTables: {
    romanBlindSolo: {
      id: '00000000-0000-0000-88ae-5ac041b40001',
      code: 'romanBlindSolo',
      name: 'ТАБЛИЦЫ РАСЧЁТА РИМСКОЙ ШТОРЫ МОДЕЛИ «СОЛО»',
      nameUk: 'ТАБЛИЦІ РОЗРАХУНКУ РИМСЬКОЇ ШТОРИ МОДЕЛІ «СОЛО»',
      supplierCurrency: 'UAH',
      // interestId?: 1,
      priceType: 'flat',
      unit: 'шт.',
      sizeUnits: 'mm',
      type: 'sizedCategorized',
      sizedCategorized: [
        {
          minWidth: 240,
          width: 400,
          categories: {
            '0000-001': { price: '1535.00' },
            '0000-002': { price: '1424.00' },
            '0000-003': { price: '1424.00' },
            '0000-004': { price: '1424.00' },
            '0000-005': { price: '1364.00' },
            '0000-006': { price: '1281.00' },
            '0000-007': { price: '1281.00' },
            '0000-008': { price: '1281.00' },
            '0000-009': { price: '1152.00' },
            '0000-010': { price: '1152.00' },
          },
        },
        {
          minWidth: 401,
          width: 500,
          categories: {
            '0000-001': { price: '1672.00' },
            '0000-002': { price: '1535.00' },
            '0000-003': { price: '1535.00' },
            '0000-004': { price: '1535.00' },
            '0000-005': { price: '1465.00' },
            '0000-006': { price: '1368.00' },
            '0000-007': { price: '1368.00' },
            '0000-008': { price: '1368.00' },
            '0000-009': { price: '1211.00' },
            '0000-010': { price: '1211.00' },
          },
        },
        {
          minWidth: 501,
          width: 600,
          categories: {
            '0000-001': { price: '1809.00' },
            '0000-002': { price: '1649.00' },
            '0000-003': { price: '1649.00' },
            '0000-004': { price: '1649.00' },
            '0000-005': { price: '1566.00' },
            '0000-006': { price: '1453.00' },
            '0000-007': { price: '1453.00' },
            '0000-008': { price: '1453.00' },
            '0000-009': { price: '1268.00' },
            '0000-010': { price: '1268.00' },
          },
        },
        {
          minWidth: 601,
          width: 700,
          categories: {
            '0000-001': { price: '1978.00' },
            '0000-002': { price: '1795.00' },
            '0000-003': { price: '1795.00' },
            '0000-004': { price: '1795.00' },
            '0000-005': { price: '1702.00' },
            '0000-006': { price: '1573.00' },
            '0000-007': { price: '1573.00' },
            '0000-008': { price: '1573.00' },
            '0000-009': { price: '1363.00' },
            '0000-010': { price: '1363.00' },
          },
        },
        {
          minWidth: 701,
          width: 800,
          categories: {
            '0000-001': { price: '2149.00' },
            '0000-002': { price: '1941.00' },
            '0000-003': { price: '1941.00' },
            '0000-004': { price: '1941.00' },
            '0000-005': { price: '1837.00' },
            '0000-006': { price: '1693.00' },
            '0000-007': { price: '1693.00' },
            '0000-008': { price: '1693.00' },
            '0000-009': { price: '1453.00' },
            '0000-010': { price: '1453.00' },
          },
        },
        {
          minWidth: 801,
          width: 900,
          categories: {
            '0000-001': { price: '2167.00' },
            '0000-002': { price: '1942.00' },
            '0000-003': { price: '1942.00' },
            '0000-004': { price: '1942.00' },
            '0000-005': { price: '1834.00' },
            '0000-006': { price: '1684.00' },
            '0000-007': { price: '1684.00' },
            '0000-008': { price: '1684.00' },
            '0000-009': { price: '1438.00' },
            '0000-010': { price: '1438.00' },
          },
        },
        {
          minWidth: 901,
          width: 1000,
          categories: {
            '0000-001': { price: '2504.00' },
            '0000-002': { price: '2252.00' },
            '0000-003': { price: '2252.00' },
            '0000-004': { price: '2252.00' },
            '0000-005': { price: '2125.00' },
            '0000-006': { price: '1949.00' },
            '0000-007': { price: '1949.00' },
            '0000-008': { price: '1949.00' },
            '0000-009': { price: '1655.00' },
            '0000-010': { price: '1655.00' },
          },
        },
        {
          minWidth: 1001,
          width: 1100,
          categories: {
            '0000-001': { price: '2740.00' },
            '0000-002': { price: '2464.00' },
            '0000-003': { price: '2464.00' },
            '0000-004': { price: '2464.00' },
            '0000-005': { price: '2325.00' },
            '0000-006': { price: '2133.00' },
            '0000-007': { price: '2133.00' },
            '0000-008': { price: '2133.00' },
            '0000-009': { price: '1813.00' },
            '0000-010': { price: '1813.00' },
          },
        },
        {
          minWidth: 1101,
          width: 1200,
          categories: {
            '0000-001': { price: '2908.00' },
            '0000-002': { price: '2612.00' },
            '0000-003': { price: '2612.00' },
            '0000-004': { price: '2612.00' },
            '0000-005': { price: '2460.00' },
            '0000-006': { price: '2251.00' },
            '0000-007': { price: '2251.00' },
            '0000-008': { price: '2251.00' },
            '0000-009': { price: '1907.00' },
            '0000-010': { price: '1907.00' },
          },
        },
        {
          minWidth: 1201,
          width: 1300,
          categories: {
            '0000-001': { price: '3078.00' },
            '0000-002': { price: '2758.00' },
            '0000-003': { price: '2758.00' },
            '0000-004': { price: '2758.00' },
            '0000-005': { price: '2586.00' },
            '0000-006': { price: '2370.00' },
            '0000-007': { price: '2370.00' },
            '0000-008': { price: '2370.00' },
            '0000-009': { price: '1998.00' },
            '0000-010': { price: '1998.00' },
          },
        },
        {
          minWidth: 1301,
          width: 1400,
          categories: {
            '0000-001': { price: '3249.00' },
            '0000-002': { price: '2904.00' },
            '0000-003': { price: '2904.00' },
            '0000-004': { price: '2904.00' },
            '0000-005': { price: '2828.00' },
            '0000-006': { price: '2488.00' },
            '0000-007': { price: '2488.00' },
            '0000-008': { price: '2488.00' },
            '0000-009': { price: '2089.00' },
            '0000-010': { price: '2089.00' },
          },
        },
        {
          minWidth: 1401,
          width: 1500,
          categories: {
            '0000-001': { price: '3417.00' },
            '0000-002': { price: '3052.00' },
            '0000-003': { price: '3052.00' },
            '0000-004': { price: '3052.00' },
            '0000-005': { price: '2864.00' },
            '0000-006': { price: '2607.00' },
            '0000-007': { price: '2607.00' },
            '0000-008': { price: '2607.00' },
            '0000-009': { price: '2183.00' },
            '0000-010': { price: '2183.00' },
          },
        },
        {
          minWidth: 1501,
          width: 1600,
          categories: {
            '0000-001': { price: '3653.00' },
            '0000-002': { price: '3262.00' },
            '0000-003': { price: '3262.00' },
            '0000-004': { price: '3262.00' },
            '0000-005': { price: '3061.00' },
            '0000-006': { price: '2791.00' },
            '0000-007': { price: '2791.00' },
            '0000-008': { price: '2791.00' },
            '0000-009': { price: '2343.00' },
            '0000-010': { price: '2343.00' },
          },
        },
        {
          minWidth: 1601,
          width: 1700,
          categories: {
            '0000-001': { price: '3821.00' },
            '0000-002': { price: '3409.00' },
            '0000-003': { price: '3409.00' },
            '0000-004': { price: '3409.00' },
            '0000-005': { price: '3201.00' },
            '0000-006': { price: '2908.00' },
            '0000-007': { price: '2908.00' },
            '0000-008': { price: '2908.00' },
            '0000-009': { price: '2432.00' },
            '0000-010': { price: '2432.00' },
          },
        },
        {
          minWidth: 1701,
          width: 1800,
          categories: {
            '0000-001': { price: '3989.00' },
            '0000-002': { price: '3554.00' },
            '0000-003': { price: '3554.00' },
            '0000-004': { price: '3554.00' },
            '0000-005': { price: '3335.00' },
            '0000-006': { price: '3027.00' },
            '0000-007': { price: '3027.00' },
            '0000-008': { price: '3027.00' },
            '0000-009': { price: '2524.00' },
            '0000-010': { price: '2524.00' },
          },
        },
        {
          minWidth: 1801,
          width: 1900,
          categories: {
            '0000-001': { price: '4159.00' },
            '0000-002': { price: '3702.00' },
            '0000-003': { price: '3702.00' },
            '0000-004': { price: '3702.00' },
            '0000-005': { price: '3469.00' },
            '0000-006': { price: '3145.00' },
            '0000-007': { price: '3145.00' },
            '0000-008': { price: '3145.00' },
            '0000-009': { price: '2617.00' },
            '0000-010': { price: '2617.00' },
          },
        },
        {
          minWidth: 1901,
          width: 2000,
          categories: {
            '0000-001': { price: '4346.00' },
            '0000-002': { price: '3867.00' },
            '0000-003': { price: '3867.00' },
            '0000-004': { price: '3867.00' },
            '0000-005': { price: '3623.00' },
            '0000-006': { price: '3284.00' },
            '0000-007': { price: '3284.00' },
            '0000-008': { price: '3284.00' },
            '0000-009': { price: '2731.00' },
            '0000-010': { price: '2731.00' },
          },
        },
        {
          minWidth: 2001,
          width: 2100,
          categories: {
            '0000-001': { price: '4583.00' },
            '0000-002': { price: '4078.00' },
            '0000-003': { price: '4078.00' },
            '0000-004': { price: '4078.00' },
            '0000-005': { price: '3823.00' },
            '0000-006': { price: '3469.00' },
            '0000-007': { price: '3469.00' },
            '0000-008': { price: '3469.00' },
            '0000-009': { price: '2888.00' },
            '0000-010': { price: '2888.00' },
          },
        },
        {
          minWidth: 2101,
          width: 2200,
          categories: {
            '0000-001': { price: '4751.00' },
            '0000-002': { price: '4226.00' },
            '0000-003': { price: '4226.00' },
            '0000-004': { price: '4226.00' },
            '0000-005': { price: '3958.00' },
            '0000-006': { price: '3585.00' },
            '0000-007': { price: '3585.00' },
            '0000-008': { price: '3585.00' },
            '0000-009': { price: '2990.00' },
            '0000-010': { price: '2990.00' },
          },
        },
        {
          minWidth: 2201,
          width: 2300,
          categories: {
            '0000-001': { price: '4931.00' },
            '0000-002': { price: '4372.00' },
            '0000-003': { price: '4372.00' },
            '0000-004': { price: '4372.00' },
            '0000-005': { price: '4093.00' },
            '0000-006': { price: '3704.00' },
            '0000-007': { price: '3704.00' },
            '0000-008': { price: '3704.00' },
            '0000-009': { price: '3070.00' },
            '0000-010': { price: '3070.00' },
          },
        },
        {
          minWidth: 2301,
          width: 2400,
          categories: {
            '0000-001': { price: '5092.00' },
            '0000-002': { price: '4520.00' },
            '0000-003': { price: '4520.00' },
            '0000-004': { price: '4520.00' },
            '0000-005': { price: '4227.00' },
            '0000-006': { price: '3825.00' },
            '0000-007': { price: '3825.00' },
            '0000-008': { price: '3825.00' },
            '0000-009': { price: '3162.00' },
            '0000-010': { price: '3162.00' },
          },
        },
        {
          minWidth: 2401,
          width: 2500,
          categories: {
            '0000-001': { price: '5320.00' },
            '0000-002': { price: '4706.00' },
            '0000-003': { price: '4706.00' },
            '0000-004': { price: '4706.00' },
            '0000-005': { price: '4403.00' },
            '0000-006': { price: '3984.00' },
            '0000-007': { price: '3984.00' },
            '0000-008': { price: '3984.00' },
            '0000-009': { price: '3294.00' },
            '0000-010': { price: '3294.00' },
          },
        },
        {
          minWidth: 2501,
          width: 2600,
          categories: {
            '0000-001': { price: '5532.00' },
            '0000-002': { price: '4896.00' },
            '0000-003': { price: '4896.00' },
            '0000-004': { price: '4896.00' },
            '0000-005': { price: '4581.00' },
            '0000-006': { price: '4144.00' },
            '0000-007': { price: '4144.00' },
            '0000-008': { price: '4144.00' },
            '0000-009': { price: '3425.00' },
            '0000-010': { price: '3425.00' },
          },
        },
        {
          minWidth: 2601,
          width: 2700,
          categories: {
            '0000-001': { price: '5726.00' },
            '0000-002': { price: '5087.00' },
            '0000-003': { price: '5087.00' },
            '0000-004': { price: '5087.00' },
            '0000-005': { price: '4755.00' },
            '0000-006': { price: '4304.00' },
            '0000-007': { price: '4304.00' },
            '0000-008': { price: '4304.00' },
            '0000-009': { price: '3555.00' },
            '0000-010': { price: '3555.00' },
          },
        },
        {
          minWidth: 2701,
          width: 2800,
          categories: {
            '0000-001': { price: '5936.00' },
            '0000-002': { price: '5272.00' },
            '0000-003': { price: '5272.00' },
            '0000-004': { price: '5272.00' },
            '0000-005': { price: '4934.00' },
            '0000-006': { price: '4464.00' },
            '0000-007': { price: '4464.00' },
            '0000-008': { price: '4464.00' },
            '0000-009': { price: '3687.00' },
            '0000-010': { price: '3687.00' },
          },
        },
        {
          minWidth: 2801,
          width: 2900,
          categories: {
            '0000-001': { price: '6153.00' },
            '0000-002': { price: '5461.00' },
            '0000-003': { price: '5461.00' },
            '0000-004': { price: '5461.00' },
            '0000-005': { price: '5107.00' },
            '0000-006': { price: '4623.00' },
            '0000-007': { price: '4623.00' },
            '0000-008': { price: '4623.00' },
            '0000-009': { price: '3821.00' },
            '0000-010': { price: '3821.00' },
          },
        },
        {
          minWidth: 2901,
          width: 3000,
          categories: {
            '0000-001': { price: '6364.00' },
            '0000-002': { price: '5651.00' },
            '0000-003': { price: '5651.00' },
            '0000-004': { price: '5651.00' },
            '0000-005': { price: '5285.00' },
            '0000-006': { price: '4780.00' },
            '0000-007': { price: '4780.00' },
            '0000-008': { price: '4780.00' },
            '0000-009': { price: '3951.00' },
            '0000-010': { price: '3951.00' },
          },
        },
        {
          minWidth: 3001,
          width: 3100,
          categories: {
            '0000-001': { price: '6578.00' },
            '0000-002': { price: '5701.00' },
            '0000-003': { price: '5701.00' },
            '0000-004': { price: '5701.00' },
            '0000-005': { price: '5461.00' },
            '0000-006': { price: '4940.00' },
            '0000-007': { price: '4940.00' },
            '0000-008': { price: '4940.00' },
            '0000-009': { price: '4085.00' },
            '0000-010': { price: '4085.00' },
          },
        },
        {
          minWidth: 3101,
          width: 3200,
          categories: {
            '0000-001': { price: '6803.00' },
            '0000-002': { price: '6025.00' },
            '0000-003': { price: '6025.00' },
            '0000-004': { price: '6025.00' },
            '0000-005': { price: '5637.00' },
            '0000-006': { price: '5101.00' },
            '0000-007': { price: '5101.00' },
            '0000-008': { price: '5101.00' },
            '0000-009': { price: '4217.00' },
            '0000-010': { price: '4217.00' },
          },
        },
        {
          minWidth: 3201,
          width: 3300,
          categories: {
            '0000-001': { price: '7002.00' },
            '0000-002': { price: '6213.00' },
            '0000-003': { price: '6213.00' },
            '0000-004': { price: '6213.00' },
            '0000-005': { price: '5811.00' },
            '0000-006': { price: '5260.00' },
            '0000-007': { price: '5260.00' },
            '0000-008': { price: '5260.00' },
            '0000-009': { price: '4346.00' },
            '0000-010': { price: '4346.00' },
          },
        },
        {
          minWidth: 3301,
          width: 3400,
          categories: {
            '0000-001': { price: '7213.00' },
            '0000-002': { price: '6402.00' },
            '0000-003': { price: '6402.00' },
            '0000-004': { price: '6402.00' },
            '0000-005': { price: '5989.00' },
            '0000-006': { price: '5419.00' },
            '0000-007': { price: '5419.00' },
            '0000-008': { price: '5419.00' },
            '0000-009': { price: '4389.00' },
            '0000-010': { price: '4389.00' },
          },
        },
        {
          minWidth: 3401,
          width: 3500,
          categories: {
            '0000-001': { price: '7425.00' },
            '0000-002': { price: '6578.00' },
            '0000-003': { price: '6578.00' },
            '0000-004': { price: '6578.00' },
            '0000-005': { price: '6165.00' },
            '0000-006': { price: '5580.00' },
            '0000-007': { price: '5580.00' },
            '0000-008': { price: '5580.00' },
            '0000-009': { price: '4610.00' },
            '0000-010': { price: '4610.00' },
          },
        },
      ],
    },
    models: {
      id: '00000000-0000-0ba0-88ae-5ac041b40003',
      code: 'models',
      name: 'МОДЕЛИ РИМСКИХ ШТОР',
      nameUk: 'МОДЕЛІ РИМСЬКИХ ШТОР',
      supplierCurrency: 'UAH',
      // interestId?: 1,
      priceType: 'add',
      unit: 'шт.',
      sizeUnits: 'mm',
      type: 'set',
      set: {
        romanBlindLine: {
          id: '00000000-0000-0000-99ff-5ac041b40900',
          code: 'romanBlindLine',
          name: 'ЛАЙН',
          nameUk: 'ЛАЙН',
          priceType: 'add',
          unit: 'шт.',
          unitDivider: null,
          price: '233.00',
          minAmount: null,
          minPricePerProduct: null,
          minPricePerOrder: null,
          images: [],
        },
        romanBlindStella: {
          id: '00000000-0000-0000-99ff-5ac041b40901',
          code: 'romanBlindStella',
          name: 'СТЕЛЛА',
          nameUk: 'СТЕЛЛА',
          priceType: 'add',
          unit: 'шт.',
          unitDivider: null,
          price: '298.00',
          minAmount: null,
          minPricePerProduct: null,
          minPricePerOrder: null,
          images: [],
        },
        romanBlindPrizma: {
          id: '00000000-0000-0000-99ff-5ac041b40902',
          code: 'romanBlindPrizma',
          name: 'ПРИЗМА',
          nameUk: 'ПРІЗМА',
          priceType: 'add',
          unit: 'шт.',
          unitDivider: null,
          price: '453.00',
          minAmount: null,
          minPricePerProduct: null,
          minPricePerOrder: null,
          images: [],
        },
        romanBlindOlimpia: {
          id: '00000000-0000-0000-99ff-5ac041b40903',
          code: 'romanBlindOlimpia',
          name: 'ОЛИМПИЯ',
          nameUk: 'ОЛІМПІЯ',
          priceType: 'add',
          unit: 'шт.',
          unitDivider: null,
          price: '233.00',
          minAmount: null,
          minPricePerProduct: null,
          minPricePerOrder: null,
          images: [],
        },
        romanBlindVenice: {
          id: '00000000-0000-0000-99ff-5ac041b40904',
          code: 'romanBlindVenice',
          name: 'ВЕНЕЦИЯ',
          nameUk: 'ВЕНЕЦІЯ',
          priceType: 'add',
          unit: 'шт.',
          unitDivider: null,
          price: '233.00',
          minAmount: null,
          minPricePerProduct: null,
          minPricePerOrder: null,
          images: [],
        },
        romanBlindMilano: {
          id: '00000000-0000-0000-99ff-5ac041b40905',
          code: 'romanBlindMilano',
          name: 'МИЛАНО',
          nameUk: 'МІЛАНО',
          priceType: 'add',
          unit: 'шт.',
          unitDivider: null,
          price: '298.00',
          minAmount: null,
          minPricePerProduct: null,
          minPricePerOrder: null,
          images: [],
        },
        romanBlindQuadro: {
          id: '00000000-0000-0000-99ff-5ac041b40906',
          code: 'romanBlindMilano',
          name: 'КВАДРО',
          nameUk: 'КВАДРО',
          priceType: 'add',
          unit: 'шт.',
          unitDivider: null,
          price: '751.00',
          minAmount: null,
          minPricePerProduct: null,
          minPricePerOrder: null,
          images: [],
        },
      },
    },
    chainRotorSystem: {
      id: '00000000-0000-0000-88ae-5ac041b40002',
      code: 'chainRotorSystem',
      name: 'Цепочно-роторный карниз',
      nameUk: 'Ланцюгово-роторний карниз',
      supplierCurrency: 'UAH',
      // interestId?: 1,
      priceType: 'add',
      unit: 'шт.',
      sizeUnits: 'mm',
      type: 'sized',
      sized: [
        {
          minWidth: 340,
          width: 400,
          price: '1501.00',
        },
        {
          minWidth: 401,
          width: 500,
          price: '1501.00',
        },
        {
          minWidth: 501,
          width: 600,
          price: '1501.00',
        },
        {
          minWidth: 601,
          width: 700,
          price: '1631.00',
        },
        {
          minWidth: 701,
          width: 800,
          price: '1631.00',
        },
        {
          minWidth: 801,
          width: 900,
          price: '1773.00',
        },
        {
          minWidth: 901,
          width: 1000,
          price: '1773.00',
        },
        {
          minWidth: 1001,
          width: 1100,
          price: '1903.00',
        },
        {
          minWidth: 1101,
          width: 1200,
          price: '2317.00',
        },
        {
          minWidth: 1201,
          width: 1300,
          price: '2317.00',
        },
        {
          minWidth: 1301,
          width: 1400,
          price: '2446.00',
        },
        {
          minWidth: 1401,
          width: 1500,
          price: '2446.00',
        },
        {
          minWidth: 1501,
          width: 1600,
          price: '2589.00',
        },
        {
          minWidth: 1601,
          width: 1700,
          price: '2589.00',
        },
        {
          minWidth: 1701,
          width: 1800,
          price: '2718.00',
        },
        {
          minWidth: 1801,
          width: 1900,
          price: '2847.00',
        },
        {
          minWidth: 1901,
          width: 2000,
          price: '2990.00',
        },
        {
          minWidth: 2001,
          width: 2100,
          price: '2990.00',
        },
        {
          minWidth: 2101,
          width: 2200,
          price: '3262.00',
        },
        {
          minWidth: 2201,
          width: 2300,
          price: '3404.00',
        },
        {
          minWidth: 2301,
          width: 2400,
          price: '3805.00',
        },
        {
          minWidth: 2401,
          width: 2500,
          price: '4077.00',
        },
        {
          minWidth: 2501,
          width: 2600,
          price: '4349.00',
        },
        {
          minWidth: 2601,
          width: 2700,
          price: '4621.00',
        },
        {
          minWidth: 2701,
          width: 2800,
          price: '4892.00',
        },
        {
          minWidth: 2801,
          width: 2900,
          price: '5307.00',
        },
        {
          minWidth: 2901,
          width: 3000,
          price: '5565.00',
        },
        {
          minWidth: 3001,
          width: 3100,
          price: '5824.00',
        },
        {
          minWidth: 3101,
          width: 3200,
          price: '6109.00',
        },
        {
          minWidth: 3201,
          width: 3300,
          price: '6472.00',
        },
        {
          minWidth: 3301,
          width: 3400,
          price: '6795.00',
        },
        {
          minWidth: 3401,
          width: 3500,
          price: '7067.00',
        },
      ],
    },
    options: {
      id: '00000000-0000-0000-88ae-5ac041b40003',
      code: 'options',
      name: 'Таблица опций',
      nameUk: 'Таблиця опцій',
      supplierCurrency: 'UAH',
      // interestId?: 1,
      priceType: 'add',
      unit: 'шт.',
      sizeUnits: 'mm',
      type: 'set',
      set: {
        heightBetween1701And2500: {
          id: '00000000-0000-0000-99ff-5ac041b40001',
          code: 'heightBetween1701And2500',
          name: 'Высота римской шторы в диапазоне 1701-2500 мм',
          nameUk: 'Висота римської штори в діапазоні 1701-2500 мм',
          priceType: 'percent',
          unit: '%',
          unitDivider: null,
          price: '40.00',
          // rollAmount: null,
          // packageAmount: null,
          minAmount: null,
          minPricePerProduct: null,
          minPricePerOrder: null,
          images: [],
        },
        heightBetween2501And3500: {
          id: '00000000-0000-0000-99ff-5ac041b40002',
          code: 'heightBetween2501And3500',
          name: 'Высота римской шторы в диапазоне 2501-3500 мм',
          nameUk: 'Висота римської штори в діапазоні 2501-3500 мм',
          priceType: 'percent',
          unit: '%',
          unitDivider: null,
          price: '80.00',
          // rollAmount: null,
          // packageAmount: null,
          minAmount: null,
          minPricePerProduct: null,
          minPricePerOrder: null,
          images: [],
        },
        grommet: {
          id: '00000000-0000-0000-99ff-5ac041b40003',
          code: 'grommet',
          name: 'Люверс для декору',
          nameUk: 'Люверс для декору',
          priceType: 'add',
          unit: 'шт.',
          unitDivider: null,
          price: '61.00',
          minAmount: null,
          minPricePerProduct: null,
          minPricePerOrder: null,
          images: [],
        },
        grommetWithBelt: {
          id: '00000000-0000-0000-99ff-5ac041b40004',
          code: 'grommetWithBelt',
          name: 'Люверс с пояском для декору',
          nameUk: 'Люверс з пояском для декору',
          priceType: 'add',
          unit: 'шт.',
          unitDivider: null,
          price: '72.00',
          minAmount: null,
          minPricePerProduct: null,
          minPricePerOrder: null,
          images: [],
        },
        cascade: {
          id: '00000000-0000-0000-99ff-5ac041b40005',
          code: 'cascade',
          name: 'Каскадная сборка',
          nameUk: 'Каскадна збірка',
          priceType: 'multiplicator',
          unit: 'шт.',
          unitDivider: null,
          price: 2,
          minAmount: null,
          minPricePerProduct: null,
          minPricePerOrder: null,
          images: [],
        },
        nonStandardForm: {
          id: '00000000-0000-0000-99ff-5ac041b40006',
          code: 'nonStandardForm',
          name: 'Нестандартная форма штори',
          nameUk: 'Нестандартна форма штори',
          priceType: 'percent',
          unit: '%',
          unitDivider: null,
          price: '40.00',
          // rollAmount: null,
          // packageAmount: null,
          minAmount: null,
          minPricePerProduct: null,
          minPricePerOrder: null,
          images: [],
        },
        printOnTextile: {
          id: '00000000-0000-0000-99ff-5ac041b40008',
          code: 'printOnTextile',
          name: 'Принт, фотопечать на джуси велюр 93 и блэкаут NovaF24-17',
          nameUk: 'Принт, фотодрук на джуси велюр 93 і блекаут NovaF24-17',
          priceType: 'multiplicator',
          unit: 'м2',
          unitDivider: null,
          price: '453.00',
          // rollAmount: null,
          // packageAmount: null,
          minAmount: null,
          minPricePerProduct: null,
          minPricePerOrder: null,
          images: [],
        },
        // TODO: Доповнити опції
      },
    },
  },
  restrictionRules: [
    {
      rule: 'twinSystemMaxWidth',
      // TODO: Дане правило справедливе лише для мотузкового карнизу і не враховує обмеження мінімум 250 мм
      name: 'Максимальная ширина системы ТВИН 2000 мм',
      nameUk: 'Максимальна ширина системи ТВІН 2000 мм',
      units: 'mm',
      baseValue: 'ТВИН',
      primaryLimit: 2000,
      action: 'exitWithWarning',
      enforcedValue: 'twinSystemMaxWidthExceeded',
    },
    // TODO: Додати правило «Діапазон можливої ширини системи ТВІН на ланцюгово-роторному карнизі 600-2500 мм»
    {
      rule: 'cordSystemWidthLimit',
      name: 'Диапазон ширины веревочного карниза 240 мм - 3000 мм',
      nameUk: 'Діапазон ширини мотузкового карнизу 240 мм - 3000 мм',
      units: 'mm',
      baseValue: 'standard-cord-roman',
      primaryLimit: 240,
      secondaryLimit: 3000,
      action: 'exitWithWarning',
      enforcedValue: 'cordSystemWidthLimitExceeded',
    },
    {
      rule: 'chainSystemWidthLimit',
      name: 'Диапазон ширины цепочно-роторного карниза 340 мм - 3000 мм',
      nameUk: 'Діапазон ширини ланцюгово-роторного карнизу 340 мм - 3000 мм',
      units: 'mm',
      baseValue: 'standard-chain-roman',
      primaryLimit: 340,
      secondaryLimit: 3500,
      action: 'exitWithWarning',
      enforcedValue: 'chainSystemWidthLimitExceeded',
    },
    {
      rule: 'heightBetween1701And2500',
      name: 'Высота римской шторы в диапазоне 1701-2500 мм',
      nameUk: 'Висота римської штори в діапазоні 1701-2500 мм',
      units: 'mm',
      // baseValue: '',
      primaryLimit: 1701,
      secondaryLimit: 2500,
      action: 'enforceSwitchOnProductOption',
      enforcedValue: 'heightBetween1701And2500',
    },
    {
      rule: 'heightBetween2501And3500',
      name: 'Высота римской шторы в диапазоне 2501-3500 мм',
      nameUk: 'Висота римської штори в діапазоні 2501-3500 мм',
      units: 'mm',
      // baseValue: '',
      primaryLimit: 2501,
      secondaryLimit: 3500,
      action: 'enforceSwitchOnProductOption',
      enforcedValue: 'heightBetween2501And3500',
    },
    // TODO: Перевірка тканини при застосуванні опції фотодруку, видача помилки якщо обрана тканина не відповідає умовам
  ],
};
