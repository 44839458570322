import axios from 'axios';

import { showModal } from 'slices/modal';
import store from 'store';

/**
 * Основне API взаємодії з ядром платформи
 */

const API_HOST = process.env.REACT_APP_API_HOST;
const API_PORT = process.env.REACT_APP_API_PORT;
const apiClient = axios.create({
  baseURL: `${API_HOST}:${API_PORT}/api`,
  responseType: 'json',
  timeout: 3e5,
});

/**
 * Об'єкт відповіді API Client щодо помилки
 */
export interface ApiClientError {
  response: ApiClientResponse;
}

/**
 * Об'єкт відповіді API з позначенням кількості одиниць сутності які були оброблені в результаті запиту
 */
export interface CountSchema {
  count: number;
}

/**
 * Статус на рівні HTTP та дані відповіді API Client
 */
export interface ApiClientResponse {
  /**
   * Числовий код напр. 422
   */
  status: number;
  /**
   * Строка статусу напр. "Unprocessable Entity"
   */
  statusText: string;
  /**
   * Дані від API
   */
  data?: unknown;
}

apiClient.interceptors.response.use(
  response => {
    return response;
  },
  (error: ApiClientError) => {
    if (error?.response?.status && (error.response.status === 401 || error.response.status === 403)) {
      store.dispatch(showModal({ modalType: 'LOGIN_FORM_MODAL', modalProps: { withBackdrop: true } }));
    }

    return Promise.reject(
      error?.response?.data ||
        error?.response?.statusText ||
        error?.response ||
        (error as unknown as Error)?.message ||
        'Something went wrong',
    );
  },
);

export default apiClient;
