/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["hydrate", "generateFreeformMaterial", "generateCustomService"] }] */
import { v4 as uuid } from 'uuid';
import { arrayCloneDeep, itemCloneDeep } from 'domain/specificUtility';
import type { FulfilledProductMaterials } from 'types/fulfilledProduct';
import type { Illustration } from 'types/illustration';
import type { Material } from 'types/material';
import type { MountTarget } from 'types/mountTarget';
import type { CustomService, ServiceGroupKey, ServiceKey, ServiceOptions } from 'types/product-services';
import type { ProductStructure, ProductCalcResult } from 'types/ProductStructure';
import type { ProductNotes } from 'types/ProductNotes';
import type { MaterialUsage, AmountUnit, ProductCompositionGroup } from 'types/MaterialUsage';
import type { ProductDesignData, ProductDesignSettingKey } from 'types/ProductDesignData';
import type { ProductMaterials, ComponentGroupKey, ProductComponentKey } from 'types/ProductMaterials';
import type { CalcResult, FreeformComponentCalcResult } from 'types/CalcResult';
import type { ProjectImage } from 'types/ProjectImage';
import type { UsedMaterial } from 'types/UsedMaterial';
import type { ComponentRow, ProductOverview, ProductPrototype, ServiceRow, ServicesOverview } from './ProductPrototype';
import { CONTROL_CHAIN_LENGTH_OPTIONS } from 'domain/constants';
import { standardRomanBlindDecorationOptions } from './standardRomanBlindSettings';

/**
 * Базовий функціональний клас продукту, що містить методи взаємодії з будь-яким продуктом. Всі специфічні класи продуктів наслідують цей клас.
 */
export default class Product implements ProductPrototype {
  id?: string;

  name = '';

  nameUk = '';

  image: Illustration | null = null;

  images?: ProjectImage[] | null = undefined;

  mechanism: string | null = null;

  supplyTimeClass: number | null = null;

  corniceSystem: string | null = null;

  composition: ProductMaterials = {
    mainTextile: undefined,
    secondaryTextile: undefined,
    thirdTextile: undefined,
    fourthTextile: undefined,
    edgingTextile: undefined,
    liningTextile: undefined,
    doublerine: undefined,
    webbing: undefined,
    webbingAdhesive: undefined,
    webbing4Stick: undefined,
    webbingDecorative: undefined,
    grommet: undefined,
    sRings: undefined,
    magnets: undefined,
    zipper: undefined,
    syntheticFluff: undefined,
    sintepon: undefined,
    romanBlindSystem: undefined,
    sticks: undefined,
    fixation: undefined,
    weightingAgent: undefined,
    chain: undefined,
    chainWeighter: undefined,
    miniCurtainRod: undefined,
    curtainRod: undefined,
    curtainRod2: undefined,
    curtainProfile: undefined,
    curtainProfile2: undefined,
    handpiece: undefined,
    handpiece2: undefined,
    cap: undefined,
    cap2: undefined,
    stopper: undefined,
    stopper2: undefined,
    pipeConnector: undefined,
    pipeConnector2: undefined,
    clipsFasten: undefined,
    bracketFasten: undefined,
    wallBracket: undefined,
    bracketExtension: undefined,
    hookSigma: undefined,
    runners: undefined,
    runners2: undefined,
    rings4CurtainRod: undefined,
    rings4CurtainRod2: undefined,
    motor: undefined,
    thirdPipeHolder: undefined,
    bendingCurtainRod: undefined,
    lambrequinLath: undefined,
    hookDrapery: undefined,
    rosette: undefined,
    pin: undefined,
    tieBack: undefined,
    curtainGuides: undefined,
    powerSupplyUnit: undefined,
    charger: undefined,
    remoteControlUnit: undefined,
    smartHub: undefined,
    freeformComponents: [],
    standardRomanBlind: undefined,
    standardRomanBlindSecondary: undefined,
    standardRomanBlindDoubleSystem: undefined,
    verticalJalousie: undefined,
    textileRollerBlind: undefined,
  };

  usedMaterials?: UsedMaterial[] = undefined;

  design: ProductDesignData = {
    width: undefined,
    height: undefined,
    length: undefined,
    widthAmountToBuy: undefined,
    heightAmountToBuy: undefined,
    lengthAmountToBuy: undefined,
    sticksOverallCount: undefined,
    draperyCoefficient: undefined,
    mountTarget: undefined,
    model: undefined,
    nonStandardForm: false,
    isComplexSewing: false,
    productionOptions: {},
    mainTextile: undefined,
    secondaryTextile: undefined,
    thirdTextile: undefined,
    fourthTextile: undefined,
    edgingTextile: undefined,
    liningTextile: undefined,
    doublerine: undefined,
    webbing: undefined,
    webbingAdhesive: undefined,
    webbing4Stick: undefined,
    webbingDecorative: undefined,
    grommet: undefined,
    sRings: undefined,
    magnets: undefined,
    zipper: undefined,
    syntheticFluff: undefined,
    sintepon: undefined,
    romanBlindSystem: undefined,
    sticks: undefined,
    fixation: undefined,
    weightingAgent: undefined,
    chain: undefined,
    chainWeighter: undefined,
    miniCurtainRod: undefined,
    curtainRod: undefined,
    curtainRod2: undefined,
    curtainProfile: undefined,
    curtainProfile2: undefined,
    handpiece: undefined,
    handpiece2: undefined,
    cap: undefined,
    cap2: undefined,
    stopper: undefined,
    stopper2: undefined,
    pipeConnector: undefined,
    pipeConnector2: undefined,
    clipsFasten: undefined,
    bracketFasten: undefined,
    wallBracket: undefined,
    bracketExtension: undefined,
    hookSigma: undefined,
    runners: undefined,
    runners2: undefined,
    rings4CurtainRod: undefined,
    rings4CurtainRod2: undefined,
    motor: undefined,
    thirdPipeHolder: undefined,
    bendingCurtainRod: undefined,
    lambrequinLath: undefined,
    hookDrapery: undefined,
    rosette: undefined,
    pin: undefined,
    tieBack: undefined,
    curtainGuides: undefined,
    powerSupplyUnit: undefined,
    charger: undefined,
    remoteControlUnit: undefined,
    smartHub: undefined,
    freeformComponents: [],
    standardRomanBlind: undefined,
    standardRomanBlindSecondary: undefined,
    standardRomanBlindDoubleSystem: undefined,
    verticalJalousie: undefined,
    textileRollerBlind: undefined,
  };

  serviceOptions: ServiceOptions = {
    sewingCurtains: undefined,
    sewingCurtainsComplex: undefined,
    sewingRomanBlinds: undefined,
    sewingRomanBlindsComplex: undefined,
    sewingBlanket: undefined,
    sewingCustom: undefined,
    hangingCurtains: undefined,
    hangingRomanBlinds: undefined,
    hangingComplex: undefined,
    hangingBlanket: undefined,
    hangingBlanketComplex: undefined,
    hangingChairCover: undefined,
    hangingChairCoverComplex: undefined,
    hangingCustom: undefined,
    mountCurtainRod: undefined,
    mountCurtainRodComplex: undefined,
    mountRomanBlindSystem: undefined,
    mountRomanBlindSystemComplex: undefined,
    mountRomanBlindInhort: undefined,
    mountRomanBlindInhortComplex: undefined,
    mountRollerBlindSystem: undefined,
    mountRollerBlindSystemComplex: undefined,
    mountJalousieSystem: undefined,
    mountJalousieSystemComplex: undefined,
    mountCafeSystem: undefined,
    mountHookDrapery: undefined,
    mountCustom: undefined,
    disassemblingCurtainRod: undefined,
    disassemblingCustom: undefined,
    customServices: [],
  };

  notes: ProductNotes = {
    sewing: '',
    hanging: '',
    mounting: '',
  };

  calcMethod = '';

  calcResult?: ProductCalcResult;

  roomName?: string;

  windowId?: number;

  projectId?: string;

  /**
   * Контрольний набір всіх доступних для даного виробу параметрів дизайну що не стосуються безпосередньо складових виробу
   */
  protected readonly _validDesignSettings: ProductDesignSettingKey[] = [
    'width',
    'height',
    'length',
    'widthAmountToBuy',
    'heightAmountToBuy',
    'lengthAmountToBuy',
    'sticksOverallCount',
    'mountTarget',
    'draperyCoefficient',
    'model',
    'nonStandardForm',
    'controlSide',
    'controlChainLength',
    'isComplexSewing',
    'productionOptions',
  ];

  /**
   * Контрольний набір обов'язкових для даного виробу параметрів дизайну що не стосуються безпосередньо складових виробу
   */
  protected readonly _requiredDesignSettings: ProductDesignSettingKey[] = [];

  /**
   * Контрольний набір дійсних ключів цільових об'єктів для монтажу виробу
   */
  protected readonly _validMountTargets: MountTarget[] = ['wall', 'casement', 'ceiling', 'window.hole'];

  /**
   * Контрольний набір дійсних ключів складових виробу по групам
   */
  protected readonly _validComponentKeysOf: {
    [componentType: string]: ProductComponentKey[];
  } = {
    textile: [
      'mainTextile',
      'secondaryTextile',
      'thirdTextile',
      'fourthTextile',
      'edgingTextile',
      'liningTextile',
      'doublerine',
    ],
    webbing: ['webbing', 'webbingAdhesive', 'webbing4Stick', 'webbingDecorative'],
    furniture: ['grommet', 'sRings', 'magnets', 'zipper', 'syntheticFluff', 'sintepon'],
    curtainRod: [
      'curtainProfile',
      'romanBlindSystem',
      'sticks',
      'miniCurtainRod',
      'curtainRod',
      'bracketFasten',
      'handpiece',
      'cap',
      'rings4CurtainRod',
      'hookSigma',
      'wallBracket',
      'runners',
      'stopper',
      'clipsFasten',
      'fixation',
      'weightingAgent',
      'chain',
      'chainWeighter',
      'pipeConnector',
      'bracketExtension',
      'thirdPipeHolder',
      'bendingCurtainRod',
      'curtainProfile2',
      'runners2',
      'stopper2',
      'curtainRod2',
      'handpiece2',
      'cap2',
      'rings4CurtainRod2',
      'pipeConnector2',
      'motor',
      'lambrequinLath',
    ],
    accessory: [
      'hookDrapery',
      'rosette',
      'pin',
      'tieBack',
      'curtainGuides',
      'powerSupplyUnit',
      'charger',
      'remoteControlUnit',
      'smartHub',
    ],
    productFulfillment: [
      'standardRomanBlind',
      'standardRomanBlindSecondary',
      'standardRomanBlindDoubleSystem',
      'verticalJalousie',
      'textileRollerBlind',
    ],
  };

  /**
   * Контрольний набір дійсних ключів видів послуг
   */
  protected readonly _validServiceGroupKeys: ServiceGroupKey[] = [
    'sewing',
    'hanging',
    'mount',
    'disassembling',
    'custom',
  ];

  /**
   * Контрольний набір дійсних ключів послуг по групам
   */
  protected readonly _validServiceKeysOf: {
    [serviceType: string]: ServiceKey[];
  } = {
    sewing: [
      'sewingCurtains',
      'sewingCurtainsComplex',
      'sewingRomanBlinds',
      'sewingRomanBlindsComplex',
      'sewingBlanket',
      'sewingCustom',
    ],
    hanging: [
      'hangingCurtains',
      'hangingRomanBlinds',
      'hangingComplex',
      'hangingCustom',
      'hangingBlanket',
      'hangingBlanketComplex',
      'hangingChairCover',
      'hangingChairCoverComplex',
    ],
    mount: [
      'mountCurtainRod',
      'mountCurtainRodComplex',
      'mountRomanBlindSystem',
      'mountRomanBlindSystemComplex',
      'mountRomanBlindInhort',
      'mountRomanBlindInhortComplex',
      'mountRollerBlindSystem',
      'mountRollerBlindSystemComplex',
      'mountJalousieSystem',
      'mountJalousieSystemComplex',
      'mountCafeSystem',
      'mountHookDrapery',
      'mountCustom',
    ],
    disassembling: ['disassemblingCurtainRod', 'disassemblingCustom'],
    custom: ['customServices'],
  };

  /**
   * Об'єкт параметрів використання тканини в дизайні виробу за умовчанням
   */
  protected _defaultTextileUsage: MaterialUsage = {
    amount: -1,
    unit: 'м.п.',
    amountToBuy: undefined,
    unitToBuy: 'м.п.',
    totalPrice: undefined,
    isManualAmount: true,
    manualAmountAuthor: undefined,
    isManualPrice: false,
    manualPriceAuthor: undefined,
    required: false,
  };

  /**
   * Об'єкт параметрів використання аксесуарів та штучної фурнітури в дизайні виробу за умовчанням
   */
  protected _defaultAccessoryUsage: MaterialUsage = {
    amount: -1,
    unit: 'шт.',
    amountToBuy: undefined,
    unitToBuy: 'шт.',
    totalPrice: undefined,
    isManualAmount: true,
    manualAmountAuthor: undefined,
    isManualPrice: false,
    manualPriceAuthor: undefined,
    required: false,
  };

  /**
   * Об'єкт параметрів використання карнизів, систем та стандартизованих продуктів в дизайні виробу за умовчанням
   */
  protected _defaultSystemUsage: MaterialUsage = {
    amount: 1,
    unit: 'шт.',
    amountToBuy: 1,
    unitToBuy: 'шт.',
    totalPrice: undefined,
    isManualAmount: true,
    manualAmountAuthor: undefined,
    isManualPrice: false,
    manualPriceAuthor: undefined,
    required: false,
  };

  /**
   * Конструктор класу
   *
   * Може приймати опціональний аргумент з об'єктом на основі якого наповнити новий екземпляр даними.
   * Якщо в наданому об'єкті виробу буде наявним ідентифікатор — він потрапить в новий інстанс!
   * @param baseProduct Об'єкт того ж класу або структура даних продукту
   */
  constructor(baseProduct?: Product | ProductStructure) {
    if (baseProduct && typeof baseProduct === 'object') {
      this.id = baseProduct.id ?? undefined;
      this.roomName = baseProduct.roomName ?? undefined;
      this.windowId = baseProduct.windowId ?? undefined;
      this.projectId = baseProduct.projectId ?? undefined;
      this.name = baseProduct.name ?? '';
      this.nameUk = baseProduct.nameUk ?? '';
      this.mechanism = baseProduct.mechanism ?? null;
      this.supplyTimeClass = baseProduct.supplyTimeClass ?? null;
      this.corniceSystem = baseProduct.corniceSystem ?? null;
      this.calcMethod = baseProduct.calcMethod ?? '';

      // Властивості з об'єктами мають бути глибоко клоновані, а не передані по референсу
      this.composition = itemCloneDeep(baseProduct.composition);
      this.usedMaterials = Array.isArray(baseProduct.usedMaterials)
        ? arrayCloneDeep(baseProduct.usedMaterials)
        : undefined;
      this.design = itemCloneDeep(baseProduct.design);
      this.serviceOptions = itemCloneDeep(baseProduct.serviceOptions);
      this.notes = { ...baseProduct.notes };
      this.image = baseProduct.image ? itemCloneDeep(baseProduct.image) : null;
      this.calcResult = baseProduct.calcResult ? itemCloneDeep(baseProduct.calcResult) : undefined;
    }
  }

  clone(): ProductPrototype {
    const clone = new Product(this);
    // Ідентифікатор клона не має збігатись з його прототипом!
    clone.id = undefined;
    return clone;
  }

  hydrate(structure: ProductStructure): ProductPrototype {
    return new Product(structure);
  }

  toDTO(): ProductStructure {
    return {
      id: this.id,
      roomName: this.roomName,
      windowId: this.windowId,
      projectId: this.projectId,
      name: this.name,
      nameUk: this.nameUk,
      mechanism: this.mechanism,
      supplyTimeClass: this.supplyTimeClass,
      corniceSystem: this.corniceSystem,
      calcMethod: this.calcMethod,
      notes: { ...this.notes },
      composition: itemCloneDeep(this.composition),
      design: itemCloneDeep(this.design),
      serviceOptions: itemCloneDeep(this.serviceOptions),
      image: itemCloneDeep(this.image),
      calcResult: itemCloneDeep(this.calcResult),
    };
  }

  toJSON(): Record<string, unknown> {
    return {
      id: this.id,
      roomName: this.roomName,
      windowId: this.windowId,
      projectId: this.projectId,
      name: this.name,
      nameUk: this.nameUk,
      mechanism: this.mechanism,
      supplyTimeClass: this.supplyTimeClass,
      corniceSystem: this.corniceSystem,
      calcMethod: this.calcMethod,
      notes: this.notes,
      composition: this.composition,
      design: this.design,
      serviceOptions: this.serviceOptions,
      image: this.image,
      calcResult: this.calcResult,
    };
  }

  /* Методи отримання довідкових даних у контексті виробу */

  validDesignSettings(): ProductDesignSettingKey[] {
    return [...this._validDesignSettings];
  }

  requiredDesignSettings(): ProductDesignSettingKey[] {
    return [...this._requiredDesignSettings];
  }

  validMountTargets(): MountTarget[] {
    return [...this._validMountTargets];
  }

  validComponentGroupKeys(): ComponentGroupKey[] {
    return Object.keys(this._validComponentKeysOf) as ComponentGroupKey[];
  }

  validSpecificComponentKeys(componentGroupKey?: ComponentGroupKey): ProductComponentKey[] {
    if (typeof componentGroupKey === 'string' && componentGroupKey) {
      return [...this._validComponentKeysOf[componentGroupKey]];
    }

    const allGroupKeys = Object.keys(this._validComponentKeysOf);
    return allGroupKeys.reduce(
      (allComponentKeys: ProductComponentKey[], groupKey) => [
        ...allComponentKeys,
        ...this._validComponentKeysOf[groupKey],
      ],
      [],
    );
  }

  validServiceGroupKeys(): ServiceGroupKey[] {
    return [...this._validServiceGroupKeys];
  }

  validServiceKeys(serviceGroupKey?: ServiceGroupKey): ServiceKey[] {
    if (typeof serviceGroupKey === 'string' && this._validServiceGroupKeys.includes(serviceGroupKey)) {
      return [...this._validServiceKeysOf[serviceGroupKey]];
    }

    const allGroupKeys = Object.keys(this._validServiceKeysOf);
    return allGroupKeys.reduce(
      (allServiceKeys: ServiceKey[], groupKey) => [...allServiceKeys, ...this._validServiceKeysOf[groupKey]],
      [],
    );
  }

  defaultMaterialUsage(componentKey: ProductComponentKey | 'freeformComponents'): MaterialUsage {
    // Визначити чи дійсний ключ складової та з якої вона категорії
    let category: number;
    if (componentKey === 'freeformComponents') {
      category = 6;
    } else if (
      this._validComponentKeysOf.textile.includes(componentKey) ||
      this._validComponentKeysOf.webbing.includes(componentKey)
    ) {
      category = 1;
    } else if (this._validComponentKeysOf.furniture.includes(componentKey)) {
      category = 2;
    } else if (this._validComponentKeysOf.accessory.includes(componentKey)) {
      category = 3;
    } else if (this._validComponentKeysOf.curtainRod.includes(componentKey)) {
      category = 4;
    } else if (this._validComponentKeysOf.productFulfillment.includes(componentKey)) {
      category = 5;
    } else {
      // Не дійсний ключ складової
      throw new InvalidComponentKey(componentKey, 'all');
    }

    let defaultUsage: MaterialUsage;
    if (category === 1) {
      defaultUsage = { ...this._defaultTextileUsage };
    } else if (category === 2) {
      defaultUsage = { ...this._defaultAccessoryUsage };
      // Розрізнення фурнітури за окремими типами складових (особливі параметри за умовчанням)
      if (componentKey === 'grommet') {
        defaultUsage.amount = -1;
        defaultUsage.amountToBuy = -1;
        defaultUsage.unit = 'шт.';
        defaultUsage.unitToBuy = 'уп.';
      } else if (componentKey === 'syntheticFluff') {
        defaultUsage.unit = 'кг';
        defaultUsage.unitToBuy = defaultUsage.unit;
      } else if (componentKey === 'sintepon') {
        defaultUsage.unit = 'м.п.';
        defaultUsage.unitToBuy = defaultUsage.unit;
      }
    } else if (category === 3) {
      defaultUsage = { ...this._defaultAccessoryUsage };
      // Розрізнення аксесуарів за окремими типами складових (особливі параметри за умовчанням)
      if (['powerSupplyUnit', 'charger', 'remoteControlUnit', 'smartHub'].includes(componentKey)) {
        defaultUsage.amount = 1;
        defaultUsage.amountToBuy = 1;
      }
    } else if (category === 4) {
      defaultUsage = { ...this._defaultSystemUsage };
      // Розрізнення карнизів за окремими типами складових (особливі параметри за умовчанням)
      if (['curtainProfile', 'curtainProfile2', 'chain', 'lambrequinLath'].includes(componentKey)) {
        defaultUsage.amount = -1;
        defaultUsage.amountToBuy = -1;
        defaultUsage.unit = 'м.п.';
        defaultUsage.unitToBuy = defaultUsage.unit;
      } else if (
        [
          'handpiece',
          'handpiece2',
          'cap',
          'cap2',
          'stopper',
          'stopper2',
          'bracketFasten',
          'wallBracket',
          'bracketExtension',
        ].includes(componentKey)
      ) {
        defaultUsage.amount = 2;
        defaultUsage.amountToBuy = 2;
        defaultUsage.unit = 'шт.';
        defaultUsage.unitToBuy = defaultUsage.unit;
      } else if (
        ['hookSigma', 'runners', 'runners2', 'rings4CurtainRod', 'rings4CurtainRod2', 'clipsFasten'].includes(
          componentKey,
        )
      ) {
        defaultUsage.amount = -1;
        defaultUsage.amountToBuy = -1;
        defaultUsage.unit = 'шт.';
        // У більшості випадків ці складові продаються пакетами
        defaultUsage.unitToBuy = 'уп.';
      }
    } else if (category === 5) {
      defaultUsage = { ...this._defaultSystemUsage, isManualPrice: true };
    } else {
      defaultUsage = { ...this._defaultAccessoryUsage };
    }

    return defaultUsage;
  }

  /* Керування даними виробу */

  setName(name: string): void {
    this.name = name;
    if (!this.nameUk) this.nameUk = name;
  }

  setNameUk(nameUk: string): void {
    this.nameUk = nameUk;
  }

  setWidth(width: number | string): void {
    const value = +width;
    if (!Number.isNaN(value)) {
      this.design.width = value;
    }
  }

  setHeight(height: number | string): void {
    const value = +height;
    if (!Number.isNaN(value)) {
      this.design.height = value;
    }
  }

  setLength(length: number | string): void {
    const value = +length;
    if (!Number.isNaN(value)) {
      this.design.length = value;
    }
  }

  setWidthSecondary(width?: number | string): void {
    const value = width !== undefined ? +width : 0;
    if (
      (typeof width === 'string' && width.trim().length !== 0 && !Number.isNaN(value) && value > 0) ||
      (typeof width === 'number' && !Number.isNaN(width) && width > 0)
    ) {
      this.design.widthSecondary = value;
    } else this.design.widthSecondary = undefined;
  }

  setHeightSecondary(height?: number | string): void {
    const value = height !== undefined ? +height : 0;
    if (
      (typeof height === 'string' && height.trim().length !== 0 && !Number.isNaN(value) && value > 0) ||
      (typeof height === 'number' && !Number.isNaN(height) && height > 0)
    ) {
      this.design.heightSecondary = value;
    } else this.design.heightSecondary = undefined;
  }

  mountTarget(): MountTarget | undefined {
    return this.design.mountTarget;
  }

  setMountTarget(value?: MountTarget): boolean {
    if (typeof value === 'undefined') {
      this.design.mountTarget = undefined;
    } else if (this._validMountTargets.includes(value)) {
      this.design.mountTarget = value;
      return true;
    }
    return false;
  }

  draperyCoefficient(): number | undefined {
    return this.design.draperyCoefficient;
  }

  setDraperyCoefficient(k: number | undefined): void {
    if (!k) {
      this.design.draperyCoefficient = undefined;
    } else if (k >= 1) {
      this.design.draperyCoefficient = k;
    }
  }

  requireComponent(componentKey: ProductComponentKey, newState?: boolean): boolean {
    // Встановити нове значення
    if (typeof newState !== 'undefined') {
      const mu = this.design[componentKey];
      if (typeof mu !== 'undefined') {
        // Оновити індикатор обов'язковості складової
        mu.required = !!newState;
      } else if (newState) {
        // Якщо призначається обов'язковість і параметри дизайну ще не містять кількісний показник
        // — встановити його стартове значення
        const defaultUsage = this.defaultMaterialUsage(componentKey);
        this.design[componentKey] = { ...defaultUsage, required: !!newState };
      }
    }

    return !!this.design[componentKey]?.required;
  }

  requireMainTextile(newState?: boolean): boolean {
    // Встановити нове значення
    if (typeof newState !== 'undefined') {
      if (typeof this.design.mainTextile !== 'undefined') {
        // Оновити індикатор обов'язковості складової
        this.design.mainTextile.required = !!newState;
      } else if (newState) {
        // Якщо призначається обов'язковість і параметри дизайну ще не містять кількісний показник
        // — встановити його стартове значення
        this.design.mainTextile = { ...this._defaultTextileUsage, required: !!newState };
      }
    }

    return !!this.design.mainTextile?.required;
  }

  requireLiningTextile(newState?: boolean): boolean {
    // Встановити нове значення
    if (typeof newState !== 'undefined') {
      if (typeof this.design.liningTextile !== 'undefined') {
        // Оновити індикатор обов'язковості складової
        this.design.liningTextile.required = !!newState;
      } else if (newState) {
        // Якщо призначається обов'язковість і параметри дизайну ще не містять кількісний показник
        // — встановити його стартове значення
        this.design.liningTextile = { ...this._defaultTextileUsage, required: !!newState };
      }
    }

    return !!this.design.liningTextile?.required;
  }

  setDesign(componentKey: ProductComponentKey, data: Partial<MaterialUsage> | undefined): void {
    // Виконати зміну даних
    const mu = this.design[componentKey];
    if (typeof data !== 'undefined') {
      // На вході об'єкт змін
      if (typeof mu !== 'undefined') {
        // Складова вже має певне значення
        this.design[componentKey] = { ...mu, ...data, required: mu.required };
      } else {
        // Складова була відсутньою
        const defaultUsage = this.defaultMaterialUsage(componentKey);
        this.design[componentKey] = { ...defaultUsage, ...data };
      }
    } else if (typeof mu !== 'undefined') {
      if (mu.required) {
        // Повернення параметрів дизайну обов'язкової складової у значення за умовчанням
        const defaultUsage = this.defaultMaterialUsage(componentKey);
        this.design[componentKey] = { ...defaultUsage, required: true };
      } else {
        // Видалення дизайну та матеріалу необов'язкової складової
        this.design[componentKey] = undefined;
        this.composition[componentKey] = undefined;
      }
    } else {
      // Видалення можливого матеріалу складової без дизайну
      this.composition[componentKey] = undefined;
    }
  }

  setMainTextileDesign(data: Partial<MaterialUsage> | undefined): void {
    if (typeof data !== 'undefined') {
      // На вході об'єкт змін
      if (typeof this.design.mainTextile !== 'undefined') {
        this.design.mainTextile = { ...this.design.mainTextile, ...data, required: this.design.mainTextile.required };
      } else {
        this.design.mainTextile = { ...this._defaultTextileUsage, ...data };
      }
    } else if (typeof this.design.mainTextile !== 'undefined') {
      if (this.design.mainTextile?.required) {
        // Повернення параметрів дизайну обов'язкової складової у значення за умовчанням
        this.design.mainTextile = { ...this._defaultTextileUsage, required: true };
      } else {
        // Видалення дизайну та матеріалу необов'язкової складової
        this.design.mainTextile = undefined;
        this.composition.mainTextile = undefined;
      }
    } else {
      // Видалення можливого матеріалу складової без дизайну
      this.composition.mainTextile = undefined;
    }
  }

  setSecondaryTextileDesign(data: Partial<MaterialUsage> | undefined): void {
    if (typeof data !== 'undefined') {
      // На вході об'єкт змін
      if (typeof this.design.secondaryTextile !== 'undefined') {
        this.design.secondaryTextile = {
          ...this.design.secondaryTextile,
          ...data,
          required: this.design.secondaryTextile.required,
        };
      } else {
        this.design.secondaryTextile = { ...this._defaultTextileUsage, ...data };
      }
    } else if (typeof this.design.secondaryTextile !== 'undefined') {
      if (this.design.secondaryTextile?.required) {
        // Повернення параметрів дизайну обов'язкової складової у значення за умовчанням
        this.design.secondaryTextile = { ...this._defaultTextileUsage, required: true };
      } else {
        // Видалення дизайну та матеріалу необов'язкової складової
        this.design.secondaryTextile = undefined;
        this.composition.secondaryTextile = undefined;
      }
    } else {
      // Видалення можливого матеріалу складової без дизайну
      this.composition.secondaryTextile = undefined;
    }
  }

  setThirdTextileDesign(data: Partial<MaterialUsage> | undefined): void {
    if (typeof data !== 'undefined') {
      // На вході об'єкт змін
      if (typeof this.design.thirdTextile !== 'undefined') {
        this.design.thirdTextile = {
          ...this.design.thirdTextile,
          ...data,
          required: this.design.thirdTextile.required,
        };
      } else {
        this.design.thirdTextile = { ...this._defaultTextileUsage, ...data };
      }
    } else if (typeof this.design.thirdTextile !== 'undefined') {
      if (this.design.thirdTextile?.required) {
        // Повернення параметрів дизайну обов'язкової складової у значення за умовчанням
        this.design.thirdTextile = { ...this._defaultTextileUsage, required: true };
      } else {
        // Видалення дизайну та матеріалу необов'язкової складової
        this.design.thirdTextile = undefined;
        this.composition.thirdTextile = undefined;
      }
    } else {
      // Видалення можливого матеріалу складової без дизайну
      this.composition.thirdTextile = undefined;
    }
  }

  setFourthTextileDesign(data: Partial<MaterialUsage> | undefined): void {
    if (typeof data !== 'undefined') {
      // На вході об'єкт змін
      if (typeof this.design.fourthTextile !== 'undefined') {
        this.design.fourthTextile = {
          ...this.design.fourthTextile,
          ...data,
          required: this.design.fourthTextile.required,
        };
      } else {
        this.design.fourthTextile = { ...this._defaultTextileUsage, ...data };
      }
    } else if (typeof this.design.fourthTextile !== 'undefined') {
      if (this.design.fourthTextile?.required) {
        // Повернення параметрів дизайну обов'язкової складової у значення за умовчанням
        this.design.fourthTextile = { ...this._defaultTextileUsage, required: true };
      } else {
        // Видалення дизайну та матеріалу необов'язкової складової
        this.design.fourthTextile = undefined;
        this.composition.fourthTextile = undefined;
      }
    } else {
      // Видалення можливого матеріалу складової без дизайну
      this.composition.fourthTextile = undefined;
    }
  }

  setLiningTextileDesign(data: Partial<MaterialUsage> | undefined): void {
    if (typeof data !== 'undefined') {
      // На вході об'єкт змін
      if (typeof this.design.liningTextile !== 'undefined') {
        this.design.liningTextile = {
          ...this.design.liningTextile,
          ...data,
          required: this.design.liningTextile.required,
        };
      } else {
        this.design.liningTextile = { ...this._defaultTextileUsage, ...data };
      }
    } else if (typeof this.design.liningTextile !== 'undefined') {
      if (this.design.liningTextile?.required) {
        // Повернення параметрів дизайну обов'язкової складової у значення за умовчанням
        this.design.liningTextile = { ...this._defaultTextileUsage, required: true };
      } else {
        // Видалення дизайну та матеріалу необов'язкової складової
        this.design.liningTextile = undefined;
        this.composition.liningTextile = undefined;
      }
    } else {
      // Видалення можливого матеріалу складової без дизайну
      this.composition.liningTextile = undefined;
    }
  }

  setEdgingTextileDesign(data: Partial<MaterialUsage> | undefined): void {
    if (typeof data !== 'undefined') {
      // На вході об'єкт змін
      if (typeof this.design.edgingTextile !== 'undefined') {
        this.design.edgingTextile = {
          ...this.design.edgingTextile,
          ...data,
          required: this.design.edgingTextile.required,
        };
      } else {
        this.design.edgingTextile = { ...this._defaultTextileUsage, ...data };
      }
    } else if (typeof this.design.edgingTextile !== 'undefined') {
      if (this.design.edgingTextile?.required) {
        // Повернення параметрів дизайну обов'язкової складової у значення за умовчанням
        this.design.edgingTextile = { ...this._defaultTextileUsage, required: true };
      } else {
        // Видалення дизайну та матеріалу необов'язкової складової
        this.design.edgingTextile = undefined;
        this.composition.edgingTextile = undefined;
      }
    } else {
      // Видалення можливого матеріалу складової без дизайну
      this.composition.edgingTextile = undefined;
    }
  }

  setWebbingDesign(data: Partial<MaterialUsage> | undefined): void {
    if (typeof data !== 'undefined') {
      // На вході об'єкт змін
      if (typeof this.design.webbing !== 'undefined') {
        this.design.webbing = {
          ...this.design.webbing,
          ...data,
          required: this.design.webbing.required,
        };
      } else {
        this.design.webbing = { ...this._defaultTextileUsage, ...data };
      }
    } else if (typeof this.design.webbing !== 'undefined') {
      if (this.design.webbing?.required) {
        // Повернення параметрів дизайну обов'язкової складової у значення за умовчанням
        this.design.webbing = { ...this._defaultTextileUsage, required: true };
      } else {
        // Видалення дизайну та матеріалу необов'язкової складової
        this.design.webbing = undefined;
        this.composition.webbing = undefined;
      }
    } else {
      // Видалення можливого матеріалу складової без дизайну
      this.composition.webbing = undefined;
    }
  }

  setGrommetDesign(data: Partial<MaterialUsage> | undefined): void {
    if (typeof data !== 'undefined') {
      // На вході об'єкт змін
      if (typeof this.design.grommet !== 'undefined') {
        this.design.grommet = {
          ...this.design.grommet,
          ...data,
          required: this.design.grommet.required,
        };
      } else {
        this.design.grommet = {
          ...this._defaultAccessoryUsage,
          amount: -1,
          amountToBuy: -1,
          unit: 'шт.',
          unitToBuy: 'уп.',
          ...data,
        };
      }
    } else if (typeof this.design.grommet !== 'undefined') {
      if (this.design.grommet?.required) {
        // Повернення параметрів дизайну обов'язкової складової у значення за умовчанням
        this.design.grommet = {
          ...this._defaultAccessoryUsage,
          amount: -1,
          amountToBuy: -1,
          unit: 'шт.',
          unitToBuy: 'уп.',
          required: true,
        };
      } else {
        // Видалення дизайну та матеріалу необов'язкової складової
        this.design.grommet = undefined;
        this.composition.grommet = undefined;
      }
    } else {
      // Видалення можливого матеріалу складової без дизайну
      this.composition.grommet = undefined;
    }
  }

  setCurtainRodDesign(data: Partial<MaterialUsage> | undefined): void {
    if (typeof data !== 'undefined') {
      // На вході об'єкт змін
      if (typeof this.design.curtainRod !== 'undefined') {
        this.design.curtainRod = {
          ...this.design.curtainRod,
          ...data,
          required: this.design.curtainRod.required,
        };
      } else {
        this.design.curtainRod = { ...this._defaultSystemUsage, ...data };
      }
    } else if (typeof this.design.curtainRod !== 'undefined') {
      if (this.design.curtainRod?.required) {
        // Повернення параметрів дизайну обов'язкової складової у значення за умовчанням
        this.design.curtainRod = { ...this._defaultSystemUsage, required: true };
      } else {
        // Видалення дизайну та матеріалу необов'язкової складової
        this.design.curtainRod = undefined;
        this.composition.curtainRod = undefined;
      }
    } else {
      // Видалення можливого матеріалу складової без дизайну
      this.composition.curtainRod = undefined;
    }
  }

  setCurtainRod2Design(data: Partial<MaterialUsage> | undefined): void {
    if (typeof data !== 'undefined') {
      // На вході об'єкт змін
      if (typeof this.design.curtainRod2 !== 'undefined') {
        this.design.curtainRod2 = {
          ...this.design.curtainRod2,
          ...data,
          required: this.design.curtainRod2.required,
        };
      } else {
        this.design.curtainRod2 = { ...this._defaultSystemUsage, ...data };
      }
    } else if (typeof this.design.curtainRod2 !== 'undefined') {
      if (this.design.curtainRod2?.required) {
        // Повернення параметрів дизайну обов'язкової складової у значення за умовчанням
        this.design.curtainRod2 = { ...this._defaultSystemUsage, required: true };
      } else {
        // Видалення дизайну та матеріалу необов'язкової складової
        this.design.curtainRod2 = undefined;
        this.composition.curtainRod2 = undefined;
      }
    } else {
      // Видалення можливого матеріалу складової без дизайну
      this.composition.curtainRod2 = undefined;
    }
  }

  setCurtainProfileDesign(data: Partial<MaterialUsage> | undefined): void {
    if (typeof data !== 'undefined') {
      // На вході об'єкт змін
      if (typeof this.design.curtainProfile !== 'undefined') {
        this.design.curtainProfile = {
          ...this.design.curtainProfile,
          ...data,
          required: this.design.curtainProfile.required,
        };
      } else {
        this.design.curtainProfile = {
          ...this._defaultSystemUsage,
          amount: -1,
          amountToBuy: -1,
          unit: 'м.п.',
          unitToBuy: 'м.п.',
          ...data,
        };
      }
    } else if (typeof this.design.curtainProfile !== 'undefined') {
      if (this.design.curtainProfile?.required) {
        // Повернення параметрів дизайну обов'язкової складової у значення за умовчанням
        this.design.curtainProfile = {
          ...this._defaultSystemUsage,
          amount: -1,
          amountToBuy: -1,
          unit: 'м.п.',
          unitToBuy: 'м.п.',
          required: true,
        };
      } else {
        // Видалення дизайну та матеріалу необов'язкової складової
        this.design.curtainProfile = undefined;
        this.composition.curtainProfile = undefined;
      }
    } else {
      // Видалення можливого матеріалу складової без дизайну
      this.composition.curtainProfile = undefined;
    }
  }

  setCurtainProfile2Design(data: Partial<MaterialUsage> | undefined): void {
    if (typeof data !== 'undefined') {
      // На вході об'єкт змін
      if (typeof this.design.curtainProfile2 !== 'undefined') {
        this.design.curtainProfile2 = {
          ...this.design.curtainProfile2,
          ...data,
          required: this.design.curtainProfile2.required,
        };
      } else {
        this.design.curtainProfile2 = {
          ...this._defaultSystemUsage,
          amount: -1,
          amountToBuy: -1,
          unit: 'м.п.',
          unitToBuy: 'м.п.',
          ...data,
        };
      }
    } else if (typeof this.design.curtainProfile2 !== 'undefined') {
      if (this.design.curtainProfile2?.required) {
        // Повернення параметрів дизайну обов'язкової складової у значення за умовчанням
        this.design.curtainProfile2 = {
          ...this._defaultSystemUsage,
          amount: -1,
          amountToBuy: -1,
          unit: 'м.п.',
          unitToBuy: 'м.п.',
          required: true,
        };
      } else {
        // Видалення дизайну та матеріалу необов'язкової складової
        this.design.curtainProfile2 = undefined;
        this.composition.curtainProfile2 = undefined;
      }
    } else {
      // Видалення можливого матеріалу складової без дизайну
      this.composition.curtainProfile2 = undefined;
    }
  }

  setRomanBlindSystemDesign(data: Partial<MaterialUsage> | undefined): void {
    if (typeof data !== 'undefined') {
      // На вході об'єкт змін
      if (typeof this.design.romanBlindSystem !== 'undefined') {
        this.design.romanBlindSystem = {
          ...this.design.romanBlindSystem,
          ...data,
          required: this.design.romanBlindSystem.required,
        };
      } else {
        this.design.romanBlindSystem = { ...this._defaultSystemUsage, ...data };
      }
    } else if (typeof this.design.romanBlindSystem !== 'undefined') {
      if (this.design.romanBlindSystem?.required) {
        // Повернення параметрів дизайну обов'язкової складової у значення за умовчанням
        this.design.romanBlindSystem = { ...this._defaultSystemUsage, required: true };
      } else {
        // Видалення дизайну та матеріалу необов'язкової складової
        this.design.romanBlindSystem = undefined;
        this.composition.romanBlindSystem = undefined;
      }
    } else {
      // Видалення можливого матеріалу складової без дизайну
      this.composition.romanBlindSystem = undefined;
    }
  }

  setStandardRomanBlindDesign(data: Partial<MaterialUsage> | undefined): void {
    if (typeof data !== 'undefined') {
      // На вході об'єкт змін
      if (typeof this.design.standardRomanBlind !== 'undefined') {
        this.design.standardRomanBlind = {
          ...this.design.standardRomanBlind,
          ...data,
          required: this.design.standardRomanBlind.required,
        };
      } else {
        this.design.standardRomanBlind = { ...this._defaultSystemUsage, isManualPrice: true, ...data };
      }
    } else if (typeof this.design.standardRomanBlind !== 'undefined') {
      if (this.design.standardRomanBlind?.required) {
        // Повернення параметрів дизайну обов'язкової складової у значення за умовчанням
        this.design.standardRomanBlind = { ...this._defaultSystemUsage, isManualPrice: true, required: true };
      } else {
        // Видалення дизайну та матеріалу необов'язкової складової
        this.design.standardRomanBlind = undefined;
        this.composition.standardRomanBlind = undefined;
      }
    } else {
      // Видалення можливого матеріалу складової без дизайну
      this.composition.standardRomanBlind = undefined;
    }
  }

  setVerticalJalousieDesign(data: Partial<MaterialUsage> | undefined): void {
    if (typeof data !== 'undefined') {
      // На вході об'єкт змін
      if (typeof this.design.verticalJalousie !== 'undefined') {
        this.design.verticalJalousie = {
          ...this.design.verticalJalousie,
          ...data,
          required: this.design.verticalJalousie.required,
        };
      } else {
        this.design.verticalJalousie = { ...this._defaultSystemUsage, isManualPrice: true, ...data };
      }
    } else if (typeof this.design.verticalJalousie !== 'undefined') {
      if (this.design.verticalJalousie?.required) {
        // Повернення параметрів дизайну обов'язкової складової у значення за умовчанням
        this.design.verticalJalousie = { ...this._defaultSystemUsage, isManualPrice: true, required: true };
      } else {
        // Видалення дизайну та матеріалу необов'язкової складової
        this.design.verticalJalousie = undefined;
        this.composition.verticalJalousie = undefined;
      }
    } else {
      // Видалення можливого матеріалу складової без дизайну
      this.composition.verticalJalousie = undefined;
    }
  }

  setTextileRollerBlindDesign(data: Partial<MaterialUsage> | undefined): void {
    if (typeof data !== 'undefined') {
      // На вході об'єкт змін
      if (typeof this.design.textileRollerBlind !== 'undefined') {
        this.design.textileRollerBlind = {
          ...this.design.textileRollerBlind,
          ...data,
          required: this.design.textileRollerBlind.required,
        };
      } else {
        this.design.textileRollerBlind = { ...this._defaultSystemUsage, isManualPrice: true, ...data };
      }
    } else if (typeof this.design.textileRollerBlind !== 'undefined') {
      if (this.design.textileRollerBlind?.required) {
        // Повернення параметрів дизайну обов'язкової складової у значення за умовчанням
        this.design.textileRollerBlind = { ...this._defaultSystemUsage, isManualPrice: true, required: true };
      } else {
        // Видалення дизайну та матеріалу необов'язкової складової
        this.design.textileRollerBlind = undefined;
        this.composition.textileRollerBlind = undefined;
      }
    } else {
      // Видалення можливого матеріалу складової без дизайну
      this.composition.textileRollerBlind = undefined;
    }
  }

  setTextileMaterial(componentKey: ProductComponentKey, data?: Material): boolean {
    if (!componentKey || !this._validComponentKeysOf.textile.includes(componentKey)) {
      // Невідома складова
      throw new InvalidComponentKey(componentKey, 'textile');
    }
    if (typeof data !== 'undefined' && data.type !== 'textile' && data.type !== 'freeform') {
      // Наданий матеріал неможливо встановити в якості 'тканини'
      return false;
    }

    const material = typeof data !== 'undefined' ? itemCloneDeep(data) : undefined;
    let isUnitChanged = false;
    if (material?.unit) {
      const mu = this.design[componentKey];
      isUnitChanged = !!mu && mu.unitToBuy !== material.unit;
    }
    // Якщо одиниця виміру матеріалу змінилась
    const usage =
      material && isUnitChanged
        ? {
            // важливо синхронізувати одиницю виміру для купівлі в об'єкті використання
            unitToBuy: material.unit,
            // кількість у придбання варто очищувати, щоб уникнути розбіжності значення з новими одиницями виміру
            amountToBuy: undefined,
            // відповідно сумарна вартість також має бути обчислена заново
            totalPrice: undefined,
          }
        : null;

    switch (componentKey) {
      case 'mainTextile':
        this.composition.mainTextile = material;
        if (usage) this.setMainTextileDesign(usage);
        break;
      case 'secondaryTextile':
        this.composition.secondaryTextile = material;
        if (usage) this.setSecondaryTextileDesign(usage);
        break;
      case 'thirdTextile':
        this.composition.thirdTextile = material;
        if (usage) this.setThirdTextileDesign(usage);
        break;
      case 'fourthTextile':
        this.composition.fourthTextile = material;
        if (usage) this.setFourthTextileDesign(usage);
        break;
      case 'edgingTextile':
        this.composition.edgingTextile = material;
        if (usage) this.setEdgingTextileDesign(usage);
        break;
      case 'liningTextile':
        this.composition.liningTextile = material;
        if (usage) this.setLiningTextileDesign(usage);
        break;
      case 'doublerine':
        this.composition.doublerine = material;
        if (usage) this.setDesign('doublerine', usage);
        break;

      default:
        throw new InvalidComponentKey(componentKey, 'textile');
    }

    return true;
  }

  setWebbingMaterial(componentKey: ProductComponentKey, data?: Material): boolean {
    if (!componentKey || !this._validComponentKeysOf.webbing.includes(componentKey)) {
      // Невідома складова
      throw new InvalidComponentKey(componentKey, 'webbing');
    }
    if (typeof data !== 'undefined' && !['webbing', 'furniture', 'curtainRod', 'freeform'].includes(data.type)) {
      // Наданий матеріал неможливо встановити в якості бажаної складової
      return false;
    }

    const material = typeof data !== 'undefined' ? itemCloneDeep(data) : undefined;
    let isUnitChanged = false;
    if (material?.unit) {
      const mu = this.design[componentKey];
      isUnitChanged = !!mu && mu.unitToBuy !== material.unit;
    }
    // Якщо одиниця виміру матеріалу змінилась
    const usage =
      material && isUnitChanged
        ? {
            // важливо синхронізувати одиницю виміру для купівлі в об'єкті використання
            unitToBuy: material.unit,
            // кількість у придбання варто очищувати, щоб уникнути розбіжності значення з новими одиницями виміру
            amountToBuy: undefined,
            // відповідно сумарна вартість також має бути обчислена заново
            totalPrice: undefined,
          }
        : null;
    if (usage && !['webbing'].includes(componentKey)) this.setDesign(componentKey, usage);

    switch (componentKey) {
      case 'webbing':
        this.composition.webbing = material;
        if (usage) this.setWebbingDesign(usage);
        break;
      case 'webbingAdhesive':
        this.composition.webbingAdhesive = material;
        break;
      case 'webbing4Stick':
        this.composition.webbing4Stick = material;
        break;
      case 'webbingDecorative':
        this.composition.webbingDecorative = material;
        break;

      default:
        throw new InvalidComponentKey(componentKey, 'webbing');
    }

    return true;
  }

  setFurnitureMaterial(componentKey: ProductComponentKey, data?: Material): boolean {
    if (!componentKey || !this._validComponentKeysOf.furniture.includes(componentKey)) {
      // Невідома складова
      throw new InvalidComponentKey(componentKey, 'furniture');
    }
    if (typeof data !== 'undefined' && !['furniture', 'curtainRod', 'freeform'].includes(data.type)) {
      // Наданий матеріал неможливо встановити в якості бажаної складової
      return false;
    }

    const material = typeof data !== 'undefined' ? itemCloneDeep(data) : undefined;
    let isUnitChanged = false;
    if (material?.unit) {
      const mu = this.design[componentKey];
      isUnitChanged = !!mu && mu.unitToBuy !== material.unit;
    }
    // Якщо одиниця виміру матеріалу змінилась
    const usage =
      material && isUnitChanged
        ? {
            // важливо синхронізувати одиницю виміру для купівлі в об'єкті використання
            unitToBuy: material.unit,
            // кількість у придбання варто очищувати, щоб уникнути розбіжності значення з новими одиницями виміру
            amountToBuy: undefined,
            // відповідно сумарна вартість також має бути обчислена заново
            totalPrice: undefined,
          }
        : null;
    if (usage && !['grommet'].includes(componentKey)) this.setDesign(componentKey, usage);

    switch (componentKey) {
      case 'grommet':
        this.composition.grommet = material;
        if (usage) this.setGrommetDesign(usage);
        break;
      case 'sRings':
        this.composition.sRings = material;
        break;
      case 'magnets':
        this.composition.magnets = material;
        break;
      case 'zipper':
        this.composition.zipper = material;
        break;
      case 'syntheticFluff':
        this.composition.syntheticFluff = material;
        break;
      case 'sintepon':
        this.composition.sintepon = material;
        break;

      default:
        throw new InvalidComponentKey(componentKey, 'furniture');
    }

    return true;
  }

  setCurtainRodMaterial(componentKey: ProductComponentKey, data?: Material): boolean {
    if (!componentKey || !this._validComponentKeysOf.curtainRod.includes(componentKey)) {
      // Невідома складова
      throw new InvalidComponentKey(componentKey, 'curtainRod');
    }
    if (typeof data !== 'undefined' && !['curtainRod', 'freeform'].includes(data.type)) {
      // Наданий матеріал неможливо встановити в якості бажаної складової
      return false;
    }

    const material = typeof data !== 'undefined' ? itemCloneDeep(data) : undefined;
    let isUnitChanged = false;
    if (material?.unit) {
      const mu = this.design[componentKey];
      isUnitChanged = !!mu && mu.unitToBuy !== material.unit;
    }
    // Якщо одиниця виміру матеріалу змінилась
    const usage =
      material && isUnitChanged
        ? {
            // важливо синхронізувати одиницю виміру для купівлі в об'єкті використання
            unitToBuy: material.unit,
            // кількість у придбання варто очищувати, щоб уникнути розбіжності значення з новими одиницями виміру
            amountToBuy: undefined,
            // відповідно сумарна вартість також має бути обчислена заново
            totalPrice: undefined,
          }
        : null;
    if (
      usage &&
      !['romanBlindSystem', 'curtainRod', 'curtainRod2', 'curtainProfile', 'curtainProfile2'].includes(componentKey)
    )
      this.setDesign(componentKey, usage);

    switch (componentKey) {
      case 'romanBlindSystem':
        this.composition.romanBlindSystem = material;
        if (usage) this.setRomanBlindSystemDesign(usage);
        break;
      case 'sticks':
        this.composition.sticks = material;
        break;
      case 'fixation':
        this.composition.fixation = material;
        break;
      case 'weightingAgent':
        this.composition.weightingAgent = material;
        break;
      case 'chain':
        this.composition.chain = material;
        break;
      case 'chainWeighter':
        this.composition.chainWeighter = material;
        break;
      case 'miniCurtainRod':
        this.composition.miniCurtainRod = material;
        break;
      case 'curtainRod':
        this.composition.curtainRod = material;
        if (usage) this.setCurtainRodDesign(usage);
        break;
      case 'curtainRod2':
        this.composition.curtainRod2 = material;
        if (usage) this.setCurtainRod2Design(usage);
        break;
      case 'curtainProfile':
        this.composition.curtainProfile = material;
        if (usage) this.setCurtainProfileDesign(usage);
        break;
      case 'curtainProfile2':
        this.composition.curtainProfile2 = material;
        if (usage) this.setCurtainProfile2Design(usage);
        break;
      case 'handpiece':
        this.composition.handpiece = material;
        break;
      case 'handpiece2':
        this.composition.handpiece2 = material;
        break;
      case 'cap':
        this.composition.cap = material;
        break;
      case 'cap2':
        this.composition.cap2 = material;
        break;
      case 'stopper':
        this.composition.stopper = material;
        break;
      case 'stopper2':
        this.composition.stopper2 = material;
        break;
      case 'pipeConnector':
        this.composition.pipeConnector = material;
        break;
      case 'pipeConnector2':
        this.composition.pipeConnector2 = material;
        break;
      case 'clipsFasten':
        this.composition.clipsFasten = material;
        break;
      case 'bracketFasten':
        this.composition.bracketFasten = material;
        break;
      case 'wallBracket':
        this.composition.wallBracket = material;
        break;
      case 'bracketExtension':
        this.composition.bracketExtension = material;
        break;
      case 'hookSigma':
        this.composition.hookSigma = material;
        break;
      case 'runners':
        this.composition.runners = material;
        break;
      case 'runners2':
        this.composition.runners2 = material;
        break;
      case 'rings4CurtainRod':
        this.composition.rings4CurtainRod = material;
        break;
      case 'rings4CurtainRod2':
        this.composition.rings4CurtainRod2 = material;
        break;
      case 'motor':
        this.composition.motor = material;
        break;
      case 'thirdPipeHolder':
        this.composition.thirdPipeHolder = material;
        break;
      case 'bendingCurtainRod':
        this.composition.bendingCurtainRod = material;
        break;
      case 'lambrequinLath':
        this.composition.lambrequinLath = material;
        break;

      default:
        throw new InvalidComponentKey(componentKey, 'curtainRod');
    }

    return true;
  }

  setAccessoryMaterial(componentKey: ProductComponentKey, data?: Material): boolean {
    if (!componentKey || !this._validComponentKeysOf.accessory.includes(componentKey)) {
      // Невідома складова
      throw new InvalidComponentKey(componentKey, 'accessory');
    }
    if (typeof data !== 'undefined' && !['curtainRod', 'freeform'].includes(data.type)) {
      // Наданий матеріал неможливо встановити в якості бажаної складової
      return false;
    }

    const material = typeof data !== 'undefined' ? itemCloneDeep(data) : undefined;
    let isUnitChanged = false;
    if (material?.unit) {
      const mu = this.design[componentKey];
      isUnitChanged = !!mu && mu.unitToBuy !== material.unit;
    }
    // Якщо одиниця виміру матеріалу змінилась
    const usage =
      material && isUnitChanged
        ? {
            // важливо синхронізувати одиницю виміру для купівлі в об'єкті використання
            unitToBuy: material.unit,
            // кількість у придбання варто очищувати, щоб уникнути розбіжності значення з новими одиницями виміру
            amountToBuy: undefined,
            // відповідно сумарна вартість також має бути обчислена заново
            totalPrice: undefined,
          }
        : null;
    if (usage) this.setDesign(componentKey, usage);

    switch (componentKey) {
      case 'hookDrapery':
        this.composition.hookDrapery = material;
        break;
      case 'rosette':
        this.composition.rosette = material;
        break;
      case 'pin':
        this.composition.pin = material;
        break;
      case 'tieBack':
        this.composition.tieBack = material;
        break;
      case 'curtainGuides':
        this.composition.curtainGuides = material;
        break;
      case 'powerSupplyUnit':
        this.composition.powerSupplyUnit = material;
        break;
      case 'charger':
        this.composition.charger = material;
        break;
      case 'remoteControlUnit':
        this.composition.remoteControlUnit = material;
        break;
      case 'smartHub':
        this.composition.smartHub = material;
        break;

      default:
        throw new InvalidComponentKey(componentKey, 'accessory');
    }

    return true;
  }

  setFulfilledProductMaterial(componentKey: ProductComponentKey, data?: FulfilledProductMaterials): boolean {
    if (!componentKey || !this._validComponentKeysOf.productFulfillment.includes(componentKey)) {
      // Невідома складова
      throw new InvalidComponentKey(componentKey, 'productFulfillment');
    }
    if (typeof data !== 'undefined' && !('systemType' in data)) {
      // Наданий матеріал неможливо встановити в якості бажаної складової
      return false;
    }

    const material = typeof data !== 'undefined' ? itemCloneDeep(data) : undefined;
    // При встановленні нової складової типу стандартизованого виробу, важливо аби був заданий об'єкт використання.
    // Використання у таких виробів завжди 1 'шт.'
    const usage = { amount: 1, unitToBuy: 'шт.' as AmountUnit };

    switch (componentKey) {
      case 'standardRomanBlind':
        this.composition.standardRomanBlind = material;
        this.setStandardRomanBlindDesign(usage);
        break;
      case 'standardRomanBlindSecondary':
        this.composition.standardRomanBlindSecondary = material;
        this.setDesign('standardRomanBlindSecondary', usage);
        break;
      case 'standardRomanBlindDoubleSystem':
        this.composition.standardRomanBlindDoubleSystem = material;
        this.setDesign('standardRomanBlindDoubleSystem', usage);
        break;
      case 'verticalJalousie':
        this.composition.verticalJalousie = material;
        this.setVerticalJalousieDesign(usage);
        break;
      case 'textileRollerBlind':
        this.composition.textileRollerBlind = material;
        this.setTextileRollerBlindDesign(usage);
        break;

      default:
        throw new InvalidComponentKey(componentKey, 'productFulfillment');
    }

    return true;
  }

  setMaterial(componentKey: ProductComponentKey, data?: Material | FulfilledProductMaterials): boolean {
    if (!componentKey) {
      // Невідома складова
      throw new InvalidComponentKey(`(empty-${typeof componentKey})`, 'none');
    }

    if (
      typeof data === 'undefined' ||
      (data &&
        'type' in data &&
        typeof data.type === 'string' &&
        ['textile', 'webbing', 'furniture', 'curtainRod', 'freeform'].includes(data.type))
    ) {
      // Маємо об'єкт матеріалу на вході
      if (this._validComponentKeysOf.textile.includes(componentKey)) {
        return this.setTextileMaterial(componentKey, data as Material | undefined);
      }
      if (this._validComponentKeysOf.webbing.includes(componentKey)) {
        return this.setWebbingMaterial(componentKey, data as Material | undefined);
      }
      if (this._validComponentKeysOf.furniture.includes(componentKey)) {
        return this.setFurnitureMaterial(componentKey, data as Material | undefined);
      }
      if (this._validComponentKeysOf.accessory.includes(componentKey)) {
        return this.setAccessoryMaterial(componentKey, data as Material | undefined);
      }
      if (this._validComponentKeysOf.curtainRod.includes(componentKey)) {
        return this.setCurtainRodMaterial(componentKey, data as Material | undefined);
      }
    }

    if (this._validComponentKeysOf.productFulfillment.includes(componentKey)) {
      // Тип системи є обов'язковою властивістю серед наявних опцій
      if (typeof data === 'undefined' || (data && 'systemType' in data && typeof data.systemType === 'string')) {
        // Маємо об'єкт опцій стандартизованого виробу на вході
        return this.setFulfilledProductMaterial(componentKey, data as FulfilledProductMaterials | undefined);
      }
      // Дійти до цього місця можна через не дійсні дані
      throw new InvalidDataError('FulfilledProductMaterials');
    }

    // Дійти до цього місця можна через не дійсний ключ складової або об'єкт даних невідомого типу
    throw new InvalidComponentKey(componentKey, 'all');
  }

  generateFreeformMaterial(): Material {
    return {
      id: uuid(),
      name: '',
      sku: '',
      supplierSku: '',
      unit: 'шт.',
      price: 0,
      supplierName: '',
      supplierId: undefined,
      type: 'freeform',
    };
  }

  setFreeformComponentDesign(index: number, data: Partial<MaterialUsage> | undefined): number {
    // Перевірити чи дійсний індекс складової або визначити яким має бути новий
    let realIndex = 0;
    let isNew = false;
    if (this.design.freeformComponents && index >= this.design.freeformComponents.length) {
      throw new InvalidFreeformComponentIndex(index);
    } else if (index === -1) {
      isNew = true;
      if (this.design.freeformComponents) {
        realIndex = this.design.freeformComponents.length;
      }
    } else {
      realIndex = index;
    }

    // Виконати зміну даних
    const mu =
      !isNew && Array.isArray(this.design.freeformComponents) ? this.design.freeformComponents[realIndex] : undefined;
    if (typeof data !== 'undefined') {
      // На вході об'єкт змін
      if (typeof mu !== 'undefined') {
        // Складова вже має певне значення
        this.setFreeformComponentDesignByIndex(realIndex, { ...mu, ...data, required: false });
      } else {
        // Складова була відсутньою
        this.setFreeformComponentDesignByIndex(realIndex, { ...this._defaultAccessoryUsage, ...data });
      }
    } else {
      // Видалення дизайну та матеріалу необов'язкової складової
      if (!isNew) this.setFreeformComponentDesignByIndex(realIndex, undefined);
      return -1;
    }

    return realIndex;
  }

  /**
   * Встановити дійсне значення параметрів використання довільного виробу
   * або взагалі видалити компонент за наданим індексом. Метод виконує перевірку
   * існування масивів freeformComponents і у випадку їх відсутності ініціалізує з наданим першим елементом.
   * @param index індекс довільної складової виробу (завжди дійсний)
   * @param data об'єкт кількісних показників складового матеріалу або `undefined`
   * @returns Індекс зміненої або нової довільної складової виробу. `-1` у випадку проведення операції видалення.
   */
  private setFreeformComponentDesignByIndex(index: number, data: MaterialUsage | undefined): number {
    let outIndex = index;
    if (data !== undefined) {
      if (Array.isArray(this.design.freeformComponents) && Array.isArray(this.composition.freeformComponents)) {
        this.design.freeformComponents[index] = data;
        if (this.composition.freeformComponents[index] === undefined) {
          // У випадку додавання нових складових одразу потрібно створити матеріал за умовчанням
          this.composition.freeformComponents[index] = this.generateFreeformMaterial();
        }
      } else {
        // Додаємо першу довільну складову у виріб, коли масив ще не був визначений
        this.design.freeformComponents = [data];
        // Встановити пустий матеріал за умовчанням
        this.composition.freeformComponents = [this.generateFreeformMaterial()];
        outIndex = 0;
      }
    } else if (Array.isArray(this.design.freeformComponents) && Array.isArray(this.composition.freeformComponents)) {
      if (index === 0) {
        this.design.freeformComponents.shift();
        this.composition.freeformComponents.shift();
      } else if (index === this.design.freeformComponents.length - 1) {
        this.design.freeformComponents.pop();
        this.composition.freeformComponents.pop();
      } else {
        this.design.freeformComponents.splice(index, 1);
        this.composition.freeformComponents.splice(index, 1);
      }
      outIndex = -1;
    }

    return outIndex;
  }

  setFreeformComponentMaterial(index: number, data: Material): number {
    // Перевірити чи дійсний індекс складової
    if (
      index < 0 ||
      !Array.isArray(this.composition.freeformComponents) ||
      index >= this.composition.freeformComponents.length
    ) {
      throw new InvalidFreeformComponentIndex(index);
    }

    if (typeof data === 'undefined' || !data.unit || !data.type) return -1;

    const material = itemCloneDeep(data);
    const mu = this.getFreeformComponentDesign(index);
    let usage = {};
    const isUnitChanged = mu.unitToBuy !== material.unit;
    // Якщо одиниця виміру матеріалу змінилась
    if (isUnitChanged) {
      usage = {
        // важливо синхронізувати одиницю виміру для купівлі в об'єкті використання
        unitToBuy: material.unit,
        // кількість у придбання варто очищувати, щоб уникнути розбіжності значення з новими одиницями виміру
        amountToBuy: undefined,
        // відповідно сумарна вартість також має бути обчислена заново
        totalPrice: undefined,
      };
    }

    // Виконати зміну даних
    this.composition.freeformComponents[index] = material;
    if (isUnitChanged) {
      const designIndex = this.setFreeformComponentDesign(index, usage);
      if (designIndex !== index) {
        throw new Error(`Unsynchronized freeformComponents arrays of composition[${index}] & design[${designIndex}]`);
      }
    }

    return index;
  }

  countFreeformComponents(): number {
    return Array.isArray(this.design.freeformComponents) ? this.design.freeformComponents.length : 0;
  }

  getFreeformComponentDesign(index: number): MaterialUsage {
    // Перевірити чи дійсний індекс складової
    if (index < 0 || !Array.isArray(this.design.freeformComponents) || index >= this.design.freeformComponents.length) {
      throw new InvalidFreeformComponentIndex(index);
    }

    return this.design.freeformComponents[index];
  }

  getFreeformComponentMaterial(index: number): Material {
    // Перевірити чи дійсний індекс складової
    if (
      index < 0 ||
      !Array.isArray(this.composition.freeformComponents) ||
      index >= this.composition.freeformComponents.length
    ) {
      throw new InvalidFreeformComponentIndex(index);
    }

    return this.composition.freeformComponents[index];
  }

  getFreeformComponentCalcResult(index: number): FreeformComponentCalcResult | null {
    // Перевірити чи існує об'єкт результатів обчислення
    if (index < 0 || !this.calcResult || !this.calcResult.data) {
      return null;
    }

    // Перевірити чи дійсний індекс складової
    if (
      index < 0 ||
      !Array.isArray(this.calcResult.data.freeformComponents) ||
      index >= this.calcResult.data.freeformComponents.length
    ) {
      throw new InvalidFreeformComponentIndex(index);
    }

    return this.calcResult.data.freeformComponents[index];
  }

  setService(serviceKey: ServiceKey, data: number | undefined): void {
    if (!serviceKey) {
      // Не вказано ключ послуги
      throw new InvalidServiceKey(`(empty-${typeof serviceKey})`);
    }

    if (serviceKey === 'customServices') {
      // Не вказано ключ послуги
      throw new InvalidServiceKey(`setService() can't be used to set customServices`);
    }

    const clearServiceVariants = (activeServices: ServiceKey[]) => {
      activeServices.forEach(key => {
        if (key !== 'customServices') this.serviceOptions[key] = undefined;
      });
    };

    if (typeof data === 'undefined' || typeof data === 'number') {
      // Маємо очікуване значення на вході
      const currentServices = this.servicesOverview();
      if (this._validServiceKeysOf.sewing.includes(serviceKey)) {
        // Додаткові дії у випадку послуги певного виду
        // Якщо встановлюється новий варіант послуги, коли в групі вже був встановлений інакший — видалити його
        if (currentServices.sewing.length > 0 && !currentServices.sewing.includes(serviceKey))
          clearServiceVariants(currentServices.sewing);
        // Встановлений вручну варіант пошиття впливає на індикатор складності
        this.design.isComplexSewing = serviceKey.includes('Complex');
      } else if (this._validServiceKeysOf.hanging.includes(serviceKey)) {
        // Додаткові дії у випадку послуги певного виду
        // Якщо встановлюється новий варіант послуги, коли в групі вже був встановлений інакший — видалити його
        if (currentServices.hanging.length > 0 && !currentServices.hanging.includes(serviceKey))
          clearServiceVariants(currentServices.hanging);
      } else if (this._validServiceKeysOf.mount.includes(serviceKey)) {
        // Додаткові дії у випадку послуги певного виду
        // Якщо встановлюється новий варіант послуги, коли в групі вже був встановлений інакший — видалити його
        if (currentServices.mount.length > 0 && !currentServices.mount.includes(serviceKey))
          clearServiceVariants(currentServices.mount);
      } else if (this._validServiceKeysOf.disassembling.includes(serviceKey)) {
        // Додаткові дії у випадку послуги певного виду
        // Якщо встановлюється новий варіант послуги, коли в групі вже був встановлений інакший — видалити його
        if (currentServices.disassembling.length > 0 && !currentServices.disassembling.includes(serviceKey))
          clearServiceVariants(currentServices.disassembling);
      } else {
        // Не дійсний ключ послуги
        throw new InvalidServiceKey(serviceKey);
      }
      // Встановити нове значення
      this.serviceOptions[serviceKey] = data;
      return;
    }

    // Дійти до цього місця можливо лише через не дійсне значення даних
    throw new InvalidDataError('number | undefined');
  }

  getService(serviceKey: ServiceKey): number | undefined {
    if (!serviceKey) {
      // Не вказано ключ послуги
      throw new InvalidServiceKey(`(empty-${typeof serviceKey})`);
    }

    if (serviceKey === 'customServices') {
      // Не вказано ключ послуги
      throw new InvalidServiceKey(`getService() can't be used to get customServices`);
    }

    if (!this.validServiceKeys().includes(serviceKey)) {
      // Не дійсний ключ послуги
      throw new InvalidServiceKey(serviceKey);
    }

    const value = this.serviceOptions[serviceKey];
    return value && +value ? +value : undefined;
  }

  hangingComplex(newState?: boolean): boolean {
    // Встановити нове значення
    if (typeof newState !== 'undefined') {
      this.serviceOptions.hangingComplex = newState ? 1 : undefined;
    }

    return !!this.serviceOptions.hangingComplex;
  }

  setCombinedServiceComplexity(
    serviceGroupKey: Extract<ServiceGroupKey, 'sewing' | 'hanging'>,
    data: [boolean, boolean],
  ): void {
    // Ігнорувати спроби вимкнути одразу обидва ключі складності
    // адже загальна складність регулюється обраною послугою
    if (!data[0] && !data[1]) return;
    // Визначити чи дійсний ключ складової та чи підтримує комбіновану складність
    if (serviceGroupKey === 'sewing' && this.serviceOptions.combinedSewingComplexity) {
      this.serviceOptions.combinedSewingComplexity.isPrimaryComplex = data[0];
      this.serviceOptions.combinedSewingComplexity.isSecondaryComplex = data[1];
    } else if (serviceGroupKey === 'hanging' && this.serviceOptions.combinedHangingComplexity) {
      this.serviceOptions.combinedHangingComplexity.isPrimaryComplex = data[0];
      this.serviceOptions.combinedHangingComplexity.isSecondaryComplex = data[1];
    } else throw new InvalidServiceGroupKey(serviceGroupKey);
  }

  generateCustomService(authorId: string): CustomService {
    return {
      id: uuid(),
      name: '',
      price: 0,
      authorId: authorId ?? '',
      created: new Date().toISOString(),
    };
  }

  countCustomServices(): number {
    return Array.isArray(this.serviceOptions?.customServices) ? this.serviceOptions.customServices.length : 0;
  }

  setCustomService(id: string, data: Partial<CustomService> | undefined): string {
    // Перевірити чи дійсний ідентифікатор послуги або визначити яким має бути новий
    let realIndex = 0;
    let isNew = false;
    let serviceId = '';
    if (
      typeof id !== 'string' ||
      (id.length > 0 &&
        (!Array.isArray(this.serviceOptions?.customServices) ||
          this.serviceOptions.customServices.length === 0 ||
          this.serviceOptions.customServices.findIndex(service => service.id === id) < 0))
    ) {
      throw new InvalidCustomServiceId(id);
    } else if (id === '') {
      isNew = true;
      realIndex = this.serviceOptions.customServices.length;
    } else {
      realIndex = this.serviceOptions.customServices.findIndex(service => service.id === id);
    }

    // Виконати зміну даних
    const service = !isNew ? this.serviceOptions.customServices[realIndex] : undefined;
    if (typeof data !== 'undefined') {
      // На вході об'єкт змін
      if (typeof service !== 'undefined') {
        // Послуга вже має певне значення
        serviceId = service.id ?? '';
        this.setCustomServiceByIndex(realIndex, { ...service, ...data });
      } else {
        // Послуга була відсутньою
        if (
          typeof data.id !== 'string' ||
          data.id.length === 0 ||
          typeof data.name !== 'string' ||
          typeof data.authorId !== 'string' ||
          typeof data.created !== 'string' ||
          typeof data.price !== 'number'
        ) {
          throw new Error("Додавати можливо лише повний об'єкт послуги. Надано частковий.");
        }
        serviceId = data.id;
        this.setCustomServiceByIndex(realIndex, {
          id: '',
          name: '',
          price: 0,
          authorId: '',
          created: '',
          ...data,
        });
      }
    } else if (!isNew) {
      // Видалення довільної послуги
      this.setCustomServiceByIndex(realIndex, undefined);
    }

    return serviceId;
  }

  /**
   * Встановити дійсне значення довільної послуги у виробі
   * або взагалі видалити послугу за наданим індексом.
   * @param index індекс довільної послуги у виробі (завжди дійсний)
   * @param data об'єкт довільної послуги або `undefined`
   * @returns Індекс зміненої або нової довільної послуги у виробі. `-1` у випадку проведення операції видалення.
   */
  private setCustomServiceByIndex(index: number, data: CustomService | undefined): number {
    let outIndex = index;
    if (data !== undefined) {
      this.serviceOptions.customServices[index] = data;
    } else {
      // Видалення
      if (index === 0) {
        this.serviceOptions.customServices.shift();
      } else if (index === this.serviceOptions.customServices.length - 1) {
        this.serviceOptions.customServices.pop();
      } else {
        this.serviceOptions.customServices.splice(index, 1);
      }
      outIndex = -1;
    }

    return outIndex;
  }

  getCustomService(id: string): CustomService | undefined {
    if (typeof id !== 'string' || id.length === 0) return undefined;
    return this.serviceOptions.customServices.find(service => service.id === id);
  }

  setNote(addresseeType: string, note?: string): boolean {
    if (typeof addresseeType !== 'string' || addresseeType.length === 0) {
      return false;
    }

    if (note === undefined) {
      this.notes[addresseeType] = '';
    } else if (typeof note === 'string') {
      this.notes[addresseeType] = note;
    } else {
      return false;
    }

    return true;
  }

  getNote(addresseeType: string): string {
    if (typeof addresseeType !== 'string' || addresseeType.length === 0) {
      return '';
    }

    return this.notes[addresseeType] ?? '';
  }

  setCalculation(data?: ProductCalcResult): boolean {
    if (typeof data === 'undefined') {
      // Очистити поточне значення калькуляції в даних виробу
      this.calcResult = undefined;
      return true;
    }

    // Перевірити чи отриманий об'єкт дійсно є результатом обчислення (в т.ч. не успішного)
    if (
      data === null ||
      typeof data.price !== 'string' ||
      typeof data.data === 'undefined' ||
      typeof data.priceError !== 'boolean'
    ) {
      throw new InvalidDataError('ProductCalcResult');
    }

    // Зберегти дані в об'єкт виробу
    this.calcResult = itemCloneDeep(data);

    if (this.calcResult.priceError || this.calcResult.data === null) {
      // Власне числові дані відсутні (у т.ч. через помилку) - нічого імплементувати
      return true;
    }

    const calcData = this.calcResult.data;
    // Перевірити об'єкт калькуляції на відповідність очікуваному типу
    // TODO: Додати більше критеріїв перевірки після зміни опціональності деяких властивостей на обов'язковість.
    if (typeof calcData.result !== 'number') {
      throw new InvalidDataError('CalcResult');
    }

    /* Імплементація результатів обчислення в дані виробу */

    // TODO: Перевірити масив calcData.noticeInvalidData з ключами складових об'єкту виробу, значень яких бракує або вони не дійсні для обчислення

    // Використання специфічних складових
    const specificComponentKeys = this.validSpecificComponentKeys();
    specificComponentKeys.forEach(componentKey => {
      const componentUsage = this.design[componentKey];
      if (componentUsage === undefined) return;

      const amount = calcData[`${componentKey}Amount` as keyof CalcResult] as number | undefined;
      const amountToBuy = calcData[`${componentKey}AmountToBuy` as keyof CalcResult] as number | undefined;
      const price = calcData[`${componentKey}Price` as keyof CalcResult] as number | undefined;
      componentUsage.amount = typeof amount === 'number' ? amount : -1;
      componentUsage.amountToBuy = typeof amountToBuy === 'number' ? amountToBuy : undefined;
      componentUsage.totalPrice = typeof price === 'number' ? price : undefined;
    });

    // TODO: Врахувати можливу примусову зміну матеріалу складової `romanBlindSystem` згідно обраного в ході калькуляції варіанту за розміром `selectedRomanBlindSystem`

    // Використання довільних складових
    const freeformComponentsCount = this.countFreeformComponents();
    if (
      freeformComponentsCount > 0 &&
      Array.isArray(calcData.freeformComponents) &&
      calcData.freeformComponents.length === freeformComponentsCount &&
      this.design.freeformComponents
    ) {
      for (let i = 0; i < freeformComponentsCount; i++) {
        const ffc = calcData.freeformComponents[i];
        this.design.freeformComponents[i].amount = typeof ffc.amount === 'number' ? ffc.amount : -1;
        this.design.freeformComponents[i].amountToBuy =
          typeof ffc.amountToBuy === 'number' ? ffc.amountToBuy : undefined;
        this.design.freeformComponents[i].totalPrice = typeof ffc.totalPrice === 'number' ? ffc.totalPrice : undefined;
      }
    }

    // Врахувати можливі примусові зміни налаштувань виробу
    if (calcData.enforcedProductModifications) {
      const { composition, design, serviceOptions } = calcData.enforcedProductModifications;
      if (composition) {
        // TODO: Примусові зміни в матеріали
        // eslint-disable-next-line no-console
        console.log('TODO: Примусові зміни в матеріали', composition);
      }
      if (design) {
        // Примусові зміни в дизайн
        if (design.isComplexSewing !== undefined) this.design.isComplexSewing = design.isComplexSewing;
        if (design.widthSecondary !== undefined) this.design.widthSecondary = design.widthSecondary;
        if (design.heightSecondary !== undefined) this.design.heightSecondary = design.heightSecondary;
        if (design.heightAmountToBuy !== undefined) this.design.heightAmountToBuy = design.heightAmountToBuy;
        if (design.nonStandardForm !== undefined) this.design.nonStandardForm = design.nonStandardForm;
        if (design.clipsFasten !== undefined) this.setDesign('clipsFasten', design.clipsFasten);
        if (design.bracketFasten !== undefined) this.setDesign('bracketFasten', design.bracketFasten);
        if (design.bracketExtension !== undefined) this.setDesign('bracketExtension', design.bracketExtension);
        if (design.productionOptions !== undefined) {
          this.design.productionOptions = {
            ...(this.design.productionOptions || {}),
            ...design.productionOptions,
          };
        }
      }
      if (serviceOptions) {
        // Примусові зміни в послуги
        if (serviceOptions.hangingComplex !== undefined)
          this.serviceOptions.hangingComplex = serviceOptions.hangingComplex;

        if (serviceOptions.combinedHangingComplexity !== undefined)
          this.serviceOptions.combinedHangingComplexity = serviceOptions.combinedHangingComplexity;

        if (serviceOptions.mountCurtainRodComplex !== undefined) {
          this.serviceOptions.mountCurtainRodComplex = serviceOptions.mountCurtainRodComplex;
          this.serviceOptions.mountCurtainRod = undefined;
        }
        if (serviceOptions.mountRomanBlindSystemComplex !== undefined) {
          this.serviceOptions.mountRomanBlindSystemComplex = serviceOptions.mountRomanBlindSystemComplex;
          this.serviceOptions.mountRomanBlindSystem = undefined;
        }
        if (serviceOptions.mountRomanBlindInhortComplex !== undefined) {
          this.serviceOptions.mountRomanBlindInhortComplex = serviceOptions.mountRomanBlindInhortComplex;
          this.serviceOptions.mountRomanBlindInhort = undefined;
        }
        if (serviceOptions.mountRollerBlindSystemComplex !== undefined) {
          this.serviceOptions.mountRollerBlindSystemComplex = serviceOptions.mountRollerBlindSystemComplex;
          this.serviceOptions.mountRollerBlindSystem = undefined;
        }
        if (serviceOptions.mountJalousieSystemComplex !== undefined) {
          this.serviceOptions.mountJalousieSystemComplex = serviceOptions.mountJalousieSystemComplex;
          this.serviceOptions.mountJalousieSystem = undefined;
        }
      }
    }

    // Обчислена вартість послуг
    this._validServiceGroupKeys.forEach(groupKey => {
      const services = this._validServiceKeysOf[groupKey].filter(
        serviceKey =>
          serviceKey !== 'customServices' &&
          this.serviceOptions[serviceKey] !== undefined &&
          !(groupKey === 'hanging' && serviceKey === 'hangingComplex'),
      );
      if (services.length > 0 && services[0] !== 'customServices' && !services[0].includes('Custom')) {
        const price = calcData[`${groupKey}Price` as keyof CalcResult] as number | undefined;
        this.serviceOptions[services[0]] = typeof price === 'number' ? price : -1;
      }
    });

    // Обчислена вартість частин послуги при комбінованій складності
    if (this.serviceOptions.combinedSewingComplexity) {
      this.serviceOptions.combinedSewingComplexity.primaryPrice = calcData.primarySewingPrice;
      this.serviceOptions.combinedSewingComplexity.secondaryPrice = calcData.secondarySewingPrice;
    }
    if (this.serviceOptions.combinedHangingComplexity) {
      this.serviceOptions.combinedHangingComplexity.primaryPrice = calcData.primaryHangingPrice;
      this.serviceOptions.combinedHangingComplexity.secondaryPrice = calcData.secondaryHangingPrice;
    }

    return true;
  }

  /* Допоміжні методи для отримання та редагування даних виробу */

  overview(): ProductOverview {
    return {
      specificComponentsList: this.validSpecificComponentKeys().filter(componentKey => !!this.design[componentKey]),
      freeformComponentsCount: this.countFreeformComponents(),
      services: this.servicesOverview(),
      calculation: this.calcResult ?? null,
    };
  }

  servicesOverview(): ServicesOverview {
    const services: ServicesOverview = {
      activeList: [],
      sewing: [],
      hanging: [],
      mount: [],
      disassembling: [],
      custom: [],
    };
    this._validServiceGroupKeys.forEach(groupKey => {
      if (groupKey === 'custom' && Array.isArray(this.serviceOptions?.customServices)) {
        services[groupKey] = this.serviceOptions?.customServices.map(service => service.id ?? '');
      } else {
        services[groupKey] = this.validServiceKeys(groupKey).filter(
          serviceKey => this.serviceOptions[serviceKey] !== undefined,
        );
      }
      if (services[groupKey].length > 0) services.activeList.push(groupKey);
    });

    return services;
  }

  getComponentRow(componentKey: ProductComponentKey | 'freeformComponents', index?: number): ComponentRow {
    // Визначити чи дійсний ключ складової та якого вона різновиду
    let rowType: number;
    let compositionGroup: ProductCompositionGroup;
    if (componentKey === 'freeformComponents') {
      rowType = 5;
      compositionGroup = 'materials';
    } else if (
      this._validComponentKeysOf.textile.includes(componentKey) ||
      this._validComponentKeysOf.webbing.includes(componentKey)
    ) {
      rowType = 1;
      compositionGroup = 'materials';
    } else if (this._validComponentKeysOf.furniture.includes(componentKey)) {
      rowType = 2;
      compositionGroup = 'materials';
    } else if (this._validComponentKeysOf.accessory.includes(componentKey)) {
      rowType = 2;
      compositionGroup = 'accessories';
    } else if (this._validComponentKeysOf.curtainRod.includes(componentKey)) {
      rowType = 3;
      compositionGroup = 'system';
    } else if (this._validComponentKeysOf.productFulfillment.includes(componentKey)) {
      rowType = 4;
      compositionGroup = 'materials';
    } else {
      // Не дійсний ключ складової
      throw new InvalidComponentKey(componentKey, 'all');
    }

    // П'ятий різновид складової — довільна
    if (rowType === 5) {
      const row: ComponentRow = {
        componentKind: 'freeform',
        componentKey,
        compositionGroup,
        fieldKeys: [
          'supplier',
          'brand',
          'sku',
          'colour',
          'amount',
          'unit',
          'amountToBuy',
          'unitToBuy',
          'rollAmount',
          'packageAmount',
          'price',
          'totalPrice',
        ],
        materialKind: 'freeform',
        supplier: {
          type: 'supplier',
          supplierName: undefined,
          supplierId: undefined,
        },
        brand: {
          type: 'brand',
          currentValue: '',
        },
        sku: {
          type: 'sku',
          currentValue: '',
        },
        colour: {
          type: 'colour',
          currentValue: '',
        },
        description: {
          type: 'description',
          currentValue: '',
        },
        amount: {
          type: 'amount',
          currentValue: -1,
          isManualAmount: true,
          manualAmountAuthor: '',
        },
        unit: {
          type: 'unit',
          currentValue: 'шт.',
          options: ['шт.', 'м.п.', 'уп.', 'рулон', 'кг', 'комплект'],
        },
        amountToBuy: {
          type: 'amountToBuy',
          currentValue: -1,
        },
        unitToBuy: {
          type: 'unitToBuy',
          currentValue: 'шт.',
          options: ['шт.', 'м.п.', 'уп.', 'рулон', 'кг', 'комплект'],
        },
        rollAmount: {
          type: 'rollAmount',
          currentValue: null,
        },
        packageAmount: {
          type: 'packageAmount',
          currentValue: null,
        },
        price: {
          type: 'price',
          currentValue: 0,
        },
        totalPrice: {
          type: 'totalPrice',
          currentValue: 0,
          isManualPrice: false,
          manualPriceAuthor: '',
        },
      };

      // Відсутність index для довільних складових значить запит на ряд створення нової
      if (index === undefined) {
        return row;
      }

      const material = this.getFreeformComponentMaterial(index);
      const usage = this.getFreeformComponentDesign(index);
      if (material) {
        row.materialKind = material.type;
        row.supplier.supplierId = material.supplierId;
        row.supplier.supplierName = material.supplierName;
        row.brand.currentValue = material.brand ?? '';
        row.sku.currentValue = material.sku;
        row.colour.currentValue = material.colour ?? '';
        row.description.currentValue = material.description ?? '';
        // При наявному матеріалі одиниці в купівлю синхронізуються з одиницями продажу
        row.unitToBuy.currentValue = material.unit ? (material.unit as AmountUnit) : row.unitToBuy.options[0];
        row.rollAmount.currentValue = material.rollAmount ?? null;
        row.packageAmount.currentValue = material.packageAmount ?? null;
        row.price.currentValue = +material.price;
      }
      if (usage) {
        row.amount.currentValue = usage.amount;
        row.amount.isManualAmount = !!usage.isManualAmount;
        row.amount.manualAmountAuthor = usage.manualAmountAuthor ?? '';
        row.unit.currentValue = usage.unit ? (usage.unit as AmountUnit) : row.unit.options[0];
        row.amountToBuy.currentValue = usage.amountToBuy ? usage.amountToBuy : -1;
        // Якщо usage.unitToBuy чітко визначено але матеріал відсутній
        row.unitToBuy.currentValue =
          usage.unitToBuy && !material?.unit ? (usage.unitToBuy as AmountUnit) : row.unitToBuy.currentValue;
        row.totalPrice.currentValue = usage.totalPrice ? +usage.totalPrice : 0;
        row.totalPrice.isManualPrice = !!usage.isManualPrice;
        row.totalPrice.manualPriceAuthor = usage.manualPriceAuthor ?? '';
        if (typeof usage.compositionGroup === 'string' && usage.compositionGroup !== 'materials')
          row.compositionGroup = usage.compositionGroup;
      }
      return row;
    }

    // Четвертий різновид складової — спеціальна зі стандартизованим продуктом
    if (rowType === 4) {
      const defaultUnit = this._defaultSystemUsage.unit ? (this._defaultSystemUsage.unit as AmountUnit) : 'шт.';
      const defaultUnitToBuy = this._defaultSystemUsage.unitToBuy
        ? (this._defaultSystemUsage.unitToBuy as AmountUnit)
        : 'шт.';
      const row: ComponentRow = {
        componentKind: 'fulfillment',
        componentKey,
        compositionGroup,
        fieldKeys: [
          // Стандартизовані продукти не розглядаються по постачальникам та не мають SKU і звичайного кольору
          // 'supplier',
          // 'brand',
          // 'sku',
          // 'colour',
          // Стандартизовані продукти завжди враховуються по одній штуці тому немає потреби у зображенні цих полів
          // 'amount',
          // 'unit',
          // 'amountToBuy',
          // 'unitToBuy',
          // 'rollAmount',
          // 'packageAmount',
          // Стандартизовані продукти не мають ціни матеріалу, а визначаються виключно кінцевою вартістю
          // 'price',
          'totalPrice',
        ],
        materialKind: undefined,
        supplier: {
          type: 'supplier',
          supplierName: undefined,
          supplierId: undefined,
        },
        brand: {
          type: 'brand',
          currentValue: '',
        },
        sku: {
          type: 'sku',
          currentValue: '',
        },
        colour: {
          type: 'colour',
          currentValue: '',
        },
        description: {
          type: 'description',
          currentValue: '',
        },
        amount: {
          type: 'amount',
          currentValue: this._defaultSystemUsage.amount,
          isManualAmount: true,
          manualAmountAuthor: '',
        },
        unit: {
          type: 'unit',
          currentValue: defaultUnit,
          options: [defaultUnit],
        },
        amountToBuy: {
          type: 'amountToBuy',
          currentValue: this._defaultSystemUsage.amountToBuy ?? 1,
        },
        unitToBuy: {
          type: 'unitToBuy',
          currentValue: defaultUnitToBuy,
          options: [defaultUnitToBuy],
        },
        rollAmount: {
          type: 'rollAmount',
          currentValue: null,
        },
        packageAmount: {
          type: 'packageAmount',
          currentValue: null,
        },
        price: {
          type: 'price',
          currentValue: 0,
        },
        totalPrice: {
          type: 'totalPrice',
          currentValue: undefined,
          isManualPrice: false,
          manualPriceAuthor: '',
        },
      };

      const material = this.composition[componentKey as ProductComponentKey] as FulfilledProductMaterials | undefined;

      if (componentKey === 'standardRomanBlindDoubleSystem') {
        row.fieldKeys = ['system', 'systemType', 'totalPrice'];

        row.system = {
          type: 'system',
          currentValue: '',
          options: ['ТВИН', 'КОМФОРТ', 'ДЕНЬ-НОЧЬ'],
        };
        row.systemType = {
          type: 'systemType',
          currentValue: '',
          options: ['standard-double-cord-roman', 'standard-double-chain-roman'],
        };

        if (material) {
          row.system.currentValue = material.system ?? '';
          row.systemType.currentValue = material.systemType ?? '';
        }
      } else if (componentKey === 'standardRomanBlind' || componentKey === 'standardRomanBlindSecondary') {
        row.fieldKeys = [
          'model',
          'decoration',
          'cascade',
          'mainTextileName',
          'mainTextileColour',
          'edgingTextileName',
          'edgingTextileColour',
          'grommetColour',
          'systemType',
          'systemColour',
          'system',
          'controlSide',
          'controlChainLength',
          'nonStandardForm',
          'fixation',
          'chainFixation',
          'totalPrice',
        ];

        row.model = {
          type: 'model',
          currentValue: '',
          options: [
            'romanBlindSolo',
            'romanBlindLine',
            'romanBlindStella',
            'romanBlindPrizma',
            'romanBlindOlimpia',
            'romanBlindVenice',
            'romanBlindMilano',
            'romanBlindQuadro',
          ],
        };
        row.decoration = {
          type: 'decoration',
          currentValue: 'none',
          options: ['none'],
        };
        row.cascade = {
          type: 'cascade',
          currentValue: false,
        };
        row.mainTextileName = {
          type: 'mainTextileName',
          currentValue: '',
        };
        row.mainTextileColour = {
          type: 'mainTextileColour',
          currentValue: '',
        };
        row.edgingTextileName = {
          type: 'edgingTextileName',
          currentValue: '',
        };
        row.edgingTextileColour = {
          type: 'edgingTextileColour',
          currentValue: '',
        };
        row.grommetColour = {
          type: 'grommetColour',
          currentValue: '',
          options: ['молочный', 'антик (бронза)', 'матовое золото', 'матовый хром'],
        };
        row.systemType = {
          type: 'systemType',
          currentValue: '',
          options: ['standard-cord-roman', 'standard-chain-roman'],
        };
        row.systemColour = {
          type: 'systemColour',
          currentValue: 'white',
          options: ['white', 'brown'],
        };
        row.system = {
          type: 'system',
          currentValue: '',
          options: ['КЛАССИК', 'МАНСАРДА'],
        };
        row.controlSide = {
          type: 'controlSide',
          currentValue: '',
          options: ['left', 'right'],
        };
        row.controlChainLength = {
          type: 'controlChainLength',
          currentValue: 0,
          options: [...CONTROL_CHAIN_LENGTH_OPTIONS],
        };
        row.nonStandardForm = {
          type: 'nonStandardForm',
          currentValue: 'none',
          options: ['none', 'triangle', 'trapeze'],
        };
        row.fixation = {
          type: 'fixation',
          currentValue: 'none',
          options: ['none', 'магніти', 'леска'],
        };
        row.chainFixation = {
          type: 'chainFixation',
          currentValue: '',
          options: ['', '1'],
        };

        if (material) {
          row.model.currentValue = material.model ?? '';
          row.cascade.currentValue = material.cascade ?? false;
          row.decoration.currentValue = material.decoration ?? 'none';
          if (material.model) row.decoration.options = ['none', ...standardRomanBlindDecorationOptions(material.model)];
          row.nonStandardForm.currentValue = material.nonStandardForm ?? 'none';
          row.mainTextileName.currentValue = material.mainTextileName ?? '';
          row.mainTextileColour.currentValue = material.mainTextileColour ?? '';
          row.edgingTextileName.currentValue = material.edgingTextileName ?? '';
          row.edgingTextileColour.currentValue = material.edgingTextileColour ?? '';
          row.grommetColour.currentValue = material.grommetColour ?? '';
          row.systemType.currentValue = material.systemType ?? '';
          row.system.currentValue = material.system ?? '';
          row.systemType.currentValue = material.systemType ?? '';
          row.systemColour.currentValue = material.systemColour ?? '';
          row.controlSide.currentValue = material.controlSide ?? '';
          row.controlChainLength.currentValue = material.controlChainLength ?? 0;
          row.fixation.currentValue = material.fixation ?? 'none';
          row.chainFixation.currentValue = material.chainFixation ?? '';
        }
      }
      // TODO: Rows for verticalJalousie & textileRollerBlind

      const usage = this.design[componentKey as ProductComponentKey];
      if (usage) {
        row.amount.currentValue = usage.amount;
        row.amount.isManualAmount = !!usage.isManualAmount;
        row.amount.manualAmountAuthor = usage.manualAmountAuthor ?? '';
        row.unit.currentValue = usage.unit ? (usage.unit as AmountUnit) : row.unit.options[0];
        row.amountToBuy.currentValue = usage.amountToBuy ? usage.amountToBuy : -1;
        row.unitToBuy.currentValue = usage.unitToBuy ? (usage.unitToBuy as AmountUnit) : row.unitToBuy.currentValue;
        row.price.currentValue = typeof usage.totalPrice === 'number' ? usage.totalPrice : 0;
        row.totalPrice.currentValue = typeof usage.totalPrice === 'number' ? usage.totalPrice : undefined;
        row.totalPrice.isManualPrice = !!usage.isManualPrice;
        row.totalPrice.manualPriceAuthor = usage.manualPriceAuthor ?? '';
      }

      return row;
    }

    // Перші три різновиди складової — спеціальна зі звичайним матеріалом
    const defaultUsage = this.defaultMaterialUsage(componentKey);
    let defaultUnitOptions: AmountUnit[];
    if (rowType === 1) {
      defaultUnitOptions = ['м.п.', 'шт.', 'уп.', 'рулон', 'кг', 'комплект'];
    } else if (rowType === 2) {
      // Розрізнення фурнітури та аксесуарів за окремими типами складових (особливі параметри за умовчанням)
      if (componentKey === 'grommet') {
        defaultUnitOptions = ['уп.', 'шт.', 'комплект'];
      } else if (componentKey === 'syntheticFluff') {
        defaultUnitOptions = ['кг', 'уп.', 'м.п.', 'рулон', 'шт.'];
      } else if (componentKey === 'sintepon') {
        defaultUnitOptions = ['м.п.', 'рулон', 'уп.', 'шт.', 'кг'];
      } else if (['rosette', 'powerSupplyUnit', 'charger', 'remoteControlUnit', 'smartHub'].includes(componentKey)) {
        defaultUnitOptions = ['шт.', 'уп.', 'комплект'];
      } else {
        defaultUnitOptions = ['шт.', 'м.п.', 'уп.', 'рулон', 'кг', 'комплект'];
      }
    } else if (['curtainProfile', 'curtainProfile2', 'chain', 'lambrequinLath'].includes(componentKey)) {
      defaultUnitOptions = ['м.п.', 'шт.', 'уп.', 'комплект'];
    } else if (
      [
        'handpiece',
        'handpiece2',
        'cap',
        'cap2',
        'stopper',
        'stopper2',
        'bracketFasten',
        'wallBracket',
        'bracketExtension',
        'pipeConnector',
        'pipeConnector2',
        'hookSigma',
        'runners',
        'runners2',
        'rings4CurtainRod',
        'rings4CurtainRod2',
        'clipsFasten',
        'chainWeighter',
        'motor',
      ].includes(componentKey)
    ) {
      defaultUnitOptions = ['шт.', 'уп.', 'комплект'];
    } else {
      defaultUnitOptions = ['шт.', 'м.п.', 'уп.', 'комплект'];
    }

    const row: ComponentRow = {
      componentKind: 'specific',
      componentKey,
      compositionGroup,
      fieldKeys: [
        'supplier',
        'brand',
        'sku',
        'colour',
        'description',
        'amount',
        'unit',
        'amountToBuy',
        'unitToBuy',
        'rollAmount',
        'packageAmount',
        'price',
        'totalPrice',
      ],
      materialKind: undefined,
      supplier: {
        type: 'supplier',
        supplierName: undefined,
        supplierId: undefined,
      },
      brand: {
        type: 'brand',
        currentValue: '',
      },
      sku: {
        type: 'sku',
        currentValue: '',
      },
      colour: {
        type: 'colour',
        currentValue: '',
      },
      description: {
        type: 'description',
        currentValue: '',
      },
      amount: {
        type: 'amount',
        currentValue: defaultUsage.amount,
        isManualAmount: true,
        manualAmountAuthor: '',
      },
      unit: {
        type: 'unit',
        currentValue: defaultUsage.unit ? (defaultUsage.unit as AmountUnit) : 'м.п.',
        options: defaultUnitOptions,
      },
      amountToBuy: {
        type: 'amountToBuy',
        currentValue: defaultUsage.amountToBuy ?? -1,
      },
      unitToBuy: {
        type: 'unitToBuy',
        currentValue: defaultUsage.unitToBuy ? (defaultUsage.unitToBuy as AmountUnit) : 'м.п.',
        options: defaultUnitOptions,
      },
      rollAmount: {
        type: 'rollAmount',
        currentValue: null,
      },
      packageAmount: {
        type: 'packageAmount',
        currentValue: null,
      },
      price: {
        type: 'price',
        currentValue: 0,
      },
      totalPrice: {
        type: 'totalPrice',
        currentValue: 0,
        isManualPrice: false,
        manualPriceAuthor: '',
      },
    };

    const material = this.composition[componentKey as ProductComponentKey] as Material | undefined;
    const usage = this.design[componentKey as ProductComponentKey];
    if (material) {
      row.materialKind = material.type;
      row.supplier.supplierId = material.supplierId;
      row.supplier.supplierName = material.supplierName;
      row.brand.currentValue = material.brand ?? '';
      row.sku.currentValue = material.sku;
      row.colour.currentValue = material.colour ?? '';
      row.description.currentValue = material.description ?? '';
      // При наявному матеріалі одиниці в купівлю синхронізуються з одиницями продажу
      row.unitToBuy.currentValue = material.unit ? (material.unit as AmountUnit) : row.unitToBuy.options[0];
      row.rollAmount.currentValue = material.rollAmount ?? null;
      row.packageAmount.currentValue = material.packageAmount ?? null;
      row.price.currentValue = +material.price;
    }
    if (usage) {
      row.amount.currentValue = usage.amount;
      row.amount.isManualAmount = !!usage.isManualAmount;
      row.amount.manualAmountAuthor = usage.manualAmountAuthor ?? '';
      row.unit.currentValue = usage.unit ? (usage.unit as AmountUnit) : row.unit.options[0];
      row.amountToBuy.currentValue = usage.amountToBuy ? usage.amountToBuy : -1;
      // Якщо usage.unitToBuy чітко визначено але матеріал відсутній
      row.unitToBuy.currentValue =
        usage.unitToBuy && !material?.unit ? (usage.unitToBuy as AmountUnit) : row.unitToBuy.currentValue;
      row.totalPrice.currentValue = usage.totalPrice ? +usage.totalPrice : 0;
      row.totalPrice.isManualPrice = !!usage.isManualPrice;
      row.totalPrice.manualPriceAuthor = usage.manualPriceAuthor ?? '';
    }
    return row;
  }

  getServiceRow(serviceGroupKey: ServiceGroupKey, serviceId?: string): ServiceRow {
    // Визначити чи дійсний ключ складової та якого вона різновиду
    let rowType: number;
    if (serviceGroupKey === 'custom') {
      rowType = 3;
    } else if (serviceGroupKey === 'hanging') {
      rowType = 2;
    } else if (serviceGroupKey === 'sewing' || serviceGroupKey === 'mount' || serviceGroupKey === 'disassembling') {
      rowType = 1;
    } else {
      // Не дійсний ключ складової
      throw new InvalidServiceGroupKey(serviceGroupKey);
    }

    let availableServices = this._validServiceKeysOf[serviceGroupKey]
      ? [...this._validServiceKeysOf[serviceGroupKey]]
      : [];
    // console.log('availableServices', availableServices);
    const services = this.serviceOptions;

    // Третій різновид ряду послуги — довільна
    if (rowType === 3) {
      const selectedService =
        typeof serviceId === 'string' && serviceId.length > 0
          ? services.customServices.find(item => item?.id === serviceId)
          : undefined;

      const row: ServiceRow = {
        serviceKind: serviceGroupKey,
        fieldKeys: ['name', 'price'],
        service: {
          type: 'service',
          currentValue: 'customServices',
          options: [],
        },
        name: {
          type: 'name',
          currentValue: selectedService?.name ?? '',
        },
        isComplex: {
          type: 'isComplex',
          currentValue: false,
        },
        combinedComplexity: {
          type: 'combinedComplexity',
          currentValue: null,
        },
        price: {
          type: 'price',
          currentValue: selectedService?.price ?? 0,
        },
      };

      return row;
    }

    // У інших різновидів послуги є кілька варіантів — визначаємо активний та рівень складності
    let activeServiceKeys = availableServices.filter(key => typeof services[key] !== 'undefined');
    // console.log('activeServiceKeys', activeServiceKeys);
    const isComplex = activeServiceKeys.some(key => key.includes('Complex') && services[key] !== undefined);

    // Другий різновид ряду послуги — навіска
    if (rowType === 2) {
      activeServiceKeys = activeServiceKeys.filter(key => key !== 'hangingComplex');
      const selectedService = activeServiceKeys && activeServiceKeys.length > 0 ? activeServiceKeys[0] : undefined;
      const price =
        typeof selectedService !== 'undefined' &&
        selectedService.length > 0 &&
        typeof services[selectedService] !== 'undefined'
          ? services[selectedService]
          : -1;
      const row: ServiceRow = {
        serviceKind: serviceGroupKey,
        fieldKeys: ['service', 'price'],
        service: {
          type: 'service',
          currentValue: selectedService,
          // Опція складної навіски виключається з переліку, оскільки зображається окремим полем
          options: availableServices.filter(key => key !== 'hangingComplex'),
        },
        name: {
          type: 'name',
          currentValue: '',
        },
        isComplex: {
          type: 'isComplex',
          currentValue: isComplex,
        },
        combinedComplexity: {
          type: 'combinedComplexity',
          currentValue:
            isComplex && services.combinedHangingComplexity ? { ...services.combinedHangingComplexity } : null,
        },
        price: {
          type: 'price',
          currentValue: price !== undefined ? +price : -1,
        },
      };

      // Властивість складності активна не для всіх варіантів сервісу
      if (selectedService && !['Custom', 'Blanket', 'Chair'].some(s => selectedService.includes(s)))
        row.fieldKeys.push('isComplex');
      // Активність властивості комбінованої складності залежить від самої наявності об'єкту налаштування
      if (row.combinedComplexity.currentValue) row.fieldKeys.push('combinedComplexity');

      return row;
    }

    // Основний різновид ряду послуги
    if (serviceGroupKey === 'mount' && this.design.mountTarget && this.design.mountTarget !== 'casement') {
      /* Вибірку активних сервісів завжди потрібно виконувати по повному набору варіантів,
      але для монтажу також важливо обмежувати перелік доступних варіантів згідно
      цільового формату кріплення. Зокрема простий монтаж доступний лише на створку (`casement`) */
      availableServices = availableServices.filter(
        key => key.includes('Complex') || key.includes('Custom') || key === 'mountHookDrapery',
      );
    }
    const selectedService = activeServiceKeys && activeServiceKeys.length > 0 ? activeServiceKeys[0] : undefined;
    const price =
      typeof selectedService !== 'undefined' &&
      selectedService.length > 0 &&
      typeof services[selectedService] !== 'undefined'
        ? services[selectedService]
        : -1;
    const possibleCombinedComplexity =
      isComplex && serviceGroupKey === 'sewing' ? services.combinedSewingComplexity : null;
    const row: ServiceRow = {
      serviceKind: serviceGroupKey,
      fieldKeys: ['service', 'price'],
      service: {
        type: 'service',
        currentValue: selectedService,
        options: availableServices,
      },
      name: {
        type: 'name',
        currentValue: '',
      },
      isComplex: {
        type: 'isComplex',
        currentValue: isComplex,
      },
      combinedComplexity: {
        type: 'combinedComplexity',
        currentValue: possibleCombinedComplexity ? { ...possibleCombinedComplexity } : null,
      },
      price: {
        type: 'price',
        currentValue: price !== undefined ? +price : -1,
      },
    };

    // Активність властивості комбінованої складності залежить від самої наявності об'єкту налаштування
    if (row.combinedComplexity.currentValue) row.fieldKeys.push('combinedComplexity');

    return row;
  }
}

/**
 * Помилка при запиті на дію з ключем складової виробу якої не існує
 * або яка не відповідає типу
 */
export class InvalidComponentKey extends Error {
  key: string;

  expectedComponentType: string;

  constructor(key: string, expectedComponentType: string) {
    super(`Не дійсний ключ складової виробу: '${key}' для типу ${expectedComponentType}`);
    this.name = 'InvalidComponentKey';
    this.key = key;
    this.expectedComponentType = expectedComponentType;
  }
}

/**
 * Помилка при запиті на дію з індексом довільної складової виробу
 * за яким такої не існує.
 */
export class InvalidFreeformComponentIndex extends Error {
  index: number | undefined;

  constructor(index: number | undefined) {
    super(`Не дійсний індекс довільної складової виробу: '${index ?? 'відсутній'}'`);
    this.name = 'InvalidFreeformComponentIndex';
    this.index = index;
  }
}

/**
 * Помилка при запиті на дію з ідентифікатором довільної послуги у виробі
 * за яким такої не існує.
 */
export class InvalidCustomServiceId extends Error {
  id: string | undefined;

  constructor(id: string | undefined) {
    super(`Не дійсний ідентифікатор довільної послуги у виробі: '${id ?? 'відсутній'}'`);
    this.name = 'InvalidCustomServiceId';
    this.id = id;
  }
}

/**
 * Помилка при запиті на дію з не дійсним ключем групи послуг за типом
 */
export class InvalidServiceGroupKey extends Error {
  key: string;

  constructor(key: string) {
    super(`Не дійсний ключ групи послуг за типом: '${key}'`);
    this.name = 'InvalidServiceGroupKey';
    this.key = key;
  }
}

/**
 * Помилка при запиті на дію з не дійсним ключем послуги
 */
export class InvalidServiceKey extends Error {
  key: string;

  constructor(key: string) {
    super(`Не дійсний ключ послуги: '${key}'`);
    this.name = 'InvalidServiceKey';
    this.key = key;
  }
}

/**
 * Помилка невідповідності даних очікуваному типу
 */
export class InvalidDataError extends Error {
  expectedDataType: string;

  constructor(expectedDataType: string) {
    super(`Надані дані не відповідають очікуваному типу '${expectedDataType}'`);
    this.name = 'InvalidDataError';
    this.expectedDataType = expectedDataType;
  }
}
