import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import type { Theme } from 'theme';

const useCustomBreakpoint = (breakpoint: 'desktop'): boolean => {
  const theme: Theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(breakpoint));

  return matches;
};

export default useCustomBreakpoint;
