import React from 'react';
import type { FC } from 'react';
import { OptionalChildrenOnlyProps } from 'types/generic';
import { Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

/**
 * Компонент який перенаправляє авторизованих користувачів
 * на їх відповідні стартові сторінки згідно ролей
 */
const GuestGuard: FC<OptionalChildrenOnlyProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated) {
    if (user?.roles.includes('designer') || user?.roles.includes('manager') || user?.roles.includes('support'))
      return <Navigate to="/projects" />;
    if (user?.roles.includes('admin')) return <Navigate to="/admin/dashboard" />;
    if (user?.roles.includes('representative')) return <Navigate to="/materials" />;
    if (user?.roles.includes('accountant')) return <Navigate to="/accountant/dashboard" />;
    if (user?.roles.includes('director')) return <Navigate to="/director/dashboard" />;
    if (user?.roles.includes('consultant')) return <Navigate to="/consultation" />;
    if (user?.roles.includes('production')) return <Navigate to="/production/dashboard" />;
    if (user?.roles.includes('atelier')) return <Navigate to="/atelier/dashboard" />;
    if (user?.roles.includes('distributor')) return <Navigate to="/distributor/dashboard" />;
    if (user?.roles.includes('mounter')) return <Navigate to="/mounter/dashboard" />;
    if (user?.roles.includes('hanger')) return <Navigate to="/hanger/dashboard" />;
    if (user?.roles.includes('courier')) return <Navigate to="/courier/dashboard" />;
    if (user?.roles.includes('intern')) return <Navigate to="/intern/dashboard" />;
  }

  return <>{children}</>;
};

export default GuestGuard;
