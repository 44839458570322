import type { PayOffice } from 'types/PayOffice';
import apiClient from './coreAPI';
import { ComplexError, errorParser } from './errorsParser';

export const getPayOfficesOfCompany = async (companyId: string): Promise<PayOffice[]> => {
  try {
    const { data } = await apiClient.get<PayOffice[]>(`/companies/${encodeURIComponent(companyId)}/pay-offices`);
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export default { getPayOfficesOfCompany };
