/* eslint-disable no-continue */
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty, map, pull, pullAll, remove, merge, get } from 'lodash';
import { v4 as uuid } from 'uuid';

import mockResponse from 'data/mockOrderProducts';
import productRegistry, { FreeformProduct } from 'domain/products';
import { RootState } from 'store';
import { loadState, resetState } from 'utils/localStorage';
import {
  getProjectImagesByUser,
  patchSingleProjectImageByUser,
  deleteSingleProjectImageByUser,
  postSingleProjectImageByUser,
  deleteAllImagesOfProductByUser,
} from 'api/userEndpoints';
import type { ProductStructure } from 'types/ProductStructure';
import type { ProjectStructure } from 'types/ProjectStructure';
import type { ClientStructure } from 'types/ClientStructure';
import type { ProjectImage, ProjectImageProto } from 'types/ProjectImage';
import type { ProjectParticipant } from 'types/ProjectParticipant';
import type { FinanceTransactionStructure } from 'types/FinanceTransactionStructure';
import type { CountSchema } from 'api/coreAPI';

export type FetchingStatus = 'idle' | 'loading' | 'fulfilled' | 'rejected';
export type ProcessingStatus = 'idle' | 'processing' | 'fulfilled' | 'rejected';
export interface ItemProcessingState {
  updating: ProcessingStatus;
  deleting: ProcessingStatus;
}

interface Designer {
  productTool:
    | {
        status: 'create';
        productId: null;
      }
    | {
        status: 'edit';
        productId: string;
      };
  loading: FetchingStatus;
  loadingImages: FetchingStatus;
  processingImages: Record<string, ItemProcessingState | undefined>;
  /** Набір виробів при створенні замовлення */
  products: ProductStructure[];
  /** Перелік обраних виробів */
  selectedProducts: string[];
  /** Поточний крок інструменту створення нового виробу */
  activeStep: number | null;
  /** Виріб що налаштовується редактором (поточний) */
  product: ProductStructure;
  /** Прототип або завершений об'єкт проєкту */
  project: ProjectStructure | null;
  /** Зображення по проєкту (вже збережені на сервері) */
  images: ProjectImage[];
  /** Нові зображення по проєкту (дані зображень для збереження на сервері) */
  newImages: ProjectImageProto[];
  /** Прототип або повний об'єкт клієнта що здійснив замовлення */
  client: ClientStructure | null;
  /** Дані оригінальної повноцінної збереженої на сервері версії клієнта для визначення факту його редагування в подальшому */
  existedClientReference: ClientStructure | null;
  /** Прототип або повний об'єкт учасника проєкту */
  projectParticipant: ProjectParticipant | null;
  /** Платежі по проєкту (прототипи або вже здійснені та збережені на сервері) */
  financeTransactions: FinanceTransactionStructure[];
  materials: Record<string, string>[];
}

const persistedState = loadState('create_order_tool');
const persistedProducts = get(persistedState, 'products', []);
const persistedSelectedProducts = get(persistedState, 'selectedProducts', []);
const persistedActiveStep = get(persistedState, 'activeStep', 0);
const persistedProduct = get(persistedState, 'product', new FreeformProduct());
const persistedProductTool = get(persistedState, 'productTool', { status: 'create', productId: null });
const persistedProject = get(persistedState, 'project', null);
const persistedClient = get(persistedState, 'client', null);
const persistedExistedClientReference = get(persistedState, 'existedClientReference', null);
const persistedImages = get<ProjectImage[]>(persistedState, 'images', []);
const persistedProjectParticipant = get(persistedState, 'projectParticipant', null);
const persistedFinanceTransactions = get<FinanceTransactionStructure[]>(persistedState, 'financeTransactions', []);

const initialState: Designer = {
  products: persistedProducts,
  selectedProducts: persistedSelectedProducts,
  loading: 'idle',
  activeStep: persistedActiveStep,
  product: persistedProduct,
  productTool: persistedProductTool,
  project: persistedProject,
  client: persistedClient,
  existedClientReference: persistedExistedClientReference,
  images: persistedImages,
  // Дані зображень краще отримувати заново після перезавантаження
  loadingImages: 'idle',
  processingImages: {},
  newImages: [],
  projectParticipant: persistedProjectParticipant,
  financeTransactions: persistedFinanceTransactions,
  materials: [],
};

export const fetchProjectImages = createAsyncThunk<ProjectImage[], { userId: string; imageSlugList: string[] }>(
  'fetchProjectImagesForCreatedOrder',
  async thunkArgs => getProjectImagesByUser(thunkArgs.userId, thunkArgs.imageSlugList),
  {
    condition: (_, { getState }) => {
      const { designer } = getState() as RootState;
      if (designer) {
        const fetchStatus = (designer as Designer).loadingImages;
        if (fetchStatus === 'loading' || fetchStatus === 'fulfilled') {
          // Вже в процесі або був успішно виконаний, повторний запит не потрібен
          return false;
        }
      }
      return undefined;
    },
  },
);
export const postNewProjectImage = createAsyncThunk<
  ProjectImage,
  { userId: string; file: File; fileName: string; fields: ProjectImage }
>('postNewProjectImageForCreatedOrder', async thunkArgs =>
  postSingleProjectImageByUser(thunkArgs.userId, thunkArgs.file, thunkArgs.fileName, thunkArgs.fields),
);
export const patchProjectImage = createAsyncThunk<
  CountSchema,
  { userId: string; imageSlug: string; payload: Partial<Omit<ProjectImage, 'userId'>> }
>('patchProjectImageForCreatedOrder', async thunkArgs =>
  patchSingleProjectImageByUser(thunkArgs.userId, thunkArgs.imageSlug, thunkArgs.payload),
);
export const delProjectImage = createAsyncThunk<CountSchema, { userId: string; imageSlug: string }>(
  'delProjectImageForCreatedOrder',
  async thunkArgs => deleteSingleProjectImageByUser(thunkArgs.userId, thunkArgs.imageSlug),
);
export const delAllProductImages = createAsyncThunk<CountSchema, { userId: string; productId: string }>(
  'delAllProductImagesInCreatedOrder',
  async thunkArgs => deleteAllImagesOfProductByUser(thunkArgs.userId, thunkArgs.productId),
);

export const fetchOrderProducts = createAsyncThunk('fetchOrderProducts', async () => {
  // const products: ProductStructure[] = [];
  // const { data } = await apiClient.get('');
  // Тимчасові тестові дані виключно на період розробки/налаштування
  const products = productRegistry.hydrateList(mockResponse, 'freeform');

  return products.map(item => ({
    ...item,
    // Генерувати унікальний ідентифікатор (якщо виріб його не має)
    id: item.id ?? uuid(),
  }));
});

const slice = createSlice({
  name: 'designer',
  initialState,
  reducers: {
    setActiveStep: (state, { payload }) => {
      state.activeStep = payload;
    },
    setupCreateProductTool: (state, { payload }: PayloadAction<'blank' | 'continue'>) => {
      if (state.product === null || payload === 'blank') {
        state.activeStep = 0;
      } else if (state.productTool.status === 'edit') {
        // Цією дією можливо продовжити створення виробу, а не редагування
        state.activeStep = 0;
      }
      state.productTool = { status: 'create', productId: null };
      state.newImages = [];
      state.processingImages = {};
    },
    setupEditProductTool: (
      state,
      { payload: { id, product } }: PayloadAction<{ id: string; product: ProductStructure }>,
    ) => {
      state.productTool = { status: 'edit', productId: id };
      state.activeStep = 1;
      state.product = merge({}, product);
      state.newImages = [];
      state.processingImages = {};
    },
    addSelectedOrderProduct: (state, { payload }: PayloadAction<string>) => {
      state.selectedProducts.push(payload);
    },
    removeSelectedOrderProduct: (state, { payload }: PayloadAction<string>) => {
      pull(state.selectedProducts, payload);
    },
    toggleAllSelectedOrderProducts: (state, { payload }: PayloadAction<string[]>) => {
      isEmpty(state.selectedProducts)
        ? (state.selectedProducts = [...state.selectedProducts, ...payload])
        : pullAll(state.selectedProducts, payload);
    },
    updateProduct: (state, { payload }: PayloadAction<ProductStructure>) => {
      state.product = merge({}, payload);
    },
    deleteProduct: (state, { payload }: PayloadAction<string>) => {
      remove(state.products, (product: any) => product.id === payload);
      pull(state.selectedProducts, payload);
    },
    addProduct: (state, { payload }: PayloadAction<ProductStructure>) => {
      // Генерувати унікальний ідентифікатор (якщо виріб його не має)
      if (typeof payload.id !== 'string') payload.id = uuid();
      state.products.push(payload);
      state.selectedProducts.push(payload.id);
    },
    replaceProduct: (state, { payload }: PayloadAction<ProductStructure>) => {
      // Редагований виріб завжди повинен мати ідентифікатор
      if (typeof payload.id !== 'string') {
        // eslint-disable-next-line no-console
        console.error('Відсутній ідентифікатор у редагованому виробі.');
        return;
      }
      const index = state.products.findIndex(item => item.id === payload.id);
      if (index < 0) {
        // eslint-disable-next-line no-console
        console.error('Відсутній виріб з даним ідентифікатором у замовленні.');
        return;
      }
      state.products.splice(index, 1, payload);
    },
    updateProject: (state, { payload }: PayloadAction<ProjectStructure | null>) => {
      state.project = payload;
    },
    updateClient: (state, { payload }: PayloadAction<ClientStructure | null>) => {
      state.client = payload;
    },
    updateExistedClientReference: (state, { payload }: PayloadAction<ClientStructure | null>) => {
      state.existedClientReference = payload;
    },
    updateProjectParticipant: (state, { payload }: PayloadAction<ProjectParticipant | null>) => {
      state.projectParticipant = payload;
    },
    updateFinanceTransactions: (
      state,
      { payload }: PayloadAction<{ index: number | null; payments: FinanceTransactionStructure[] }>,
    ) => {
      if (payload.index === null) {
        state.financeTransactions = payload.payments;
      } else if (payload.index >= 0) {
        if (payload.payments.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          state.financeTransactions[payload.index] = payload.payments[0];
        } else if (payload.payments.length > 1) {
          state.financeTransactions = [
            ...state.financeTransactions.slice(0, payload.index),
            ...payload.payments,
            ...state.financeTransactions.slice(payload.index + payload.payments.length),
          ];
        }
      }
    },
    deleteNewImage: (state, { payload }: PayloadAction<string>) => {
      const protoIndex = state.newImages.findIndex(image => image.description.slug === payload);
      if (protoIndex >= 0) {
        // Видалити файл з кешу по objectURL
        URL.revokeObjectURL(state.newImages[protoIndex].objectURL);
        state.newImages.splice(protoIndex, 1);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    clearProjectCreationTool: (state, _: PayloadAction<undefined | null>) => {
      state.client = null;
      state.existedClientReference = null;
      state.project = null;
      state.products = [];
      state.selectedProducts = [];
      state.images = [];
      state.newImages = [];
      state.projectParticipant = null;
      state.financeTransactions = [];
      state.activeStep = 0;
      state.product = new FreeformProduct();
      state.productTool = { status: 'create', productId: null };
      state.loadingImages = 'idle';
      state.processingImages = {};
      resetState('create_order_tool');
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchOrderProducts.pending, state => {
        state.loading = 'loading';
      })
      .addCase(fetchOrderProducts.fulfilled, (state, { payload }) => {
        state.products = payload;
        state.loading = 'fulfilled';
        state.selectedProducts.push(...payload.map(product => product.id));
      })
      .addCase(fetchOrderProducts.rejected, state => {
        state.loading = 'rejected';
      })
      .addCase(fetchProjectImages.pending, state => {
        state.loadingImages = 'loading';
      })
      .addCase(fetchProjectImages.fulfilled, (state, { payload }) => {
        state.images = payload;
        state.loadingImages = 'fulfilled';
      })
      .addCase(fetchProjectImages.rejected, state => {
        state.images = [];
        state.loadingImages = 'rejected';
      })
      .addCase(postNewProjectImage.pending, (state, action) => {
        if (action.meta.arg.fields && action.meta.arg.file) {
          // Зберегти об'єкт нового зображення у newImages
          // попередньо підставити в якості тимчасового slug значення requestId
          // та перевірити чи вже є в наявності зображення з таким slug (захист від дублікатів при повторному виконанні запиту на збереження)
          if (state.newImages.findIndex(image => image.description.slug === action.meta.requestId) < 0)
            state.newImages.push({
              objectURL: URL.createObjectURL(action.meta.arg.file),
              description: {
                ...action.meta.arg.fields,
                slug: action.meta.requestId,
              },
              uploadingState: 'processing',
            });
        }
      })
      .addCase(postNewProjectImage.fulfilled, (state, action) => {
        // Покласти збережене зображення у перелік images
        state.images.push(action.payload);
        // Видалити прототип зображення з newImages
        const protoIndex = state.newImages.findIndex(image => image.description.slug === action.meta.requestId);
        if (protoIndex >= 0) {
          URL.revokeObjectURL(state.newImages[protoIndex].objectURL);
          state.newImages.splice(protoIndex, 1);
        }
        // Додати дійсний slug нового зображення у перелік для поєднання з новим проєктом
        if (action.payload.slug && state.project && state.project.extraData) {
          if (Array.isArray(state.project.extraData?.imagesSlugs)) {
            state.project.extraData.imagesSlugs.push(action.payload.slug);
          } else {
            state.project.extraData.imagesSlugs = [action.payload.slug];
          }
        }
      })
      .addCase(postNewProjectImage.rejected, (state, action) => {
        const protoIndex = state.newImages.findIndex(image => image.description.slug === action.meta.requestId);
        if (protoIndex >= 0) state.newImages[protoIndex].uploadingState = 'rejected';
      })
      .addCase(patchProjectImage.pending, (state, action) => {
        if (action.meta.arg?.imageSlug) {
          const processingState = state.processingImages[action.meta.arg.imageSlug];
          if (processingState) {
            processingState.updating = 'processing';
          } else {
            state.processingImages[action.meta.arg.imageSlug] = {
              updating: 'processing',
              deleting: 'idle',
            };
          }
        }
      })
      .addCase(patchProjectImage.fulfilled, (state, action) => {
        if (action.meta.arg) {
          const { imageSlug, payload } = action.meta.arg;
          if (typeof imageSlug !== 'string') {
            // eslint-disable-next-line no-console
            console.error('Відсутній ідентифікатор редагованого зображення.');
            return;
          }
          const processingState = state.processingImages[imageSlug];

          if (action.payload?.count && action.payload.count > 0) {
            // Оновити зображення в store
            const index = state.images.findIndex(item => item.slug === imageSlug);
            if (index < 0) {
              // eslint-disable-next-line no-console
              console.error('Відсутнє зображення з даним ідентифікатором.');
              return;
            }
            state.images.splice(index, 1, {
              ...state.images[index],
              ...payload,
            });
            // Позначити успішний статус процесу
            if (processingState) {
              processingState.updating = 'fulfilled';
            } else {
              state.processingImages[imageSlug] = {
                updating: 'fulfilled',
                deleting: 'idle',
              };
            }
          } else {
            // Позначити що процес не відбувся
            // eslint-disable-next-line no-lonely-if
            if (processingState) {
              processingState.updating = 'rejected';
            } else {
              state.processingImages[imageSlug] = {
                updating: 'rejected',
                deleting: 'idle',
              };
            }
          }
        }
      })
      .addCase(patchProjectImage.rejected, (state, action) => {
        if (action.meta.arg?.imageSlug) {
          const processingState = state.processingImages[action.meta.arg.imageSlug];
          if (processingState) {
            processingState.updating = 'rejected';
          } else {
            state.processingImages[action.meta.arg.imageSlug] = {
              updating: 'rejected',
              deleting: 'idle',
            };
          }
        }
      })
      .addCase(delProjectImage.pending, (state, action) => {
        if (action.meta.arg?.imageSlug) {
          const processingState = state.processingImages[action.meta.arg.imageSlug];
          if (processingState) {
            processingState.deleting = 'processing';
          } else {
            state.processingImages[action.meta.arg.imageSlug] = {
              updating: 'idle',
              deleting: 'processing',
            };
          }
        }
      })
      .addCase(delProjectImage.fulfilled, (state, action) => {
        if (action.meta.arg) {
          const { imageSlug } = action.meta.arg;
          if (typeof imageSlug !== 'string') {
            // eslint-disable-next-line no-console
            console.error('Відсутній ідентифікатор редагованого зображення.');
            return;
          }
          const processingState = state.processingImages[imageSlug];

          if (action.payload?.count && action.payload.count > 0) {
            // Видалити зображення зі store
            remove(state.images, (image: any) => image.slug === imageSlug);
            // Видалити slug зображення з переліку для поєднання з новим проєктом
            if (state.project && state.project.extraData && Array.isArray(state.project.extraData?.imagesSlugs)) {
              const imageIndex = state.project.extraData.imagesSlugs.findIndex(slug => slug === imageSlug);
              if (imageIndex >= 0) state.project.extraData.imagesSlugs.splice(imageIndex, 1);
            }
            // Видалити об'єкт статусу обробки зображення якого вже не існує
            if (processingState) {
              state.processingImages[imageSlug] = undefined;
            }
          } else {
            // Позначити що процес не відбувся
            // eslint-disable-next-line no-lonely-if
            if (processingState) {
              processingState.deleting = 'rejected';
            } else {
              state.processingImages[imageSlug] = {
                updating: 'idle',
                deleting: 'rejected',
              };
            }
          }
        }
      })
      .addCase(delProjectImage.rejected, (state, action) => {
        if (action.meta.arg?.imageSlug) {
          const processingState = state.processingImages[action.meta.arg.imageSlug];
          if (processingState) {
            processingState.deleting = 'rejected';
          } else {
            state.processingImages[action.meta.arg.imageSlug] = {
              updating: 'idle',
              deleting: 'rejected',
            };
          }
        }
      })
      .addCase(delAllProductImages.pending, (state, action) => {
        if (action.meta.arg?.productId) {
          const allImagesOfProduct = state.images.filter(image => image.productId === action.meta.arg.productId);
          // eslint-disable-next-line no-restricted-syntax
          for (const image of allImagesOfProduct) {
            if (typeof image.slug !== 'string' || image.slug.trim().length === 0) {
              // eslint-disable-next-line no-console
              console.error(
                `Неможливо видалити з переліку state.images зображення без ідентифікатора: ${image.originalName}`,
              );
              continue;
            }

            const processingState = state.processingImages[image.slug];
            if (processingState) {
              processingState.deleting = 'processing';
            } else {
              state.processingImages[image.slug] = {
                updating: 'idle',
                deleting: 'processing',
              };
            }
          }
        }
      })
      .addCase(delAllProductImages.fulfilled, (state, action) => {
        if (action.meta.arg) {
          const { productId } = action.meta.arg;
          if (typeof productId !== 'string') {
            // eslint-disable-next-line no-console
            console.error('Відсутній ідентифікатор виробу зображення якого необхідно видалити.');
            return;
          }

          const allImagesOfProduct = state.images.filter(image => image.productId === action.meta.arg.productId);
          // eslint-disable-next-line no-restricted-syntax
          for (const image of allImagesOfProduct) {
            if (typeof image.slug !== 'string' || image.slug.trim().length === 0) {
              // eslint-disable-next-line no-console
              console.error(
                `Неможливо видалити з переліку state.images зображення без ідентифікатора: ${image.originalName}`,
              );
              continue;
            }

            const processingState = state.processingImages[image.slug];

            if (action.payload?.count && action.payload.count > 0) {
              // TODO: Логіка має потенціал переробки на видалення всіх зображень та їх slug у project.extraData.imagesSlugs за один прохід (тобто без ітерації по allImagesOfProduct)
              // Видалити зображення зі store
              remove(state.images, (img: any) => img.slug === image.slug);
              // Видалити slug зображення з переліку для поєднання з новим проєктом
              if (state.project && state.project.extraData && Array.isArray(state.project.extraData?.imagesSlugs)) {
                const imageIndex = state.project.extraData.imagesSlugs.findIndex(slug => slug === image.slug);
                if (imageIndex >= 0) state.project.extraData.imagesSlugs.splice(imageIndex, 1);
              }
              // Видалити об'єкт статусу обробки зображення якого вже не існує
              if (processingState) {
                state.processingImages[image.slug] = undefined;
              }
            } else {
              // Позначити що процес не відбувся
              // eslint-disable-next-line no-lonely-if
              if (processingState) {
                processingState.deleting = 'rejected';
              } else {
                state.processingImages[image.slug] = {
                  updating: 'idle',
                  deleting: 'rejected',
                };
              }
            }
          }
        }
      })
      .addCase(delAllProductImages.rejected, (state, action) => {
        if (action.meta.arg?.productId) {
          const allImagesOfProduct = state.images.filter(image => image.productId === action.meta.arg.productId);
          // eslint-disable-next-line no-restricted-syntax
          for (const image of allImagesOfProduct) {
            if (typeof image.slug !== 'string' || image.slug.trim().length === 0) {
              // eslint-disable-next-line no-console
              console.error(
                `Неможливо видалити з переліку state.images зображення без ідентифікатора: ${image.originalName}`,
              );
              continue;
            }

            const processingState = state.processingImages[image.slug];
            if (processingState) {
              processingState.deleting = 'rejected';
            } else {
              state.processingImages[image.slug] = {
                updating: 'idle',
                deleting: 'rejected',
              };
            }
          }
        }
      });
  },
});

const selectSelf = (state: RootState): Designer => state.designer;

export const getProducts = createSelector(selectSelf, state => state.products);
export const getProduct = createSelector(selectSelf, state => state.product);
export const getProject = createSelector(selectSelf, state => state.project);
export const getProjectId = createSelector(selectSelf, state => state.project?.id);
export const getProjectRooms = createSelector(selectSelf, state => state.project?.rooms ?? []);
export const getProjectImageSlugList = createSelector(selectSelf, state => state.project?.extraData?.imagesSlugs ?? []);
export const getClient = createSelector(selectSelf, state => state.client);
export const getExistedClientReference = createSelector(selectSelf, state => state.existedClientReference);
export const getProjectParticipant = createSelector(selectSelf, state => state.projectParticipant);
export const getFinanceTransactions = createSelector(selectSelf, state => state.financeTransactions);
export const getImages = createSelector(selectSelf, state => state.images);
export const getLoadingImages = createSelector(selectSelf, state => state.loadingImages);
export const getProcessingImagesStates = createSelector(selectSelf, state => state.processingImages);
export const getNewImages = createSelector(selectSelf, state => state.newImages);
export const getActiveStep = createSelector(selectSelf, state => state.activeStep);
export const getProductTool = createSelector(selectSelf, state => state.productTool);
export const getSelectedProducts = createSelector(selectSelf, state => state.selectedProducts);
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const getAllProductsIds = createSelector(selectSelf, state => state.products.map(item => item.id!));
export const getAllMaterials = createSelector(selectSelf, state => state.materials);
export const getAllComponentTypes = createSelector(selectSelf, state => map(state.materials, 'productComponent'));

export const {
  reducer,
  actions: {
    setActiveStep,
    setupCreateProductTool,
    setupEditProductTool,
    updateProduct,
    addSelectedOrderProduct,
    removeSelectedOrderProduct,
    toggleAllSelectedOrderProducts,
    deleteProduct,
    addProduct,
    replaceProduct,
    updateProject,
    updateClient,
    updateExistedClientReference,
    updateProjectParticipant,
    updateFinanceTransactions,
    deleteNewImage,
    clearProjectCreationTool,
  },
} = slice;
