import React, { ReactElement } from 'react';
import merge from 'lodash/merge';
import { ThemeOptions } from '@material-ui/core';
import { blueGrey, common } from '@material-ui/core/colors';
import type { Theme as MuiTheme } from '@material-ui/core/styles/createTheme';
// TODO update import unstable_createMuiStrictModeTheme with v5 https://github.com/mui-org/material-ui/blob/next/CHANGELOG.md
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import type {
  Palette as MuiPalette,
  TypeBackground as MuiTypeBackground,
} from '@material-ui/core/styles/createPalette';

import { ChildrenOnlyProps } from 'types/generic';
import useSettings from 'hooks/useSettings';
import { softShadows } from './shadows';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    desktop: true;
  }
}

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    name: string;
  }

  interface ThemeOptions {
    name?: string;
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface TypeBackground {
    dark: string;
  }
  interface Palette {
    semanticVar1: PaletteColor;
    semanticVar2: PaletteColor;
  }
  interface PaletteOptions {
    semanticVar1: PaletteColorOptions;
    semanticVar2: PaletteColorOptions;
  }
}

// eslint-disable-next-line no-shadow
export enum THEMES {
  LIGHT = 'LIGHT',
}

interface TypeBackground extends MuiTypeBackground {
  dark: string;
}

interface Palette extends MuiPalette {
  background: TypeBackground;
}

export interface Theme extends MuiTheme {
  name: string;
  palette: Palette;
}

const baseConfig: ThemeOptions = {
  props: {
    MuiCardHeader: {
      titleTypographyProps: {
        variant: 'h6',
      },
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  overrides: {
    MuiStepper: {
      root: {
        width: '100%',
        maxWidth: 1000,
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: 'unset',
      },
    },
    MuiStepIcon: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 600,
      },
      root: {
        padding: '12px 8px',
      },
    },
    MuiCardMedia: {
      root: {
        backgroundSize: 'contain',
      },
    },
  },
  shadows: softShadows,
};

const themeBreakPoints: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      desktop: 1500,
      xl: 1920,
    },
  },
};

// https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=252bb2&secondary.color=e2e2ef&secondary.text.color=263238&primary.text.color=ffffff
// main colors is taken from figma. Other colors is generated in Color tool
const themeConfigs: ThemeOptions[] = [
  {
    name: THEMES.LIGHT,
    palette: {
      primary: {
        main: '#252bb2',
        light: '#6556e5',
        dark: '#000481',
        contrastText: common.white,
      },
      secondary: {
        main: '#e2e2ef',
        light: common.white,
        dark: '#b0b0bd',
        contrastText: blueGrey[900],
      },
      text: {
        primary: blueGrey[900],
        secondary: blueGrey[600],
      },
      semanticVar1: {
        main: '#26c6da',
        light: '#26c6da',
        dark: '#26c6da',
        // contrastText: '#26c6da',
      },
      semanticVar2: {
        main: '#42a5f5',
        light: '#42a5f5',
        dark: '#42a5f5',
        // contrastText: '#42a5f5',
      },
      divider: 'rgba(145, 158, 171, 0.24)',
    },
    shadows: softShadows,
  },
];

export default function AppThemeProvider({ children }: ChildrenOnlyProps): ReactElement<ChildrenOnlyProps> {
  const { settings } = useSettings();

  let themeConfig = themeConfigs.find(theme => theme.name === settings.theme);

  if (!themeConfig) {
    // eslint-disable-next-line no-console
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  const theme = createMuiTheme(merge(baseConfig, themeConfig, themeBreakPoints));

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
