import React from 'react';
import type { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { OptionalChildrenOnlyProps } from 'types/generic';
import useAuth from 'hooks/useAuth';
import LoginForm from 'components/LoginForm';

const DirectLinkGuard: FC<OptionalChildrenOnlyProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  // Не автентифікованим демонструвати безпосередньо форму автентифікації
  if (!isAuthenticated || !user) return <LoginForm />;

  // Фільтр доступності прямих посилань згідно ролей користувачів
  if (user?.roles.includes('intern') || user?.roles.includes('representative') || user?.roles.includes('courier')) {
    return <Navigate to="/404" />;
  }

  // Авторизований користувач отримує внутрішній зміст
  return <>{children}</>;
};

export default DirectLinkGuard;
