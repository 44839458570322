import { FC, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { makeStyles } from '@material-ui/styles';

type PropsType = {
  children: JSX.Element;
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
});

export const topBarPortalId = 'top-bar-portal-root';

const TopBarPortal: FC<PropsType> = ({ children }) => {
  const classes = useStyles();
  const mount = document.getElementById(topBarPortalId);
  const el = document.createElement('div');
  el.className = classes.root;

  useEffect((): any => {
    if (mount) {
      mount.appendChild(el);
      return () => mount.removeChild(el);
    }
    return null;
  }, [el, mount]);

  return createPortal(children, el);
};

export default TopBarPortal;
