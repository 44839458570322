import { ApiClientResponse } from './coreAPI';

/**
 * Статус на рівні HTTP та дані відповіді API Client
 */
interface CoreApiClientResponse extends ApiClientResponse {
  /**
   * Дані від API
   */
  data: CurtainsApiResponseData;
}

/**
 * Дані відповіді від API
 */
export interface CurtainsApiResponseData {
  /**
   * Опис помилки у відповіді API на рівні внутрішньої логіки серверу
   */
  error: CurtainsApiResponseError;
}

/**
 * Помилка від API з деталізацією
 */
interface CurtainsApiResponseError {
  /**
   * Те саме що й error.response.status тобто числовий код HTTP помилки (напр. 422)
   */
  statusCode: number;
  /**
   * Клас помилки згідно внутрішньої класифікації сервера напр. "UnprocessableEntityError"
   */
  name: string;
  /**
   * Більш розгорнуте повідомлення про помилку. Напр. "The request body is invalid. See error object `details` property for more info."
   */
  message: string;
  /**
   * Код помилки згідно внутрішньої класифікації сервера. Точніше ніж HTTP варіант відповідає суті визначеної проблеми. Напр. "VALIDATION_FAILED"
   */
  code?: string;
  /**
   * Масив з даними деталізації щодо причини помилки яка трапилась
   *
   * @example
   * Об'єкт у масиві details можна умовно розібрати як
   * Саме правило — minLength limit: 8
   * Зауваження про порушення — password should NOT be shorter than 8 characters
   */
  details?: [
    {
      /**
       * Код порушеного правила згідно внутрішньої класифікації сервера, напр. "minLength"
       */
      code: string;
      /**
       * Об'єкт з параметрами, що описують правило. Напр. { limit: 8 }
       */
      info: Record<string, unknown>;
      /**
       * Шлях до джерела помилки (поля у запиті, тощо). Напр. "/password"
       */
      path: string;
      /**
       * Текстове зауваження щодо правила, яке було порушено. Напр. "should NOT be shorter than 8 characters"
       */
      message: string;
    },
  ];
}

export type ComplexError = string | Error | CoreApiClientResponse | CurtainsApiResponseData;

/**
 * Утиліта парсингу можливих помилок у відповіді від серверу
 * @param error повідомлення або об'єкт помилки
 * @returns Повідомлення про помилку придатне для виведення в інтерфейс
 */
// eslint-disable-next-line import/prefer-default-export
export function errorParser(error: ComplexError): string {
  let message = 'Something went wrong'; // default
  // eslint-disable-next-line no-console
  console.log('errorParser', error, typeof error);

  if (!error) return message;
  if (typeof error === 'string' || typeof error === 'number') return error;
  if ('message' in error) return error.message ? error.message : message;

  if ('statusText' in error && error.statusText) {
    message = error.statusText;
  }

  // Можливо це об'єкт помилки від API
  let apiError: CoreApiClientResponse | CurtainsApiResponseData | CurtainsApiResponseError | null = null;

  if ('data' in error && error.data) {
    // CurtainsApiResponseData
    apiError = error.data;
  } else {
    apiError = error;
  }

  if ('error' in apiError && apiError.error) {
    apiError = apiError.error;
    if ('message' in apiError) {
      message = apiError.message;
    }
    // const httpStatus = apiError.statusCode;
    // const httpStatusTxt = apiError.name;

    if ('details' in apiError && Array.isArray(apiError.details) && apiError.details.length > 0) {
      // TODO: Враховувати більшу можливу кількість елементів деталізації
      const detailsDesc = apiError.details[0];

      if (detailsDesc.path && detailsDesc.message) {
        const firstMeaningfulChar = detailsDesc.path.startsWith('/') ? 1 : 0;
        const errorDetailsPath =
          detailsDesc.path.charAt(firstMeaningfulChar).toUpperCase() + detailsDesc.path.slice(firstMeaningfulChar + 1);
        message = `${errorDetailsPath} ${detailsDesc.message}`;
      }
    }
  }

  return message;
}
