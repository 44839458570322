import React from 'react';
import type { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { PathAndOptionalChildrenProps } from 'types/generic';
import useAuth from 'hooks/useAuth';
import { loadState } from 'utils/localStorage';
import { canUserAccessTool } from 'domain/RBAC';

const RbacGuard: FC<PathAndOptionalChildrenProps> = ({ path, children }) => {
  const { isAuthenticated, user } = useAuth();
  const acctkn = loadState('acctkn');

  // Не автентифіковані та без слідів автентифікації - направити на стартову форму
  if (!isAuthenticated && !acctkn) return <Navigate to="/" />;
  // Не автентифіковані але з наявним збереженим токеном автентифікації - замість внутрішнього змісту вивести пусту підкладку під модальну форму
  if (!isAuthenticated && acctkn) return <div></div>;

  if (isAuthenticated && !canUserAccessTool(user, path)) {
    return <Navigate to="/404" />;
  }

  return <>{children}</>;
};

export default RbacGuard;
