/* eslint class-methods-use-this: ["error", { "exceptMethods": ["hydrate"] }] */
import type { ProductStructure } from 'types/ProductStructure';
import Product from './Product';
import type { ProductPrototype } from './ProductPrototype';

/**
 * Клас довільного продукту, тобто такого що не має чітко визначеного типу, заздалегідь відомого набору складових і методу обчислення.
 */
export default class FreeformProduct extends Product implements ProductPrototype {
  constructor(baseProduct?: FreeformProduct | Product | ProductStructure) {
    super(baseProduct);
    this.calcMethod = 'freeform';
  }

  clone(): ProductPrototype {
    const clone = new FreeformProduct(this);
    // Ідентифікатор клону не має збігатись з його прототипом!
    clone.id = undefined;
    return clone;
  }

  hydrate(structure: ProductStructure): ProductPrototype {
    const hydrated = new FreeformProduct(structure);
    hydrated.calcMethod = 'freeform';
    return hydrated;
  }
}
