import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getResidentialComplexSelectorData } from 'api/residentialComplexesEndpoints';
import { get } from 'lodash';

import { RootState } from 'store';
import { ResidentialComplexSelector } from 'types/ResidentialComplexSelector';
import { loadState } from 'utils/localStorage';
import { PayOffice } from 'types/PayOffice';
import { getPayOfficesOfCompany } from 'api/companyEndpoints';

const persistedRegistries = loadState('registries');
const persistedResidenceSelector = get<ResidentialComplexSelector | null>(
  persistedRegistries,
  'residenceSelector',
  null,
);
const persistedPayOffices = get<PayOffice[] | null>(persistedRegistries, 'payOffices', null);

type FetchingStatus = 'idle' | 'loading' | 'fulfilled' | 'rejected';

interface Registries {
  residenceSelector: ResidentialComplexSelector | null;
  loadingResidenceSelector: FetchingStatus;
  payOffices: PayOffice[] | null;
  loadingPayOffices: FetchingStatus;
}

const initialState: Registries = {
  residenceSelector: persistedResidenceSelector,
  loadingResidenceSelector: 'idle',
  payOffices: persistedPayOffices,
  loadingPayOffices: 'idle',
};

export const fetchResidenceSelector = createAsyncThunk(
  'fetchResidenceSelector',
  async () => getResidentialComplexSelectorData(),
  {
    condition: (_, { getState }) => {
      const { registries } = getState() as RootState;
      if (registries) {
        const fetchStatus = (registries as Registries).loadingResidenceSelector;
        if (fetchStatus === 'fulfilled' || fetchStatus === 'loading') {
          // Вже завантажено або в процесі, повторний запит не потрібен
          return false;
        }
      }
      return undefined;
    },
  },
);

export const fetchPayOffices = createAsyncThunk<PayOffice[], { companyId: string }>(
  'fetchPayOffices',
  async thunkArgs => getPayOfficesOfCompany(thunkArgs.companyId),
  {
    condition: (_, { getState }) => {
      const { registries } = getState() as RootState;
      if (registries) {
        const fetchStatus = (registries as Registries).loadingPayOffices;
        if (fetchStatus === 'fulfilled' || fetchStatus === 'loading') {
          // Вже завантажено або в процесі, повторний запит не потрібен
          return false;
        }
      }
      return undefined;
    },
  },
);

const slice = createSlice({
  name: 'registries',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchResidenceSelector.pending, state => {
        state.loadingResidenceSelector = 'loading';
      })
      .addCase(fetchResidenceSelector.fulfilled, (state, { payload }) => {
        state.residenceSelector = payload;
        state.loadingResidenceSelector = 'fulfilled';
      })
      .addCase(fetchResidenceSelector.rejected, state => {
        state.loadingResidenceSelector = 'rejected';
      })
      .addCase(fetchPayOffices.pending, state => {
        state.loadingPayOffices = 'loading';
      })
      .addCase(fetchPayOffices.fulfilled, (state, { payload }) => {
        state.payOffices = payload;
        state.loadingPayOffices = 'fulfilled';
      })
      .addCase(fetchPayOffices.rejected, state => {
        state.loadingPayOffices = 'rejected';
      });
  },
});

const selectSelf = (state: RootState): Registries => state.registries;

export const getResidenceSelector = createSelector(selectSelf, state => state.residenceSelector);
export const getPayOffices = createSelector(selectSelf, state => state.payOffices);

export const { reducer } = slice;
