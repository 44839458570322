import React from 'react';
import type { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/uk';
import 'moment/locale/ru';
import 'moment/locale/pl';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import { IntlProvider } from 'react-intl';
import { HelmetProvider } from 'react-helmet-async';

import { SessionAuthProvider } from 'contexts/SessionAuthProvider';
import ScrollReset from 'components/ScrollReset';
// import SettingsNotification from 'components/notifications/SettingsNotification';
import UpdateNotification from 'components/notifications/UpdateNotification';
import RenderRoutes from 'routes/renderRoutes';
import uk from 'locales/uk';
import ru from 'locales/ru';
import pl from 'locales/pl';
import { useSelector } from 'store/hooks';
import { getLanguage } from 'slices/settings';
import { DEFAULT_LOCALE } from 'constants/strings';
import { Locale } from 'locales';
import GlobalStyles from 'components/GlobalStyles';
import ModalRoot from 'components/ModalRoot';
import { getModalType } from 'slices/modal';
import { Theme } from 'theme';

// Обрати локалізацію за умовчанням екземпляру модуля роботи з часом
moment.locale('uk');

const useStyles = makeStyles((theme: Theme) => ({
  snackbarProviderRoot: {
    maxWidth: '50%',
    fontFamily: theme.typography.fontFamily,
  },
}));

const App: FC = (): JSX.Element => {
  // TODO use navigator language for more flexibility
  const language: Locale = useSelector(state => getLanguage(state));
  const modalType = useSelector(state => getModalType(state));
  const classes = useStyles();
  const intlMessages = {
    uk,
    ru,
    pl,
  };

  return (
    <HelmetProvider>
      <IntlProvider messages={intlMessages[language]} locale={language} defaultLocale={DEFAULT_LOCALE}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={language}>
          <SnackbarProvider dense maxSnack={3} classes={{ root: classes.snackbarProviderRoot }}>
            <Router>
              <SessionAuthProvider>
                <GlobalStyles />
                <ScrollReset />
                {/* Модальне вікно щодо встановлених початкових налаштувань. Вимкнене оскільки на даний момент не має корисного змісту. */}
                {/* <SettingsNotification /> */}
                <UpdateNotification />
                <RenderRoutes />
                {modalType && <ModalRoot />}
              </SessionAuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </IntlProvider>
    </HelmetProvider>
  );
};

export default App;
