import React from 'react';

// import EditConsultProductModal from 'views/consultant/Step2/EditConsultProductModal';
import PromptDialog from 'components/shared/PromptDialog';
import LoginForm from './LoginForm';
// import TextileSelectorModal from '../views/ProjectEditor/OrderProductEditor/SelectMaterialModals/TextileSelectorModal';

const MODAL_COMPONENTS: Record<string, React.ElementType> = {
  LOGIN_FORM_MODAL: LoginForm,
  PROMPT_DIALOG: PromptDialog,
  // Використання наступного модального компонента через ModalRoot неможливо, оскільки він покладається на наявність productCalc у контексті
  // EDIT_CONSULT_PRODUCT_MODAL: EditConsultProductModal,
  // Використання наступного модального компонента через ModalRoot неможливо, оскільки він містить callback property
  // SELECT_TEXTILE_MODAL: TextileSelectorModal,
};

export default MODAL_COMPONENTS;
