import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';

interface Modal {
  modalType: any | null;
  modalProps?: any | null;
}

const initialState: Modal = {
  modalType: null,
  modalProps: null,
};

const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state, { payload }: PayloadAction<Modal>) => {
      state.modalType = payload.modalType;
      state.modalProps = payload.modalProps;
    },
    hideModal: state => {
      state.modalType = null;
      state.modalProps = null;
    },
  },
});

const selectSelf = (state: RootState): Modal => state.modal;

export const getModalType = createSelector(selectSelf, state => state.modalType);
export const getModalProps = createSelector(selectSelf, state => state.modalProps);

export const {
  reducer,
  actions: { showModal, hideModal },
} = slice;
