import type { ProjectParticipant } from 'types/ProjectParticipant';
import type { User } from 'types/user';
import type { ProjectStatus } from './project/status';
import {
  ROLE_CAN_ACCESS_TOOL_BY_ROUTE,
  ROLE_CAN_EDIT_PROJECT_IN_STATUS,
  ROLE_CAN_REGISTER_PAYMENT_FOR_PROJECT_IN_STATUS,
  ROLE_CAN_SEE_DRAFT_PRODUCTS,
  ROLE_CAN_NOT_EXPORT_MATERIALS,
  ROLE_CAN_MODIFY_MATERIALS,
  ROLE_CAN_VIEW_RESTRICTED_MATERIAL_FIELDS,
  ROLE_CAN_SEE_CLIENT_DATA,
  ROLE_CAN_SEE_PRODUCT_MATERIALS_PRICES,
} from './RBACSettings';

export const canUserAccessTool = (user: User | null, toolRoute: string): boolean => {
  if (
    !user ||
    !Array.isArray(user.roles) ||
    user.roles.length === 0 ||
    typeof toolRoute !== 'string' ||
    toolRoute.trim().length === 0 ||
    toolRoute === '/' ||
    toolRoute === '*'
  )
    return false;

  if (toolRoute in ROLE_CAN_ACCESS_TOOL_BY_ROUTE && Array.isArray(ROLE_CAN_ACCESS_TOOL_BY_ROUTE[toolRoute])) {
    // Наданий повний шлях до інструменту має власне правило доступу
    return user.roles.some(role => ROLE_CAN_ACCESS_TOOL_BY_ROUTE[toolRoute].includes(role));
  }
  // Перевірити правило за базовою текою шляху
  const pathParts = toolRoute.slice(1).split('/');
  if (
    pathParts.length > 0 &&
    pathParts[0] in ROLE_CAN_ACCESS_TOOL_BY_ROUTE &&
    Array.isArray(ROLE_CAN_ACCESS_TOOL_BY_ROUTE[pathParts[0]])
  ) {
    return user.roles.some(role => ROLE_CAN_ACCESS_TOOL_BY_ROUTE[pathParts[0]].includes(role));
  }

  return false;
};

export const canUserEditProject = (
  user: User | null,
  projectParticipants: ProjectParticipant[] | undefined,
  projectStatus: ProjectStatus | undefined,
): boolean => {
  if (!user || !Array.isArray(user.roles) || user.roles.length === 0 || typeof projectStatus !== 'string') return false;
  // Адміністратори та користувачі ролі «Підтримка» мають повний доступ до всіх дій
  if (user.roles.includes('admin') || user.roles.includes('support')) return true;
  // Для інших ролей вони повинні бути учасниками проєкту
  if (
    !Array.isArray(projectParticipants) ||
    projectParticipants.length === 0 ||
    !projectParticipants.some(participant => participant.userId === user.id)
  )
    return false;
  // та мати дозвіл згідно таблиці дозволів за ролями
  return user.roles.some(
    role =>
      ROLE_CAN_EDIT_PROJECT_IN_STATUS.all.includes(role) ||
      ROLE_CAN_EDIT_PROJECT_IN_STATUS[projectStatus].includes(role),
  );
};

export const canParticipantEditProject = (
  participantPositions: string[] | undefined,
  projectStatus: ProjectStatus | undefined,
): boolean => {
  if (Array.isArray(participantPositions) && participantPositions.length > 0 && typeof projectStatus === 'string') {
    return participantPositions.some(
      role =>
        ROLE_CAN_EDIT_PROJECT_IN_STATUS.all.includes(role) ||
        ROLE_CAN_EDIT_PROJECT_IN_STATUS[projectStatus].includes(role),
    );
  }
  return false;
};

export const canUserRegisterProjectPayment = (
  user: User | null,
  projectParticipants: ProjectParticipant[] | undefined,
  projectStatus: ProjectStatus | undefined,
): boolean => {
  if (!user || !Array.isArray(user.roles) || user.roles.length === 0 || typeof projectStatus !== 'string') return false;
  if (
    // Адміністратори та користувачі ролей «Підтримка» та «Консультант» мають дозвіл на роботу з платежами по всім замовленням
    !user.roles.includes('admin') &&
    !user.roles.includes('support') &&
    !user.roles.includes('consultant') &&
    // Для інших ролей вони повинні бути учасниками проєкту
    (!Array.isArray(projectParticipants) ||
      projectParticipants.length === 0 ||
      !projectParticipants.some(participant => participant.userId === user.id))
  )
    return false;

  // дозвіл згідно таблиці дозволів за ролями
  return user.roles.some(
    role =>
      ROLE_CAN_REGISTER_PAYMENT_FOR_PROJECT_IN_STATUS.all.includes(role) ||
      ROLE_CAN_REGISTER_PAYMENT_FOR_PROJECT_IN_STATUS[projectStatus].includes(role),
  );
};

export const canParticipantRegisterProjectPayment = (
  participantPositions: string[] | undefined,
  projectStatus: ProjectStatus | undefined,
): boolean => {
  if (Array.isArray(participantPositions) && participantPositions.length > 0 && typeof projectStatus === 'string') {
    return participantPositions.some(
      role =>
        ROLE_CAN_REGISTER_PAYMENT_FOR_PROJECT_IN_STATUS.all.includes(role) ||
        ROLE_CAN_REGISTER_PAYMENT_FOR_PROJECT_IN_STATUS[projectStatus].includes(role),
    );
  }
  return false;
};

/**
 * Перевірка чи даний користувач має право переглядати чернетки виробів у проєкті замовлення
 * @param user поточний користувач
 * @param projectParticipants перелік учасників проєкту
 * @param _projectStatus поточний статус проєкту (не береться до уваги)
 */
export const canUserSeeDraftProducts = (
  user: User | null,
  projectParticipants: ProjectParticipant[] | undefined,
  _projectStatus: ProjectStatus | undefined,
): boolean => {
  if (!user || !Array.isArray(user.roles) || user.roles.length === 0 || typeof _projectStatus !== 'string')
    return false;
  // Адміністратори, користувачі ролей «Підтримка» та «Консультант» мають повний доступ до перегляду чернеток виробів у всіх проєктах
  if (user.roles.includes('admin') || user.roles.includes('support') || user.roles.includes('consultant')) return true;
  // Для інших ролей вони повинні бути учасниками проєкту
  if (
    !Array.isArray(projectParticipants) ||
    projectParticipants.length === 0 ||
    !projectParticipants.some(participant => participant.userId === user.id)
  )
    return false;
  // та мати дозвіл згідно таблиці дозволів за ролями
  return user.roles.some(role => ROLE_CAN_SEE_DRAFT_PRODUCTS.includes(role));
};

/**
 * Перевірка чи даний користувач має право переглядати дані клієнта у проєкті замовлення
 * @param user поточний користувач
 * @param projectParticipants перелік учасників проєкту
 * @param _projectStatus поточний статус проєкту (не береться до уваги)
 */
export const canUserSeeClientData = (
  user: User | null,
  projectParticipants: ProjectParticipant[] | undefined,
  _projectStatus: ProjectStatus | undefined,
): boolean => {
  if (!user || !Array.isArray(user.roles) || user.roles.length === 0 || typeof _projectStatus !== 'string')
    return false;
  // Адміністратори, користувачі ролей «Підтримка», «Директор», «Бухгалтер» та «Консультант»  мають повний доступ до перегляду даних клієнтів у всіх проєктах
  if (
    user.roles.includes('admin') ||
    user.roles.includes('support') ||
    user.roles.includes('director') ||
    user.roles.includes('accountant') ||
    user.roles.includes('consultant')
  )
    return true;
  // Для інших ролей вони повинні бути учасниками проєкту
  if (
    !Array.isArray(projectParticipants) ||
    projectParticipants.length === 0 ||
    !projectParticipants.some(participant => participant.userId === user.id)
  )
    return false;
  // та мати дозвіл згідно таблиці дозволів за ролями
  return user.roles.some(role => ROLE_CAN_SEE_CLIENT_DATA.includes(role));
};

/**
 * Перевірка чи даний користувач має право переглядати дані з цінами матеріалів виробів у проєкті замовлення
 * @param user поточний користувач
 * @param projectParticipants перелік учасників проєкту
 * @param _projectStatus поточний статус проєкту (не береться до уваги)
 */
export const canUserSeeProductMaterialsPrices = (
  user: User | null,
  projectParticipants: ProjectParticipant[] | undefined,
  _projectStatus: ProjectStatus | undefined,
): boolean => {
  if (!user || !Array.isArray(user.roles) || user.roles.length === 0 || typeof _projectStatus !== 'string')
    return false;
  // Адміністратори, користувачі ролей «Підтримка», «Директор», «Бухгалтер» та «Консультант»  мають повний доступ до перегляду цін у всіх проєктах
  if (
    user.roles.includes('admin') ||
    user.roles.includes('support') ||
    user.roles.includes('director') ||
    user.roles.includes('accountant') ||
    user.roles.includes('consultant')
  )
    return true;
  // Для інших ролей вони повинні бути учасниками проєкту
  if (
    !Array.isArray(projectParticipants) ||
    projectParticipants.length === 0 ||
    !projectParticipants.some(participant => participant.userId === user.id)
  )
    return false;
  // та мати дозвіл згідно таблиці дозволів за ролями
  return user.roles.some(role => ROLE_CAN_SEE_PRODUCT_MATERIALS_PRICES.includes(role));
};

/**
 * Перевірка чи даний користувач має право експортувати вибірку матеріалів у документи
 * @param user поточний користувач
 */
export const canUserExportMaterials = (user: User | null): boolean => {
  if (!user || !Array.isArray(user.roles) || user.roles.length === 0) return false;
  return !user.roles.some(role => ROLE_CAN_NOT_EXPORT_MATERIALS.includes(role));
};

/**
 * Перевірка чи даний користувач має право змінювати матеріали через імпорт або інструменти додавання/редагування
 * @param user поточний користувач
 */
export const canUserModifyMaterials = (user: User | null): boolean => {
  if (!user || !Array.isArray(user.roles) || user.roles.length === 0) return false;
  return user.roles.some(role => ROLE_CAN_MODIFY_MATERIALS.includes(role));
};

/**
 * Отримати перелік ключів полів матеріалів з обмеженим доступом, до яких даний користувач його має
 * @param user поточний користувач
 */
export const restrictedMaterialFieldsAccessedBy = (user: User | null): string[] => {
  if (!user || !Array.isArray(user.roles) || user.roles.length === 0) return [];

  const restrictedFields = new Set<string>();
  // eslint-disable-next-line no-restricted-syntax
  for (const role of user.roles) {
    if (
      role in ROLE_CAN_VIEW_RESTRICTED_MATERIAL_FIELDS &&
      Array.isArray(ROLE_CAN_VIEW_RESTRICTED_MATERIAL_FIELDS[role]) &&
      ROLE_CAN_VIEW_RESTRICTED_MATERIAL_FIELDS[role].length > 0
    ) {
      // eslint-disable-next-line no-restricted-syntax
      for (const fieldKey of ROLE_CAN_VIEW_RESTRICTED_MATERIAL_FIELDS[role]) restrictedFields.add(fieldKey);
    }
  }

  return Array.from(restrictedFields);
};

export default {
  canUserAccessTool,
  canUserEditProject,
  canUserSeeDraftProducts,
  canUserExportMaterials,
  canUserModifyMaterials,
  canParticipantEditProject,
  canParticipantRegisterProjectPayment,
  restrictedMaterialFieldsAccessedBy,
};
