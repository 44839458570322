import { ResidentialComplexSelector } from 'types/ResidentialComplexSelector';
import apiClient from './coreAPI';
import { ComplexError, errorParser } from './errorsParser';

export const getResidentialComplexSelectorData = async (): Promise<ResidentialComplexSelector> => {
  try {
    const { data } = await apiClient.get<ResidentialComplexSelector>('/residential-complexes/selector-data');
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export default { getResidentialComplexSelectorData };
