import { configureStore } from '@reduxjs/toolkit';
import apiClient from 'api/coreAPI';
import axiosMiddleware from 'redux-axios-middleware';
import { throttle } from 'lodash';

import { saveState } from 'utils/localStorage';
import { LOCAL_STORAGE_THROTTLE } from 'constants/state';
import rootReducer from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(axiosMiddleware(apiClient)),
  devTools: process.env.NODE_ENV !== 'production',
});

store.subscribe(
  throttle(() => {
    const state = store.getState();
    saveState(
      {
        filters: state.consultant.filters,
        steps: state.consultant.steps,
        settings: state.settings,
        activeStep: state.consultant.activeStep,
        product: state.consultant.product,
        productCard: state.consultant.productCard,
      },
      'consultant_tool',
    );
    saveState(state.designer, 'create_order_tool');
    saveState(state.projects, 'projects_tool');
    saveState(state.suppliers, 'suppliers_tool');
    saveState(
      { filters: state.representative.filters, awaitedDocuments: state.representative.awaitedDocuments },
      'materials_tool',
    );
  }, LOCAL_STORAGE_THROTTLE),
);

store.subscribe(
  throttle(() => {
    const state = store.getState();
    saveState(
      {
        residenceSelector: state.registries.residenceSelector,
      },
      'registries',
    );
  }, LOCAL_STORAGE_THROTTLE * 20),
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
