export const CITIES = [
  // Населені пункти в яких є салони
  'Київ',
  'Одеса',
  'Харків',
  'Львів',
  'Дніпро',
  'Івано-Франківськ',
  'Хмельницький',
  'Ужгород',
  'Бровари',
  'Ірпінь',
  'Бориспіль',
  'Крюківщина',
  'Святопетрівське',
  'Софіївська Борщагівка',
  // Інші населені пункти (регіони) з яких часто приходять замовлення
  'Петропавлівська Борщагівка',
  'Буча',
  'Обухів',
  'Вишгород',
  'Вишневе',
  'Боярка',
  'Гатне',
  'Тарасівка',
  'Білогородка',
  'Українка',
  'Васильків',
  'Черкаси',
  'Полтава',
  'Рівне',
  'Вінниця',
  'Мукачево',
  'Запоріжжя',
  'Славутич',
  'Кривий Ріг',
  'Миколаїв',
  'Житомир',
  'Чернігів',
  'Херсон',
  'Луцьк',
  'Суми',
  'Краматорськ',
];
export const DEFAULT_ACTIVE_STEP = 0;
export const DEFAULT_PRODUCT_CARD = 0;
export const LOCAL_STORAGE_THROTTLE = 2500;
